const calcPercent = 100;

export const getOverAllPl = (
  balance: number,
  challengeAmount: number,
  pl: number
) => {
  const closePl = balance - challengeAmount;
  return closePl + pl;
};

export const getGoalReached = (
  profitTarget: number,
  pl: number,
  goal: number
) => {
  const amount = Number(goal) * profitTarget;
  if (!amount) {
    return 0;
  }
  const result = ((pl * calcPercent) / amount) * calcPercent;
  return result <= 0 ? 0 : result >= calcPercent ? calcPercent : result;
};
