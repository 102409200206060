import { getIconBaseProps } from './utils';

export const markets77Logo = {
  ...getIconBaseProps('markets77Logo'),
  width: '154px',
  height: '35px',
  viewBox: '0 0 154 35',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <g clipPath='url(#clip0_1977_14396)'>
        <path
          d='M23.4097 0.139893H10.7497C7.89853 0.140404 5.1643 1.27345 3.1484 3.28982C1.13249 5.3062 -4.59168e-08 8.04077 0 10.8921V24.0989C-4.59168e-08 26.9502 1.13249 29.6848 3.1484 31.7012C5.1643 33.7175 7.89853 34.8506 10.7497 34.8511H32.5154C32.9499 34.8511 33.3666 34.6785 33.6738 34.3712C33.981 34.064 34.1536 33.6473 34.1536 33.2128V10.8921C34.1536 8.04178 33.0219 5.30808 31.0073 3.29187C28.9926 1.27566 26.2599 0.141939 23.4097 0.139893ZM16.8858 11.7064L11.0198 24.8109C10.9971 24.862 10.96 24.9055 10.9131 24.9359C10.8662 24.9664 10.8114 24.9827 10.7555 24.9827H7.66816C7.22822 24.9827 7.02754 24.7029 7.22822 24.2822L11.4251 14.8267C11.4442 14.7826 11.452 14.7345 11.448 14.6866C11.444 14.6387 11.4282 14.5926 11.4021 14.5523C11.3759 14.512 11.3402 14.4787 11.2981 14.4556C11.2559 14.4324 11.2088 14.4201 11.1607 14.4196H6.23255C6.1302 14.4196 6.03204 14.3789 5.95967 14.3065C5.88729 14.2342 5.84664 14.136 5.84664 14.0336V11.5829C5.83664 11.5017 5.84528 11.4193 5.8719 11.342C5.89853 11.2646 5.94243 11.1943 6.00028 11.1365C6.05813 11.0786 6.12841 11.0347 6.20577 11.0081C6.28313 10.9814 6.36554 10.9728 6.44674 10.9828H16.4806C16.9591 10.9828 17.0594 11.2838 16.8858 11.7064ZM29.1116 11.7064L23.2457 24.8109C23.2229 24.862 23.1859 24.9055 23.1389 24.9359C23.092 24.9664 23.0373 24.9827 22.9813 24.9827H19.894C19.4541 24.9827 19.2553 24.7029 19.4541 24.2822L23.6586 14.8267C23.6782 14.7827 23.6865 14.7344 23.6827 14.6863C23.6789 14.6382 23.6632 14.5919 23.6369 14.5514C23.6107 14.5109 23.5747 14.4777 23.5323 14.4547C23.49 14.4316 23.4425 14.4196 23.3943 14.4196H18.4584C18.356 14.4196 18.2579 14.3789 18.1855 14.3065C18.1131 14.2342 18.0725 14.136 18.0725 14.0336V11.5829C18.0629 11.5018 18.0718 11.4196 18.0985 11.3424C18.1253 11.2652 18.1692 11.1951 18.227 11.1373C18.2847 11.0795 18.3548 11.0356 18.432 11.0089C18.5092 10.9821 18.5914 10.9732 18.6726 10.9828H28.7064C29.1869 10.9828 29.2872 11.2838 29.1058 11.7064H29.1116Z'
          fill='#B8DD28'
        />
        <path
          d='M42.3408 14.4948V15.7587C42.9737 14.6472 43.8247 14.1436 45.1117 14.1436C46.3987 14.1436 47.379 14.734 47.8594 15.8243C48.5367 14.6665 49.4745 14.1436 50.914 14.1436C51.9386 14.1436 52.7915 14.4716 53.3568 15.0833C53.9685 15.7587 54.1653 16.4572 54.1653 17.9778V24.5967H51.3944V18.5085C51.3944 17.1346 50.8927 16.4823 49.8508 16.4823C48.8088 16.4823 48.2376 17.2214 48.2376 18.5085V24.5967H45.461V18.5973C45.461 17.2002 44.9361 16.4746 43.9327 16.4746C42.8869 16.4746 42.3408 17.1925 42.3408 18.5973V24.5967H39.5699V14.4948H42.3408Z'
          fill='currentColor'
        />
        <path
          d='M64.5812 14.4948H67.352V24.5967H64.5812V23.1996C63.6434 24.3999 62.5532 24.946 61.0693 24.946C58.2348 24.946 56.2048 22.7423 56.2048 19.6683C56.2048 16.4824 58.3428 14.1455 61.2893 14.1455C62.6631 14.1455 63.6048 14.626 64.5812 15.7819V14.4948ZM59.1783 19.6239C59.1783 21.3895 60.3361 22.7114 61.8411 22.7114C63.3848 22.7114 64.6333 21.2718 64.6333 19.5293C64.6333 17.7386 63.4987 16.4746 61.8624 16.4746C60.2859 16.4824 59.1706 17.7695 59.1706 19.6181L59.1783 19.6239Z'
          fill='currentColor'
        />
        <path
          d='M72.8436 14.4948V15.9788C73.1273 15.2378 73.3241 14.9329 73.737 14.628C74.2218 14.3071 74.792 14.1397 75.3733 14.1475C75.4831 14.1464 75.5928 14.1541 75.7013 14.1706V17.0053C75.4833 16.9841 75.2633 16.9629 75.1109 16.9629C74.7512 16.9557 74.3951 17.0353 74.0727 17.1948C73.7503 17.3543 73.471 17.5892 73.2585 17.8795C72.9305 18.3368 72.8436 18.7729 72.8436 19.732V24.5967H70.0728V14.4948H72.8436Z'
          fill='currentColor'
        />
        <path
          d='M79.9716 24.5967H77.2007V10.0449H79.9716V18.3773L82.7849 14.4948H85.9938L82.5669 19.3402L85.9938 24.5967H82.7849L79.9716 20.4073V24.5967Z'
          fill='currentColor'
        />
        <path
          d='M89.3687 20.2106C89.6079 21.6077 90.6345 22.4818 92.0103 22.4818C92.4817 22.4961 92.9481 22.3815 93.3592 22.1502C93.7703 21.919 94.1105 21.58 94.3431 21.1696L96.894 21.8701C95.8926 23.8345 94.1251 24.946 91.9871 24.946C88.7801 24.946 86.4878 22.6767 86.4878 19.5429C86.4878 16.4091 88.6933 14.1514 91.8134 14.1514C95.0397 14.1514 97.2877 16.4226 97.2877 19.6741L97.2664 20.2164L89.3687 20.2106ZM94.43 18.6186C94.1907 17.2871 93.2317 16.4824 91.8559 16.4824C90.5264 16.4824 89.6311 17.2447 89.3687 18.6186H94.43Z'
          fill='currentColor'
        />
        <path
          d='M102.146 21.6288C102.146 22.1093 102.322 22.2405 102.93 22.2849H103.412V24.6005H101.209C99.8578 24.5561 99.3542 24.01 99.3754 22.6708V16.7872H98.2177V14.4523H99.3754V11.7507H102.146V14.4523H103.412V16.7872H102.146V21.6288Z'
          fill='currentColor'
        />
        <path
          d='M109.865 17.4183V17.2446C109.865 16.6348 109.319 16.1968 108.578 16.1968C107.9 16.1968 107.466 16.5692 107.466 17.1134C107.463 17.2367 107.486 17.3593 107.535 17.4726C107.583 17.5859 107.656 17.6872 107.748 17.7695C108.053 18.0088 108.076 18.0088 109.471 18.443C111.742 19.1434 112.636 20.0369 112.636 21.6733C112.636 23.6377 111.065 24.9537 108.709 24.9537C106.308 24.9537 104.85 23.7322 104.74 21.6366H107.401C107.532 22.5107 107.922 22.9025 108.73 22.9025C109.384 22.9025 109.843 22.5165 109.843 21.9376C109.843 21.3259 109.458 21.0442 107.966 20.5868C105.786 19.9308 104.805 18.9505 104.805 17.3566C104.805 15.4384 106.349 14.1494 108.709 14.1494C111.024 14.1494 112.418 15.3728 112.481 17.4299L109.865 17.4183Z'
          fill='currentColor'
        />
        <path
          d='M116.558 23.8345C116.554 24.0105 116.498 24.1813 116.396 24.3254C116.295 24.4696 116.154 24.5807 115.99 24.645C115.826 24.7092 115.647 24.7237 115.475 24.6866C115.303 24.6494 115.145 24.5624 115.022 24.4363C114.899 24.3102 114.816 24.1507 114.784 23.9776C114.751 23.8046 114.77 23.6258 114.838 23.4635C114.907 23.3013 115.021 23.1628 115.168 23.0654C115.315 22.968 115.487 22.916 115.663 22.9159C115.783 22.9137 115.903 22.9361 116.014 22.9818C116.125 23.0275 116.226 23.0954 116.309 23.1815C116.393 23.2676 116.459 23.3699 116.502 23.4822C116.544 23.5945 116.564 23.7144 116.558 23.8345Z'
          fill='currentColor'
        />
        <path
          d='M127.244 23.2845C126.175 24.3304 125.108 24.7665 123.667 24.7665C120.875 24.7665 118.934 22.6284 118.934 19.5756C118.934 16.5229 120.94 14.3829 123.777 14.3829C124.423 14.3639 125.065 14.4803 125.664 14.7245C126.262 14.9688 126.802 15.3355 127.25 15.8012L126.575 16.5441C125.827 15.8192 124.826 15.4137 123.785 15.4133C121.579 15.4133 120.078 17.0902 120.078 19.537C120.078 22.0456 121.537 23.7457 123.698 23.7457C124.236 23.7551 124.771 23.6501 125.266 23.4375C125.761 23.2249 126.205 22.9097 126.569 22.5126L127.244 23.2845Z'
          fill='currentColor'
        />
        <path
          d='M137.122 16.1543C137.894 17.0902 138.233 18.1612 138.233 19.5795C138.233 22.503 136.053 24.7704 133.261 24.7704C132.54 24.7713 131.828 24.6166 131.173 24.3169C130.518 24.0171 129.936 23.5794 129.465 23.0337C128.767 22.2058 128.373 20.9399 128.373 19.5448C128.373 17.9084 128.854 16.6638 129.834 15.7472C130.783 14.8685 132.033 14.3853 133.326 14.3964C134.05 14.3873 134.767 14.5404 135.424 14.8447C136.08 15.1489 136.661 15.5964 137.122 16.1543ZM129.51 19.6027C129.51 22.0669 131.034 23.7457 133.303 23.7457C135.507 23.7457 137.099 22.009 137.099 19.5795C137.099 18.2287 136.728 17.2214 135.966 16.4824C135.251 15.8078 134.309 15.4262 133.326 15.4133C131.034 15.4133 129.51 17.0902 129.51 19.6027Z'
          fill='currentColor'
        />
        <path
          d='M142.048 14.5603V16.023C142.876 14.8652 143.727 14.3866 144.927 14.3866C145.611 14.3677 146.281 14.5836 146.826 14.9984C147.173 15.282 147.37 15.5773 147.632 16.2855C148.395 14.9347 149.334 14.3866 150.882 14.3866C152.082 14.3866 153.004 14.7726 153.5 15.5001C153.913 16.1311 154 16.6135 154 18.2499V24.5966H152.91V18.5741C152.91 16.9184 152.91 16.8528 152.605 16.3511C152.279 15.7606 151.536 15.4132 150.598 15.4132C148.941 15.4132 148.024 16.5247 148.024 18.5741V24.5966H146.94V18.5741C146.94 17.2001 146.812 16.5691 146.419 16.0886C146.025 15.6081 145.479 15.4132 144.738 15.4132C143.081 15.4132 142.056 16.6347 142.056 18.5741V24.5966H140.958L140.979 14.5622L142.048 14.5603Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1977_14396'>
          <rect
            width='154'
            height='34.7093'
            fill='white'
            transform='translate(0 0.139893)'
          />
        </clipPath>
      </defs>
    </>
  ),
};
