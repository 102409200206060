import { useEffect } from 'react';

import { calcAllPositionsPl } from '@trader/utils';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { maxAmountPositionsToPersist } from '@trader/constants';

export const usePositionMetricsPl = () => {
  const store = useMst();

  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
  const pl = calcAllPositionsPl(positions);

  /**
   * Handle the case when the positions weren't
   * initially fetched to calculate the portfolio details.
   */
  // TODO: (av) Call positions request in the prefetch information action.
  useEffect(() => {
    const isMaxAmountOfPositionsPersisted =
      positions.length === maxAmountPositionsToPersist;
    const isPositionsTabSelected =
      store.pages.trading.assetsView === 'positions';
    const isPositionsFetching =
      store.entities.positionsMetrics.getPositionsMetricsAsync.inProgress;

    if (
      isMaxAmountOfPositionsPersisted &&
      !isPositionsTabSelected &&
      !isPositionsFetching
    ) {
      store.entities.positionsMetrics.getPositionsMetricsAsync.run();
    }
  }, []);

  return { positionsPl: pl };
};
