import React from 'react';
import { Title } from './styled';

interface IEmptyData {
  title: string;
}

export const EmptyData: React.FC<IEmptyData> = ({ title }) => {
  return <Title>{title}</Title>;
};
