import { VariantType } from 'notistack';
import { analyzeErrorType, EErrorTypes } from '@trader/services';
import { errorStatuses, IError, responseStatusCodes } from './requestErrorType';

export const handleErrors = (
  errors: Array<IError>,
  errorStatus: keyof typeof errorStatuses,
  responseStatusCode: keyof typeof responseStatusCodes,
  onCreateNotification: (message: string, variant?: VariantType) => void
) => {
  errors.length &&
    errors.forEach(error => {
      const errorType = analyzeErrorType(error);
      const isHideNotifications =
        errorType === EErrorTypes.REFRESH_TOKEN ||
        errorType === EErrorTypes.INSUFFICIENT_FUNDS ||
        errorType === EErrorTypes.SESSION_EXPIRED;

      if (!isHideNotifications) {
        onCreateNotification(error.message, 'error');
      }
    });
};
