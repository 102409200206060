import styled, { css } from 'styled-components';

import { EChallenge } from '@trader/types';
import { conditionApply } from '@trader/utils';
import { Wrapper, Button } from '@trader/components';
import { hasCopierFunctionality } from '@trader/constants';

export const Item = styled(Button)<{
  $isActive: boolean;
  $challengeType?: EChallenge;
}>`
  ${({ theme, $isActive, $challengeType }) => css`
    border: 1px solid
      ${$isActive ? theme.palette.green[400] : theme.palette.gray.main};
    background: ${$isActive ? theme.palette.green[300] : 'transparent'};
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 2px 11px;
    min-height: 45px;
    width: 100%;
    justify-content: space-between;

    color: ${$isActive
      ? theme.palette.primary.main
      : theme.palette.common.black};

    svg {
      color: ${theme.palette.primary.main};
    }

    & > span {
      width: 100%;
    }

    ${conditionApply(
      [$challengeType === EChallenge.Slave && hasCopierFunctionality],
      css`
        border: none;
        border-top: 1px solid ${theme.palette.gray.main};
        border-radius: 0;
        margin-bottom: 0;
        margin-top: 2px;
        padding: 2px 0 0 0;
      `
    )}
  `}
`;

export const Slaves = styled(Wrapper)`
  ${() => css`
    flex-direction: column;

    button {
      &:hover {
        background: transparent;
      }
    }
  `}
`;
