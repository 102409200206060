import { IChartWidgetProps } from '../types';

export const disabledFeatures: IChartWidgetProps['disabled_features'] = [
  'header_symbol_search',
  'header_compare',
  'object_tree_legend_mode',
  'go_to_date',
  'show_object_tree',
  'header_saveload',
  'header_undo_redo',
  'study_templates',
  'border_around_the_chart',
  'timezone_menu',
];

export const enabledFeatures: IChartWidgetProps['enabled_features'] = [
  'use_localstorage_for_settings',
  'save_chart_properties_to_local_storage',
  'hide_resolution_in_legend',
  'remove_library_container_border',
  'side_toolbar_in_fullscreen_mode',
  'header_in_fullscreen_mode',
  'snapshot_trading_drawings',
];
