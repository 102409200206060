import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 408px;
    height: 500px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
      height: 400px;
    }
  `}
`;

export const Position = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  `}
`;

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 0 ${modalHorizontalPadding}px;
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: 15px;
    font-weight: 500;
    min-width: max-content;
    color: ${theme.palette.gray.dark};
  `}
`;
