import i18next from 'i18next';
import { NavigateFunction } from 'react-router-dom';

import { returnTypedThis } from '@trader/utils';
import {
  defaultSubCategoryName,
  EAccountTypeNames,
  LOCAL_STORAGE_KEYS,
} from '@trader/constants';
import { devLoggerService, localStorageService } from '@trader/services';
import { api, IGetTradingAccountsParams } from '@trader/api';

import { createThunk } from '../../utils/asyncModel';
import { getRootInstance } from '../../configureStore/configureStore';
import { tradingAccountsSchema } from './schemas';
import { initialPortfolio } from './portfolio';
import { TTradingAccountEntity, TTradingAccountsEntity } from './index';

export const getTradingAccountsAsync = createThunk<
  IGetTradingAccountsParams,
  void
>(
  (params?: IGetTradingAccountsParams) =>
    async function getTradingAccounts(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const root = getRootInstance();
      const that = returnTypedThis<TTradingAccountsEntity>(this);

      const userId = root.auth.tokens.cognitoId;
      const tradingAccountsFromLocalStorage = localStorageService.get(
        LOCAL_STORAGE_KEYS.tradingAccounts
      );
      const currStorageAcc =
        tradingAccountsFromLocalStorage &&
        JSON.parse(tradingAccountsFromLocalStorage)[userId];

      const response = await api.User.getTradingAccounts(_options, params);

      // must extend response with challenge reference from persist to avoid UI bug
      (response as unknown as TTradingAccountEntity[]).forEach(account => {
        if (account.portfolio === null) {
          account.portfolio = initialPortfolio;
        }

        const existingAcc = that.get<TTradingAccountEntity>(account.tradingId);
        if (existingAcc) {
          account.challenge = existingAcc.challenge;
        }
      });

      const activeAcc = response?.find(a => a.isInUse);

      const isOneAccountMatched = response?.find(
        a => a.platformLogin === currStorageAcc?.platformLogin
      );

      if (currStorageAcc && isOneAccountMatched) {
        const result = response?.map(a => {
          a.isInUse = false;
          if (
            a.platformLogin === currStorageAcc?.platformLogin &&
            a.accountType === currStorageAcc?.accountType
          ) {
            a.isInUse = true;
          } else {
            // eslint-disable-next-line no-self-assign
            a.isInUse = a.isInUse;
          }
          return a;
        });
        root.entities.normalizeMerge(result, tradingAccountsSchema, true);
      } else {
        if (activeAcc) {
          if (tradingAccountsFromLocalStorage) {
            localStorageService.set(
              LOCAL_STORAGE_KEYS.tradingAccounts,
              JSON.stringify({
                ...JSON.parse(tradingAccountsFromLocalStorage),
                [userId]: {
                  platformLogin: activeAcc.platformLogin,
                  accountType: activeAcc.accountType,
                },
              })
            );
          } else {
            localStorageService.set(
              LOCAL_STORAGE_KEYS.tradingAccounts,
              JSON.stringify({
                [userId]: {
                  platformLogin: activeAcc.platformLogin,
                  accountType: activeAcc.accountType,
                },
              })
            );
          }
        }
        root.entities.normalizeMerge(response, tradingAccountsSchema, true);
      }
      root.user.runInAction(() => {
        root.user.tradingAccount = that
          .getAll<TTradingAccountEntity>()
          .find(acc => acc.isInUse);
      });
    }
);

interface IChangeTradingAccountAsync {
  navigation?: NavigateFunction;
  tradingId: number;
}

export const changeTradingAccountAsync = createThunk<
  IChangeTradingAccountAsync,
  void
>(
  ({ tradingId, navigation }) =>
    async function changeTradingAccount(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const root = getRootInstance();
      const that = returnTypedThis<TTradingAccountsEntity>(this);

      const activeAccountPrev = root.user.tradingAccount;
      const activeAccountNew = that.get<TTradingAccountEntity>(tradingId);

      if (!activeAccountPrev || !activeAccountNew) {
        return;
      }

      if (
        root.user.profile.scoreLevel === 'LOW' &&
        activeAccountNew?.accountType === EAccountTypeNames.Live
      ) {
        root.notifications.add({
          message: i18next.t('WARNINGS.NOT_ELIGIBLE_FOR_LIVE_ACCOUNT'),
          options: {
            autoHideDuration: 3000,
            variant: 'warning',
          },
        });
        return;
      }

      that.update(activeAccountPrev.tradingId, { isInUse: false });
      that.update(activeAccountNew.tradingId, { isInUse: true });
      root.user.runInAction(() => {
        root.user.tradingAccount = activeAccountNew;
      });

      root.pages.trading.layout.clearLayouts();
      root.pages.trading.runInAction(() => {
        root.pages.trading.selectedCategory = null;
        root.pages.trading.selectedSubCategory = defaultSubCategoryName;
        root.pages.trading.assetsView = 'positions';
        root.pages.trading.layoutView = 'chart';
      });

      localStorageService.remove(LOCAL_STORAGE_KEYS.persist);

      const tradingAccountsFromLocalStorage = localStorageService.get(
        LOCAL_STORAGE_KEYS.tradingAccounts
      );

      const userId = root.auth.tokens.cognitoId;

      if (tradingAccountsFromLocalStorage) {
        localStorageService.set(
          LOCAL_STORAGE_KEYS.tradingAccounts,
          JSON.stringify({
            ...JSON.parse(tradingAccountsFromLocalStorage),
            [userId]: {
              platformLogin: activeAccountNew?.platformLogin,
              accountType: activeAccountNew?.accountType,
            },
          })
        );
      } else {
        localStorageService.set(
          LOCAL_STORAGE_KEYS.tradingAccounts,
          JSON.stringify({
            [userId]: {
              platformLogin: activeAccountNew?.platformLogin,
              accountType: activeAccountNew?.accountType,
            },
          })
        );
      }

      try {
        const prefetch = root.pages.trading.getPrefetchInformationAsync.run({
          navigation,
          isFetchTradingAccount: false,
        });
        const positionsM =
          root.entities.positionsMetrics.getPositionsMetricsAsync.run();
        const ordersM = root.entities.ordersMetrics.getOrdersMetricsAsync.run({
          shouldClearBeforeMerge: true,
        });
        const alerts = root.entities.alerts.getAlertsAsync.run();
        const historyPositions =
          root.entities.positionsHistory.getPositionsHistoryAsync.run({
            shouldClearBeforeMerge: true,
            completedFrom: root.filters.positionsHistory.date.completedFrom,
            completedTo: root.filters.positionsHistory.date.completedTo,
            openedFrom: root.filters.positionsHistory.date.openedFrom,
            openedTo: root.filters.positionsHistory.date.openedTo,
          });
        await Promise.all([
          prefetch,
          positionsM,
          ordersM,
          alerts,
          historyPositions,
        ]);

        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.ACCOUNT_HAS_BEEN_CHANGED'),
          options: {
            variant: 'success',
            autoHideDuration: 1000,
          },
        });
      } catch (e) {
        devLoggerService.error('Error in changeTradingAccountAsync', e);
        root.notifications.add({
          message: i18next.t('WARNINGS.SWITCHING_ACCOUNT_FAILED'),
          options: {
            variant: 'error',
            autoHideDuration: 2000,
          },
        });
      }
    }
);
