import React from 'react';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MenuItem,
  Select as SelectCore,
  SelectProps,
  InputLabel,
} from '@mui/material';

import { IChangeFieldEvent, IOption, TOptions } from '@trader/types';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

type TRenderItem = (item: IOption) => React.ReactNode;

interface ISelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
  value: TOptions;
  onChange?: (event: IChangeFieldEvent<TOptions>) => void;
  options: TOptions;
  label?: string;
  renderItem?: TRenderItem;
}

export const Select: React.FC<ISelectProps> = observer(
  ({ value, multiple, label, onChange, options, renderItem, ...rest }) => {
    const { translate } = useI18next();
    const currentValue = multiple ? value.map(v => v.value) : value[0].value;
    const currentTitle = multiple ? value.map(v => v.title) : value[0].title;

    const onChangeOptions = (newValues: string | Array<string>) => {
      let changedOptions: Array<IOption> = [];

      if (typeof newValues === 'object') {
        newValues.forEach(newValue => {
          changedOptions = [
            ...changedOptions,
            ...options.filter(option => option.value === newValue),
          ];
        });
      } else {
        changedOptions = options.filter(option => option.value === newValues);
      }

      onChange?.({
        target: {
          name: rest.name || '',
          value: changedOptions,
        },
      });
    };

    return (
      <Styled.FormControl>
        {label && <InputLabel>{label}</InputLabel>}
        <SelectCore
          variant='outlined'
          label={label}
          // event type material ui issue https://github.com/mui/material-ui/issues/16065
          onChange={event =>
            onChangeOptions(event.target.value as string | Array<string>)
          }
          value={currentValue}
          color='primary'
          fullWidth
          multiple={multiple}
          renderValue={() => {
            if (typeof currentTitle === 'object') {
              return currentTitle.map((title, index) => (
                <span style={{ textTransform: 'capitalize' }} key={title}>
                  {title}
                  {index !== currentTitle.length - 1 ? ', ' : ''}
                </span>
              ));
            }
            return (
              <span style={{ textTransform: 'capitalize' }}>
                {currentTitle}
              </span>
            );
          }}
          IconComponent={ExpandMoreIcon}
          {...rest}
        >
          {!options.length && (
            <Styled.NoOptions>
              {translate('COMMON.LABELS.NO_OPTIONS')}
            </Styled.NoOptions>
          )}
          {options.length &&
            options.map(item => (
              <MenuItem
                style={{ textTransform: 'capitalize' }}
                key={item.value}
                value={item.value}
              >
                {renderItem ? renderItem(item) : item.title}
              </MenuItem>
            ))}
        </SelectCore>
      </Styled.FormControl>
    );
  }
);
