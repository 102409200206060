import { getIconBaseProps } from './utils';

export const closeArrows = {
  ...getIconBaseProps('closeArrows'),
  width: '11px',
  height: '7px',
  viewBox: '0 0 11 7',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M1.00082 4.00009L3.57227 6.25261L1.00082 10.50513'
        stroke='#212121'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.42856 1.00009L10 3.25261L7.42856 5.50513'
        stroke='#212121'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
