import { types } from 'mobx-state-tree';

const userActivityModel = types
  .model({
    secondsWithNoActivity: types.optional(types.number, 0),
  })
  .actions(self => ({
    resetSeconds() {
      self.secondsWithNoActivity = 0;
    },
    countUpSeconds() {
      self.secondsWithNoActivity += 1;
    },
    setSeconds(value: number) {
      self.secondsWithNoActivity = value;
    },
  }));

export const userActivity = types.optional(userActivityModel, {});
