import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  TInstrumentEntity,
  TOrderMetricEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  getBuyLossRateWithAmplitude,
  getDefaultPipsBaseOnTickSize,
  getLimitPrice,
  getLossPipsFromRate,
  getLossRateFromPips,
  getSellLossRateWithAmplitude,
  handleIndicesPipSize,
} from '@trader/utils';
import { defaultPipSize } from '@trader/constants';

import { useTriggerTradingFormFields } from './useTriggerTradingFormFields';
import { TCommonOrderType } from '@trader/types';

export const useUpdateStopLossRealTime = (type: TCommonOrderType) => {
  const store = useMst();
  const { formState, setValue, getValues } = useFormContext();
  const { triggerLossFields } = useTriggerTradingFormFields();

  const isStopLossTouched = formState.touchedFields.stopLoss;
  const isStopLossPipsDirty = formState.dirtyFields.stopLossPips;

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;
  const isPendingOrder = getValues().orderType !== 'Market';

  const positionId =
    type === 'modifyOrder' && store.ui.modal.options.get('positionId');
  const orderId =
    type === 'modifyOrder' && store.ui.modal.options.get('orderId');

  const positionMetric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);
  const orderMetric =
    store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);

  const orderSL = orderMetric?.stopLoss || undefined;
  const positionSL = positionMetric?.stopLoss?.stopPrice || undefined;

  const stopLoss = positionSL || orderSL;

  const pipSize = handleIndicesPipSize(
    instrument.pipSize ?? defaultPipSize,
    instrument.category || undefined,
    instrument.minOrderSizeIncrement
  );

  const initialRate = () => {
    const price = getValues().price
      ? getValues().price
      : getLimitPrice(
          instrument.stopsLevel,
          instrument.tickSize,
          instrument.pipSize,
          instrument.bid,
          instrument.ask,
          getValues().side
        );

    return String(
      stopLoss ||
        (getValues().side === 'Buy'
          ? getBuyLossRateWithAmplitude(
              instrument.stopsLevel,
              instrument.tickSize,
              pipSize,
              isPendingOrder ? Number(price) : instrument.ask,
              instrument.pipSize,
              undefined
            )
          : getSellLossRateWithAmplitude(
              instrument.stopsLevel,
              instrument.tickSize,
              pipSize,
              isPendingOrder ? Number(price) : instrument.bid,
              instrument.pipSize,
              undefined
            ))
    );
  };

  const initialPips = useMemo(() => {
    const pips = getDefaultPipsBaseOnTickSize({
      pipSize,
      stopsLevel: instrument.stopsLevel,
      tickSize: instrument.tickSize,
      category: instrument.category,
    });

    return stopLoss
      ? getLossPipsFromRate(
          stopLoss.toString(),
          instrument.tickSize,
          getValues().side,
          instrument.ask,
          instrument.bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize
        ).toString()
      : pips;
  }, [instrument.stopsLevel]);

  const handleUpdate = (ask: number, bid: number) => {
    if (!isStopLossTouched && !isStopLossPipsDirty) {
      setValue('stopLoss', initialRate());
      setValue('stopLossPips', initialPips);
      triggerLossFields();
    }
    if (isStopLossPipsDirty && !isStopLossTouched) {
      setValue(
        'stopLoss',
        getLossRateFromPips(
          getValues().stopLossPips,
          instrument.tickSize,
          getValues().side,
          ask,
          bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize,
          getValues().orderType !== 'Market' ? getValues().price : undefined
        )
      );
      triggerLossFields();
    }
  };

  useEffect(() => {
    if (!isPendingOrder) {
      handleUpdate(instrument.ask, instrument.bid);
    }
  }, [
    instrument.bid,
    instrument.ask,
    getValues().stopLossPips,
    getValues().side,
    isStopLossTouched,
    isStopLossPipsDirty,
    isPendingOrder,
  ]);

  useEffect(() => {
    if (isPendingOrder) {
      handleUpdate(+getValues().price, +getValues().price);
    }
  }, [
    getValues().stopLossPips,
    getValues().side,
    isStopLossTouched,
    isStopLossPipsDirty,
    isPendingOrder,
    getValues().price,
  ]);
};
