import * as React from 'react';

import * as Styled from './styled';
import { observer } from 'mobx-react-lite';

interface IRightSideBarMenu {
  isOpen: boolean;
  children: React.ReactNode;
  onToggleSideBar: () => void;
}

export const RightSideBarMenu: React.FC<IRightSideBarMenu> = observer(
  ({ isOpen, onToggleSideBar, children }) => {
    return (
      <Styled.Drawer $isOpen={isOpen}>
        <Styled.ToggleButton
          variant='text'
          iconType={isOpen ? 'openArrows' : 'closeArrows'}
          onClick={onToggleSideBar}
        />
        {children}
      </Styled.Drawer>
    );
  }
);
