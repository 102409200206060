import { TPlaceOrderSide } from '@trader/types';
import { getMaxDigitWithDot } from '@trader/utils';

const fixToNineDigit = 9;

export const getMarketValue = (
  side: TPlaceOrderSide,
  ask: number,
  bid: number,
  amount: number,
  maxDigit: number
) => {
  const result =
    side === 'Buy'
      ? Number((amount * bid).toString().slice(0, fixToNineDigit)).toFixed(
          getMaxDigitWithDot(maxDigit)
        )
      : Number((amount * ask).toString().slice(0, fixToNineDigit)).toFixed(
          getMaxDigitWithDot(maxDigit)
        );

  return Number(result);
};
