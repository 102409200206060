import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Form, Progress, TradingIsClosed } from '@trader/components';
import { TPlaceOrderType, IInitialTradingFormValues } from '@trader/types';

import { TradingRealTimeUpdatesInitializer } from '../hocs';
import { PlaceOrderHeader, MarketOrder, LimitOrder, Tabs } from '../components';
import { usePlaceOrderFormValidation } from './formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const trading = store.trading.getTrading('createOrder');
  const instrument = trading.instrument as TInstrumentEntity;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const defaultFormValues = useGetTradingDefaultFormValues({
    symbol: instrument.symbol,
    side: trading.side,
  });

  const { validationSchema } = usePlaceOrderFormValidation('createOrder');

  const tabs = [
    {
      value: 'Market',
      label: translate('COMMON.LABELS.MARKET'),
      children: <MarketOrder type='createOrder' />,
    },
    {
      value: 'EntryLimit',
      label: translate('COMMON.LABELS.LIMIT_STOP_ORDER'),
      children: <LimitOrder type='createOrder' />,
    },
  ];

  return (
    <Styled.Root $isInvestmentAccProduct={isInvestmentAccProduct}>
      <PlaceOrderHeader symbol={instrument.symbol} />
      <Form<IInitialTradingFormValues>
        onSubmit={async (_reset, data) => {
          await store.trading.createOrder.prePlaceOrderAsync.run(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ setValue, reset, getValues, trigger }) => (
          <TradingRealTimeUpdatesInitializer type='createOrder'>
            {isInvestmentAccProduct ? (
              <MarketOrder type='createOrder' />
            ) : (
              <Tabs
                tabsPosition='top'
                tabs={tabs}
                onChange={key => {
                  store.trading.createOrder.updateOrderType(
                    key as TPlaceOrderType
                  );
                  setValue('orderType', key as TPlaceOrderType);
                  trigger(['amount']);
                  reset(
                    {
                      side: getValues().side,
                      orderType: key as TPlaceOrderType,
                    },
                    { keepValues: true }
                  );
                }}
              />
            )}
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
    </Styled.Root>
  );
});

export const PlaceOrder = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { applyCondition } = useBusinessLogic(
    EConditionType.RegistrationCompleted
  );
  const { applyCondition: applyTradingAllowedCondition } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const instrument = store.trading.createOrder.instrument;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

  if (!instrument) {
    return (
      <Styled.Root $isLoading $isInvestmentAccProduct={isInvestmentAccProduct}>
        <Progress />
      </Styled.Root>
    );
  }

  if (applyCondition().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='completeRegistration'
        title={translate('WARNINGS.COMPLETE_REGISTRATION')}
        content={translate('WARNINGS.HAVENT_COMPLETED_REGISTRATION')}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyCondition()?.action}
      />
    );
  }

  if (instrument.disabledByMarketNews) {
    return (
      <TradingIsClosed
        iconTypeName='notAllowed'
        title={translate('WARNINGS.TRADING_IS_TEMPORARY_DISABLED')}
        content={translate(
          'WARNINGS.TRADING_IS_TEMPORARY_DISABLED_DESCRIPTION'
        )}
      />
    );
  }

  if (applyTradingAllowedCondition().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
      />
    );
  }

  if (instrument.tradingStatus === 'CLOSE_ONLY') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
      />
    );
  }

  if (isDailyTargetGoalReached) {
    return (
      <TradingIsClosed
        iconTypeName='filledCircleCheckmark'
        title={translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED')}
        content={translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED_DESCRIPTION')}
      />
    );
  }

  return <Content />;
});
