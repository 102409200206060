import { observer } from 'mobx-react-lite';
import { TInstrumentEntity, useMst } from '@trader/store';
import { LAYOUTS_TYPES } from '@trader/constants';
import { MultipleLayoutsContainer } from './styled';
import { MultipleChart } from './components/multipleChart';
import { EChartLayouts } from '@trader/types';
import { useEffect } from 'react';

export const MultipleLayouts = observer(() => {
  const store = useMst();

  const layout = store.pages.trading.layout;
  const layoutType = layout.layoutType;
  const layoutsNumbers = LAYOUTS_TYPES[layoutType].layouts;

  const instruments = store.entities.instruments.getAll<TInstrumentEntity>();

  useEffect(() => {
    layoutsNumbers.forEach((layoutNumber, index) => {
      const symbolInLayout = layout.layouts[layoutNumber]?.symbol;
      const symbol = symbolInLayout || instruments[index]?.symbol;
      symbol && layout.selectInstrument(symbol, layoutNumber);
    });
  }, [layoutType]);

  return (
    <MultipleLayoutsContainer $layout={layoutType}>
      {layoutsNumbers.map((l: EChartLayouts) => (
        <MultipleChart key={`${layout}_${l}`} layoutNumber={l} />
      ))}
    </MultipleLayoutsContainer>
  );
});
