import React from 'react';

import { IChangeFieldEvent, TOrderVariation } from '@trader/types';
import { Icon } from '@trader/components';

import * as Styled from './styled';

export interface IOrderInput {
  value?: string;
  step?: number;
  isError?: boolean;
  helperText?: string;
  name?: string;
  onChange?: (event: IChangeFieldEvent) => void;
  onFocus?: () => void;
  placeholder?: string;
  fixDigitAfterDot?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  shouldCheckActivityOfButtons?: boolean;
  isDependOnDigitsStep?: boolean;
}

const maxInputCharacters = 12;

export const OrderInput: React.FC<IOrderInput> = React.forwardRef<
  HTMLInputElement,
  IOrderInput
>(
  (
    {
      value,
      onChange,
      step = 1,
      disabled,
      fixDigitAfterDot = 0,
      isError,
      helperText,
      minValue = 0,
      maxValue = 0,
      placeholder,
      name = '',
      shouldCheckActivityOfButtons,
      onFocus,
    },
    ref
  ) => {
    const handleChange = (v: string): void => {
      if (v.length >= maxInputCharacters) {
        return;
      }
      const replacedValue = v.replace(',', '.');
      const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
      const fixDigit = !fixDigitAfterDot ? 0 : fixDigitAfterDot + 1;
      if (!replacedValue) {
        onChange && onChange({ target: { value: '', name } });
      }

      if (numberRegex.test(replacedValue)) {
        if (replacedValue.includes('.')) {
          const findIndex = replacedValue.indexOf('.');
          if (replacedValue.length <= findIndex + fixDigit) {
            onChange && onChange({ target: { value: replacedValue, name } });
          }
        } else {
          onChange && onChange({ target: { value: replacedValue, name } });
        }
      }
    };

    const handlePressBtn = (variation: TOrderVariation): void => {
      onFocus && onFocus();

      const v =
        variation === 'minus' ? Number(value) - step : Number(value) + step;
      const formatValue = Number(v).toFixed(fixDigitAfterDot);
      onChange && onChange({ target: { value: formatValue, name } });
    };

    return (
      <Styled.RootInput>
        <Styled.Main>
          <Styled.ButtonAction
            $isMinus
            disabled={
              disabled ||
              (shouldCheckActivityOfButtons &&
                (!value || Number(value) <= minValue))
            }
            onClick={() => handlePressBtn('minus')}
          >
            <Icon iconType='minus' />
          </Styled.ButtonAction>
          <Styled.Input
            type='number'
            ref={ref}
            value={value}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            onChange={event => handleChange(event.target.value)}
          />
          <Styled.ButtonAction
            disabled={
              disabled ||
              (shouldCheckActivityOfButtons && Number(value) >= maxValue)
            }
            onClick={() => handlePressBtn('plus')}
          >
            <Icon iconType='plus' />
          </Styled.ButtonAction>
        </Styled.Main>
        <Styled.InputError>{isError && helperText}</Styled.InputError>
      </Styled.RootInput>
    );
  }
);
