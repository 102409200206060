import { getIconBaseProps } from './utils';

export const launchTradeAccount = {
  ...getIconBaseProps('launchTradeAccount'),
  width: '36px',
  height: '35px',
  viewBox: '0 0 36 35',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <g filter='url(#filter0_d_1746_13959)'>
        <circle
          cx='18'
          cy='17.5'
          r='14.5'
          fill='#08C99D'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.8015 16.5489C23.8839 17.1674 23.8839 18.7281 22.8015 19.3466L16.3821 23.0148C15.3081 23.6285 13.9717 22.853 13.9717 21.6159L13.9717 14.2795C13.9717 13.0425 15.3081 12.2669 16.3821 12.8807L22.8015 16.5489Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_1746_13959'
          x='0.75'
          y='0.25'
          width='34.5'
          height='34.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1746_13959'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1746_13959'
            result='shape'
          />
        </filter>
      </defs>
      ;
    </>
  ),
};
