import { getIconBaseProps } from './utils';

export const completeRegistration = {
  ...getIconBaseProps('completeRegistration'),
  width: '72px',
  height: '72px',
  viewBox: '0 0 72 72',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <mask
        id='mask0_260_2312'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='72'
        height='72'
      >
        <path
          d='M36 72C55.8823 72 72 55.8823 72 36C72 16.1178 55.8823 0 36 0C16.1177 0 0 16.1178 0 36C0 55.8823 16.1177 72 36 72Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_260_2312)'>
        <path
          d='M84.0846 -14.5503H-12.083V81.6173H84.0846V-14.5503Z'
          fill='#9CC5F5'
        />
        <path
          d='M64.8512 28.7253H11.9586C10.6304 28.7253 9.55469 29.8017 9.55469 31.1292V81.6173H67.2551V31.1292C67.2551 29.8011 66.1787 28.7253 64.8512 28.7253Z'
          fill='#54A0FB'
        />
        <path
          d='M64.8508 28.7253H7.15039V81.6173H64.8508V28.7253Z'
          fill='white'
        />
        <path
          d='M7.15039 28.7253H64.8508V26.3214C64.8508 24.9939 63.7744 23.9175 62.4469 23.9175H9.55497C8.22747 23.9175 7.15106 24.9939 7.15106 26.3214V28.7253H7.15039Z'
          fill='#2C3E50'
        />
        <path
          d='M59.6901 27.1714C60.1596 26.7019 60.1596 25.9407 59.6901 25.4711C59.2206 25.0016 58.4593 25.0016 57.9898 25.4711C57.5203 25.9407 57.5203 26.7019 57.9898 27.1714C58.4593 27.641 59.2206 27.641 59.6901 27.1714Z'
          fill='#ABB2B9'
        />
        <path
          d='M56.4048 26.6078C56.5617 25.9626 56.1659 25.3124 55.5207 25.1554C54.8755 24.9985 54.2252 25.3943 54.0683 26.0395C53.9114 26.6847 54.3072 27.335 54.9524 27.4919C55.5976 27.6488 56.2479 27.253 56.4048 26.6078Z'
          fill='#ABB2B9'
        />
        <path
          d='M52.7884 26.6493C52.9677 26.01 52.5948 25.3463 51.9555 25.167C51.3162 24.9877 50.6525 25.3605 50.4732 25.9999C50.2938 26.6392 50.6667 27.3029 51.306 27.4822C51.9454 27.6615 52.609 27.2887 52.7884 26.6493Z'
          fill='#ABB2B9'
        />
        <path
          d='M7.15039 52.1416V72.0002H40.8091C26.3177 72.0002 13.7091 63.9787 7.15039 52.1416Z'
          fill='#DDE0E3'
        />
        <path
          d='M40.8086 72.0006H64.8503V63.5354C58.2626 68.8212 49.9119 72.0006 40.8086 72.0006Z'
          fill='#DDE0E3'
        />
        <path
          d='M23.9794 47.9585C26.635 47.9585 28.7879 45.8057 28.7879 43.15C28.7879 40.4944 26.635 38.3416 23.9794 38.3416C21.3237 38.3416 19.1709 40.4944 19.1709 43.15C19.1709 45.8057 21.3237 47.9585 23.9794 47.9585Z'
          fill='#0271F5'
        />
        <path
          d='M26.3837 41.5476L23.178 44.7533L21.5752 43.1504'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M23.9794 62.3841C26.635 62.3841 28.7879 60.2312 28.7879 57.5756C28.7879 54.9199 26.635 52.7671 23.9794 52.7671C21.3237 52.7671 19.1709 54.9199 19.1709 57.5756C19.1709 60.2312 21.3237 62.3841 23.9794 62.3841Z'
          fill='#ABB2B9'
        />
        <path
          d='M26.3837 55.9729L23.178 59.1786L21.5752 57.5757'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          d='M33.5967 40.7461H48.0221'
          stroke='#2C3E50'
          strokeMiterlimit='10'
        />
        <path
          d='M50.4258 40.7461H55.2343'
          stroke='#2C3E50'
          strokeMiterlimit='10'
        />
        <path
          d='M55.234 45.5547H40.8086'
          stroke='#2C3E50'
          strokeMiterlimit='10'
        />
        <path
          d='M38.4052 45.5547H33.5967'
          stroke='#2C3E50'
          strokeMiterlimit='10'
        />
        <path
          d='M33.5967 55.1711H48.0221'
          stroke='#ABB2B9'
          strokeMiterlimit='10'
        />
        <path
          d='M50.4258 55.1711H55.2343'
          stroke='#ABB2B9'
          strokeMiterlimit='10'
        />
        <path
          d='M55.234 59.9797H40.8086'
          stroke='#ABB2B9'
          strokeMiterlimit='10'
        />
        <path
          d='M38.4052 59.9797H33.5967'
          stroke='#ABB2B9'
          strokeMiterlimit='10'
        />
        <path
          d='M53.3255 50.4292L53.3174 57.5884L59.0474 54.7315L53.3255 50.4292Z'
          fill='#C2CCD9'
        />
        <path
          d='M56.8994 55.8028L55.4668 56.5171L56.8953 59.3823L58.328 58.668L56.8994 55.8028Z'
          fill='#C2CCD9'
        />
        <path
          d='M50.9173 50.4177L50.9092 57.5769L56.6392 54.72L50.9173 50.4177Z'
          fill='#2C3E50'
        />
        <path
          d='M54.4902 55.7909L53.0576 56.5051L54.4862 59.3704L55.9188 58.6561L54.4902 55.7909Z'
          fill='#2C3E50'
        />
      </g>
    </>
  ),
};
