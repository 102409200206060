/* eslint-disable no-magic-numbers */
import { StudyInputValue } from '../charting_library';

export const getInputValues = (
  inputs: <T extends StudyInputValue>(index: number) => T
) => ({
  priceSource: inputs(0) as
    | 'open'
    | 'close'
    | 'high'
    | 'low'
    | 'hl2'
    | 'hlc3'
    | 'ohlc4',
  barsConfirm: inputs(1) as number,
  entryTrigger: inputs(2) as
    | 'close'
    | 'high/low'
    | 'high/low median'
    | 'open/close median',
  // useSuperTrendSignal: inputs(),
  useSuperTrend: inputs(3) as boolean,
  superTrendFactor: inputs(4) as number,
  superTrendPeriod: inputs(5) as number,
  useMaStrategy: inputs(6) as boolean,
  maBasisType: inputs(7) as
    | 'high'
    | 'low'
    | 'sma'
    | 'wma'
    | 'smma'
    | 'swma'
    | 'vwma',
  maSignalType: inputs(8) as
    | 'high'
    | 'low'
    | 'sma'
    | 'wma'
    | 'smma'
    | 'swma'
    | 'vwma',
  maRatio: inputs(9) as number,
  maMultiplier: inputs(10) as number,
  bbType: inputs(11) as
    | 'high'
    | 'low'
    | 'sma'
    | 'wma'
    | 'smma'
    | 'swma'
    | 'vwma',
  bbLength: inputs(12) as number,
  bbMultiplier: inputs(13) as number,
  useBBLong: inputs(14),
  useBBLong2: inputs(15),
  useBBLong3: inputs(16),
  useBBShort: inputs(17),
  useBBShort2: inputs(18),
  useBBShort3: inputs(19),
  takeProfitTrigger: inputs(20) as
    | 'close'
    | 'high/low'
    | 'high/low median'
    | 'open/close median',
  useTakeProfit: inputs(21) as boolean,
  useTakeProfit2: inputs(22) as boolean,
  useTakeProfit3: inputs(23) as boolean,
  takeProfitLongQty: inputs(24) as number,
  takeProfitShortQty: inputs(25) as number,
  useLimitOrder: inputs(26) as boolean,
  limitOrderPeriod: inputs(27) as number,
  limitOrderMultiplier: inputs(28) as number,
  stopLossTrigger: inputs(29) as
    | 'close'
    | 'high/low'
    | 'high/low median'
    | 'open/close median',
  stopLossType: inputs(30) as 'SuperTrend + ATR' | 'ATR' | 'StdDev' | 'off',
  SLAtrPeriod: inputs(31) as number,
  SLAtrMultiplierLong: inputs(32) as number,
  SLAtrMultiplierShort: inputs(33) as number,
});
