import styled, { css } from 'styled-components';
import {
  layoutChildrenRadius,
  layoutMargin,
  sideBarClosedWidth,
  sideBarWidth,
} from '@trader/constants';
import { conditionApply } from '@trader/utils';
import { Wrapper } from '../wrapper';
import { Button } from '../button';

interface IDrawer {
  $isOpen: boolean;
}

export const LogoButton = styled(Button)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

export const Drawer = styled(Wrapper)<IDrawer>`
  ${({ $isOpen, theme }) => css`
    z-index: 1;
    width: ${sideBarWidth}px;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    flex-direction: column;
    height: inherit;
    position: relative;
    flex-shrink: 0;
    margin-right: ${layoutMargin}px;
    white-space: nowrap;
    box-sizing: border-box;

    ${conditionApply(
      [$isOpen],
      css`
        width: ${sideBarWidth}px;
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })};
        overflow-x: hidden;
      `
    )};
    ${conditionApply(
      [!$isOpen],
      css`
        width: ${sideBarClosedWidth}px;
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        overflow-x: hidden;
      `
    )}

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 100%;
      margin-right: 0;
    }
  `}
`;
