import { getIconBaseProps } from './utils';

export const abovePrice = {
  ...getIconBaseProps('abovePrice'),
  width: '10px',
  height: '11px',
  viewBox: '0 0 10 11',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5 9.875L5 1.125'
        stroke='#26A69A'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.5 4.625L5 1.125L8.5 4.625'
        stroke='#26A69A'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </>
  ),
};
