import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { StableDesign } from './stableDesign';
import { BetaDesign } from './betaDesign';

export const BalanceInformation: FC = observer(() => {
  const store = useMst();

  if (store.app.isBetaDesignEnabled()) {
    return <BetaDesign />;
  }

  return <StableDesign />;
});
