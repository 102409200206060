import { getIconBaseProps } from './utils';

export const twoColumnLayout = {
  ...getIconBaseProps('twoColumnLayout'),
  width: '20px',
  height: '20px',
  viewBox: '0 0 20 20',
  body: (
    <>
      <rect
        x='19.399'
        y='0.6'
        width='18.8'
        height='18.8'
        rx='2.4'
        transform='rotate(90 19.399 0.6)'
        strokeWidth='1.2'
      />
      <path
        d='M0.999023 10L18.999 10'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
