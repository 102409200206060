import { getIconBaseProps } from '../utils';

export const cryptos = {
  ...getIconBaseProps('cryptos'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.20947 8.24634H11.9227C12.6834 8.24634 13.2998 8.8628 13.2998 9.62346C13.2998 10.3841 12.6834 11.0006 11.9227 11.0006H8.95217'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.20947 13.7543H12.4136C13.1741 13.7543 13.7907 13.1378 13.7907 12.3771C13.7907 11.6165 13.1743 11 12.4136 11H9.1036'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.94739 6.70898V8.24631'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5276 6.70898V8.24631'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.94727 13.7546V15.292'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5273 13.7546V15.292'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.95117 8.24634V11.0006V13.7548'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
