import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    :first-child {
      margin-right: 5px;
    }
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: 15px;
    font-weight: 500;
    min-width: 46px;
    position: absolute;
    bottom: -15px;
    text-align: center;
    z-index: 1;
    background: ${theme.palette.background.paper};
    border-radius: 3px;
  `}
`;
