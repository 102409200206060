import React from 'react';
import { observer } from 'mobx-react-lite';

import { AssetDetails, BottomBar, Modals, TopBar } from '@trader/containers';

import * as Styled from './styled';

export const Assets: React.FC = observer(() => {
  return (
    <Styled.Root>
      <TopBar />
      <AssetDetails />
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
