import { devLoggerService } from '@trader/services';
import { returnTypedThis } from '@trader/utils';
import { IOption } from '@trader/types';
import { api, IBackTestConfig } from '@trader/api';

import { createThunk } from '../../../utils/asyncModel';
import { TBackTestingStore } from './index';

interface IBackTestingAction extends Omit<IBackTestConfig, 'symbol'> {
  symbolOption: IOption;
}

export const startBackTestingAsync = createThunk<IBackTestingAction, void>(
  body =>
    async function startBackTesting(this: unknown, options, _flow) {
      const that = returnTypedThis<TBackTestingStore>(this);

      try {
        const res = await api.BandStrategy.startBackTesting(
          {
            symbol: body.symbolOption.value,
            backTestConfig: body.backTestConfig,
          },
          options
        );

        that.runInAction(() => {
          that.id = res.id;
          that.percentFinished = 0;
          that.symbolOption = body.symbolOption;
          that.quantity = body.backTestConfig.quantity;
          that.oneTradePerSession = body.backTestConfig.oneTradePerSession;
          that.allowAveraging = body.backTestConfig.allowAveraging;
          that.lowestMultiplier = body.backTestConfig.lowestMultiplier;
          that.highestMultiplier = body.backTestConfig.highestMultiplier;
          that.multiplierStep = body.backTestConfig.multiplierStep;
          that.from = body.backTestConfig.from;
          that.to = body.backTestConfig.to;
          that.isPending = true;
          that.multipliers = [];
          that.summary = null;
          that.selectedMultiplierOption = null;
          that.selectedBandOption = null;

          if (body.backTestConfig?.sl) {
            that.sl = body.backTestConfig?.sl;
          }

          if (body.backTestConfig.sessionRange?.from) {
            that.sessionFrom = body.backTestConfig.sessionRange
              ?.from as unknown as Date;
            that.sessionTo = body.backTestConfig.sessionRange
              ?.to as unknown as Date;
          }
        });
      } catch (e) {
        devLoggerService.error('startBackTestingAsync', e);
      }
    }
);

export const pingBackTestAsync = createThunk<void, void>(
  () =>
    async function pingBackTest(this: unknown, options, _flow) {
      const that = returnTypedThis<TBackTestingStore>(this);
      try {
        that.id &&
          (await api.BandStrategy.pingMuliBand(
            { id: that.id, isBackTest: true },
            options
          ));
      } catch (e) {
        devLoggerService.error('pingMuliBandAsync', e);
      }
    }
);
