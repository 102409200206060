import {
  CustomIndicator,
  IPineSeries,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  StudyNumericInputInfo,
  StudySourceInputInfo,
  StudyTextInputInfo,
} from '../charting_library';
import {
  bollingerBandsDefaults,
  bollingerBandsFilledAreas,
  bollingerBandsInputs,
  bollingerBandsPallets,
  bollingerBandsPlots,
  bollingerPlotsStyles,
  getBollingerBandsValues,
} from './bollingerBands';
import {
  getSuperTrendValues,
  superTrendDefaults,
  superTrendFilledAreas,
  superTrendInputs,
  superTrendPallets,
  superTrendPlots,
  superTrendStyles,
} from './superTrend';
import {
  getMovingAverageValues,
  movingAverageDefaults,
  movingAverageFilledAreas,
  movingAverageInputs,
  movingAveragePallets,
  movingAveragePlots,
  movingAverageStyles,
} from './movingAverage';
import { defaultInputs } from './defaultInputs';
import { takeProfitInputs } from './takeProfit';
import { limitOrdersInputs } from './limitOrders';
import {
  getStopLossValues,
  stopLossDefaults,
  stopLossInputs,
  stopLossPallets,
  stopLossPlots,
  stopLossStyles,
} from './stopLoss';
import { getConditions } from './conditions';
import { getInputValues } from './inputValues';
import { getType, TGetTypeResult } from '../utils/getType';

export const twoMars = (Pine: PineJS): CustomIndicator => ({
  name: 'EdgeX',
  metainfo: {
    _metainfoVersion: 51,
    id: 'EdgeX@tv-basicstudies-1' as RawStudyMetaInfoId,
    description: 'EdgeX',
    shortDescription: 'EdgeX',
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      ...bollingerBandsPlots,
      ...superTrendPlots,
      ...movingAveragePlots,
      ...stopLossPlots,
    ],
    filledAreas: [
      ...bollingerBandsFilledAreas,
      ...superTrendFilledAreas,
      ...movingAverageFilledAreas,
    ],

    palettes: {
      ...bollingerBandsPallets,
      ...superTrendPallets,
      ...movingAveragePallets,
      ...stopLossPallets,
    },

    defaults: {
      filledAreasStyle: {
        ...bollingerBandsDefaults.filledAreasStyle,
        ...superTrendDefaults.filledAreasStyle,
        ...movingAverageDefaults.filledAreasStyle,
      },
      palettes: {
        ...bollingerBandsDefaults.pallets,
        ...superTrendDefaults.palettes,
        ...movingAverageDefaults.palettes,
        ...stopLossDefaults.palettes,
      },
      styles: {
        ...bollingerBandsDefaults.styles,
        ...superTrendDefaults.styles,
        ...movingAverageDefaults.styles,
        ...stopLossDefaults.styles,
      },
      inputs: defaultInputs,
    },
    styles: {
      ...bollingerPlotsStyles,
      ...superTrendStyles,
      ...movingAverageStyles,
      ...stopLossStyles,
    },
    inputs: [
      {
        id: 'source',
        name: '[General] Source',
        type: 'source' as StudySourceInputInfo['type'],
        defval: 'close',
      },
      {
        id: 'barsConfirm',
        name: '[General] Bars confirm',
        type: 'integer' as StudyNumericInputInfo['type'],
        defval: 2,
        isHidden: true,
      },
      {
        id: 'entryTrigger',
        name: '[General] Entry trigger',
        type: 'text' as StudyTextInputInfo['type'],
        defval: 'close',
        options: ['close', 'high/low', 'high/low median', 'open/close median'],
        isHidden: true,
      },
      ...superTrendInputs,
      ...movingAverageInputs,
      ...bollingerBandsInputs,
      ...takeProfitInputs,
      ...limitOrdersInputs,
      ...stopLossInputs,
    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const inputValues: ReturnType<typeof getInputValues> = getInputValues(
        this._input
      );

      const source: IPineSeries = context.new_var(
        Pine.Std[inputValues.priceSource](context)
      );
      const close = Pine.Std.close(context);
      const priceMedianValue = Pine.Std.hl2(context);

      // Bollinger Bands constants
      const BBDeviation = Pine.Std.dev(source, inputValues.bbLength, context);

      // Moving Average constants
      const basisLength = (
        inputValues.maRatio * inputValues.maMultiplier
      ).toFixed(0);
      const signalLength = inputValues.maMultiplier;
      const valuesFromType: TGetTypeResult = getType({
        context,
        source,
        Pine,
      });
      const valueFromSignalType: number = valuesFromType[
        inputValues.maSignalType
      ](Number(signalLength));
      const valueFromBasisType: number = valuesFromType[
        inputValues.maBasisType
      ](Number(basisLength));

      const MAShort = valueFromBasisType;
      const MALong = valueFromSignalType;

      // Super Trend constants
      const StAverageTrueRange = Pine.Std.atr(
        inputValues.superTrendPeriod,
        context
      );

      //
      const standardDeviation = Pine.Std.stdev(
        source,
        inputValues.SLAtrPeriod,
        context
      );
      const SlAverageTrueRange = Pine.Std.atr(inputValues.SLAtrPeriod, context);

      const bollingerBands: ReturnType<typeof getBollingerBandsValues> =
        getBollingerBandsValues({
          context,
          source,
          Pine,
          deviation: BBDeviation,
          inputValues,
        });

      const movingAverage: ReturnType<typeof getMovingAverageValues> =
        getMovingAverageValues({
          context,
          Pine,
          MAShort,
          MALong,
          isMaCrossingStrategy: inputValues.useMaStrategy,
        });

      const superTrend: ReturnType<typeof getSuperTrendValues> =
        getSuperTrendValues({
          context,
          Pine,
          averageTrueRange: StAverageTrueRange,
          inputValues,
          close,
          priceMedianValue,
        });

      const conditions: ReturnType<typeof getConditions> = getConditions({
        BB: bollingerBands.values,
        MA: movingAverage.values,
        ST: superTrend.values,
        Pine,
        context,
        inputValues,
      });

      const stopLoss: ReturnType<typeof getStopLossValues> = getStopLossValues({
        context,
        Pine,
        standardDeviation,
        averageTrueRange: SlAverageTrueRange,
        conditions,
        inputValues,
        supertrend: superTrend.values.supertrend,
      });

      // const signals: ReturnType<typeof getSignals> = getSignals({
      //   BB: bollingerBands.values,
      //   MA: movingAverage.values,
      //   ST: superTrend.values,
      //   Pine,
      //   context,
      //   inputValues,
      // });

      return [
        ...bollingerBands.result,
        ...superTrend.result,
        ...movingAverage.result,
        ...stopLoss,
        // ...signals,
      ];
    };
  },
});
