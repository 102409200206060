import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';

import { INotificationOut, useMst } from '@trader/store';

import {
  createCustomNotification,
  createDefaultNotification,
} from './createSnackbar';

export const NotificationContainer: React.FC = observer(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const store = useMst();

  const notifications = store.notifications.list;
  const isAllNotificationsCompleted = store.notifications.checkIsAllCompleted();

  const removeNotificationFromStore = useCallback(
    (uid: INotificationOut['uid']) => store.notifications.complete(uid),
    [store.notifications]
  );

  const showNotification = (notification: INotificationOut) => {
    enqueueSnackbar(
      notification.message,
      (notification.customType
        ? createCustomNotification
        : createDefaultNotification)({
        notification,
        closeSnackbar,
        removeNotificationFromStore,
      })
    );
  };

  useEffect(() => {
    isAllNotificationsCompleted && store.notifications.clearAll();
  }, [isAllNotificationsCompleted]);

  useEffect(() => {
    if (notifications.length) {
      notifications.forEach(n => {
        showNotification(n);
      });
      store.notifications.clearAll();
    }
  }, [notifications.length]);

  return null;
});
