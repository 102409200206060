import { getIconBaseProps } from './utils';

export const marketClosed = {
  ...getIconBaseProps('marketClosed'),
  width: '15px',
  height: '16px',
  viewBox: '0 0 15 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='7.5' cy='8' r='7.5' fill='url(#paint0_radial_1393_28893)' />
      <circle cx='7.50001' cy='8.00038' r='4.03846' fill='#F73F4A' />
      <defs>
        <radialGradient
          id='paint0_radial_1393_28893'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.5 8) scale(7.5)'
        >
          <stop offset='0.76' stopColor='#FDDDDF' />
          <stop offset='1' stopColor='#F5CACD' stopOpacity='0' />
        </radialGradient>
      </defs>
    </>
  ),
};
