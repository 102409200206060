import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { LAYOUTS_TYPES } from '@trader/constants';
import { EChartLayoutTypes, IOption } from '@trader/types';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { DropdownMenu } from '@trader/components';
import { useResponsiveDesign } from '@trader/hooks';

import * as Styled from './styled';

export const TradingChartLayoutSelection: React.FC = observer(() => {
  const { translate } = useI18next();
  const location = useLocation();

  const store = useMst();
  const layoutType = store.pages.trading.layout.layoutType;

  const { layoutSelection } = useResponsiveDesign();

  let layouts: Array<IOption> = Object.values(LAYOUTS_TYPES).map(layout => ({
    value: layout.type,
    title: layout.iconType,
    id: layout.type,
  }));
  if (layoutSelection.shouldHideThreeInRows) {
    layouts = layouts.filter(l => l.value !== EChartLayoutTypes.ThreeRow);
  }
  if (layoutSelection.shouldHideThreeInColumns) {
    layouts = layouts.filter(l => l.value !== EChartLayoutTypes.ThreeColumn);
  }
  if (layoutSelection.shouldHideFour) {
    layouts = layouts.filter(l => l.value !== EChartLayoutTypes.Four);
  }

  /**
   * Reset the layout selection when screen is not supported selected one.
   */
  useEffect(() => {
    if (
      (layoutSelection.shouldHideThreeInRows &&
        layoutType === EChartLayoutTypes.ThreeRow) ||
      (layoutSelection.shouldHideThreeInColumns &&
        layoutType === EChartLayoutTypes.ThreeColumn) ||
      (layoutSelection.shouldHideFour &&
        layoutType === EChartLayoutTypes.Four) ||
      layoutSelection.shouldHideWholeSelection
    ) {
      changeLayout({
        value: LAYOUTS_TYPES.single.type,
        title: LAYOUTS_TYPES.single.iconType,
        id: LAYOUTS_TYPES.single.type,
      });
    }
  }, [
    layoutSelection.shouldHideThreeInRows,
    layoutSelection.shouldHideThreeInColumns,
    layoutSelection.shouldHideFour,
    layoutSelection.shouldHideWholeSelection,
  ]);

  const changeLayout = (option: IOption) => {
    store.pages.trading.layout.changeLayout(option.value as EChartLayoutTypes);
  };

  if (location?.state?.disableMultipleLayouts) {
    return null;
  }

  if (layoutSelection.shouldHideWholeSelection) {
    return null;
  }

  return (
    <Styled.Container>
      <DropdownMenu
        itemType='icon'
        items={layouts}
        buttonTitle={translate('COMMON.LABELS.SELECT_LAYOUT')}
        onChange={changeLayout}
        selectedOption={{
          value: layoutType,
          title: LAYOUTS_TYPES[layoutType].iconType,
          id: layoutType,
        }}
        isChartLayout={true}
      />
    </Styled.Container>
  );
});
