import React from 'react';
import { observer } from 'mobx-react-lite';

import { ModalNotification } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

export const TradingDisabledDueToMarketNews: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  return (
    <ModalNotification
      iconTypeName='notAllowed'
      title={translate('WARNINGS.TRADING_IS_TEMPORARY_DISABLED')}
      content={translate('WARNINGS.TRADING_IS_TEMPORARY_DISABLED_DESCRIPTION')}
      buttonContent={translate('COMMON.LABELS.OKAY')}
      onClick={store.ui.modal.close}
    />
  );
});
