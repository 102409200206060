// Here is the example of all locales.
// eslint-disable-next-line max-len
//https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript#:~:text=const%20formats%20%3D%20%7B%0A%20%20%20%20%22af,yyyy/MM/dd%22%2C%0A%20%20%7D%3B

export type TFormatterType =
  | 'dd/mm/yyyy hh:mm:ss'
  | 'dd/mm/yyyy hh:mm'
  | 'dd/mm/yyyy; hh:mm:ss'
  | 'dd.mm.yyyy'
  | 'dd.mm.yyyy hh:mm:ss'
  | 'dd.mm.yyyy hh:mm'
  | 'Mm dd, yyyy hh:mm:ss'
  | 'Mm dd, yyyy'
  | 'YYYY-MM-DD'
  | 'hh:mm:ss';
type TIntervalType =
  | 'prevDay'
  | 'thisMonth'
  | 'pastMonth'
  | 'thisQuarter'
  | 'pastQuarter'
  | 'thisYear'
  | 'pastYear';

type TSupportedLocales = 'en' | 'it' | 'es' | 'de' | 'pl';

const thousand = 1000;
const ten = 10;
const sixty = 60;
const localeKey: Record<TSupportedLocales, string> = {
  en: 'en-EN',
  it: 'it-IT',
  es: 'es-ES',
  de: 'de-DE',
  pl: 'pl-PL',
};

export const dateFormats = (
  date: number | Date,
  formatterType: TFormatterType,
  locale?: TSupportedLocales
) => {
  switch (formatterType) {
    case 'dd/mm/yyyy hh:mm':
      return `${getDateEN(getFullyDate(date))} ${getTimeSecondsFree(
        getFullyDate(date)
      )}`;
    case 'dd/mm/yyyy; hh:mm:ss':
      return `${getDateEN(getFullyDate(date))}; ${getTime(getFullyDate(date))}`;
    case 'dd.mm.yyyy':
      return getDateBY(getFullyDate(date));
    case 'dd.mm.yyyy hh:mm:ss':
      return `${getDateBY(getFullyDate(date))} ${getTime(getFullyDate(date))}`;
    case 'dd.mm.yyyy hh:mm':
      return `${getDateBY(getFullyDate(date))} ${getTime(getFullyDate(date))
        .split(':')
        .slice(0, 2)
        .join(':')}`;
    case 'Mm dd, yyyy':
      return getDateLongMonth(date as Date, locale);
    case 'Mm dd, yyyy hh:mm:ss':
      return `${getDateLongMonth(date as Date, locale)} ${getTime(
        getFullyDate(date)
      )}`;
    case 'hh:mm:ss':
      return `${getTime(getFullyDate(date))}`;
    case 'YYYY-MM-DD': {
      const year = new Date(date).getUTCFullYear();
      const month = String(new Date(date).getUTCMonth() + 1).padStart(2, '0');
      const day = String(new Date(date).getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    case 'dd/mm/yyyy hh:mm:ss':
    default:
      return `${getDateEN(getFullyDate(date))} ${getTime(getFullyDate(date))}`;
  }
};

export const dateToUTC = (
  date: number | Date,
  includeGMTLabel?: boolean,
  locale?: TSupportedLocales
): string => {
  const formatter = new Intl.DateTimeFormat(locale || localeKey['en'], {
    timeZone: 'UTC',
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hour12: false,
  });
  const utcDate = formatter.format(getFullyDate(date));
  const utcDateWithGMT = utcDate
    .replace('UTC', 'GMT')
    // Replace all commas instead of the first one.
    .replace(/,/g, '')
    .replace(' ', ', ');

  return includeGMTLabel ? utcDateWithGMT : utcDateWithGMT.replace('GMT', '');
};

const getFullyDate = (date: number | Date): Date =>
  new Date(typeof date === 'number' ? date * thousand : date);

const getDateEN = (date: Date): string => date.toLocaleDateString('en-EN');
const getDateBY = (date: Date): string => date.toLocaleDateString('be-BY');
const getDateLongMonth = (
  date: Date,
  locale: TSupportedLocales = 'en'
): string => {
  const monthAndDay = date.toLocaleDateString(localeKey[locale], {
    month: 'long',
    day: '2-digit',
  });
  return `${monthAndDay}, ${date.getFullYear()}`;
};
export const getTime = (date: Date): string => date.toLocaleTimeString('en-GB');

const getTimeSecondsFree = (date: Date): string =>
  date.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });

export const addZeroToDate = (val: number) => (val < ten ? `0${val}` : val);

export const getTimeInSeconds = (time?: number) =>
  Math.floor((time || new Date().getTime()) / thousand);

export const convertSecondsToMs = (seconds: number) => seconds * thousand;

const getQuarterInterval = (quarterOffset = 0) => {
  const numberOfMonthInQuarter = 3;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  let quarterStartMonth: number | null = null;
  let quarterEndMonth: number | null = null;

  const targetYear =
    currentMonth - quarterOffset < 0 ? currentYear - 1 : currentYear;
  const quarterIndex = Math.floor(
    (currentMonth - quarterOffset) / numberOfMonthInQuarter
  );

  switch (quarterIndex) {
    case 0:
      quarterStartMonth = 0;
      quarterEndMonth = 2;
      break;
    case 1:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 3;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 5;
      break;
    case 2:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 6;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 8;
      break;
    // eslint-disable-next-line no-magic-numbers
    case 3:
      // eslint-disable-next-line no-magic-numbers
      quarterStartMonth = 9;
      // eslint-disable-next-line no-magic-numbers
      quarterEndMonth = 11;
      break;
    default:
      break;
  }

  const from = new Date(targetYear, quarterStartMonth as number, 1);
  const to = new Date(
    targetYear,
    (quarterEndMonth as number) + 1,
    0,
    lastHour,
    lastMinute,
    lastSecond
  );

  return { from, to };
};

export const getDateInterval = (intervalType: TIntervalType) => {
  const numberOfMonthInQuarter = 3;
  const daysInLastMonth = 31;
  const lastMonthIndex = 11;
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  const currentDate = new Date();

  let from: null | Date = null;
  let to: null | Date = null;

  switch (intervalType) {
    case 'prevDay': {
      const date = new Date().getUTCDate();

      const prevDay = new Date().setUTCDate(date - 1);

      from = new Date(new Date(prevDay).setUTCHours(0, 0, 0, 0));
      to = new Date(new Date().setUTCHours(0, 0, 0, 0));
      break;
    }
    case 'thisMonth':
      from = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      to = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'pastMonth':
      from = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      to = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'thisQuarter':
      from = getQuarterInterval().from;
      to = getQuarterInterval().to;
      break;
    case 'pastQuarter':
      from = getQuarterInterval(numberOfMonthInQuarter).from;
      to = getQuarterInterval(numberOfMonthInQuarter).to;
      break;
    case 'thisYear':
      from = new Date(currentDate.getFullYear(), 0, 1);
      to = new Date(
        currentDate.getFullYear(),
        lastMonthIndex,
        daysInLastMonth,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    case 'pastYear':
      from = new Date(currentDate.getFullYear() - 1, 0, 1);
      to = new Date(
        currentDate.getFullYear() - 1,
        lastMonthIndex,
        daysInLastMonth,
        lastHour,
        lastMinute,
        lastSecond
      );
      break;
    default:
      throw new Error('Unsupported interval');
  }

  return { from, to };
};

export const convertDateToEndOfDay = (date: Date) => {
  const lastHour = 23;
  const lastMinute = 59;
  const lastSecond = 59;
  const converted = new Date(date);
  converted.setHours(lastHour);
  converted.setMinutes(lastMinute);
  converted.setSeconds(lastSecond);

  return converted;
};

export const getUTCDate = () => {
  const millisecondsInOneMinute = 60000;
  const now = new Date();
  return new Date(
    now.getTime() + now.getTimezoneOffset() * millisecondsInOneMinute
  );
};

export const getNextDay = (date: Date) => {
  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);
  nextDay.setHours(0, 1, 0, 0); // Set time to 00:01:00.000
  return nextDay;
};

export const getTimeDifference = (fromTime: Date, toTime: Date) => {
  const diffInMilliseconds = toTime.getTime() - fromTime.getTime();
  // eslint-disable-next-line no-magic-numbers
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  // eslint-disable-next-line no-magic-numbers
  const hours = Math.floor(diffInMinutes / 60);
  // eslint-disable-next-line no-magic-numbers
  const minutes = diffInMinutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}h:${formattedMinutes}m`;
};

export const isDateInTimeInterval = (date: Date, from: string, to: string) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const fromHours = +from.split(':')[0];
  const fromMinutes = +from.split(':')[1];
  const fromTime = new Date(year, month, day, fromHours, fromMinutes);

  const toHours = +to.split(':')[0];
  const toMinutes = +to.split(':')[1];
  const toTime = new Date(year, month, day, toHours, toMinutes);

  return date >= fromTime && date <= toTime;
};

export const getMillisecondsUntilNextMinute = () => {
  const now = new Date();
  const currentMilliseconds = now.getMilliseconds();
  const currentSeconds = now.getSeconds();
  const millisecondsUntilNextMinute =
    (sixty - currentSeconds) * thousand - currentMilliseconds;

  return millisecondsUntilNextMinute;
};

export const getDaysInRange = () => {
  const date = new Date().getUTCDate();

  const prevDay = new Date().setUTCDate(date - 1);

  const from = new Date(prevDay).setUTCHours(0, 0, 0, 0);
  const to = new Date().setUTCHours(0, 0, 0, 0);

  return {
    from: new Date(from),
    to: new Date(to),
  };
};
