import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';

import * as Styled from './styled';

interface ILoss extends ICommonOrderType {
  openPrice?: string;
}

export const Loss: React.FC<ILoss> = observer(({ type, openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { formState, getValues } = useFormContext<IInitialTradingFormValues>();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const shouldRecalculateLoss =
    formState.dirtyFields.stopLoss || formState.dirtyFields.stopLossPips;

  const calculateLoss = (shouldAvoidPipsValidation?: boolean) => {
    trading.calculateLoss(
      {
        amount: getValues().amount,
        stopLoss: getValues().stopLoss,
        currentPrice: getValues().price,
        openPrice: openPrice,
        side: getValues().side,
        orderType: getValues().orderType,
        stopLossType: getValues().stopLossType,
      },
      shouldAvoidPipsValidation
    );
  };

  useEffect(() => {
    if (!shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [shouldRecalculateLoss, trading.currencyConvert, getValues().amount]);

  useEffect(() => {
    if (shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [
    shouldRecalculateLoss,
    instrument.ask,
    instrument.bid,
    getValues().amount,
  ]);

  useEffect(() => {
    calculateLoss(true);
  }, [getValues().side, getValues().orderType]);

  if (formState.errors.stopLoss || formState.errors.stopLossPips) {
    return (
      <Styled.InputLabel>
        {translate('COMMON.LABELS.LOSS')}: --
      </Styled.InputLabel>
    );
  }

  return (
    <Styled.InputLabel>
      {translate('COMMON.LABELS.LOSS')}: {trading.loss}
    </Styled.InputLabel>
  );
});
