import { IContext, PineJS } from '../charting_library';
import { getInputValues } from '../twoMarsIndicator/inputValues';

interface IGetEntryTrigger {
  type: 'short' | 'long';
  context: IContext;
  Pine: PineJS;
  entryTrigger: ReturnType<typeof getInputValues>['entryTrigger'];
}

const medianDivider = 2;

export const getEntryTrigger = ({
  entryTrigger,
  Pine,
  context,
  type,
}: IGetEntryTrigger) => {
  const close = Pine.Std.close(context);
  const open = Pine.Std.open(context);
  const high = Pine.Std.high(context);
  const low = Pine.Std.low(context);
  const HLMedian = Pine.Std.hl2(context);
  const OCMedian = (open + close) / medianDivider;

  return entryTrigger === 'high/low'
    ? type === 'long'
      ? high
      : low
    : entryTrigger === 'high/low median'
    ? HLMedian
    : entryTrigger === 'close'
    ? close
    : entryTrigger === 'open/close median'
    ? OCMedian
    : high;
};
