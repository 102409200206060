import { SnackbarProvider, OptionsObject, SnackbarKey } from 'notistack';
import { INotificationOut } from '@trader/store';

interface IBaseCreateNotification {
  closeSnackbar: SnackbarProvider['closeSnackbar'];
  notification: INotificationOut;
  removeNotificationFromStore: (uid: string | number) => void;
}

export const createDefaultNotification = ({
  notification,
  removeNotificationFromStore,
}: IBaseCreateNotification): OptionsObject => {
  return {
    key: notification.uid,
    ...notification?.options,
    onExited: (_event, uid) => {
      removeNotificationFromStore(uid);
    },
  };
};

export const createCustomNotification = ({
  notification,
  closeSnackbar,
  removeNotificationFromStore,
}: IBaseCreateNotification): OptionsObject => {
  const customType = notification.customType;
  const customTypeOptions = customType ? customType.options : {};

  const action = customTypeOptions.action
    ? {
        action: (key: SnackbarKey | undefined) =>
          customTypeOptions.action &&
          customTypeOptions.action({
            notification,
            closeSnackbar: () => closeSnackbar(key),
          }),
      }
    : {};

  const content = customTypeOptions.content
    ? {
        content: (key: SnackbarKey | undefined) =>
          customTypeOptions.content &&
          customTypeOptions.content({
            notification,
            closeSnackbar: () => closeSnackbar(key),
          }),
      }
    : {};

  return {
    key: notification.uid,
    ...notification.options,
    // get action && content react components from our callbacks types
    ...action,
    ...content,
    // should be last for always remove closed notification from mst store
    onExited: (_event, uid) => {
      const onExitedCb = notification.options.onExitedCb;
      removeNotificationFromStore(uid);
      onExitedCb && onExitedCb(notification);
    },
  };
};

// An example how to use custom snackbar
// store.notifications.add({
//   customType: {
//     type: 'customType',
//     options: {
//       content: ({ notification, closeSnackbar }) => {
//         return (
//           <div style={{ background: 'green', width: '100%', height: '100%' }}>
//             <p>Hello</p>
//             <button onClick={closeSnackbar}>close</button>
//           </div>
//         );
//       },
//       action: ({ notification, closeSnackbar }) => {
//         return <span onClick={closeSnackbar}>exit</span>;
//       },
//     },
//   },
//   options: {
//     autoHideDuration: 5000,
//     variant: 'success',
//     anchorOrigin: {
//       vertical: 'bottom',
//       horizontal: 'left',
//     },
//   },
// });
