import { PaletteOptions } from '@mui/material/styles/createPalette';

export const lightPallet: PaletteOptions = {
  mode: 'light',
  primary: {
    light: '#66A0FA',
    main: '#0061F7',
    dark: '#1bd27f',
  },
  yellow: {
    light: '#0061F7',
    main: '#D1D53D',
    dark: '#0061F7',
    '100': '#F3B53D',
  },
  green: {
    light: '#74a150',
    main: '#31b228',
    dark: '#225d34',
    '100': '#24d917',
  },
};
