import { getIconBaseProps } from './utils';

export const info = {
  ...getIconBaseProps('info'),
  width: '12px',
  height: '12px',
  viewBox: '0 0 12 12',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M6 11C8.76142 11 11 8.76143 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 0.999999 3.23858 0.999999 6C0.999998 8.76142 3.23857 11 6 11Z'
        stroke='#ABABAB'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.83341 3.77767C6.83341 4.23791 6.46032 4.611 6.00008 4.611C5.53984 4.611 5.16675 4.23791 5.16675 3.77767C5.16675 3.31743 5.53984 2.94434 6.00008 2.94434C6.46032 2.94434 6.83341 3.31743 6.83341 3.77767Z'
        fill='#ABABAB'
      />
      <path
        d='M6 6L6 8.22222'
        stroke='#ABABAB'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
