import styled, { css } from 'styled-components';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  IconButton,
} from '@mui/material';

export const Dialog = styled(MuiDialog)`
  ${() => css``}
`;

export const DialogContent = styled(MuiDialogContent)`
  ${({ theme }) => css`
    font-size: 20px;
    overflow: auto;
    position: relative;
    background: ${theme.palette.background.paper};

    ${theme.breakpoints.down('sm')} {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  `}
`;

export const Close = styled(IconButton)`
  ${() => css`
    position: absolute;
    top: 13px;
    right: 17px;
    > svg {
      width: 22px;
      height: 22px;
    }
  `}
`;
