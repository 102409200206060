import { getIconBaseProps } from './utils';

export const remove = {
  ...getIconBaseProps('remove'),
  width: '10px',
  height: '10px',
  viewBox: '0 0 10 10',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M1 1L7 7M1 7L7 1'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
