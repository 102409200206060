import {
  IContext,
  IPineSeries,
  MappedObject,
  PineJS,
  StudyColorerPlotInfo,
  StudyFilledAreaInfo,
  StudyInputInfo,
  StudyLinePlotInfo,
  StudyNumericInputInfo,
  StudyPlotInformation,
  StudyShapesPlotInfo,
  // StudyShapesPlotInfo,
  StudyStylesInfo,
  StudyTextInputInfo,
} from '../charting_library';
import { typeOptions } from './defaultInputs';
import { getInputValues } from './inputValues';
import { getType, TGetTypeResult } from '../utils/getType';
import { baseDefaultsStyles } from '../constants';

export const bollingerBands = {
  bollingerBand: {
    upper: 'bollingerBandsUpper',
    lower: 'bollingerBandsLower',
    upperOne: 'bollingerBandsUpperOne',
    lowerOne: 'bollingerBandsLowerOne',
    upperTwo: 'bollingerBandsUpperTwo',
    lowerTwo: 'bollingerBandsLowerTwo',
    tpLongSignal: 'bollingerBandsTpLongSignal',
    tpShortSignal: 'bollingerBandsTpShortSignal',
    tpLong2Signal: 'bollingerBandsTpLong2Signal',
    tpShort2Signal: 'bollingerBandsTpShort2Signal',
    tpLong3Signal: 'bollingerBandsTpLong3Signal',
    tpShort3Signal: 'bollingerBandsTpShort3Signal',
  },
};

export const bollingerBandsAreas = {
  area: 'bollingerBandsArea',
  oneArea: 'bollingerBandsOneArea',
  twoArea: 'bollingerBandsTwoArea',
};

export const bollingerBandsPlots: StudyPlotInformation[] = [
  // level one
  {
    id: bollingerBands.bollingerBand.upper,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.lower,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  // filled area between two plots
  {
    id: bollingerBandsAreas.area,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: bollingerBandsAreas.area,
    palette: bollingerBandsAreas.area,
  },

  // level two
  {
    id: bollingerBands.bollingerBand.upperOne,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.lowerOne,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  // filled area between two plots
  {
    id: bollingerBandsAreas.oneArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: bollingerBandsAreas.oneArea,
    palette: bollingerBandsAreas.oneArea,
  },

  // level three
  {
    id: bollingerBands.bollingerBand.upperTwo,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.lowerTwo,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  // filled area between two plots
  {
    id: bollingerBandsAreas.twoArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: bollingerBandsAreas.twoArea,
    palette: bollingerBandsAreas.twoArea,
  },
  {
    id: bollingerBands.bollingerBand.tpLongSignal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.tpShortSignal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.tpLong2Signal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.tpShort2Signal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.tpLong3Signal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: bollingerBands.bollingerBand.tpShort3Signal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
];

export const bollingerBandsInputs: StudyInputInfo[] = [
  {
    id: 'bollingerBandsType',
    name: '[BB] Type',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'wma',

    options: typeOptions,
  },
  {
    id: 'bollingerBandsLength',
    name: '[BB] Length',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 30,
  },
  {
    id: 'bollingerBandsStdDev',
    name: '[BB] StdDev',
    isHidden: true,
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 3,
  },
  {
    id: 'bollingerBandsLongOrders',
    name: '[BB] ↗️ Long orders:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'lower3',
    isHidden: true,
    options: ['lower', 'lower2', 'lower3', 'upper', 'upper2', 'upper3', 'off'],
  },
  {
    id: 'bollingerBandsLongOrdersSecond',
    name: '[BB] Long orders +:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'lower2',
    isHidden: true,
    options: ['lower', 'lower2', 'lower3', 'upper', 'upper2', 'upper3', 'off'],
  },
  {
    id: 'bollingerBandsLongOrdersThird',
    name: '[BB] Long orders +:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'off',
    isHidden: true,
    options: ['basis', 'signal', 'off'],
  },
  {
    id: 'bollingerBandsShortOrders',
    name: '[BB] ↗️ Short orders:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'upper3',
    isHidden: true,
    options: ['upper', 'upper2', 'upper3', 'lower', 'lower2', 'lower3', 'off'],
  },
  {
    id: 'bollingerBandsShortOrdersSecond',
    name: '[BB] Short orders +:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'upper2',
    isHidden: true,
    options: ['upper', 'upper2', 'upper3', 'lower', 'lower2', 'lower3', 'off'],
  },
  {
    id: 'bollingerBandsShortOrdersThird',
    name: '[BB] Short orders +:',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'off',
    isHidden: true,
    options: ['basis', 'signal', 'off'],
  },
];

export const bollingerPlotsStyles: MappedObject<StudyStylesInfo> = {
  [bollingerBands.bollingerBand.upper]: {
    histogramBase: 0,
    title: '[BB] Upper',
  },
  [bollingerBands.bollingerBand.lower]: {
    histogramBase: 0,
    title: '[BB] Lower',
  },
  [bollingerBands.bollingerBand.upperOne]: {
    histogramBase: 0,
    title: '[BB] Upper 1',
  },
  [bollingerBands.bollingerBand.lowerOne]: {
    histogramBase: 0,
    title: '[BB] Lower 1',
  },
  [bollingerBands.bollingerBand.upperTwo]: {
    histogramBase: 0,
    title: '[BB] Upper 2',
  },
  [bollingerBands.bollingerBand.lowerTwo]: {
    histogramBase: 0,
    title: '[BB] Lower 2',
  },
  [bollingerBands.bollingerBand.tpLongSignal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Short, price crossing BB 1',
    title: 'Short Signal Shape',
  },
  [bollingerBands.bollingerBand.tpShortSignal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Long, price crossing BB 1',
    title: 'Long Signal Shape',
  },
  [bollingerBands.bollingerBand.tpLong2Signal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Short, price crossing BB 2',
    title: 'Short Signal Shape',
  },
  [bollingerBands.bollingerBand.tpShort2Signal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Long, price crossing BB 2',
    title: 'Long Signal Shape',
  },
  [bollingerBands.bollingerBand.tpLong3Signal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Short, price crossing BB 3',
    title: 'Short Signal Shape',
  },
  [bollingerBands.bollingerBand.tpShort3Signal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Long, price crossing BB 3',
    title: 'Long Signal Shape',
  },
};

export const bollingerBandsFilledAreas: StudyFilledAreaInfo[] = [
  {
    id: bollingerBandsAreas.area,
    objAId: bollingerBands.bollingerBand.upper,
    objBId: bollingerBands.bollingerBand.lower,
    title: '[BB] Background 1-1',
    type: 'plot_plot' as StudyFilledAreaInfo['type'],
    palette: bollingerBandsAreas.area,
  },
  {
    id: bollingerBandsAreas.oneArea,
    objAId: bollingerBands.bollingerBand.upperOne,
    objBId: bollingerBands.bollingerBand.lowerOne,
    title: '[BB] Background 2-2',
    type: 'plot_plot' as StudyFilledAreaInfo['type'],
    palette: bollingerBandsAreas.oneArea,
  },
  {
    id: bollingerBandsAreas.twoArea,
    objAId: bollingerBands.bollingerBand.upperTwo,
    objBId: bollingerBands.bollingerBand.lowerTwo,
    title: '[BB] Background 3-3',
    type: 'plot_plot' as StudyFilledAreaInfo['type'],
    palette: bollingerBandsAreas.twoArea,
  },
];

export const bollingerBandsPallets = {
  [bollingerBandsAreas.area]: {
    valToIndex: {
      0: 0,
    },
    colors: {
      0: {
        name: 'Background color',
      },
    },
  },
  [bollingerBandsAreas.oneArea]: {
    valToIndex: {
      0: 0,
    },
    colors: {
      0: {
        name: 'Background color',
      },
    },
  },
  [bollingerBandsAreas.twoArea]: {
    valToIndex: {
      0: 0,
    },
    colors: {
      0: {
        name: 'Background color',
      },
    },
  },
};

export const bollingerBandsDefaults = {
  filledAreasStyle: {
    [bollingerBandsAreas.area]: {
      color: 'rgba(148,26,189,0.45)',
      visible: true,
      transparency: 0,
    },
    [bollingerBandsAreas.oneArea]: {
      color: 'rgba(148,26,189,0.11)',
      visible: true,
      transparency: 0,
    },
    [bollingerBandsAreas.twoArea]: {
      color: 'rgba(148,26,189,0.15)',
      visible: true,
      transparency: 0,
    },
  },
  pallets: {
    [bollingerBandsAreas.area]: {
      colors: {
        0: {
          color: 'rgba(225, 190, 231, 0.63)',
          width: 3,
          style: 0,
        },
      },
    },
    [bollingerBandsAreas.oneArea]: {
      colors: {
        0: {
          color: 'rgba(148,26,189,0.11)',
          width: 1,
          style: 0,
        },
      },
    },
    [bollingerBandsAreas.twoArea]: {
      colors: {
        0: {
          color: 'rgba(148,26,189,0.15)',
          width: 1,
          style: 0,
        },
      },
    },
  },
  styles: {
    [bollingerBands.bollingerBand.upper]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.lower]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.upperOne]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.lowerOne]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.upperTwo]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.lowerTwo]: baseDefaultsStyles,
    [bollingerBands.bollingerBand.tpLongSignal]: {
      color: 'rgba(227,13,37,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_up',
      location: 'BelowBar',
      visible: true,
    },
    [bollingerBands.bollingerBand.tpShortSignal]: {
      color: 'rgba(77,180,43,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_down',
      location: 'AboveBar',
      visible: true,
    },
    [bollingerBands.bollingerBand.tpLong2Signal]: {
      color: 'rgba(227,13,37,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_up',
      location: 'BelowBar',
      visible: true,
    },
    [bollingerBands.bollingerBand.tpShort2Signal]: {
      color: 'rgba(77,180,43,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_down',
      location: 'AboveBar',
      visible: true,
    },
    [bollingerBands.bollingerBand.tpLong3Signal]: {
      color: 'rgba(227,13,37,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_up',
      location: 'BelowBar',
      visible: true,
    },
    [bollingerBands.bollingerBand.tpShort3Signal]: {
      color: 'rgba(77,180,43,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_down',
      location: 'AboveBar',
      visible: true,
    },
  },
};

interface IBollingerBands {
  context: IContext;
  source: IPineSeries;
  Pine: PineJS;
  deviation: number;
  inputValues: ReturnType<typeof getInputValues>;
}

export const getBollingerBandsValues = ({
  context,
  source,
  Pine,
  deviation,
  inputValues,
}: IBollingerBands) => {
  const bollingerBandsZero = 0.618;
  const bollingerBandsOne = 1.618;
  const bollingerBandsTwo = 2.618;

  const high = Pine.Std.high(context);
  const low = Pine.Std.low(context);

  const valuesFromType: TGetTypeResult = getType({
    context,
    source,
    Pine,
  });
  const valueFromType: number = valuesFromType[inputValues.bbType](
    inputValues.bbLength
  );

  const BBUpper = valueFromType + deviation * bollingerBandsZero;
  const BBLower = valueFromType - deviation * bollingerBandsZero;

  const BBUpperOne = valueFromType + deviation * bollingerBandsOne;
  const BBLowerOne = valueFromType - deviation * bollingerBandsOne;

  const BBUpperTwo = valueFromType + deviation * bollingerBandsTwo;
  const BBLowerTwo = valueFromType - deviation * bollingerBandsTwo;

  const isBBUpper3CrossingPrice = Pine.Std.cross(high, BBLowerTwo, context);
  const isBBLower3CrossingPrice = Pine.Std.cross(low, BBUpperTwo, context);
  const BBUpper3CrossingPrice = isBBUpper3CrossingPrice ? 1 : NaN;
  const BBLower3CrossingPrice = isBBLower3CrossingPrice ? 1 : NaN;

  const isBBUpper2CrossingPrice = Pine.Std.cross(high, BBLowerOne, context);
  const isBBLower2CrossingPrice = Pine.Std.cross(low, BBUpperOne, context);
  const BBUpper2CrossingPrice = isBBUpper3CrossingPrice
    ? NaN
    : isBBUpper2CrossingPrice
    ? 1
    : NaN;
  const BBLower2CrossingPrice = isBBLower3CrossingPrice
    ? NaN
    : isBBLower2CrossingPrice
    ? 1
    : NaN;

  const isBBUpperCrossingPrice = Pine.Std.cross(high, BBLower, context);
  const isBBLowerCrossingPrice = Pine.Std.cross(low, BBUpper, context);
  const BBUpperCrossingPrice = BBUpper2CrossingPrice
    ? NaN
    : isBBUpperCrossingPrice
    ? 1
    : NaN;
  const BBLowerCrossingPrice = BBLower2CrossingPrice
    ? NaN
    : isBBLowerCrossingPrice
    ? 1
    : NaN;

  return {
    values: {
      BBSma: valueFromType,
      BBUpper,
      BBLower,
      BBUpperOne,
      BBLowerOne,
      BBUpperTwo,
      BBLowerTwo,
    },
    result: [
      BBUpper,
      BBLower,
      0,
      BBUpperOne,
      BBLowerOne,
      0,
      BBUpperTwo,
      BBLowerTwo,
      0,
      inputValues.useTakeProfit ? BBUpperCrossingPrice : NaN,
      inputValues.useTakeProfit ? BBLowerCrossingPrice : NaN,
      inputValues.useTakeProfit2 ? BBUpper2CrossingPrice : NaN,
      inputValues.useTakeProfit2 ? BBLower2CrossingPrice : NaN,
      inputValues.useTakeProfit3 ? BBUpper3CrossingPrice : NaN,
      inputValues.useTakeProfit3 ? BBLower3CrossingPrice : NaN,
    ],
  };
};
