import React, { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import MuiPopover, { PopoverProps } from '@mui/material/Popover';

interface IPopover
  extends Pick<
    PopoverProps,
    | 'anchorOrigin'
    | 'disableRestoreFocus'
    | 'disableScrollLock'
    | 'elevation'
    | 'transformOrigin'
  > {
  behavior?: 'click' | 'hover';
  component?: React.ElementType;
  content: ReactNode;
  showContent?: boolean;
  showTrigger?: boolean;
  trigger: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Popover: FC<IPopover> = ({
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  behavior = 'click',
  component = 'div',
  content,
  disableRestoreFocus,
  disableScrollLock,
  elevation,
  showContent = true,
  showTrigger = true,
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  trigger,
  onOpen,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (behavior === 'hover' || isOpen) {
      return;
    }

    return handleShowContent(event);
  };

  const handleShowContent = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen && onOpen();
  };

  const handleHideContent = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'mui-popover' : undefined;

  const renderTrigger = () => {
    if (!showTrigger) {
      return null;
    }

    return (
      <Box id={id} component={component}>
        {trigger}
      </Box>
    );
  };

  const renderContent = () => {
    if (!showContent) {
      return null;
    }

    return (
      <MuiPopover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        disableRestoreFocus={disableRestoreFocus}
        disableScrollLock={disableScrollLock}
        elevation={elevation}
        onClose={handleHideContent}
        open={isOpen}
        sx={{
          pointerEvents: behavior === 'hover' ? 'none' : 'initial',
          '.MuiPaper-root': {
            borderRadius: '5px',
          },
        }}
        transformOrigin={transformOrigin}
      >
        <Box
          style={{ pointerEvents: behavior === 'hover' ? 'auto' : undefined }}
        >
          {content}
        </Box>
      </MuiPopover>
    );
  };

  return (
    <Box
      component={component}
      onClick={handleClick}
      onMouseEnter={behavior === 'hover' ? handleShowContent : undefined}
      onMouseLeave={behavior === 'hover' ? handleHideContent : undefined}
    >
      {renderTrigger()}
      {renderContent()}
    </Box>
  );
};
