import React from 'react';
import { observer } from 'mobx-react-lite';

import { ModalNotification } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

export const DailyTargetProfitReached: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  return (
    <ModalNotification
      iconTypeName='filledCircleCheckmark'
      title={translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED')}
      content={translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED_DESCRIPTION')}
      buttonContent={translate('COMMON.LABELS.CLOSE')}
      onClick={store.ui.modal.close}
    />
  );
});
