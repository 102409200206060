import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { UseFormReset } from 'react-hook-form';

import { useChangePasswordSchema } from '@trader/hooks';
import { Controller, Form } from '@trader/components';
import { IChangePasswordParams } from '@trader/api';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import * as Styled from './styled';

type TInitialState = {
  password: string;
  proposedPassword: string;
  matchProposedPassword: string;
};

type THandleSubmit = (
  reset: UseFormReset<TInitialState>,
  data: TInitialState
) => void;

const defaultValues = {
  password: '',
  proposedPassword: '',
  matchProposedPassword: '',
};

export const ChangePassword: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { validationSchema } = useChangePasswordSchema();

  const handleSubmit: THandleSubmit = async (reset, data) => {
    const body: IChangePasswordParams = {
      previousPassword: data.password,
      proposedPassword: data.proposedPassword,
    };
    await store.auth.changePasswordAsync.run(body);
    reset(defaultValues);
  };

  return (
    <Styled.Root>
      <Form<TInitialState>
        onSubmit={(reset, data) => handleSubmit(reset, data)}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        mode='all'
      >
        {({ control, formState }) => (
          <Fragment>
            <Styled.Field>
              <Styled.Label>
                {translate('COMMON.LABELS.CURRENT_PASSWORD')}
              </Styled.Label>
              <Controller
                type='passwordField'
                keyboardType='password'
                label={translate('COMMON.LABELS.CURRENT_PASSWORD')}
                control={control}
                name='password'
              />
            </Styled.Field>
            <Styled.Field>
              <Styled.Label>
                {translate('COMMON.LABELS.NEW_PASSWORD')}
              </Styled.Label>
              <Controller
                type='passwordField'
                keyboardType='password'
                label={translate('COMMON.LABELS.NEW_PASSWORD')}
                control={control}
                name='proposedPassword'
              />
            </Styled.Field>
            <Styled.Field>
              <Styled.Label>
                {translate('COMMON.LABELS.RETYPE_PASSWORD')}
              </Styled.Label>
              <Controller
                type='passwordField'
                keyboardType='password'
                label={translate('COMMON.LABELS.RETYPE_PASSWORD')}
                control={control}
                name='matchProposedPassword'
              />
            </Styled.Field>
            <Styled.Container>
              <Styled.Save disabled={!formState.isDirty} type='submit'>
                {translate('COMMON.LABELS.SAVE_CHANGES')}
              </Styled.Save>
            </Styled.Container>
          </Fragment>
        )}
      </Form>
    </Styled.Root>
  );
});
