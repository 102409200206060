import { requestService, TAsyncActionOptions } from '@trader/services';

import { IPlaceOrderBody } from './models/placeOrder';
import { tradingRoutes } from './routes';
import {
  IAssetOrderItemsBE,
  IEditOrderParams,
  IGetAssetOrderMetricsParams,
  IOrderItemsBE,
} from './models/ordersMetrics';
import {
  ICloseAllPositionsParams,
  IClosePositionParams,
  IEditPositionParams,
  IPositionItemsBE,
} from './models/positionsMetrics';
import {
  IRequiredOpenCostBE,
  IRequiredOpenCostParams,
} from './models/requiredOpenCost';
import {
  IPositionParamsBE,
  IPositionsHistoryBE,
} from './models/positionsHistory';
import {
  ICostAndChargesBE,
  IGetCostAndChargesParams,
} from './models/costAndCharges';
import {
  IAccountStatementBE,
  IGetAccountStatementParams,
} from './models/accountStatement';

export class Trading {
  routes = tradingRoutes;

  async placeOrder(
    body: IPlaceOrderBody,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.post<void>(this.routes.placeOrder(), body, {
      ...options.cancelToken,
    });
  }

  async placeChallengeOrder(
    body: IPlaceOrderBody,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.post<void>(this.routes.placeChallengeOrder(), body, {
      ...options.cancelToken,
    });
  }

  async getOrdersMetrics(): Promise<IOrderItemsBE> {
    const resp = await requestService.get<IOrderItemsBE>(
      this.routes.getOrdersMetrics
    );
    return resp?.data;
  }

  async getAssetOrdersMetrics(
    params: IGetAssetOrderMetricsParams
  ): Promise<IAssetOrderItemsBE> {
    const resp = await requestService.get<IAssetOrderItemsBE>(
      this.routes.getAssetOrderMetrics,
      {
        params,
      }
    );
    return resp?.data;
  }

  async getPositionsMetrics(): Promise<IPositionItemsBE> {
    const resp = await requestService.get<IPositionItemsBE>(
      this.routes.getPositionsMetrics
    );
    return resp?.data;
  }

  async getRequiredOpenCost(
    params: IRequiredOpenCostParams,
    options: TAsyncActionOptions
  ): Promise<IRequiredOpenCostBE> {
    const resp = await requestService.get<IRequiredOpenCostBE>(
      this.routes.getRequiredOpenCost,
      {
        ...options.cancelToken,
        params,
      }
    );
    return resp?.data;
  }

  async cancelOrder(
    orderId: string,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.delete<void>(
      this.routes.cancelOrder(orderId),
      {},
      {
        ...options.cancelToken,
      }
    );
  }

  async cancelChallengeOrder(
    orderId: string,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.delete<void>(
      this.routes.cancelChallengeOrder(orderId),
      {},
      {
        ...options.cancelToken,
      }
    );
  }

  async editOrder(
    params: IEditOrderParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.put<void>(
      this.routes.editOrder(params.orderId),
      params.body,
      {
        ...options.cancelToken,
      }
    );
  }

  async editChallengeOrder(
    params: IEditOrderParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.put<void>(
      this.routes.editChallengeOrder(params.orderId),
      params.body,
      {
        ...options.cancelToken,
      }
    );
  }

  async closePosition(
    closePositionParams: IClosePositionParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.delete<void>(
      this.routes.closePosition(closePositionParams.positionId),
      {},
      {
        params: {
          quantity: closePositionParams.quantity,
        },
        ...options.cancelToken,
      }
    );
  }

  async closeChallengePosition(
    closePositionParams: IClosePositionParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.delete<void>(
      this.routes.closeChallengePosition(closePositionParams.positionId),
      {},
      {
        params: {
          quantity: closePositionParams.quantity,
        },
        ...options.cancelToken,
      }
    );
  }

  async editPosition(
    params: IEditPositionParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.put<void>(
      this.routes.editPosition(params.positionId),
      params.body,
      {
        ...options.cancelToken,
      }
    );
  }

  async editChallengePosition(
    params: IEditPositionParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    await requestService.put<void>(
      this.routes.editChallengePosition(params.positionId),
      params.body,
      {
        ...options.cancelToken,
      }
    );
  }

  async getPositionsHistory(
    params: IPositionParamsBE,
    options: TAsyncActionOptions
  ): Promise<IPositionsHistoryBE> {
    const resp = await requestService.get<IPositionsHistoryBE>(
      this.routes.getPositionsHistory,
      {
        params: {
          completedFrom: params.completedFrom,
          completedTo: params.completedTo,
          openedFrom: params.openedTo,
          openedTo: params.openedFrom,
        },
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getCostAndCharges(
    params: IGetCostAndChargesParams,
    options?: TAsyncActionOptions
  ): Promise<ICostAndChargesBE> {
    const resp = await requestService.get<ICostAndChargesBE>(
      this.routes.getCostAndCharges,
      {
        ...options?.cancelToken,
        params,
      }
    );
    return resp?.data;
  }

  async getAccountStatement(
    params: IGetAccountStatementParams,
    options?: TAsyncActionOptions
  ): Promise<IAccountStatementBE> {
    const resp = await requestService.get<IAccountStatementBE>(
      this.routes.getAccountStatement,
      {
        params: {
          from: params.from,
          to: params.to,
          platformLogin: params.platformLogin,
        },
        headers: {
          'Account-Type': params.accountType,
          'Platform-Login': params.platformLogin,
        },
        ...options?.cancelToken,
      }
    );
    return resp?.data;
  }

  async closeAllPositions(
    params?: ICloseAllPositionsParams,
    options?: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.closeAllPositions,
      {},
      {
        params,
        ...options?.cancelToken,
      }
    );
    return resp?.data;
  }

  async cancelAllOrders(options?: TAsyncActionOptions): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.cancelAllOrders,
      {},
      {
        ...options?.cancelToken,
      }
    );
    return resp?.data;
  }

  async closeAllChallengePositions(
    params?: ICloseAllPositionsParams,
    options?: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.closeAllChallengePositions,
      {},
      {
        params,
        ...options?.cancelToken,
      }
    );
    return resp?.data;
  }

  async cancelAllChallengeOrders(options?: TAsyncActionOptions): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.cancelAllChallengeOrders,
      {},
      {
        ...options?.cancelToken,
      }
    );
    return resp?.data;
  }
}
