import styled, { css } from 'styled-components';
import { Button, Typography } from '@trader/components';

export const ForgotPassword = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.gray.main};
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    font-family: Poppins, serif;
    cursor: pointer;
  `};
`;

export const Submit = styled(Button)`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 500;
    font-family: Poppins, serif;
    border-radius: 5px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.primary.main};
    margin: 20px 0 20px 0;

    &:hover {
      background: ${theme.palette.primary.main};
    }
  `};
`;
