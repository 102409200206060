import { types, Instance, cast } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

export const stripModel = types
  .model('muliBandModel', {
    longStrip: types.optional(types.string, 'N/A'),
    shortStrip: types.optional(types.string, 'N/A'),
    vwapTrend: types.optional(types.string, 'N/A'),
    movingValues: types.array(
      types.model('movingValues', {
        title: types.string,
        value: types.string,
      })
    ),
  })
  .views(() => ({}))
  .actions(store => ({
    runInAction,
    updateLongStrip: (longStrip: string) => {
      store.longStrip = longStrip || 'N/A';
    },
    updateShortStrip: (shortStrip: string) => {
      store.shortStrip = shortStrip || 'N/A';
    },
    updateVwapTrend: (vwapTrend: string) => {
      store.vwapTrend = vwapTrend;
    },
    updateMovingValues: (values: Array<{ title: string; value: string }>) => {
      store.movingValues = cast(values);
    },
  }));

export const strip = types.optional(stripModel, {});

export type TStripStore = Instance<typeof stripModel>;
