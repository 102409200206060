import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

import { Typography } from '@trader/components';

export const ValueLabel = styled(Typography)`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 700;
    flex-direction: column;
    text-transform: capitalize;
    color: ${theme.palette.black.main};
    background: ${theme.palette.aqua[100]};
    padding: 1px 6px;
    border-radius: 2px;
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Root = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 4px 10px;
    border-radius: 5px;
    background-color: ${theme.palette.aqua[100]};

    :nth-child(1) {
      color: ${theme.palette.black.main};
      text-transform: capitalize;
      font-size: 10px;
    }

    :nth-child(2) {
      color: ${theme.palette.black.main};
      font-size: 12px;
      font-weight: 700;
    }
  `}
`;
