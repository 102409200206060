import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { TAlertEntity, useMst } from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';
import { useI18next } from '@trader/services';
import { dateFormats, formatTableRowValueByPipSize } from '@trader/utils';
import {
  usePriceAlertsCreation,
  usePriceAlertsCurrentPrice,
} from '@trader/hooks';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  PurchaseType,
  ScrollingList,
  SymbolWithIcon,
} from '../scrollingList';
import { IconButton } from '../iconButton';
import { Icon } from '../icon';
import { Wrapper } from '../wrapper';

import * as Styled from './styled';

export const AlertsList = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();

  usePriceAlertsCurrentPrice();
  usePriceAlertsCreation();

  const alerts = store.entities.alerts.getAll<TAlertEntity>();

  useEffect(() => {
    store.entities.alerts.getAlertsAsync.run();
  }, []);

  const cells: Array<IHeadCell> = [
    {
      id: 'symbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 120,
      padding: '0 10px 0 30px',
      align: 'start',
    },
    {
      id: 'side',
      label: translate('COMMON.LABELS.TYPE'),
      align: 'center',
    },
    {
      id: 'name',
      label: translate('COMMON.LABELS.CREATION_DATE'),
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'endTime',
      label: translate('COMMON.LABELS.EXPIRATION_DATE'),
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'alertPrice',
      label: translate('COMMON.LABELS.ALERT_WHEN'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'currentPrice',
      label: translate('COMMON.LABELS.CURRENT_PRICE'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'actions',
      label: '',
      minWidth: 120,
      align: 'end',
    },
  ];

  const renderActions = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    const openModal = (modalType: keyof typeof MODAL_TYPES) => {
      store.ui.modal.open(modalType, {
        borderRadius: 10,
        symbol: instrument.symbol,
        alertId: alert.id,
      });
    };

    return (
      <Styled.Actions>
        <IconButton
          iconType='edit'
          onClick={event => {
            event.stopPropagation();
            openModal(MODAL_TYPES.editPriceAlert);
          }}
        />
        <IconButton
          iconType='remove'
          onClick={event => {
            event.stopPropagation();
            openModal(MODAL_TYPES.deletePriceAlert);
          }}
        />
      </Styled.Actions>
    );
  };

  const renderAlertPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;
    const rightExpression = alert?.conditions[0]?.rightExpression;
    const defaultAlertPrice = parseFloat(rightExpression?.parameters?.Number);

    return (
      <Wrapper alignItems='center' justifyContent='center'>
        <Styled.Title>
          {instrument?.currencySymbol}
          {defaultAlertPrice
            ? +formatTableRowValueByPipSize(
                defaultAlertPrice,
                instrument?.pipSize
              )
            : ''}
        </Styled.Title>
        <Icon
          style={{ margin: '0 0 1px 4px' }}
          iconType={
            alert?.conditions[0]?.compareType === 'BiggerThen'
              ? 'abovePrice'
              : 'belowPrice'
          }
        />
      </Wrapper>
    );
  };

  const renderPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    return (
      <Styled.Title>
        {instrument?.currencySymbol}
        {formatTableRowValueByPipSize(
          instrument?.currentPrice,
          instrument?.pipSize
        )}
      </Styled.Title>
    );
  };

  const renderSide = item => {
    const alert = item.row;
    const indicatorType = alert.conditions[0]?.leftExpression?.indicatorType;
    const side =
      indicatorType === 'Ask' ? 'Sell' : indicatorType === 'Bid' ? 'Buy' : '';

    if (!side) {
      return;
    }

    return <PurchaseType value={side} />;
  };

  const renderRowItemsCb: IRenderRowItemsCallBacks<TAlertEntity> = useMemo(
    () => ({
      symbol: item => {
        const alert = item.row;
        const instrument = alert.conditions[0].instrument;
        return (
          <SymbolWithIcon
            symbol={instrument?.symbol}
            iconUrl={instrument?.iconUrl}
          />
        );
      },
      side: renderSide,
      name: item =>
        `${dateFormats(
          new Date(item.value as string),
          'Mm dd, yyyy hh:mm:ss',
          currentLng
        )}`,
      endTime: item =>
        `${
          item.value
            ? dateFormats(
                new Date(item.value as string),
                'Mm dd, yyyy hh:mm:ss',
                currentLng
              )
            : ''
        }`,
      alertPrice: renderAlertPrice,
      currentPrice: renderPrice,
      actions: renderActions,
    }),
    []
  );

  return (
    <Styled.Root>
      <ScrollingList<TAlertEntity>
        rows={alerts}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
        totalCount={alerts.length}
      />
    </Styled.Root>
  );
});
