import React, { useEffect, useState } from 'react';
import { FormControlLabel as ControlLabel } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';

import { ControlInfo } from '../controlInfo';

import * as Styled from './styled';

export interface ISwitcher
  extends Omit<SwitchProps, 'helperText' | 'onChange' | 'title'> {
  shouldHideControlInfo?: boolean;
  isError?: boolean;
  helperText?: string;
  label?: string;
  leftLabel?: string;
  checked?: boolean;
  isDisabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // remove second arg: checked for one signature of diff inputs onChange cb
}

interface IState {
  checked: boolean;
}

const SwitcherComp = React.forwardRef<HTMLButtonElement, ISwitcher>(
  (props, ref) => {
    const [state, setState] = useState<IState>({ checked: !!props.checked });

    useEffect(() => {
      setState({ ...state, checked: !!props.checked });
    }, [props.checked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, checked: !state.checked });
      props.onChange && props.onChange(event);
    };

    return (
      <Styled.Container
        className={props.className}
        checked={state.checked}
        $isDisabled={props.isDisabled}
        $isError={props.isError}
      >
        {props.leftLabel && (
          <Styled.LeftLabel>{props.leftLabel}</Styled.LeftLabel>
        )}
        <ControlLabel
          disabled={props.isDisabled}
          style={{ margin: 0 }}
          control={
            <Styled.Switcher
              ref={ref}
              {...getValidProps(props)}
              checked={state.checked}
              onChange={handleChange}
              onBlur={props.onBlur}
              color='primary'
            />
          }
          label={props.label}
        />
        {!props.shouldHideControlInfo && (
          <ControlInfo
            helperText={props.helperText}
            isError={props.isError}
            isDisabled={props.isDisabled}
          />
        )}
      </Styled.Container>
    );
  }
);

export const Switcher = React.memo(SwitcherComp);

const getValidProps = (props: ISwitcher): Partial<ISwitcher> => {
  const {
    type, // 'switcher'
    label,
    className,
    shouldHideControlInfo,
    helperText,
    isError,
    leftLabel,
    ...rest
  } = props;
  return rest;
};
