import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `};
`;
