import { getIconBaseProps } from './utils';

export const purchaseChallenge = {
  ...getIconBaseProps('purchaseChallenge'),
  width: '20px',
  height: '21px',
  viewBox: '0 0 20 21',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M6 10.5H10M10 10.5H14M10 10.5V14.5M10 10.5V6.5'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='10'
        cy='10.5'
        r='9'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
