export const getAccountTypeForConnection = {
  Demo: 1,
  Live: 2,
};
export const getAccountTypeName = {
  1: 'Demo',
  2: 'Live',
};
export enum EAccountTypeNames {
  Demo = 'Demo',
  Live = 'Live',
}
