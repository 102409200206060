import i18next from 'i18next';
import { cast } from 'mobx-state-tree';

import { devLoggerService } from '@trader/services';
import { returnTypedThis } from '@trader/utils';
import { MODAL_TYPES } from '@trader/constants';
import { EChallenge } from '@trader/types';
import { api } from '@trader/api';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { TCopierStore } from './index';
import { IChallengeEntitySnapshotOut, TChallengeEntity } from '@trader/store';

export const assignMasterAsync = createThunk<number | undefined, void>(
  masterId =>
    async function assignMaster(this: unknown, options, _flow) {
      const that = returnTypedThis<TCopierStore>(this);
      const root = getRootInstance();

      try {
        const masterIds = masterId ? [masterId] : that.selectedChallenges;
        await api.Challenge.assignMaster(options, {
          masterIds: masterId ? [masterId] : that.selectedChallenges,
        });

        masterIds.forEach(id => {
          root.entities.challenges.update(id, {
            hierarchyType: EChallenge.Master,
          });
        });

        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.COPIER.MASTER_CREATED'),
          options: {
            variant: 'success',
          },
        });

        that.runInAction(() => {
          that.selectedChallenges = cast([]);
        });
      } catch (e) {
        devLoggerService.error('catch assignMasterAsync error', e);
      }
    }
);

interface IAssignSlaveAsync {
  slaveId?: number;
  masterId?: number;
}

export const assignSlaveAsync = createThunk<IAssignSlaveAsync, void>(
  ({ slaveId, masterId }) =>
    async function assignSlave(this: unknown, options, _flow) {
      const that = returnTypedThis<TCopierStore>(this);
      const root = getRootInstance();

      const mastersAccounts = that.getChallengesByType(EChallenge.Master);

      const challengesAmounts = new Set<number>();
      (slaveId ? [slaveId] : that.selectedChallenges).forEach(id => {
        const challenge =
          root.entities.challenges.get<IChallengeEntitySnapshotOut>(id);
        challenge && challengesAmounts.add(challenge.challengeAmount);
      });

      const selectedSlaveAmount = challengesAmounts.values().next().value;

      if (challengesAmounts.size > 1 && !slaveId) {
        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.COPIER.SIMILAR_CHALLENGES'),
          options: {
            variant: 'warning',
          },
        });
        return;
      }

      const mastersAccountsWithTheSameAmount = mastersAccounts.filter(
        acc => acc.challengeAmount === selectedSlaveAmount
      );

      if (!mastersAccountsWithTheSameAmount?.length) {
        root.notifications.add({
          message: i18next.t(
            'NOTIFICATIONS.COPIER.CREATE_MASTER_BEFORE_PROCEED'
          ),
          options: {
            variant: 'warning',
          },
        });
        return;
      }

      if (mastersAccountsWithTheSameAmount?.length > 1 && !masterId) {
        root.ui.modal.open(MODAL_TYPES.connectMaster, {
          shouldHideCloseButton: true,
          selectedSlaveAmount: selectedSlaveAmount,
          slaveId: slaveId,
        });
        return;
      }

      try {
        const slaveIds = slaveId ? [slaveId] : that.selectedChallenges;
        const masterAccId = masterId || mastersAccountsWithTheSameAmount[0].id;

        await api.Challenge.assignSlave(options, {
          slaveIds,
          masterId: masterAccId,
        });

        slaveIds.forEach(id => {
          root.entities.challenges.update(id, {
            hierarchyType: EChallenge.Slave,
            masterId: masterAccId,
          });
        });

        that.runInAction(() => {
          that.selectedChallenges = cast([]);
        });

        root.ui.modal.close();

        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.COPIER.SUB_CONNECTED'),
          options: {
            variant: 'success',
          },
        });
      } catch (e) {
        devLoggerService.error('catch assignSlaveAsync error', e);
      }
    }
);

interface IRemoveAccountAsync {
  challengeId: number;
  isConfirmed?: boolean;
}

export const removeAccountAsync = createThunk<IRemoveAccountAsync, void>(
  ({ challengeId, isConfirmed }) =>
    async function removeAccount(this: unknown, options, _flow) {
      const that = returnTypedThis<TCopierStore>(this);
      const root = getRootInstance();

      const hierarchyType =
        root.entities.challenges.get<IChallengeEntitySnapshotOut>(
          challengeId
        )?.hierarchyType;

      if (hierarchyType === EChallenge.Master && !isConfirmed) {
        root.ui.modal.open(MODAL_TYPES.removeMasterAccount, {
          shouldHideCloseButton: true,
          challengeId,
        });
        return;
      }

      try {
        if (hierarchyType === EChallenge.Master) {
          await api.Challenge.removeMaster(options, challengeId);

          root.entities.challenges.update(challengeId, {
            hierarchyType: EChallenge.NonAssigned,
          });

          const slaves = root.entities.challenges
            .getAll<TChallengeEntity>()
            ?.filter(challenge => challenge.masterId === challengeId);

          slaves.forEach(({ id }) => {
            root.entities.challenges.update(id, {
              hierarchyType: EChallenge.NonAssigned,
              masterId: null,
            });
          });
        } else {
          await api.Challenge.removeSlave(options, challengeId);
          root.entities.challenges.update(challengeId, {
            hierarchyType: EChallenge.NonAssigned,
            masterId: null,
          });
        }

        root.ui.modal.close();

        root.notifications.add({
          message: i18next.t('NOTIFICATIONS.COPIER.ACC_DELETED'),
          options: {
            variant: 'success',
          },
        });

        that.runInAction(() => {
          that.selectedChallenges = cast([]);
        });
      } catch (e) {
        devLoggerService.error('catch assignMasterAsync error', e);
      }
    }
);
