import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { oneSecond } from '@trader/constants';
import { useMst } from '@trader/store';

import * as Styled from './styled';
import { Icon, Typography } from '@trader/components';
import { useI18next } from '@trader/services';

const logUserOutInTenSeconds = 10;

export const LogOutWithNoActivity: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const [logOutIn, setLogOutIn] = useState(logUserOutInTenSeconds);

  const proceed = () => {
    store.auth.authRefreshTokenAsync.run();
    store.ui.modal.close();
    window.document.location.reload();
  };

  const logOut = () => {
    store.auth.logOut();
    store.ui.modal.close();
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setLogOutIn(prev => {
        const result = prev - 1;

        if (result === 0) {
          store.auth.logOut();
          store.ui.modal.close();
        }

        return result;
      });
    }, oneSecond);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Styled.Root>
      <Icon iconType='warning' />
      <Styled.Content>
        <Typography>
          {translate('COMMON.LABELS.SESSION_IS_ABOUT_TO_EXPIRE')}
        </Typography>
        <Typography>{translate('COMMON.LABELS.LOGGED_OUT')}</Typography>
        <Typography>
          {logOutIn}{' '}
          {translate(
            logOutIn === 1 ? 'COMMON.LABELS.SECOND' : 'COMMON.LABELS.SECONDS'
          )}
        </Typography>
        <Typography>{translate('COMMON.LABELS.STAY_SIGN_IN')}</Typography>
      </Styled.Content>
      <Styled.Footer>
        <Styled.ActionButton $isSignOut size='medium' onClick={logOut}>
          {translate('COMMON.LABELS.SIGN_ME_OUT')}
        </Styled.ActionButton>
        <Styled.ActionButton size='medium' onClick={proceed}>
          {translate('COMMON.LABELS.KEEP_SIGNED_IN')}
        </Styled.ActionButton>
      </Styled.Footer>
    </Styled.Root>
  );
});
