import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { TCommonOrderType } from '@trader/types';
import { isValueTimesStep } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';

export const useAmountSchema = (type: TCommonOrderType, maxValue?: number) => {
  const { translate } = useI18next();
  const store = useMst();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const amountSchema = yup
    .number()
    .test(
      'increment',
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: instrument.minOrderSizeIncrement,
      }),
      value =>
        !!value && isValueTimesStep(value, instrument.minOrderSizeIncrement)
    )
    .required(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.minOrderSize,
      })
    )
    .max(
      maxValue || instrument.maxOrderSize,
      translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
        amount: maxValue || instrument.maxOrderSize,
      })
    )
    .min(
      instrument.minOrderSize,
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: instrument.minOrderSizeIncrement,
      })
    )
    .typeError(
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: instrument.minOrderSizeIncrement,
      })
    );

  return { amountSchema };
};
