import {
  types,
  Instance,
  cast,
  getRoot,
  IAnyStateTreeNode,
} from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import {
  assignMasterAsync,
  assignSlaveAsync,
  removeAccountAsync,
} from './actions';
import { EChallenge } from '@trader/types';
import { TChallengeEntity } from '@trader/store';

export const copierModel = types
  .model('copierModel', {
    selectedChallenges: types.array(types.number),
    assignMasterAsync,
    assignSlaveAsync,
    removeAccountAsync,
  })
  .views(store => ({
    getChallengesByType: (type: EChallenge): TChallengeEntity[] => {
      const root = getRoot(store) as IAnyStateTreeNode;
      return root.entities.challenges
        .getAll()
        .filter(
          (challenge: TChallengeEntity) =>
            challenge.hierarchyType === type && challenge.status !== 'DISABLED'
        );
    },
  }))
  .actions(store => ({
    runInAction,
    handleSelectChallenge: (id: number) => {
      const challengeId = store.selectedChallenges.find(c => c === id);

      if (challengeId) {
        store.selectedChallenges = cast(
          store.selectedChallenges.filter(c => c !== id)
        );
      } else {
        store.selectedChallenges.push(id);
      }
    },
  }));

export const copier = types.optional(copierModel, {});

export type TCopierStore = Instance<typeof copierModel>;
