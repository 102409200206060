import { localStorageService } from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { IAnyStateTreeNode } from 'mobx-state-tree';

const expiresInEmpty = 0;

const getAuthTokensFromLocalStorage = () => {
  const expiresInCookie = localStorageService.get(
    LOCAL_STORAGE_KEYS.tokens.expiresIn
  );
  const expiresIn = expiresInCookie ? Number(expiresInCookie) : expiresInEmpty;
  const timestamp = localStorageService.get(
    LOCAL_STORAGE_KEYS.tokens.authorizedSessionTimestamp
  );

  return {
    accessToken:
      localStorageService.get(LOCAL_STORAGE_KEYS.tokens.access) || '',
    idToken: localStorageService.get(LOCAL_STORAGE_KEYS.tokens.idToken) || '',
    refreshToken:
      localStorageService.get(LOCAL_STORAGE_KEYS.tokens.refresh) || '',
    cognitoId:
      localStorageService.get(LOCAL_STORAGE_KEYS.tokens.cognitoId) || '',
    tokenType:
      localStorageService.get(LOCAL_STORAGE_KEYS.tokens.tokenType) || '',
    sessionId:
      localStorageService.get(LOCAL_STORAGE_KEYS.tokens.sessionId) || '',
    expiresIn,
    authorizedSessionTimestamp: timestamp ? Number(timestamp) : 0,
  };
};

export const setupAuthTokensToStore = <T extends IAnyStateTreeNode>(
  store: T
) => {
  const tokens = getAuthTokensFromLocalStorage();
  store.auth.tokens.setTokens(tokens);
  tokens.accessToken &&
    store.auth.runInAction(() => {
      store.auth.isAuth = true;
      store.auth.isAuthChecked = true;
    });
};
