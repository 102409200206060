import styled, { css } from 'styled-components';

import { Typography } from '../../../typography';

interface IType {
  $isBuy: boolean;
}

export const Type = styled(Typography)<IType>`
  ${({ theme, $isBuy }) => css`
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${theme.palette.white.main};
    background: ${$isBuy
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
    width: auto;
    max-width: 70px;
    padding: 0 5px;
    box-sizing: border-box;
    height: 18px;
    margin: 0 auto;
    border-radius: 3px;
  `};
`;
