export enum ETradingView {
  Advanced = 'advanced',
  Classic = 'classic',
}

export enum EChartLayouts {
  FirstLayout = 'firstLayout',
  SecondLayout = 'secondLayout',
  ThirdLayout = 'thirdLayout',
  FourthLayout = 'fourthLayout',
  FifthLayout = 'fifthLayout',
}

export enum EChartLayoutTypes {
  Single = 'single',
  TwoRow = 'twoRow',
  ThreeRow = 'threeRow',
  TwoColumn = 'twoColumn',
  ThreeColumn = 'threeColumn',
  Four = 'four',
}
