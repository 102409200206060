import React from 'react';
import { DialogProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import * as Styled from './styled';

interface IBaseModal extends Omit<DialogProps, 'open' | 'color'> {
  headerTitle?: string;
  shouldHideCloseButton?: boolean;
  isDividers?: boolean;
  isOpen: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const BaseModal: React.FC<IBaseModal> = observer(
  ({
    isOpen,
    headerTitle,
    shouldHideCloseButton,
    isDividers,
    onConfirm,
    onClose,
    children,
    ...rest
  }) => {
    const store = useMst();
    const modal = store.ui.modal;

    const handleClose = (_event: object, reason?: string) => {
      if (reason && reason === 'backdropClick') {
        return;
      }
      onClose && onClose();
      modal.close();
    };

    return (
      <Styled.Dialog
        maxWidth={false}
        disableEscapeKeyDown
        onClose={handleClose}
        open={isOpen}
        {...rest}
      >
        <Styled.DialogContent dividers={isDividers}>
          {!shouldHideCloseButton && (
            <Styled.Close onClick={handleClose} size='small'>
              <CloseIcon />
            </Styled.Close>
          )}
          {children}
        </Styled.DialogContent>
      </Styled.Dialog>
    );
  }
);
