import React from 'react';
import * as Styled from './styled';
import { observer } from 'mobx-react-lite';

import { ModalTitle, Tabs } from '@trader/components';
import { useI18next } from '@trader/services';

import { General, ChangePassword } from './components';

export const Settings: React.FC = observer(() => {
  const { translate } = useI18next();
  const tabs = [
    {
      value: 'general',
      label: translate('COMMON.LABELS.GENERAL'),
      children: <General />,
    },
    {
      value: 'change-password',
      label: translate('COMMON.LABELS.CHANGE_PASSWORD'),
      children: <ChangePassword />,
    },
  ];

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.SETTINGS')} />
      <Tabs tabsPosition='top' tabs={tabs} />
    </Styled.Root>
  );
});
