import { useContext } from 'react';
import { IRootStoreInstance, rootStoreContext } from './configureStore';

export function useMst<TR extends IRootStoreInstance>(
  fn?: (store: IRootStoreInstance) => TR
): IRootStoreInstance {
  const store: IRootStoreInstance =
    useContext<IRootStoreInstance>(rootStoreContext);

  if (fn) {
    return fn(store);
  }

  return store;
}
