import { ListItem as ListItemMui, ListItemProps } from '@mui/material';
import styled, { css } from 'styled-components';
import { Typography } from '../typography';

export const ListItem = styled(ListItemMui)<ListItemProps>`
  ${({ theme }) => css`
    '&.hover': {
      background: ${theme.palette.blue.main};
    }
    height: 37px;
  `}
`;

export const ListTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  `}
`;
