import { types } from 'mobx-state-tree';
import { trading } from './trading';
import { muliBands } from './muliBands';
import { copier } from './copier';

export const pages = types.model('pages', {
  trading,
  muliBands,
  copier,
});
