import { MODAL_TYPES } from '@trader/constants';
import { useMst } from '@trader/store';
import { useMemo } from 'react';

enum EPurchaseType {
  ADD_FUNDS,
  EXTERNAL_PURCHASE,
  INTERNAL_PURCHASE,
}

interface IOutput {
  title: string;
  action: () => void;
  isDisabled: boolean;
  shouldHideButtonOnCreateOrder: boolean;
}

export const usePurchase = (): IOutput => {
  const store = useMst();
  const isDemoInUse: boolean = store.user.isDemoAccInUse();

  const purchaseType: EPurchaseType = import.meta.env.VITE_ADD_PURCHASE_TYPE;

  const purchase = useMemo(
    () => ({
      ADD_FUNDS: {
        title: 'ADD_FUNDS.TITLE',
        action: () => store.ui.modal.open(MODAL_TYPES.addFunds),
        isDisabled: isDemoInUse,
        shouldHideButtonOnCreateOrder: false,
      },
      EXTERNAL_PURCHASE: {
        title: 'COMMON.LABELS.PURCHASE',
        // TODO: (av) Should we redirect to /purchase-challenges instead?
        action: () => window.open(import.meta.env.VITE_REGISTRATION_LINK),
        isDisabled: false,
        shouldHideButtonOnCreateOrder: true,
      },
      INTERNAL_PURCHASE: {
        title: 'COMMON.LABELS.PURCHASE',
        // TODO: (av) Should we redirect to /purchase-challenges instead?
        action: () => window.open(import.meta.env.VITE_REGISTRATION_LINK),
        isDisabled: false,
        shouldHideButtonOnCreateOrder: true,
      },
    }),
    [isDemoInUse]
  );

  return purchase[purchaseType];
};
