import { defaultPipSize } from '@trader/constants';
import { formatByPipSize } from '@trader/utils';

import { IChartWidgetProps } from '../types';

export const customFormatters: IChartWidgetProps['custom_formatters'] = {
  dateFormatter: {
    format: date => {
      return `${date.getUTCFullYear()}/${
        date.getUTCMonth() + 1
      }/${date.getUTCDate()}`;
    },
  },
  priceFormatterFactory: symbolInfo => {
    return {
      format: price => {
        return formatByPipSize(price, symbolInfo?.pipSize || defaultPipSize);
      },
    };
  },
};
