import { useState, useEffect, useRef } from 'react';

import { api } from '@trader/api';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

export type TNetworkQuality =
  | 'strong'
  | 'good'
  | 'moderate'
  | 'weak'
  | 'offline';

const thresholds: Record<'strong' | 'good' | 'moderate' | 'weak', number> = {
  strong: 350,
  good: 550,
  moderate: 850,
  weak: 3150,
};

const checkTime = 5000;
const weakQualityCounterToShowNotification = 3;

export const useServerNetworkQuality = () => {
  const [quality, setQuality] = useState<TNetworkQuality | null>(null);
  const [weakQualityCounter, setWeakQualityCounter] = useState(0);
  const [qualityCounter, setQualityCounter] = useState(0);
  const intervalRef = useRef<number | null>(null);

  const store = useMst();
  const { translate } = useI18next();

  const serverStatusDescriptions: Record<TNetworkQuality, string> = {
    strong: translate('TOOLTIPS.STABLE_INTERNET_CONNECTION'),
    good: translate('TOOLTIPS.STABLE_INTERNET_CONNECTION'),
    moderate: translate('TOOLTIPS.UNSTABLE_INTERNET_CONNECTION'),
    weak: translate('TOOLTIPS.SLOW_INTERNET_CONNECTION'),
    offline: translate('TOOLTIPS.OFFLINE_INTERNET_CONNECTION'),
  };

  const updateQuality = (value: TNetworkQuality) => {
    setQuality(value);
    setQualityCounter(prev => prev + 1);
  };

  const checkServerStatus = async () => {
    const isOnline = navigator.onLine;

    if (!isOnline) {
      updateQuality('offline');
      return;
    }

    try {
      const startTime = window.performance.now();
      await api.Server.pingServer();
      const endTime = window.performance.now();
      const duration = endTime - startTime;

      if (duration <= thresholds.strong) {
        updateQuality('strong');
      } else if (duration <= thresholds.good) {
        updateQuality('good');
      } else if (duration <= thresholds.moderate) {
        updateQuality('moderate');
      } else if (duration <= thresholds.weak) {
        updateQuality('weak');
      } else {
        updateQuality('offline');
      }
    } catch (error) {
      updateQuality('offline');
    }
  };

  useEffect(() => {
    intervalRef.current = window.setInterval(checkServerStatus, checkTime);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (quality === 'weak') {
      setWeakQualityCounter(prev => prev + 1);
    }

    if (quality === 'strong' || quality === 'good') {
      weakQualityCounter !== 0 && setWeakQualityCounter(0);
    }
  }, [qualityCounter]);

  useEffect(() => {
    if (weakQualityCounter === weakQualityCounterToShowNotification) {
      store.notifications.add({
        message: translate('WARNINGS.SLOW_INTERNET_CONNECTION'),
        options: {
          variant: 'warning',
        },
      });
    }
  }, [weakQualityCounter]);

  return {
    networkQuality: quality,
    networkQualityDescription: quality ? serverStatusDescriptions[quality] : '',
  };
};
