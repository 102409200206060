import React from 'react';

import * as Styled from './styled';
import { useNavigation } from '@trader/hooks';
import { Typography } from '../typography';
import { Button } from '../button';

export const NotFound: React.FC = () => {
  const { goBackNavigate } = useNavigation();

  return (
    <Styled.Root>
      <Typography>Page Not Found</Typography>
      <Button onClick={goBackNavigate}>Go Back</Button>
    </Styled.Root>
  );
};
