import React, { useEffect, useState } from 'react';

import { Tabs, Progress } from '@trader/components';
import { IAvailablePaymentMethodBE } from '@trader/api';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

interface IPaymentMethods {
  contentLinkId: number;
  purchaseAmount: number;
  paymentMethods: IAvailablePaymentMethodBE[];
}

export const PaymentMethods: React.FC<IPaymentMethods> = ({
  contentLinkId,
  purchaseAmount,
  paymentMethods,
}) => {
  const tabs = paymentMethods.map(pm => ({
    value: pm.id.toString(),
    label: pm.displayName || '--',
    children: (
      <PaymentMethod
        amount={purchaseAmount}
        pspId={pm.id}
        contentLinkId={contentLinkId}
      />
    ),
  }));
  const maxAmountOfNotScrollableTabs = 4;

  return (
    <Tabs
      tabsPosition='top'
      tabs={tabs}
      padding='8px 0 0 0'
      withScrollableTabs={paymentMethods.length > maxAmountOfNotScrollableTabs}
    />
  );
};

interface IPaymentMethod {
  amount: number;
  pspId: number;
  contentLinkId: number;
}

const PaymentMethod: React.FC<IPaymentMethod> = ({
  amount,
  pspId,
  contentLinkId,
}) => {
  const store = useMst();
  const { translate } = useI18next();
  const [depositLink, setDepositLink] = useState<string | undefined>();
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);

  const fetchDepositLink = async () => {
    const response = await store.user.getDepositLinkAsync.run({
      amount,
      tradingAccountId: store.user.activeTradingAcc().tradingId,
      pspId,
      contentLinkId,
    });
    setDepositLink(response);
  };

  useEffect(() => {
    fetchDepositLink();
  }, []);

  if (store.user.getDepositLinkAsync.isError) {
    return translate('PURCHASE_CHALLENGES.FAILED_TO_FETCH_PAYMENT_INFORMATION');
  }

  const isLoading = store.user.getDepositLinkAsync.inProgress || !isFrameLoaded;

  return (
    <>
      {isLoading && <Progress size={25} color='primary' />}
      <iframe
        id='my-iframe'
        src={depositLink}
        title='Payment'
        width={isLoading ? 0 : '100%'}
        height={isLoading ? 0 : '680px'}
        frameBorder='0'
        onLoad={() => {
          setIsFrameLoaded(true);
        }}
      />
    </>
  );
};
