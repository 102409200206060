import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { EChallenge } from '@trader/types';
import { TableLegend } from '@trader/components';
import { useI18next } from '@trader/services';
import {
  IChallengeEntitySnapshotOut,
  TChallengeEntity,
  useMst,
} from '@trader/store';

import {
  Challenge,
  NoMastersAccount,
  NoNonAssignedAccounts,
} from './components';
import { useGetCells } from './hooks';
import * as Styled from './styled';

interface IChallenges {
  shouldTriggerGetChallenges?: boolean;
  leftAction?: (challengeId: number) => JSX.Element;
  rightAction?: (challengeId: number) => JSX.Element;
  rightActionWidth?: number;
  shouldDisplayAccTypeIcon?: boolean;
  isMasterEmpty?: boolean;
  shouldDisplayId?: boolean;
  shouldDisplayStatus?: boolean;
  shouldDisplayResult?: boolean;
  dividedChallenges?: TChallengeEntity[];
  isFullHeight?: boolean;
}

export const Challenges: React.FC<IChallenges> = observer(
  ({
    shouldTriggerGetChallenges = true,
    leftAction,
    rightAction,
    rightActionWidth,
    dividedChallenges,
    shouldDisplayAccTypeIcon,
    shouldDisplayId,
    shouldDisplayStatus,
    shouldDisplayResult,
    isMasterEmpty,
    isFullHeight,
  }) => {
    const store = useMst();
    const { translate } = useI18next();

    const { cells } = useGetCells(
      leftAction,
      rightActionWidth,
      shouldDisplayAccTypeIcon,
      shouldDisplayId,
      shouldDisplayStatus,
      shouldDisplayResult
    );

    const challenges =
      dividedChallenges ||
      store.entities.challenges.getAll<IChallengeEntitySnapshotOut>();

    const sortedChallenges = useMemo(() => {
      const masters = challenges.filter(
        ch => ch.hierarchyType === EChallenge.Master
      );

      if (!masters.length) {
        return challenges;
      }

      const sortedArray: TChallengeEntity[] = [];

      masters.forEach(master => {
        sortedArray.push(master);
        const slavesToMaster = challenges.filter(
          ch =>
            ch.hierarchyType === EChallenge.Slave && ch.masterId === master.id
        );
        slavesToMaster.length && sortedArray.push(...slavesToMaster);
      });

      const nonAssigned = challenges.filter(
        ch => ch.hierarchyType === EChallenge.NonAssigned
      );

      sortedArray.push(...nonAssigned);

      return sortedArray;
    }, [challenges.length]);

    useEffect(() => {
      if (shouldTriggerGetChallenges) {
        store.entities.tradingAccounts.getTradingAccountsAsync.run();
        store.entities.challenges.getChallengesAsync.run();
      }
    }, [shouldTriggerGetChallenges]);

    return (
      <Styled.Root $isFullHeight={isFullHeight}>
        <Styled.Container>
          <Styled.Header>
            {cells.map(cell => (
              <span
                key={cell.id}
                style={{
                  textAlign: cell.align,
                  minWidth: cell.minWidth,
                }}
              >
                {cell.label}
              </span>
            ))}
          </Styled.Header>
          <Styled.Body $isFullHeight={isFullHeight}>
            {!challenges.length && isMasterEmpty && <NoMastersAccount />}
            {!challenges.length && !isMasterEmpty && <NoNonAssignedAccounts />}
            {sortedChallenges.map(challenge => (
              <Challenge
                key={challenge.id}
                challenge={challenge}
                cells={cells}
                leftAction={leftAction}
                rightAction={rightAction}
                shouldDisplayAccTypeIcon={shouldDisplayAccTypeIcon}
              />
            ))}
          </Styled.Body>
        </Styled.Container>
        <TableLegend
          items={[
            {
              iconType: 'dailyTargetProfit',
              text: translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED'),
            },
            {
              iconType: 'accountTypeMaster',
              text: translate('COPIER.MASTER_ACC'),
            },
            {
              iconType: 'accountTypeSlave',
              text: translate('COPIER.SLAVE_ACC'),
            },
            {
              iconType: 'activeAcc',
              text: translate('COPIER.ACTIVE_ACC'),
            },
          ]}
        />
      </Styled.Root>
    );
  }
);
