import React from 'react';
import styled, { css } from 'styled-components';
import { AppBarProps } from '@mui/material';

import { Wrapper } from '../wrapper';
import {
  layoutMargin,
  layoutChildrenRadius,
  footerHeight,
} from '@trader/constants';

interface IAppBar extends AppBarProps {
  children?: React.ReactNode;
}

export const AppBar = styled<React.FC<IAppBar>>(({ children, ...rest }) => (
  <Wrapper {...rest} ref={null}>
    {children}
  </Wrapper>
))`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    min-height: ${footerHeight}px;
    margin-top: ${layoutMargin}px;

    ${theme.breakpoints.down('sm')} {
      height: auto;
      background: transparent;
      flex-direction: column;
    }
  `}
`;

export const Toolbar = styled(Wrapper)`
  ${({ theme }) => css`
    padding: 0 18px;
    align-items: center;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      height: auto;
      padding: 0;
      background: transparent;
      flex-direction: column;
    }
  `}
`;
