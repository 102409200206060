import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { Wrapper } from '../wrapper';

export const Progress = styled(CircularProgress)`
  ${() => css``};
`;

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 150px;
  `};
`;
