import { getIconBaseProps } from './utils';

export const favouriteOff = {
  ...getIconBaseProps('favouriteOff'),
  width: '24px',
  height: '22px',
  viewBox: '0 0 24 22',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M15.3428 7.16883L12.5082 1.3101C12.3082 0.896634 11.6918 0.896634 11.4918 1.3101L8.65723 7.16883C8.5757 7.33735 8.40819 7.45337 8.2149 7.47522L1.49447 8.23488C1.02019 8.28849 0.829724 8.84737 1.18037 9.15652L6.14895 13.5371C6.29186 13.6631 6.35584 13.8508 6.31791 14.0328L4.999 20.3611C4.90592 20.8077 5.40458 21.1531 5.82133 20.9307L11.7266 17.7792C11.8965 17.6886 12.1035 17.6886 12.2734 17.7792L18.1787 20.9307C18.5954 21.1531 19.0941 20.8077 19.001 20.3611L17.6821 14.0328C17.6442 13.8508 17.7081 13.6631 17.851 13.5371L22.8196 9.15652C23.1703 8.84737 22.9798 8.28849 22.5055 8.23488L15.7851 7.47522C15.5918 7.45337 15.4243 7.33735 15.3428 7.16883Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
