/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars,no-magic-numbers */
import {
  IContext,
  PineJS,
  StudyInputInfo,
  StudyLinePlotInfo,
  StudyNumericInputInfo,
  StudyPlotInformation,
  StudyShapesPlotInfo,
  StudyStylesInfo,
  StudyTextInputInfo,
} from '../charting_library';
import { getConditions } from './conditions';
import { getInputValues } from './inputValues';
import { getEntryTrigger } from '../utils/getEntryTrigger';
import { baseDefaultsStyles } from '../constants';

const stopLoss = {
  long: 'long',
  short: 'short',
  longSLSignal: 'longSLSignal',
  shortSLSignal: 'shortSLSignal',
};

export const stopLossPlots: StudyPlotInformation[] = [
  {
    id: stopLoss.long,
    type: 'line' as StudyLinePlotInfo['type'],
    visible: false,
  } as StudyLinePlotInfo,
  {
    id: stopLoss.short,
    type: 'line' as StudyLinePlotInfo['type'],
    visible: false,
  } as StudyLinePlotInfo,
  {
    id: stopLoss.longSLSignal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: stopLoss.shortSLSignal,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
];

export const stopLossInputs: StudyInputInfo[] = [
  {
    id: 'stopLossTriger',
    name: 'Stop-loss trigger',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'open/close median',
    options: ['close', 'high/low', 'high/low median', 'open/close median'],
  },
  {
    id: 'stopLossType',
    name: 'Stop-loss',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'ATR',
    options: ['off', 'SuperTrend + ATR', 'ATR', 'StdDev'],
  },
  {
    id: 'stopLossLength',
    name: '[SL] Length',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 12,
  },
  {
    id: 'stopLossLongMult',
    name: '[SL] Long mult.',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 6,
    step: 0.1,
  },
  {
    id: 'stopLossShortMult',
    name: '[SL] Short mult.',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 4.3,
    step: 0.1,
  },
];

export const stopLossPallets = {
  // [stopLoss.stopLossBgAreaLong]: {
  //   colors: {
  //     0: {
  //       name: 'Color 0',
  //     },
  //     1: {
  //       name: 'Color 1',
  //     },
  //   },
  // },
  // [stopLoss.stopLossBgAreaShort]: {
  //   colors: {
  //     0: {
  //       name: 'Color 0',
  //     },
  //     1: {
  //       name: 'Color 1',
  //     },
  //   },
  // },
};

export const stopLossDefaults = {
  palettes: {
    // [stopLoss.stopLossBgAreaLong]: {
    //   colors: {
    //     0: {
    //       color: 'rgb(234,137,11)',
    //       width: 2,
    //       style: 0,
    //     },
    //     1: {
    //       color: 'rgb(245,12,38)',
    //       width: 2,
    //       style: 0,
    //     },
    //   },
    // },
    // [stopLoss.stopLossBgAreaShort]: {
    //   colors: {
    //     0: {
    //       color: 'rgb(234,137,11)',
    //       width: 2,
    //       style: 0,
    //     },
    //     1: {
    //       color: 'rgb(245,12,38)',
    //       width: 2,
    //       style: 0,
    //     },
    //   },
    // },
  },
  styles: {
    [stopLoss.long]: {
      ...baseDefaultsStyles,
      color: 'rgba(232,129,11,0.78)',
    },
    [stopLoss.short]: {
      ...baseDefaultsStyles,
      color: 'rgba(225,18,42,0.7)',
    },
    [stopLoss.longSLSignal]: {
      color: 'rgba(227,13,37,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_up',
      location: 'AboveBar',
      visible: true,
    },
    [stopLoss.shortSLSignal]: {
      color: 'rgba(77,180,43,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_down',
      location: 'BelowBar',
      visible: true,
    },
  },
};

export const stopLossStyles = {
  [stopLoss.long]: {
    histogramBase: 0,
    isHidden: true,
    title: '[ST] Long',
  },
  [stopLoss.short]: {
    histogramBase: 0,
    isHidden: true,
    title: '[ST] Short',
  },
  [stopLoss.longSLSignal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[SL] Long, price crossing stop line',
    title: 'Short Signal Shape',
  },
  [stopLoss.shortSLSignal]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[TP] Short, price crossing stop line',
    title: 'Long Signal Shape',
  },
};

interface IStopLoss {
  context: IContext;
  Pine: PineJS;
  standardDeviation: number;
  averageTrueRange: number;
  supertrend: number;
  conditions: ReturnType<typeof getConditions>;
  inputValues: ReturnType<typeof getInputValues>;
}

const result = {
  limitOrderPrice: 0,
  limitOrderDirection: '',
  stopLossPriceLong: 0,
  stopLossPriceShort: 0,
};

export const getStopLossValues = ({
  context,
  Pine,
  standardDeviation,
  averageTrueRange,
  conditions,
  inputValues,
  supertrend,
}: IStopLoss): Array<number> => {
  const low = Pine.Std.low(context);
  const high = Pine.Std.high(context);

  if (!averageTrueRange) {
    return [];
  }
  const useSuperTrendSignal = true; // useSuperTrendSignal
  const useSuperTrend = inputValues.useSuperTrend;
  // const superTrendFactor = inputValues.superTrendFactor;
  // const superTrendPeriod = inputValues.superTrendPeriod;
  const useMaStrategy = inputValues.useMaStrategy;
  // const maBasisType = inputValues.maBasisType;
  // const maSignalType = inputValues.maSignalType;
  // const maRatio = inputValues.maRatio;
  // const maMultiplier = inputValues.maMultiplier;
  // const bbType = inputValues.bbType;
  // const bbLength = inputValues.bbLength;
  // const bbMultiplier = inputValues.bbMultiplier;
  const useBBLong = inputValues.useBBLong;
  const useBBLong2 = inputValues.useBBLong2;
  const useBBLong3 = inputValues.useBBLong3;
  const useBBShort = inputValues.useBBShort;
  const useBBShort2 = inputValues.useBBShort2;
  const useBBShort3 = inputValues.useBBShort3;
  const useTakeProfit = inputValues.useTakeProfit;
  const useTakeProfit2 = inputValues.useTakeProfit2;
  const useTakeProfit3 = inputValues.useTakeProfit3;
  // const takeProfitLongQty = inputValues.takeProfitLongQty;
  // const takeProfitShortQty = inputValues.takeProfitShortQty;
  // const useLimitOrder = inputValues.useLimitOrder;
  // const limitOrderPeriod = inputValues.limitOrderPeriod;
  // const limitOrderMultiplier = inputValues.limitOrderMultiplier;
  const stopLossTrigger = inputValues.stopLossTrigger;
  const stopLossType = inputValues.stopLossType;
  // const SLAtrPeriod = inputValues.SLAtrPeriod;
  const SLAtrMultiplierLong = inputValues.SLAtrMultiplierLong;
  const SLAtrMultiplierShort = inputValues.SLAtrMultiplierShort;
  const useFlexStopLoss = false;
  const SLFlexMult = 0;

  const limitOrderPriceLong = low - averageTrueRange * 0.1;
  const limitOrderPriceShort = high + averageTrueRange * 0.1;
  const stopLossAtr = averageTrueRange;
  const stopLossStdDev = standardDeviation;

  // const calcStopLossPriceLong =
  //   low - standardDeviation - averageTrueRange * SLAtrMultiplierLong;
  // const calcStopLossPriceShort =
  //   high + standardDeviation + averageTrueRange * SLAtrMultiplierShort;

  const stopTriggerLong = getEntryTrigger({
    type: 'long',
    entryTrigger: stopLossTrigger,
    context,
    Pine,
  });
  const isCondStopLong =
    stopTriggerLong < result.stopLossPriceLong && conditions.isCondPositionLong;

  const stopTriggerShort = getEntryTrigger({
    type: 'short',
    entryTrigger: stopLossTrigger,
    context,
    Pine,
  });
  const isCondStopShort =
    stopTriggerShort > result.stopLossPriceShort &&
    conditions.isCondPositionShort;

  const calcStopLossPriceLong =
    stopLossType === 'SuperTrend + ATR'
      ? supertrend - stopLossAtr * SLAtrMultiplierLong
      : stopLossType === 'ATR'
      ? low - stopLossAtr * SLAtrMultiplierLong
      : low - stopLossStdDev - stopLossAtr * SLAtrMultiplierLong;

  const calcStopLossPriceShort =
    stopLossType === 'SuperTrend + ATR'
      ? supertrend - stopLossAtr * SLAtrMultiplierShort
      : stopLossType === 'ATR'
      ? high + stopLossAtr * SLAtrMultiplierShort
      : high + stopLossStdDev + stopLossAtr * SLAtrMultiplierShort;
  ///////////////////////////////////////////////////////////////////////
  if (useSuperTrendSignal) {
    if (
      conditions.isCondSuperTrendLongSignal &&
      conditions.maSignal > conditions.maBasis
    ) {
      result.limitOrderPrice = limitOrderPriceLong;
      result.limitOrderDirection = 'Long';
      result.stopLossPriceLong = calcStopLossPriceLong;
    }

    if (
      conditions.isCondSuperTrendShortSignal &&
      conditions.maSignal < conditions.maBasis
    ) {
      result.limitOrderPrice = limitOrderPriceShort;
      result.limitOrderDirection = 'Short';
      result.stopLossPriceShort = calcStopLossPriceShort;
    }
  }
  ///////////////////////////////////////////////////////////////////////
  if (useSuperTrend) {
    // >>>>>>>>>>>>>>>>>>>>>> LONG ORDERS WITH SuperTrend
    //-> Additionals ordrders #1-2
    if (useBBLong === 'lower' || useBBLong2 === 'lower') {
      if (conditions.isCondBBLong1 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'lower2' || useBBLong2 === 'lower2') {
      if (conditions.isCondBBLong2 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'lower3' || useBBLong2 === 'lower3') {
      if (conditions.isCondBBLong3 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper' || useBBLong2 === 'upper') {
      if (conditions.isCondBBLong4 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper2' || useBBLong2 === 'upper2') {
      if (conditions.isCondBBLong5 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper3' || useBBLong2 === 'upper3') {
      if (conditions.isCondBBLong6 && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    //-> Additionals ordrders #3
    if (useBBLong3 === 'basis') {
      if (conditions.isCondBasisLong && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong3 === 'signal') {
      if (conditions.isCondSignalLong && conditions.isCondSuperTrendLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    //-> cross ma strategy
    if (
      useMaStrategy &&
      conditions.isCondSuperTrendLong &&
      conditions.isCondMaLong
    ) {
      result.limitOrderPrice = limitOrderPriceLong;
      result.limitOrderDirection = 'Long';
      result.stopLossPriceLong = calcStopLossPriceLong;
    }

    // >>>>>>>>>>>>>>>>>>>>>> SHORT ORDERS WITH SuperTrend
    //-> Additionals ordrders #1-2
    if (useBBShort === 'upper' || useBBShort2 === 'upper') {
      if (conditions.isCondBBShort1 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'upper2' || useBBShort2 === 'upper2') {
      if (conditions.isCondBBShort2 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'upper3' || useBBShort2 === 'upper3') {
      if (conditions.isCondBBShort3 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower' || useBBShort2 === 'lower') {
      if (conditions.isCondBBShort4 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower2' || useBBShort2 === 'lower2') {
      if (conditions.isCondBBShort5 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower3' || useBBShort2 === 'lower3') {
      if (conditions.isCondBBShort6 && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    //-> Additionals ordrders #3
    if (useBBShort3 === 'basis') {
      if (conditions.isCondBasisShort && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort3 === 'signal') {
      if (conditions.isCondSignalShort && conditions.isCondSuperTrendShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    //-> cross ma strategy
    if (
      useMaStrategy &&
      conditions.isCondSuperTrendShort &&
      conditions.isCondMaShort
    ) {
      result.limitOrderPrice = limitOrderPriceShort;
      result.limitOrderDirection = 'Short';
      result.stopLossPriceShort = calcStopLossPriceShort;
    }
  } else {
    // >>>>>>>>>>>>>>>>>>>>>> LONG ORDERS
    //vvvvvvvvv Additionals ordrders #1-2 vvvvvvvvv
    if (useBBLong === 'lower' || useBBLong2 === 'lower') {
      if (conditions.isCondBBLong1) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'lower2' || useBBLong2 === 'lower2') {
      if (conditions.isCondBBLong2) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'lower3' || useBBLong2 === 'lower3') {
      if (conditions.isCondBBLong3) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper' || useBBLong2 === 'upper') {
      if (conditions.isCondBBLong4) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper2' || useBBLong2 === 'upper2') {
      if (conditions.isCondBBLong5) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong === 'upper3' || useBBLong2 === 'upper3') {
      if (conditions.isCondBBLong6) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    //vvvvvvvvv Additionals ordrders #3 vvvvvvvvv
    if (useBBLong3 === 'basis') {
      if (conditions.isCondBasisLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    if (useBBLong3 === 'signal') {
      if (conditions.isCondSignalLong) {
        result.limitOrderPrice = limitOrderPriceLong;
        result.limitOrderDirection = 'Long';
        result.stopLossPriceLong = calcStopLossPriceLong;
      }
    }
    //vvvvvvvvv cross ma strategy vvvvvvvvv
    if (useMaStrategy && conditions.isCondMaLong) {
      result.limitOrderPrice = limitOrderPriceLong;
      result.limitOrderDirection = 'Long';
      result.stopLossPriceLong = calcStopLossPriceLong;
    }

    // >>>>>>>>>>>>>>>>>>>>>> SHORT ORDERS
    //vvvvvvvvv Additionals ordrders #1-2 vvvvvvvvv
    if (useBBShort === 'upper' || useBBShort2 === 'upper') {
      if (conditions.isCondBBShort1) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'upper2' || useBBShort2 === 'upper2') {
      if (conditions.isCondBBShort2) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'upper3' || useBBShort2 === 'upper3') {
      if (conditions.isCondBBShort3) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower' || useBBShort2 === 'lower') {
      if (conditions.isCondBBShort4) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower2' || useBBShort2 === 'lower2') {
      if (conditions.isCondBBShort5) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort === 'lower3' || useBBShort2 === 'lower3') {
      if (conditions.isCondBBShort6) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    //vvvvvvvvv Additionals ordrders #3 vvvvvvvvv
    if (useBBShort3 === 'basis') {
      if (conditions.isCondBasisShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    if (useBBShort3 === 'signal') {
      if (conditions.isCondSignalShort) {
        result.limitOrderPrice = limitOrderPriceShort;
        result.limitOrderDirection = 'Short';
        result.stopLossPriceShort = calcStopLossPriceShort;
      }
    }
    //vvvvvvvvv Cross MA strategy vvvvvvvvv
    if (useMaStrategy && conditions.isCondMaShort) {
      result.limitOrderPrice = limitOrderPriceShort;
      result.limitOrderDirection = 'Short';
      result.stopLossPriceShort = calcStopLossPriceShort;
    }
  }

  if (useTakeProfit) {
    if (conditions.isCondTakeLong1 && conditions.isCondTakePriceLong) {
      // add label
    }
    if (conditions.isCondTakeShort1 && conditions.isCondTakePriceShort) {
      // add label
    }
  }

  if (useTakeProfit2) {
    if (conditions.isCondTakeLong2 && conditions.isCondTakePriceLong) {
      // add label
    }
    if (conditions.isCondTakeShort2 && conditions.isCondTakePriceShort) {
      // add label
    }
  }

  if (useTakeProfit3) {
    if (conditions.isCondTakeLong3 && conditions.isCondTakePriceLong) {
      result.stopLossPriceLong = calcStopLossPriceLong;
    }
    if (conditions.isCondTakeShort3 && conditions.isCondTakePriceShort) {
      result.stopLossPriceShort = calcStopLossPriceShort;
    }
  }

  if (stopLossType === 'SuperTrend + ATR') {
    if (conditions.isCondStopSuperTrendLong) {
      result.stopLossPriceLong = supertrend - stopLossAtr * SLAtrMultiplierLong;
    }
  }
  if (stopLossType === 'SuperTrend + ATR') {
    if (conditions.isCondStopSuperTrendShort) {
      result.stopLossPriceShort =
        supertrend + stopLossAtr * SLAtrMultiplierShort;
    }
  }

  if (useFlexStopLoss) {
    if (stopLossType === 'ATR' || stopLossType === 'StdDev') {
      if (conditions.isCondStopFlexSuperTrendLong) {
        result.stopLossPriceLong =
          result.stopLossPriceLong +
          averageTrueRange * SLAtrMultiplierLong * SLFlexMult;
      }
      if (conditions.isCondStopFlexMaLong) {
        result.stopLossPriceLong =
          result.stopLossPriceLong +
          averageTrueRange * SLAtrMultiplierLong * SLFlexMult;
      }
      if (conditions.isCondStopFlexSuperTrendShort) {
        result.stopLossPriceShort =
          result.stopLossPriceShort -
          averageTrueRange * SLAtrMultiplierLong * SLFlexMult;
      }
      if (conditions.isCondStopFlexMaShort) {
        result.stopLossPriceShort =
          result.stopLossPriceShort -
          averageTrueRange * SLAtrMultiplierLong * SLFlexMult;
      }
    }
  }

  if (stopLossType !== 'off') {
    if (useSuperTrend) {
      if (isCondStopLong && conditions.isCondSuperTrendShort)
        if (result.limitOrderDirection === 'Long') {
          // some label here
        }
      if (isCondStopShort && conditions.isCondSuperTrendLong) {
        if (result.limitOrderDirection === 'Short') {
          // some label here
        }
      }
    } else {
      if (isCondStopLong) {
        if (result.limitOrderDirection === 'Long') {
          // some label here
        }
      }
      if (isCondStopShort) {
        if (result.limitOrderDirection === 'Short') {
          // some label here
        }
      }
    }
  }

  const isPriceCrossingSLLong = Pine.Std.cross(
    high,
    result.stopLossPriceLong,
    context
  );
  const isPriceCrossingSLShort = Pine.Std.cross(
    low,
    result.stopLossPriceShort,
    context
  );
  const BBUpper3CrossingPrice = isPriceCrossingSLLong ? 1 : NaN;
  const BBLower3CrossingPrice = isPriceCrossingSLLong
    ? NaN
    : isPriceCrossingSLShort
    ? 1
    : NaN;

  return [
    result.stopLossPriceLong,
    result.stopLossPriceShort,
    BBUpper3CrossingPrice,
    BBLower3CrossingPrice,
    // plotCondStopLossLong ? 0 : 1,
    // plotCondStopLossShort ? 1 : 0,
  ];
};
