import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const SwitcherContainer = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-bottom: 15px;
    flex-direction: column;
  `}
`;

export const Note = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 12px;
    color: ${theme.palette.gray.main};
  `}
`;
