import { schema } from 'normalizr';

const availableChallenges = new schema.Entity(
  'availableChallenges',
  {},
  {
    idAttribute: 'contentLinkId',
  }
);

export const availableChallengesSchema = [availableChallenges];
