import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { layoutChildrenRadius } from '@trader/constants';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
  `};
`;

export const Asset = styled(Wrapper)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const Dashboard = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
  `};
`;

interface IRoot {
  $alignment?: string;
  $layout?: EChartLayoutTypes;
  $selectedLayout?: EChartLayouts;
  $instrumentLayout?: EChartLayouts;
}

export const MultipleLayoutsContainer = styled(Wrapper)<IRoot>`
  ${({ theme, $alignment, $layout = 'single' }) => css`
    display: flex;
    height: 100%;
    border-radius: 8px;
    background: ${theme.palette.background.paper};
    ${conditionApply(
      [$alignment],
      css`
        justify-content: center;
        align-items: center;
      `
    )};

    ${conditionApply(
      [
        $layout === 'single' ||
          $layout === 'twoColumn' ||
          $layout === 'threeColumn',
      ],
      css`
        flex-direction: column;
      `
    )};

    ${conditionApply(
      [$layout === 'twoRow' || $layout === 'threeRow'],
      css`
        justify-content: space-between;
      `
    )}

    ${conditionApply(
      [$layout === 'four'],
      css`
        flex-wrap: wrap;
        justify-content: space-between;
      `
    )}
  `};
`;
