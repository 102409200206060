import {
  ICostAndChargesBE,
  ICostAndChargesCommissionsModel,
} from '@trader/api';
import { TPlaceOrderSide } from '@trader/types';

const hundred = 100;
const threeHundredSixty = 360;
const fourDigits = 4;

export function getOrderCommissions(
  instrument,
  marketAmount: string,
  side: TPlaceOrderSide,
  instrumentApiCharges: ICostAndChargesBE,
  daysHeld: number,
  hasDifferentCurrency: boolean,
  instrumentSpread: number,
  translate
): ICostAndChargesCommissionsModel {
  const volume = Number(marketAmount);
  const notional = (Number(instrument.ask) + Number(instrument.bid)) * volume;
  const afterDot = 2;

  const calculatePercentage = (value: string | number) => {
    return Math.abs((Number(value) / notional) * hundred) || 0;
  };

  const commissionCategories: string[] = ['Shares', 'Cryptos', 'ETFs'];
  const hasCommissionCharges = commissionCategories.includes(
    instrument.category
  );

  const entryPrice =
    side === 'Buy' ? Number(instrument.bid) : Number(instrument.ask);

  const currentPrice =
    side === 'Buy' ? Number(instrument.ask) : Number(instrument.bid);

  const overnightSwapRate =
    instrumentApiCharges.overnightSwapRate / threeHundredSixty;

  // Spread
  const instrumentCurrencySpread = Math.abs(volume * instrumentSpread);
  const userCurrencySpread = Math.abs(
    instrumentCurrencySpread / instrumentApiCharges.conversionRate
  );

  // Swap
  const overnightSwapInstrumentCurrency = Math.abs(
    (overnightSwapRate * entryPrice * volume * daysHeld) / hundred
  );
  const overnightSwapUserCurrency = Math.abs(
    overnightSwapInstrumentCurrency / instrumentApiCharges.conversionRate
  );

  // Close commission
  const closeCommissionInstrumentCurrency = hasCommissionCharges
    ? (instrumentApiCharges.closeCommission / hundred) * currentPrice * volume
    : 0;
  const closeCommissionUserCurrency = hasCommissionCharges
    ? closeCommissionInstrumentCurrency / instrumentApiCharges.conversionRate
    : 0;

  // Open commission
  const openCommissionInstrumentCurrency = hasCommissionCharges
    ? (instrumentApiCharges.openCommission / hundred) * entryPrice * volume
    : 0;
  const openCommissionUserCurrency = hasCommissionCharges
    ? openCommissionInstrumentCurrency / instrumentApiCharges.conversionRate
    : 0;

  const conversionUserCurrencyFee = hasDifferentCurrency
    ? (instrumentApiCharges.conversionFee / hundred) *
      (overnightSwapUserCurrency +
        userCurrencySpread +
        openCommissionUserCurrency +
        closeCommissionUserCurrency)
    : 0;

  const total =
    userCurrencySpread +
    overnightSwapUserCurrency +
    conversionUserCurrencyFee +
    closeCommissionUserCurrency +
    openCommissionUserCurrency;

  const totalPercent =
    calculatePercentage(openCommissionInstrumentCurrency) +
    calculatePercentage(closeCommissionInstrumentCurrency) +
    calculatePercentage(overnightSwapInstrumentCurrency) +
    calculatePercentage(instrumentCurrencySpread) +
    calculatePercentage(
      conversionUserCurrencyFee * instrumentApiCharges.conversionRate
    );

  return {
    hasCommissionCharges: hasCommissionCharges,
    overnightSwapRate: Number(overnightSwapRate.toFixed(fourDigits)),
    commissionPercentage:
      Number(instrumentApiCharges.openCommission) +
      Number(instrumentApiCharges.closeCommission),
    openCommissionInstrumentCurrency: {
      label:
        closeCommissionUserCurrency === 0
          ? translate('COMMON.LABELS.COMMISSION')
          : translate('COMMON.LABELS.OPEN_COMMISSION'),
      value: (-openCommissionInstrumentCurrency || 0).toFixed(afterDot),
      inPercent: `${calculatePercentage(
        openCommissionInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    openCommissionUserCurrency: {
      label:
        closeCommissionUserCurrency === 0
          ? translate('COMMON.LABELS.COMMISSION')
          : translate('COMMON.LABELS.OPEN_COMMISSION'),
      value: (-openCommissionUserCurrency || 0).toFixed(afterDot),
      inPercent: `${calculatePercentage(
        openCommissionInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    closeCommissionInstrumentCurrency: {
      label: translate('COMMON.LABELS.CLOSE_COMMISSION'),
      value: (-closeCommissionInstrumentCurrency || 0).toFixed(afterDot),
      inPercent: `${calculatePercentage(
        closeCommissionInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    closeCommissionUserCurrency: {
      label: translate('COMMON.LABELS.CLOSE_COMMISSION'),
      value: (-closeCommissionUserCurrency || 0).toFixed(afterDot),
      inPercent: `${calculatePercentage(
        closeCommissionInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    instrumentCurrencySpread: {
      label: translate('COMMON.LABELS.SPREAD'),
      value: Number(-instrumentCurrencySpread.toFixed(afterDot)).toFixed(
        afterDot
      ),
      inPercent: `${calculatePercentage(instrumentCurrencySpread).toFixed(
        afterDot
      )}%`,
    },
    userCurrencySpread: {
      label: translate('COMMON.LABELS.SPREAD'),
      value: Number(-userCurrencySpread.toFixed(afterDot)).toFixed(afterDot),
      inPercent: `${calculatePercentage(instrumentCurrencySpread).toFixed(
        afterDot
      )}%`,
    },
    overnightSwapInstrumentCurrency: {
      label: translate('COMMON.LABELS.OVERNIGHT_SWAP'),
      value: Number(-overnightSwapInstrumentCurrency.toFixed(afterDot)).toFixed(
        afterDot
      ),
      inPercent: `${calculatePercentage(
        overnightSwapInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    overnightSwapUserCurrency: {
      label: translate('COMMON.LABELS.OVERNIGHT_SWAP'),
      value: Number(-overnightSwapUserCurrency.toFixed(afterDot)).toFixed(
        afterDot
      ),
      inPercent: `${calculatePercentage(
        overnightSwapInstrumentCurrency
      ).toFixed(afterDot)}%`,
    },
    conversionUserCurrencyFee: {
      label: translate('COMMON.LABELS.CURRENCY_CONVERSION_FEE'),
      value: Number(-conversionUserCurrencyFee.toFixed(afterDot)).toFixed(
        afterDot
      ),
      inPercent: `${calculatePercentage(
        conversionUserCurrencyFee * instrumentApiCharges.conversionRate
      ).toFixed(afterDot)}%`,
    },
    total: {
      label: translate('COMMON.LABELS.TOTAL_AMOUNT_PAYABLE'),
      value: Number((-total || 0).toFixed(afterDot)).toFixed(afterDot),
      inPercent: `${totalPercent.toFixed(afterDot)}%`,
    },
  };
}
