/* eslint-disable no-magic-numbers */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { addZeroToDate } from '@trader/utils';

import * as Styled from './styled';

export const StripSize: React.FC = observer(() => {
  const store = useMst();

  const longStrip = store.pages.muliBands.strip.longStrip;
  const shortStrip = store.pages.muliBands.strip.shortStrip;

  return (
    <Styled.Box $width={235}>
      <Styled.Info>
        Strip for long: {longStrip} Strip for Short: {shortStrip}
      </Styled.Info>
    </Styled.Box>
  );
});

export const VwapTrend: React.FC = observer(() => {
  const store = useMst();

  const vwapTrend = store.pages.muliBands.strip.vwapTrend;

  return (
    <Styled.Box $width={140} $isLower={vwapTrend === 'Lower'}>
      <Styled.Info>Vwap Trend: {vwapTrend}</Styled.Info>
    </Styled.Box>
  );
});

export const Time: React.FC = observer(() => {
  const store = useMst();
  const firstOpenedPositionTime = store.pages.muliBands.firstOpenedPositionTime;
  const strips = Array.from(store.pages.muliBands.strips.values());
  const hasSomePositions = strips.some(s => s.positionId);

  const [time, setTime] = useState('00:00:00:00');

  const countUpFromTime = useCallback(() => {
    if (firstOpenedPositionTime) {
      const currenDate = new Date().getTime();
      const countFrom = new Date(firstOpenedPositionTime).getTime();
      const timeDifference = currenDate - countFrom;

      const secondsInADay = 60 * 60 * 1000 * 24;
      const secondsInAHour = 60 * 60 * 1000;

      const days = Math.floor(timeDifference / secondsInADay);
      const hours = Math.floor(
        (timeDifference % secondsInADay) / secondsInAHour
      );
      const mins = Math.floor(
        ((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)
      );
      const secs = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
          1000
      );

      setTime(
        `${addZeroToDate(days)}:${addZeroToDate(hours)}:${addZeroToDate(
          mins
        )}:${addZeroToDate(secs)}`
      );
    } else {
      setTime('00:00:00:00');
    }
  }, [firstOpenedPositionTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      countUpFromTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [firstOpenedPositionTime]);

  useEffect(() => {
    if (!hasSomePositions) {
      store.pages.muliBands.runInAction(() => {
        store.pages.muliBands.firstOpenedPositionTime = null;
      });
    }
  }, [hasSomePositions]);

  return (
    <Styled.Box $width={133}>
      <Styled.Info>Time = {time}</Styled.Info>
    </Styled.Box>
  );
});
export const TimeRemaining: React.FC = observer(() => {
  const secondss = new Date().getSeconds();
  const minutess = new Date().getMinutes();
  const initialTimeInSeconds = 120 - (minutess % 2 === 0 ? 0 : 60) - secondss;

  // State to hold the remaining time in seconds
  const [remainingTime, setRemainingTime] = useState(initialTimeInSeconds);

  // Calculate the remaining time
  function calculateRemainingTime() {
    if (remainingTime <= 0) {
      return { minutes: 0, seconds: 0 };
    }

    const minutes = Math.floor((remainingTime % 120) / 60);
    const seconds = remainingTime % 60;

    return { minutes, seconds };
  }

  // Update the remaining time every second
  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date().getMinutes() % 2 === 0 && new Date().getSeconds() === 0) {
        setRemainingTime(120);
      } else {
        setRemainingTime(prevTime => prevTime - 1);
      }
    }, 1000);

    // Cleanup the interval when the component is unmounted or remainingTime is zero
    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedTime = calculateRemainingTime();

  return (
    <Styled.Box $width={160}>
      <Styled.Info>
        Time remaining = {addZeroToDate(formattedTime.minutes)}:
        {addZeroToDate(formattedTime.seconds)}
      </Styled.Info>
    </Styled.Box>
  );
});

export const InfoBlocks: React.FC = observer(() => {
  return (
    <Styled.Root>
      <TimeRemaining />
      <StripSize />
      <VwapTrend />
      <Time />
    </Styled.Root>
  );
});
