import { getIconBaseProps } from './utils';

export const currency = {
  ...getIconBaseProps('currency'),
  width: '22px',
  height: '21px',
  viewBox: '0 0 22 21',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M18.6471 4.5293H3.35294C2.05345 4.5293 1 5.58274 1 6.88224V17.4705C1 18.77 2.05345 19.8234 3.35294 19.8234H18.6471C19.9466 19.8234 21 18.77 21 17.4705V6.88224C21 5.58274 19.9466 4.5293 18.6471 4.5293Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
      <path
        d='M8.64752 1H13.3534C14.3299 1 15.1181 1.78824 15.1181 2.76471V4.52941H6.88281V2.76471C6.88281 1.78824 7.67105 1 8.64752 1Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
      <path
        d='M1 11.0078H8.64706'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
      />
      <path
        d='M13.3525 11.0078H20.7055'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinejoin='bevel'
      />
      <path
        d='M12.7651 9.24316H9.2357C8.91082 9.24316 8.64746 9.50653 8.64746 9.8314V12.1843C8.64746 12.5092 8.91082 12.7726 9.2357 12.7726H12.7651C13.09 12.7726 13.3533 12.5092 13.3533 12.1843V9.8314C13.3533 9.50653 13.09 9.24316 12.7651 9.24316Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
    </>
  ),
};
