import { useFormContext } from 'react-hook-form';

import { observer } from 'mobx-react-lite';
import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';

import { IBackTestingInitialValues } from './index';

import * as Styled from './styled';

export const StopLoss = observer(() => {
  const { translate } = useI18next();

  const { getValues, control } = useFormContext<IBackTestingInitialValues>();

  return (
    <Styled.ControllerWrapper>
      <Controller
        type='switcherField'
        label={translate('COMMON.LABELS.STOP_LOSS')}
        name='isSl'
        control={control}
      />
      {getValues().isSl && (
        <Controller
          type='textField'
          label={translate('COMMON.LABELS.STOP_LOSS')}
          name='sl'
          control={control}
        />
      )}
    </Styled.ControllerWrapper>
  );
});
