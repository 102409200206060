import React from 'react';

import { useI18next } from '@trader/services';
import { useNavigation } from '@trader/hooks';
import { NAVIGATE_TO } from '@trader/constants';

import * as Styled from './styled';

export const NoNonAssignedAccounts: React.FC = () => {
  const { navigateTo } = useNavigation();
  const { translate } = useI18next();
  return (
    <Styled.Root>
      {translate('COMMON.LABELS.NO_CHALLENGES')}
      <span onClick={() => navigateTo(NAVIGATE_TO.main.purchaseChallenges)}>
        {translate('COMMON.LABELS.PURCHASE_NEW')}
      </span>
    </Styled.Root>
  );
};
