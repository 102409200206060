import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';
import { useFormContext } from 'react-hook-form';

import { IBackTestingInitialValues } from './index';

import * as Styled from './styled';

export const SessionRange = observer(() => {
  const { translate } = useI18next();

  const { getValues, control } = useFormContext<IBackTestingInitialValues>();

  return (
    <Styled.ControllerWrapper>
      <Controller
        type='switcherField'
        label={translate('MULI_BANDS.SESSION_RANGE')}
        name='isSessionRange'
        control={control}
      />
      {getValues().isSessionRange && (
        <>
          <Controller
            type='timePickerField'
            label={translate('MULI_BANDS.SESSION_FROM')}
            name='sessionFrom'
            control={control}
          />
          <Controller
            type='timePickerField'
            label={translate('MULI_BANDS.SESSION_TO')}
            name='sessionTo'
            control={control}
          />
        </>
      )}
    </Styled.ControllerWrapper>
  );
});
