import styled from 'styled-components';

export const ErrorHandler = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  flex-direction: column;
`;

export const ErrorHandlerTitle = styled.h6`
  font-family: 'sans-serif', Arial, Helvetica;
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 500;
`;
