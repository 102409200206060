import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';
import { IChartApi } from 'lightweight-charts';
import AutoSizer from 'react-virtualized-auto-sizer';

import { getAccountTypeForConnection, productId } from '@trader/constants';
import { useGetPageVisibility, useTradingView } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ConfigurationMenu } from '@trader/components';
import { IBarFE, renderCharts } from '@trader/utils';
import { EChartLayouts } from '@trader/types';
import { IIndicators } from '@trader/api';

import { Main, Symbol, Root } from './styled';

interface ICandleChart {
  pipSize?: number;
  symbol?: string;
  staticHeight?: boolean;
  layoutNumber: EChartLayouts;
  onIndicators?: (indicators: IIndicators | undefined) => void;
}

export const CandleChart: React.FC<ICandleChart> = observer(
  ({ layoutNumber, staticHeight, onIndicators, ...rest }) => {
    const store = useMst();
    const isVisible = useGetPageVisibility();

    const theme = useTheme();

    const { subscribe, unsubscribe } = useTradingView();

    const symbol =
      rest.symbol || store.pages.trading.getInstrumentSymbolByLayout() || '';
    const pipSize =
      rest.pipSize ||
      store.entities.instruments.get<TInstrumentEntity>(symbol)?.pipSize ||
      0;

    const selectedInstrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    const activeTradingAccount = store.user.activeTradingAcc();

    const period =
      store.pages.trading.layout.layouts[layoutNumber].configuration.period;
    const isDisplayIndicators = store.user.settings.isDisplayedByUser;

    const product = store.user.getAccountProduct();

    const [refState, setRefState] = useState<HTMLDivElement | null>(null);
    const chartInstance = useRef<IChartApi | null>(null);

    const getBars = async (fromTime = 0) => {
      return (await selectedInstrument?.getInstrumentBarsAsync.run({
        symbol,
        period,
        fromTime,
      })) as Array<IBarFE>;
    };

    const getIndicators = async (): Promise<IIndicators | undefined> => {
      if (isDisplayIndicators && symbol) {
        const response =
          (await selectedInstrument?.getInstrumentBarsIndicatorsAsync.run(
            symbol
          )) as IIndicators;
        onIndicators && onIndicators(response);
        return response;
      }
      return undefined;
    };

    // rendering chart
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (refState) {
        const { chartObject, remove } = renderCharts({
          htmlElement: refState,
          getBars,
          getIndicators,
          period,
          theme,
          subscribe,
          symbol,
          activeTradingAccount,
          pipSize,
          product,
        });

        if (!chartInstance.current) {
          chartInstance.current = chartObject;
        }
        return () => {
          remove();
          chartInstance.current = null;
        };
      }
    }, [symbol, isDisplayIndicators, period, refState]);

    useEffect(() => {
      chartInstance.current &&
        chartInstance.current.applyOptions({
          grid: {
            vertLines: {
              color: theme.palette.background.default,
            },
            horzLines: {
              color: theme.palette.background.default,
            },
          },
          rightPriceScale: {
            borderColor: theme.palette.background.paper,
          },
          timeScale: {
            borderColor: theme.palette.background.paper,
          },
          layout: {
            background: {
              color: theme.palette.background.paper,
            },
            textColor: theme.palette.text.primary,
          },
        });
    }, [store.app.themeMode]);

    useEffect(() => {
      if (isVisible && chartInstance.current) {
        subscribe(async connection => {
          await connection?.send(
            'SubscribeToQuote',
            symbol,
            1,
            productId[product],
            activeTradingAccount.platformLogin,
            getAccountTypeForConnection[activeTradingAccount.accountType]
          );
        });
      } else {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromQuote',
            symbol,
            1,
            productId[product],
            activeTradingAccount.platformLogin,
            getAccountTypeForConnection[activeTradingAccount.accountType]
          );
        });
      }
    }, [isVisible, chartInstance]);

    useEffect(() => {
      return () => {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromQuote',
            symbol,
            1,
            productId[product],
            activeTradingAccount.platformLogin,
            getAccountTypeForConnection[activeTradingAccount.accountType]
          );
        });
      };
    }, [symbol]);

    return (
      <Root>
        <ConfigurationMenu
          layoutNumber={EChartLayouts.FirstLayout}
          shouldHideActions
          instrument={selectedInstrument}
        />
        <AutoSizer>
          {({ width, height }) => (
            <Main
              $width={width}
              $height={height}
              $staticHeight={staticHeight}
              id={symbol}
              ref={(ref: HTMLDivElement) => {
                setRefState(ref);
              }}
            >
              {staticHeight && <Symbol>{symbol}</Symbol>}
            </Main>
          )}
        </AutoSizer>
      </Root>
    );
  }
);
