import styled, { css } from 'styled-components';
import { FormControl as FormControlMui } from '@mui/material';
import { Typography } from '../typography';

export const FormControl = styled(FormControlMui)`
  ${() => css`
    width: 100%;
    > div {
      border-radius: 5px;
    }
  `};
`;
export const NoOptions = styled(Typography)`
  ${({ theme }) => css`
    margin: 0;
    font-size: 12px;
    color: ${theme.palette.grey[500]};
    text-align: center;
    cursor: not-allowed;
  `};
`;
