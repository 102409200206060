import React from 'react';
import { RouteObject } from 'react-router-dom';

export enum ERouteType {
  Public = 'Public',
  Authorized = 'Authorized',
  UnauthorizedOnly = 'UnauthorizedOnly',
}

export interface ICustomRouteObject
  extends Omit<RouteObject, 'children' | 'path'> {
  id: string;
  path?: string;
  element: React.ReactElement;
  routeType: ERouteType;
  children?: ICustomRouteObject[];
}
