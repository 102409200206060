import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { formatTableRowValueByPipSize } from '@trader/utils';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  PurchaseType,
  ScrollingList,
  SymbolWithIcon,
} from '../scrollingList';
import { IconButton } from '../iconButton';

import * as Styled from './styled';

interface IPendingOrdersList {
  symbol?: string;
}

export const PendingOrdersList: React.FC<IPendingOrdersList> = observer(
  ({ symbol }) => {
    const store = useMst();
    const { translate } = useI18next();

    const orderMetrics: TOrderMetricEntity[] =
      store.entities.ordersMetrics.getAll();

    const allMetrics = symbol
      ? orderMetrics.filter(p => p.symbol === symbol)
      : orderMetrics;

    useEffect(() => {
      store.entities.ordersMetrics.getOrdersMetricsAsync.run({ symbol });
    }, [symbol]);

    const cells: Array<IHeadCell> = [
      {
        id: 'symbol',
        label: translate('COMMON.LABELS.SYMBOL'),
        minWidth: 120,
        padding: '0 10px 0 30px',
        align: 'start',
      },
      {
        id: 'side',
        label: translate('COMMON.LABELS.TYPE'),
        minWidth: 120,
        align: 'center',
      },
      {
        id: 'quantity',
        label: translate('COMMON.LABELS.VOLUME'),
        minWidth: 150,
        align: 'center',
      },
      {
        id: 'stopLoss',
        label: translate('COMMON.LABELS.SL'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'takeProfit',
        label: translate('COMMON.LABELS.TP'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'limitPrice',
        label: translate('COMMON.LABELS.LIMIT_PRICE'),
        minWidth: 140,
        align: 'center',
      },
      {
        id: 'stopPrice',
        label: translate('COMMON.LABELS.STOP_PRICE'),
        minWidth: 140,
        align: 'center',
      },
      {
        id: 'actions',
        label: allMetrics.length ? (
          <span
            onClick={() => {
              store.ui.modal.open(MODAL_TYPES.removeAllMetrics, {
                shouldHideCloseButton: true,
                removingType: 'orders',
              });
            }}
          >
            {translate('COMMON.LABELS.CLOSE_ALL')}
          </span>
        ) : (
          ''
        ),
        minWidth: 120,
        align: 'end',
      },
    ];

    const renderActions = item => {
      const order = item.row;

      const openModal = (modalType: keyof typeof MODAL_TYPES) => {
        store.ui.modal.open(modalType, {
          symbol: order.symbol,
          orderId: order.orderId,
          side: order.side,
        });
      };

      return (
        <Styled.Actions>
          <IconButton
            iconType='edit'
            onClick={event => {
              event.stopPropagation();
              openModal('editOrder');
            }}
          />
          <IconButton
            iconType='remove'
            onClick={event => {
              event.stopPropagation();
              openModal('cancelOrder');
            }}
          />
        </Styled.Actions>
      );
    };

    const renderRowItemsCb: IRenderRowItemsCallBacks<TOrderMetricEntity> =
      useMemo(
        () => ({
          symbol: item => (
            <SymbolWithIcon
              symbol={item.row.symbol}
              iconUrl={item.row.iconUrl}
            />
          ),
          side: item => <PurchaseType value={item.value as string} />,
          quantity: item => item.value,
          limitPrice: item =>
            formatTableRowValueByPipSize(item.value, item.row.pipSize),
          stopPrice: item =>
            formatTableRowValueByPipSize(item.value, item.row.pipSize),
          takeProfit: item =>
            formatTableRowValueByPipSize(item.value, item.row.pipSize),
          stopLoss: item =>
            formatTableRowValueByPipSize(item.value, item.row.pipSize),
          actions: renderActions,
        }),
        []
      );

    return (
      <Styled.Root>
        <ScrollingList<TOrderMetricEntity>
          rows={allMetrics}
          headCells={cells}
          renderRowItemsCallBacks={renderRowItemsCb}
          totalCount={allMetrics.length}
        />
      </Styled.Root>
    );
  }
);
