import { getIconBaseProps } from './utils';

export const accountTypeMaster = {
  ...getIconBaseProps('accountTypeMaster'),
  width: '15px',
  height: '18px',
  viewBox: '0 0 15 18',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M4.46662 8.00023C4.46662 10.0457 5.33333 10.864 5.76657 11.682C5.96935 12.0646 6.25414 13.024 5.33259 13.3434C1.86647 14.5455 1 15.7727 1 15.7727V17H14V15.7727C14 15.7727 13.1333 14.5455 9.66667 13.3182C8.73771 12.9895 9.03361 12.059 9.23343 11.6818C9.66667 10.8635 10.5334 10.0453 10.5334 8'
        fill='currentColor'
      />
      <path
        d='M4.46662 8.00023C4.46662 10.0457 5.33333 10.864 5.76657 11.682C5.96935 12.0646 6.25414 13.024 5.33259 13.3434C1.86647 14.5455 1 15.7727 1 15.7727V17H14V15.7727C14 15.7727 13.1333 14.5455 9.66667 13.3182C8.73771 12.9895 9.03361 12.059 9.23343 11.6818C9.66667 10.8635 10.5334 10.0453 10.5334 8'
        stroke='currentColor'
        strokeMiterlimit='10'
      />
      <path
        d='M3.69803 1.88307L4.12037 5.41509H10.8792L11.3018 1.88307L9.25994 3.13381L7.49979 1L5.73965 3.13381L3.69803 1.88307Z'
        fill='#08C99D'
        stroke='#08C99D'
        strokeMiterlimit='10'
      />
    </>
  ),
};
