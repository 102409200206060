import { schema } from 'normalizr';

const tradingAccount = new schema.Entity(
  'tradingAccounts',
  {},
  {
    idAttribute: 'tradingId',
  }
);

export const tradingAccountsSchema = [tradingAccount];
