import { TInstrumentEntity } from '@trader/store';

import * as Styled from '../../styled';

export const FavouriteIconButton = ({
  instrument,
}: {
  instrument: TInstrumentEntity;
}) => {
  if (instrument.isFavorite) {
    return (
      <Styled.ActionButton
        onClick={() =>
          instrument.removeInstrumentFromFavouriteAsync.run(instrument.symbol)
        }
        size='medium'
        iconType='favoriteOn'
      />
    );
  }

  return (
    <Styled.ActionButton
      onClick={() =>
        instrument.addInstrumentToFavouriteAsync.run(instrument.symbol)
      }
      size='medium'
      iconType='favouriteOff'
    />
  );
};
