import { types } from 'mobx-state-tree';

const LanguageModel = types
  .model('LanguageModel', {
    language: types.optional(types.enumeration(['en', 'ua'].map(l => l)), 'en'),
  })
  .actions(store => ({
    change(language: 'en' | 'ua') {
      store.language = language;
    },
  }));

export const language = types.optional(LanguageModel, {});
