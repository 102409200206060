import { getIconBaseProps } from './utils';

export const tradeItalyLightLogo = {
  ...getIconBaseProps('tradeItalyLightLogo'),
  width: '177px',
  height: '34px',
  viewBox: '0 0 177 34',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M156.126 33.9999C155.474 33.9999 154.878 33.8196 154.337 33.4589C153.806 33.0983 153.381 32.6074 153.06 31.9862C152.739 31.3651 152.579 30.6638 152.579 29.8824C152.579 29.091 152.739 28.3897 153.06 27.7786C153.391 27.1574 153.831 26.6715 154.382 26.3209C154.943 25.9702 155.57 25.7949 156.261 25.7949C156.672 25.7949 157.047 25.855 157.388 25.9753C157.729 26.0955 158.024 26.2658 158.275 26.4862C158.535 26.6966 158.745 26.942 158.906 27.2225C159.076 27.503 159.181 27.8036 159.221 28.1242L158.876 28.004V25.9602H160.438V33.8497H158.876V31.9712L159.236 31.866C159.176 32.1365 159.051 32.402 158.861 32.6625C158.68 32.9129 158.445 33.1384 158.154 33.3387C157.874 33.5391 157.558 33.6994 157.208 33.8196C156.867 33.9398 156.506 33.9999 156.126 33.9999ZM156.531 32.5723C157.002 32.5723 157.418 32.4571 157.779 32.2267C158.139 31.9963 158.42 31.6807 158.62 31.28C158.831 30.8692 158.936 30.4034 158.936 29.8824C158.936 29.3715 158.831 28.9156 158.62 28.5149C158.42 28.1142 158.139 27.7986 157.779 27.5682C157.418 27.3377 157.002 27.2225 156.531 27.2225C156.07 27.2225 155.66 27.3377 155.299 27.5682C154.948 27.7986 154.668 28.1142 154.458 28.5149C154.257 28.9156 154.157 29.3715 154.157 29.8824C154.157 30.4034 154.257 30.8692 154.458 31.28C154.668 31.6807 154.948 31.9963 155.299 32.2267C155.66 32.4571 156.07 32.5723 156.531 32.5723Z'
        fill='#798CA4'
      />
      <path
        d='M148.412 33.8496V25.9602H149.96V33.8496H148.412ZM149.164 24.217C148.833 24.217 148.578 24.1318 148.397 23.9615C148.217 23.7912 148.127 23.5508 148.127 23.2402C148.127 22.9497 148.217 22.7143 148.397 22.5339C148.588 22.3536 148.843 22.2634 149.164 22.2634C149.494 22.2634 149.75 22.3486 149.93 22.5189C150.11 22.6892 150.201 22.9296 150.201 23.2402C150.201 23.5307 150.105 23.7662 149.915 23.9465C149.735 24.1268 149.484 24.217 149.164 24.217Z'
        fill='#798CA4'
      />
      <path
        d='M143.664 33.8493V22.729H145.212V33.8493H143.664Z'
        fill='#798CA4'
      />
      <path
        d='M136.409 33.9999C135.757 33.9999 135.161 33.8196 134.62 33.4589C134.089 33.0983 133.664 32.6074 133.343 31.9862C133.022 31.3651 132.862 30.6638 132.862 29.8824C132.862 29.091 133.022 28.3897 133.343 27.7786C133.674 27.1574 134.114 26.6715 134.665 26.3209C135.226 25.9702 135.853 25.7949 136.544 25.7949C136.955 25.7949 137.33 25.855 137.671 25.9753C138.011 26.0955 138.307 26.2658 138.557 26.4862C138.818 26.6966 139.028 26.942 139.189 27.2225C139.359 27.503 139.464 27.8036 139.504 28.1242L139.159 28.004V25.9602H140.721V33.8497H139.159V31.9712L139.519 31.866C139.459 32.1365 139.334 32.402 139.144 32.6625C138.963 32.9129 138.728 33.1384 138.437 33.3387C138.157 33.5391 137.841 33.6994 137.491 33.8196C137.15 33.9398 136.789 33.9999 136.409 33.9999ZM136.814 32.5723C137.285 32.5723 137.701 32.4571 138.062 32.2267C138.422 31.9963 138.703 31.6807 138.903 31.28C139.113 30.8692 139.219 30.4034 139.219 29.8824C139.219 29.3715 139.113 28.9156 138.903 28.5149C138.703 28.1142 138.422 27.7986 138.062 27.5682C137.701 27.3377 137.285 27.2225 136.814 27.2225C136.353 27.2225 135.943 27.3377 135.582 27.5682C135.231 27.7986 134.951 28.1142 134.74 28.5149C134.54 28.9156 134.44 29.3715 134.44 29.8824C134.44 30.4034 134.54 30.8692 134.74 31.28C134.951 31.6807 135.231 31.9963 135.582 32.2267C135.943 32.4571 136.353 32.5723 136.814 32.5723Z'
        fill='#798CA4'
      />
      <path
        d='M127.904 33.8497V23.9465H129.452V33.8497H127.904ZM126.266 27.463V25.9602H131.285V27.463H126.266Z'
        fill='#798CA4'
      />
      <path
        d='M119 33.8496V32.3468H120.698V24.8331H119V23.3303H124.019V24.8331H122.321V32.3468H124.019V33.8496H119Z'
        fill='#798CA4'
      />
      <path
        d='M0 2.79035H6.01189V15.9736H8.77846V2.79035H14.7904V0H0V2.79035Z'
        fill='#005C97'
      />
      <path
        d='M33.0948 9.16921C33.6756 8.27006 33.9666 7.15412 33.9679 5.82137C33.98 4.99864 33.8454 4.18026 33.5704 3.404C33.3239 2.71785 32.9311 2.09252 32.4187 1.57071C31.8993 1.05434 31.2723 0.656897 30.5816 0.406247C29.7933 0.124819 28.9601 -0.0125186 28.1223 0.000895833H16.5649V15.9736H19.3512V2.79598H28.1186C29.0944 2.79598 29.851 3.0484 30.3885 3.55325C30.9259 4.05809 31.1952 4.8301 31.1965 5.86928C31.1965 6.90968 30.9383 7.70441 30.4219 8.25348C29.9055 8.80255 29.1377 9.07708 28.1186 9.07708H21.4149V11.5368H27.4369L31.5104 15.9736H35.4966L30.6467 11.1186C31.657 10.7542 32.5177 10.0689 33.0948 9.16921Z'
        fill='#005C97'
      />
      <path
        d='M47.9214 5.25963L48.2117 5.73086L54.5176 15.9733H57.9788L49.5021 2.36621L47.9214 5.25963Z'
        fill='#005C97'
      />
      <path
        d='M48.5129 1.09831L48.3822 0.889307C48.2457 0.638814 48.0605 0.418209 47.8375 0.240502C47.6157 0.0764368 47.3452 -0.00807037 47.0695 0.000607475C46.7906 -0.00717518 46.5169 0.0771252 46.2907 0.240502C46.0597 0.414903 45.8679 0.63595 45.7278 0.889307L36.6807 15.9736H39.8198L46.5485 4.58223L48.5129 1.09831Z'
        fill='#005C97'
      />
      <path
        d='M74.1038 2.17539C73.3974 1.47723 72.5534 0.931329 71.6246 0.571859C70.6232 0.182941 69.5557 -0.0110458 68.4805 0.000485487H59.1614V15.9731H68.4805C69.5611 15.9846 70.6322 15.7712 71.6246 15.3465C72.5593 14.9475 73.4028 14.3643 74.1038 13.6324C74.8117 12.8867 75.3645 12.0098 75.7307 11.052C76.1285 10.0209 76.3276 8.92492 76.3175 7.82093C76.3311 6.73521 76.1318 5.65724 75.7307 4.64704C75.3638 3.72175 74.8101 2.88066 74.1038 2.17539ZM73.1307 10.0401C72.9007 10.6522 72.5474 11.2115 72.0924 11.6837C71.6374 12.1558 71.0903 12.531 70.4843 12.7864C69.8511 13.0545 69.1688 13.1894 68.4805 13.1826H61.9508V2.791H68.4805C69.1676 2.78364 69.8492 2.91404 70.4843 3.17437C71.0884 3.42072 71.6351 3.7877 72.0904 4.25252C72.5456 4.71734 72.8997 5.27008 73.1307 5.87642C73.3844 6.53965 73.5104 7.24426 73.5021 7.95364C73.5099 8.66597 73.3839 9.37351 73.1307 10.0401Z'
        fill='#005C97'
      />
      <path
        d='M92.2919 6.50781H83.4177V8.87427H92.2919V6.50781Z'
        fill='#005C97'
      />
      <path
        d='M80.96 13.1832V2.79035H92.8602V0H78.0928V15.9736H92.8831V13.1832H80.96Z'
        fill='#005C97'
      />
      <path
        d='M97.6161 14.4937C97.6208 14.6904 97.5845 14.8859 97.5095 15.0678C97.4345 15.2497 97.3224 15.414 97.1804 15.5501C97.0403 15.6886 96.8738 15.7974 96.6908 15.87C96.5078 15.9427 96.312 15.9777 96.1151 15.973C95.9214 15.9791 95.7286 15.9445 95.5489 15.8717C95.3693 15.7989 95.2069 15.6894 95.072 15.5501C94.8061 15.263 94.6584 14.886 94.6584 14.4947C94.6584 14.1033 94.8061 13.7263 95.072 13.4392C95.2069 13.3 95.3693 13.1904 95.5489 13.1176C95.7286 13.0448 95.9214 13.0103 96.1151 13.0163C96.3124 13.0123 96.5084 13.0482 96.6915 13.1219C96.8745 13.1955 97.0408 13.3053 97.1804 13.4448C97.3216 13.58 97.4331 13.743 97.5081 13.9236C97.5831 14.1041 97.6199 14.2983 97.6161 14.4937Z'
        fill='#005C97'
      />
      <path
        d='M124.915 15.9732C123.85 15.9837 122.795 15.7701 121.819 15.3463C120.893 14.9431 120.056 14.361 119.356 13.6336C118.646 12.8892 118.091 12.0115 117.724 11.0514C117.329 10.0207 117.131 8.92526 117.14 7.82179C117.127 6.72466 117.325 5.63516 117.724 4.61272C118.089 3.68416 118.645 2.84206 119.356 2.14061C120.064 1.45301 120.903 0.915435 121.825 0.560331C122.811 0.179093 123.862 -0.0108806 124.92 0.000609202H128.822C129.91 -0.0123454 130.99 0.181417 132.004 0.571526C132.933 0.926399 133.776 1.47184 134.48 2.17251C135.184 2.87317 135.732 3.71343 136.09 4.63884C136.481 5.65029 136.675 6.7267 136.662 7.8106C136.672 8.91406 136.474 10.0095 136.078 11.0402C135.711 11.9998 135.157 12.8775 134.448 13.6224C133.744 14.3515 132.901 14.9337 131.969 15.3351C130.973 15.761 129.9 15.9745 128.817 15.962L124.915 15.9732ZM128.817 13.1746C129.513 13.1827 130.202 13.0481 130.844 12.7791C131.449 12.5294 131.996 12.1582 132.451 11.6886C132.906 11.219 133.259 10.6611 133.488 10.0495C133.742 9.38354 133.869 8.67607 133.863 7.96359C133.87 7.25347 133.743 6.54833 133.488 5.88515C133.259 5.27685 132.906 4.72262 132.45 4.25758C131.995 3.79253 131.448 3.4268 130.844 3.18356C130.2 2.92281 129.511 2.79217 128.817 2.79922H124.915C124.229 2.78856 123.548 2.91612 122.912 3.17423C122.32 3.41936 121.784 3.78179 121.336 4.23957C120.887 4.70835 120.535 5.26132 120.301 5.8665C120.044 6.53228 119.917 7.24092 119.927 7.95426C119.92 8.66675 120.047 9.37421 120.301 10.0402C120.533 10.6564 120.885 11.2207 121.336 11.7007C121.783 12.1647 122.319 12.534 122.912 12.7865C123.542 13.0549 124.222 13.1896 124.907 13.1821L128.817 13.1746Z'
        fill='#005C97'
      />
      <path
        d='M106.94 15.9661C105.907 15.9766 104.884 15.7631 103.937 15.3395C103.036 14.9367 102.222 14.3548 101.542 13.6275C100.852 12.8835 100.314 12.0061 99.9583 11.0464C99.5745 10.0163 99.3823 8.92125 99.3918 7.81826C99.3785 6.72161 99.5708 5.63258 99.9583 4.61059C100.312 3.68243 100.852 2.8407 101.542 2.13956C102.228 1.45263 103.043 0.915333 103.937 0.559969C104.894 0.178897 105.913 -0.0109933 106.94 0.000491484H115.365V2.79788H106.94C106.277 2.79119 105.619 2.92185 105.006 3.18206C104.432 3.4234 103.912 3.78257 103.477 4.2376C103.04 4.70607 102.699 5.25879 102.471 5.86382C102.222 6.52931 102.099 7.23764 102.108 7.95067C102.101 8.66285 102.225 9.37 102.471 10.0357C102.697 10.6517 103.038 11.2158 103.477 11.6954C103.91 12.1601 104.43 12.5294 105.006 12.7808C105.617 13.0491 106.275 13.1838 106.94 13.1762H115.365V15.9736L106.94 15.9661Z'
        fill='#005C97'
      />
      <path
        d='M157.616 15.7584L154.618 4.81768L150.174 14.9439C150.044 15.237 149.848 15.4973 149.603 15.7056C149.366 15.8882 149.073 15.9828 148.773 15.9729C148.126 15.9729 147.658 15.6299 147.37 14.9439L142.949 4.81768L139.947 15.7584H137.254L141.284 1.11443C141.471 0.371476 141.924 0 142.643 0C142.93 0.0005707 143.211 0.0825716 143.452 0.236339C143.71 0.400586 143.909 0.64006 144.024 0.921722L148.856 11.926L153.684 0.921722C153.943 0.307241 154.381 0 154.999 0C155.66 0 156.098 0.371476 156.316 1.11443L160.327 15.7584H157.616Z'
        fill='#005C97'
      />
    </>
  ),
};
