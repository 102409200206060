import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Icon } from '@trader/components';
import { TIconsName } from '@trader/assets';
import { useNavigation } from '@trader/hooks';
import { NAVIGATE_TO } from '@trader/constants';
import { EChallengeResult } from '@trader/types';
import { TTradingAccountEntity, useMst } from '@trader/store';

import * as Styled from './styled';

export const ResultChallenge: React.FC = observer(() => {
  const store = useMst();
  const { navigateTo } = useNavigation();

  const isActiveChallenge = store.ui.modal.options.get('isActiveChallenge');
  const result = store.ui.modal.options.get('result');
  const challengeId = store.ui.modal.options.get('challengeId');

  const accounts =
    store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

  const iconTypeName: TIconsName =
    result === EChallengeResult.Completed ? 'completed' : 'failed';

  const isFailed =
    result === EChallengeResult.Failed || result === EChallengeResult.Cancelled;

  const title =
    result === 'EmptyAccounts'
      ? 'No trading accounts'
      : isFailed
      ? isActiveChallenge
        ? 'Stop out evaluation stage!'
        : 'Challenge Failed!'
      : isActiveChallenge
      ? 'Challenge Passed!'
      : 'Challenge Succeed!';

  const content =
    result === 'EmptyAccounts'
      ? 'You currently do not have any trading accounts. Please go to your client area and create a trading again'
      : isFailed
      ? `Unfortunately, your performance ${
          isActiveChallenge ? '' : `in (${challengeId}) challenge`
        } didn't meet the criteria for successful completion. Keep practicing and refining your skills and consider trying again in the future. Best of luck!`
      : isActiveChallenge
      ? ' Your exceptional trading performance has met and surpassed the criteria for successful completion. Well done! Go to your Dashboard to view details or start new challenge.'
      : `Your Intermediate challenge (${challengeId}) has been successful`;

  const startNewChallenge = () => {
    store.ui.modal.close();
    navigateTo(NAVIGATE_TO.main.myAccount, {
      state: {
        disableMultipleLayouts: true,
        disableTradingNavigation: true,
      },
    });
  };

  const logOut = () => {
    store.auth.logOut();
  };

  const gotToClientSide = () => {
    window.open(import.meta.env.VITE_REGISTRATION_LINK);
  };

  useEffect(() => {
    if (result === 'EmptyAccounts' && accounts.length) {
      store.ui.modal.close();
      store.pages.trading.getPrefetchInformationAsync.run({
        navigation: navigateTo,
      });
    }
  }, [result, accounts.length]);

  return (
    <Styled.Root>
      <Icon iconType={iconTypeName} />
      <Styled.Title>{title}</Styled.Title>
      {!!content && <Styled.Content>{content}</Styled.Content>}
      {result === 'EmptyAccounts' && (
        <Styled.NoAccounts>
          <Styled.Cta $isFailed onClick={logOut}>
            Sign out
          </Styled.Cta>
          <Styled.Cta onClick={gotToClientSide}>Proceed</Styled.Cta>
        </Styled.NoAccounts>
      )}
      {isActiveChallenge && (
        <Styled.Cta $isFailed={isFailed} onClick={startNewChallenge}>
          {isFailed ? 'Choose another challenge' : 'Go to dashboard'}
        </Styled.Cta>
      )}
    </Styled.Root>
  );
});
