import { ReactNode } from 'react';
import { SimpleInterpolation } from '@mui/styled-engine-sc';

type TConditionApplyProps =
  | boolean
  | null
  | undefined
  | string
  | number
  | Record<string, unknown>
  | ReactNode
  | ReactNode[];

const isTrue = (value: TConditionApplyProps) => !!value;

export const conditionApply = (
  conditions: TConditionApplyProps[] | TConditionApplyProps,
  styles: SimpleInterpolation // in general string, but this type need for correct work of css`` helper
): SimpleInterpolation => {
  if (Array.isArray(conditions)) {
    return conditions.every(isTrue) ? styles : '';
  }
  return conditions ? styles : '';
};
