import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { TIconsName } from '@trader/assets';
import { useMst } from '@trader/store';
import {
  EGeneralTabContainerId,
  EGeneralTabContainerType,
} from '@trader/types';
import { useI18next } from '@trader/services';

interface ITab {
  title: string;
  iconType: TIconsName;
  id: EGeneralTabContainerId;
  onClick: () => void;
}

export const GeneralTabs: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const tabs: Array<ITab> = [
    {
      title: 'Alerts',
      iconType: 'alerts',
      id: EGeneralTabContainerId.Alerts,
      onClick: () => {
        store.pages.muliBands.updateGeneralBox(
          EGeneralTabContainerId.Alerts,
          EGeneralTabContainerType.FullChart
        );
      },
    },
    {
      title: 'Data Box',
      iconType: 'dataBox',
      id: EGeneralTabContainerId.DataBox,
      onClick: () => {
        store.pages.muliBands.updateGeneralBox(EGeneralTabContainerId.DataBox);
      },
    },
    {
      title: `${translate('COMMON.LABELS.POSITIONS')}/${translate(
        'COMMON.LABELS.ORDERS'
      )}`,
      iconType: 'indices',
      id: EGeneralTabContainerId.OrdersAndPositions,
      onClick: () => {
        store.pages.muliBands.updateGeneralBox(
          EGeneralTabContainerId.OrdersAndPositions,
          EGeneralTabContainerType.FullChart
        );
      },
    },
  ];

  return (
    <Styled.Root>
      {tabs.map(tab => (
        <Styled.Tab
          $isSelected={tab.id === store.pages.muliBands.generalTab.id}
          iconType={tab.iconType}
          iconSide='left'
          variant='text'
          key={tab.title}
          onClick={tab.onClick}
        >
          {tab.title}
        </Styled.Tab>
      ))}
    </Styled.Root>
  );
});
