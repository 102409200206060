import { getIconBaseProps } from './utils';

export const accountTypeSlave = {
  ...getIconBaseProps('accountTypeSlave'),
  width: '18px',
  height: '18px',
  viewBox: '0 0 18 18',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M11.6667 12.2003C10.5233 11.7717 10.8875 10.5587 11.1335 10.0668C11.6667 9 12.7334 7.93323 12.7334 5.26677C12.7334 3.13323 11.6667 1 9 1C6.33333 1 5.26661 3.13354 5.26661 5.26677C5.26661 7.93354 6.33333 9.0003 6.86655 10.0668C7.11613 10.5656 7.46664 11.8163 6.33242 12.2328C2.06642 13.8 1 15.4 1 15.4V17H17V15.4C17 15.4 15.9333 13.8 11.6667 12.2V12.2003Z'
      fill='#B4B4B4'
      stroke='#B4B4B4'
      strokeMiterlimit='10'
    />
  ),
};
