import { getIconBaseProps } from './utils';

export const minimize = {
  ...getIconBaseProps('minimize'),
  width: '9px',
  height: '6px',
  viewBox: '0 0 9 6',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M8 5L4.5 1L1 5'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
