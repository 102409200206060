import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';

export const Box = styled(Wrapper)`
  ${() => css`
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  `};
`;

export const Main = styled(Wrapper)`
  ${() => css`
    background: #f5f5f533;
    width: 100%;
    border-radius: 11px;
    display: flex;
    height: 82px;
    align-items: center;
    padding-left: 20px;
  `};
`;
