import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';

export const useLoginSchema = () => {
  const { translate, currentLng } = useI18next();

  const loginSchema = useMemo(
    () =>
      yup
        .object({
          username: yup
            .string()
            .email(translate('COMMON.ERRORS.INVALID_EMAIL'))
            .required(translate('COMMON.ERRORS.REQUIRED_EMAIL')),
          password: yup
            .string()
            .required(translate('COMMON.ERRORS.REQUIRED_PASSWORD')),
        })
        .required(),
    [currentLng]
  );

  return { loginSchema };
};
