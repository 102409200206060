import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';
import { useMst } from '@trader/store';

interface IAuthorizedContainer {
  element: React.ReactElement;
}

export const AuthorizedContainer: React.FC<IAuthorizedContainer> = observer(
  ({ element }) => {
    const { navigateTo } = useNavigation();
    const location = useLocation();
    const store = useMst();

    useEffect(() => {
      if (store.auth.isAuth === false) {
        navigateTo(NAVIGATE_TO.auth.root);
      }
    }, [location.pathname, store.auth.isAuth]);

    return store.auth.isAuth === true ? element : null;
  }
);
