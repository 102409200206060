import React from 'react';

import { defaultIcon } from '@trader/constants';

import * as Styled from './styled';

interface ISymbolWithIcon {
  iconUrl?: string | null;
  symbol: string;
}

export const SymbolWithIcon: React.FC<ISymbolWithIcon> = ({
  iconUrl,
  symbol,
}) => {
  return (
    <Styled.Symbol>
      <img
        src={iconUrl || defaultIcon}
        alt='instrument icon'
        width={20}
        height={20}
      />
      <Styled.Title>{symbol}</Styled.Title>
    </Styled.Symbol>
  );
};
