import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { BetaDesign } from './betaDesign';
import { StableDesign } from './stableDesign';

export interface IActionButtons {
  symbol: string;
  layout?: string;
  ask: number;
  bid: number;
  pipSize: number;
  direction?: 'row' | 'column';
  buttonVariant?: 'text' | 'outlined' | 'contained';
  actionsStyles?: Record<string, string | number>;
  actionButtonStyles?: Record<string, string | number>;
  buttonTitleStyles?: Record<string, string | number>;
  buttonPriceStyles?: Record<string, string | number>;
}

export const ActionButtons: React.FC<IActionButtons> = observer(props => {
  const store = useMst();

  if (store.app.isBetaDesignEnabled()) {
    return <BetaDesign {...props} />;
  }

  return <StableDesign {...props} />;
});
