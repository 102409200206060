import { formatByPipSize } from '../trading';

export const formatTableRowValueByPipSize = (
  value: string | number | undefined | null | boolean,
  pipSize: number
): string => {
  if (!value || (typeof value !== 'string' && typeof value !== 'number')) {
    return '-';
  }

  return formatByPipSize(value, pipSize);
};
