export const productId = {
  Wallet: 0,
  TradingCfd: 1,
  Crypto: 2,
  Investment: 3,
};

export const productName = {
  0: 'Wallet',
  1: 'TradingCfd',
  2: 'Crypto',
  3: 'Investment',
};
