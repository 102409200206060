import { getIconBaseProps } from './utils';

export const arrowLeft = {
  ...getIconBaseProps('arrowLeft'),
  width: '9px',
  height: '15px',
  viewBox: '0 0 9 15',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M8 14L1 7.5L8 1'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
