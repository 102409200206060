import { Components } from '@mui/material/styles/components';
import { IThemeOptions } from '../createTheme/index';

export const SelectOverride = (
  options?: IThemeOptions
): Components['MuiSelect'] => ({
  styleOverrides: {
    icon: {
      width: '16px',
      color: options?.palette.common?.black,
    },
    select: {
      color: options?.palette.common?.black,
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
