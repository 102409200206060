import { getIconBaseProps } from './utils';

export const filter = {
  ...getIconBaseProps('filter'),
  width: '12px',
  height: '11px',
  viewBox: '0 0 12 11',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M10.999 1H0.999023L4.88791 5.47882V8.94118L7.11014 10V5.47882L10.999 1Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
