import React, { ReactNode, useEffect } from 'react';
import { RadioGroupProps } from '@mui/material/RadioGroup';
import { RadioButton, IRadioButton } from './radioButton';
import * as Styled from './styled';
import { TDirection } from '@trader/themes';
import { ControlInfo } from '../controlInfo';

export interface IRadioGroup extends RadioGroupProps {
  value?: string;
  name?: string;
  items?: IRadioButton[];
  direction?: TDirection;
  helperText?: string;
  isError?: boolean;
  isDisabled?: boolean;
  label?: string | ReactNode;
  shouldHideControlInfo?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroup = React.forwardRef<HTMLInputElement, IRadioGroup>(
  (props, ref) => {
    const [value, setValue] = React.useState(props.value);

    useEffect(() => {
      if (value === props.value) return;
      setValue(props.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
      props.onChange && props.onChange(event);
    };

    const renderItems = (): ReactNode => {
      return props.items
        ? props.items.map((item: IRadioButton) => (
            <RadioButton
              key={item.value}
              value={item.value}
              label={item.label}
              disabled={item.disabled}
            />
          ))
        : props.children;
    };

    return (
      <Styled.RadioContainer>
        <Styled.RadioGroupContainer
          {...getValidProps(props)}
          aria-label={props.name}
          onChange={handleChange}
          value={value}
          ref={ref}
        >
          {props.label && (
            <Styled.TextContainer>
              <span>{props.label}</span>
            </Styled.TextContainer>
          )}
          {renderItems()}
        </Styled.RadioGroupContainer>
        <ControlInfo
          isError={props.isError}
          helperText={props.helperText}
          isDisabled={props.isDisabled}
          isHide={props.shouldHideControlInfo}
        />
      </Styled.RadioContainer>
    );
  }
);

const getValidProps = (props: IRadioGroup): RadioGroupProps => {
  const {
    isError,
    helperText,
    className,
    shouldHideControlInfo,
    items,
    ...rest
  } = props;
  return rest;
};
