import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';
import {
  footerHeight,
  headerHeight,
  layoutChildrenRadius,
  layoutMargin,
} from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  `}
`;

const headerLayoutMargin = layoutMargin * 2;
const footerLayoutMargin = layoutMargin * 2;
export const Body = styled(Wrapper)`
  ${({ theme }) => css`
    padding-bottom: 50px;
    flex-direction: column;
    width: 100%;
    height: calc(
      100vh - ${headerHeight + headerLayoutMargin}px -
        ${footerHeight + footerLayoutMargin}px
    );
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};

    ${theme.breakpoints.down('sm')} {
      padding-top: 25px;
      overflow: auto;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    display: block;
    padding: 27px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;
