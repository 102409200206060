import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const UncheckedCheckboxIcon = styled('div')`
  ${({ theme }) => css`
    width: 11px;
    height: 11px;
    border: 1px solid ${theme.palette.gray.dark};
    border-radius: 2px;
  `};
`;

export const CheckedCheckboxIcon = styled(Wrapper)`
  ${({ theme }) => css`
    width: 11px;
    height: 11px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: ${theme.palette.primary.main};
  `};
`;
