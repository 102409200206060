const widthCalc = 0;
const heightCalc = 20;

export const calculateSizes = (
  type: 'width' | 'height',
  size: number
): string => {
  if (type === 'width') {
    return `${size - widthCalc}px`;
  }
  return `${size - heightCalc}px`;
};
