import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface IFooter {
  children: React.ReactNode;
}

export const Footer: React.FC<IFooter> = observer(({ children }) => {
  return (
    <Styled.AppBar>
      <Styled.Toolbar>{children}</Styled.Toolbar>
    </Styled.AppBar>
  );
});
