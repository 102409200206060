import React from 'react';
import styled, { css } from 'styled-components';
import { SvgIcon, SvgIconProps } from '@mui/material';

export interface ISvgIconProps extends Omit<SvgIconProps, 'fontSize'> {
  width: string;
  height: string;
  fontSize?: string;
}

export const Icon = styled<React.FC<ISvgIconProps>>(
  ({ width, height, fontSize, ...rest }) => <SvgIcon {...rest} />
)`
  ${({ width, height, fontSize }) => css`
    width: ${width};
    height: ${height};
    font-size: ${fontSize ? fontSize : 'inherit'};
  `}
`;
