import { Components } from '@mui/material/styles/components';

export const TypographyOverride: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      subtitle1: 'span',
      subtitle2: 'span',
      body1: 'p',
      body2: 'p',
    },
  },
  styleOverrides: {
    h5: {
      fontSize: '24px',
    },
    h6: {
      fontSize: '18px',
    },
  },
};
