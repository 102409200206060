import React from 'react';

import * as Styled from './styled';
import { Icon } from '../icon';
import { TIconsName } from '@trader/assets';

interface IModalTitle {
  title: string;
  iconType?: TIconsName;
  bottomMargin?: string;
}

export const ModalTitle: React.FC<IModalTitle> = ({
  title,
  iconType,
  bottomMargin = '11px',
}) => {
  return (
    <Styled.Root $bottomMargin={bottomMargin}>
      <Styled.Title>{title}</Styled.Title>
      {iconType && <Icon iconType={iconType} />}
    </Styled.Root>
  );
};
