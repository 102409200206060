import React from 'react';
import { LineChart as ReChart, Line, ResponsiveContainer } from 'recharts';

interface ILineChart {
  data: Array<number>;
}

export const LineChart: React.FC<ILineChart> = ({ data }) => {
  const isGrow = data[data.length - 1] - data[0] > 0;
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ReChart data={data} style={{ cursor: 'pointer' }}>
        <Line
          type='monotone'
          dot={false}
          isAnimationActive={false}
          dataKey={v => v}
          stroke={isGrow ? '#22C36B' : '#F65556'}
          strokeWidth={1.5}
        />
      </ReChart>
    </ResponsiveContainer>
  );
};
