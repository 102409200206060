import { calcAllPositionsPl } from '@trader/utils';
import { TTradingAccountEntity, useMst } from '@trader/store';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { usePositionsCurrentPrice } from './usePositionsCurrentPrice';
import { usePositionsExchangeRate } from './usePositionsExchangeRate';

export const useTradingAccountPositions = (accountId: number) => {
  const store = useMst();

  const currentAccount =
    store.entities.tradingAccounts.get<TTradingAccountEntity>(accountId);
  const pl = calcAllPositionsPl(currentAccount.positions);

  usePositionsCurrentPrice({
    hub: EConnectionHub.TradingAccount,
    subscriber: EConnectionHubSubscriber.TradingAccountPositionsCurrentPrice,
    account: currentAccount,
    onUpdate: updatedPositions => {
      if (updatedPositions.platformLogin === currentAccount.platformLogin) {
        const currentPositions =
          store.entities.tradingAccounts.get<TTradingAccountEntity>(
            accountId
          ).positions;
        store.entities.tradingAccounts.update(accountId, {
          positions: currentPositions.map(p => {
            const updated = updatedPositions.positions.find(
              up => up.positionId === p.positionId
            );
            return {
              ...p,
              currentPrice: updated ? updated.price : p.currentPrice,
            };
          }),
        });
      }
    },
  });

  usePositionsExchangeRate({
    hub: EConnectionHub.TradingAccount,
    subscriber: EConnectionHubSubscriber.TradingAccountPositionsExchangeRate,
    account: currentAccount,
    onUpdate: updatedPositions => {
      if (updatedPositions.platformLogin === currentAccount.platformLogin) {
        const currentPositions =
          store.entities.tradingAccounts.get<TTradingAccountEntity>(
            accountId
          ).positions;
        store.entities.tradingAccounts.update(accountId, {
          positions: currentPositions.map(p => {
            const updated = updatedPositions.positions.find(
              up => up.positionId === p.positionId
            );
            return {
              ...p,
              conversionRate: updated
                ? updated.conversionRate
                : p.conversionRate,
            };
          }),
        });
      }
    },
  });

  return {
    positionsPl: pl,
  };
};
