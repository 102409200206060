import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { Icon } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const RemoveMasterAccount: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const challengeId = store.ui.modal.options.get('challengeId');

  return (
    <Styled.Root>
      <Styled.Title>
        <Icon iconType='redCloseInCircle' />
      </Styled.Title>
      <Styled.Content>
        {translate('COPIER.REMOVE_MASTER_ACC')}
        <span>{translate('COPIER.SUB_ACC_WILL_BE_REMOVED')}</span>
      </Styled.Content>
      <Styled.Body>
        <Styled.Cta
          size='medium'
          $isFailed
          onClick={() => store.ui.modal.close()}
        >
          {translate('COPIER.CANCEL')}
        </Styled.Cta>
        <Styled.Cta
          size='medium'
          onClick={() =>
            store.pages.copier.removeAccountAsync.run({
              challengeId: Number(challengeId),
              isConfirmed: true,
            })
          }
        >
          {translate('COPIER.YES_DELETE')}
        </Styled.Cta>
      </Styled.Body>
    </Styled.Root>
  );
});
