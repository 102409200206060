import { Instance, types } from 'mobx-state-tree';
import { runInAction } from '@trader/utils';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';
import { getInstrumentSpecificationAsync } from '../../entities/instruments/actions';

const initState = {
  firstLayout: {
    symbol: '',
    configuration: {
      period: 'Hour1',
    },
  },
  secondLayout: {
    symbol: '',
    configuration: {
      period: 'Hour1',
    },
  },
  thirdLayout: {
    symbol: '',
    configuration: {
      period: 'Hour1',
    },
  },
  fourthLayout: {
    symbol: '',
    configuration: {
      period: 'Hour1',
    },
  },
  fifthLayout: {
    symbol: '',
    configuration: {
      period: 'Hour1',
    },
  },
};

const LayoutEntity = types.model('LayoutEntity', {
  symbol: types.optional(types.string, ''),
  configuration: types.model('layoutConfig', {
    period: types.string,
  }),
  getInstrumentSpecificationAsync,
});

export const LayoutNumber = types
  .model('LayoutNumber', {
    [EChartLayouts.FirstLayout]: LayoutEntity,
    [EChartLayouts.SecondLayout]: LayoutEntity,
    [EChartLayouts.ThirdLayout]: LayoutEntity,
    [EChartLayouts.FourthLayout]: LayoutEntity,
    [EChartLayouts.FifthLayout]: LayoutEntity,
  })
  .actions(() => ({
    runInAction,
  }));

export type TLayoutNumber = Instance<typeof LayoutEntity>;

export const Layout = types.optional(
  types
    .model('LayoutNumber', {
      layouts: LayoutNumber,
      layoutType: types.union(
        types.literal(EChartLayoutTypes.Single),
        types.literal(EChartLayoutTypes.TwoRow),
        types.literal(EChartLayoutTypes.TwoColumn),
        types.literal(EChartLayoutTypes.ThreeRow),
        types.literal(EChartLayoutTypes.ThreeColumn),
        types.literal(EChartLayoutTypes.Four)
      ),
      selectedLayoutNumber: types.union(
        types.literal(EChartLayouts.FirstLayout),
        types.literal(EChartLayouts.SecondLayout),
        types.literal(EChartLayouts.ThirdLayout),
        types.literal(EChartLayouts.FourthLayout),
        types.literal(EChartLayouts.FifthLayout)
      ),
    })
    .views(state => ({
      isSymbolPresentedOnLayouts: (symbol: string) => {
        const layoutSymbols = [
          state.layouts.firstLayout.symbol,
          state.layouts.secondLayout.symbol,
          state.layouts.thirdLayout.symbol,
          state.layouts.fourthLayout.symbol,
        ];

        const numberOfLayouts = {
          [EChartLayoutTypes.Single]: 1,
          [EChartLayoutTypes.TwoRow]: 2,
          [EChartLayoutTypes.TwoColumn]: 2,
          [EChartLayoutTypes.ThreeRow]: 3,
          [EChartLayoutTypes.ThreeColumn]: 3,
          [EChartLayoutTypes.Four]: 4,
        }[state.layoutType];

        return layoutSymbols.slice(0, numberOfLayouts).includes(symbol);
      },
    }))
    .actions(state => ({
      selectInstrument: (symbol: string, layoutNumber?: EChartLayouts) => {
        state.layouts[layoutNumber || state.selectedLayoutNumber].symbol =
          symbol;
      },
      clearLayouts: () => {
        state.selectedLayoutNumber = EChartLayouts.FirstLayout;
        state.layoutType = EChartLayoutTypes.Single;
        state.layouts.firstLayout = initState.firstLayout as TLayoutNumber;
        state.layouts.secondLayout = initState.secondLayout as TLayoutNumber;
        state.layouts.thirdLayout = initState.thirdLayout as TLayoutNumber;
        state.layouts.fourthLayout = initState.fourthLayout as TLayoutNumber;
        state.layouts.fifthLayout = initState.fifthLayout as TLayoutNumber;
      },
      changeLayout: (type: EChartLayoutTypes) => {
        state.selectedLayoutNumber = EChartLayouts.FirstLayout;
        state.layoutType = type;
      },
      changeLayoutNumber: (layoutNumber: EChartLayouts) => {
        state.selectedLayoutNumber = layoutNumber;
      },
    })),
  {
    layouts: initState,
    layoutType: EChartLayoutTypes.Single,
    selectedLayoutNumber: EChartLayouts.FirstLayout,
  }
);
