export const isChallengeAccountType =
  import.meta.env.VITE_TRADING_ACC_TYPE === 'Challenge';

export const isLiveEnv = import.meta.env.VITE_ENVIRONMENT === 'production';

export const shouldDisplaySpreadColumnOnInstrumentList =
  import.meta.env.VITE_DISPLAY_SPREAD_COLUMN_ON_INSTRUMENT_LIST === 'true';

export const shouldDisplayChangeColumnOnInstrumentList =
  import.meta.env.VITE_DISPLAY_CHANGE_COLUMN_ON_INSTRUMENT_LIST === 'true';

export const shouldDisplayCreditInPortfolio =
  import.meta.env.VITE_DISPLAY_CREDIT_IN_PORTFOLIO === 'true';

export const isMuliBandsEnv = import.meta.env.VITE_HAS_MULI_BANDS === 'true';

export const hasCopierFunctionality =
  import.meta.env.VITE_HAS_COPIER === 'true';

export const shouldDisplayEdgeX = import.meta.env.VITE_HAS_EDGE_X === 'true';
export const shouldDisplayEdgeZone =
  import.meta.env.VITE_HAS_EDGE_ZONE === 'true';
export const shouldDisplayMuliBands =
  import.meta.env.VITE_HAS_MULI_BANDS === 'true';
