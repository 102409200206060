import { getIconBaseProps } from './utils';

export const tradeLogo = {
  ...getIconBaseProps('tradeLogo'),
  width: '232px',
  height: '24px',
  viewBox: '0 0 232 24',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M0 4.48303H8.8064V23.8646H12.8514V4.48303H21.6578V0.342041H0V4.48303Z'
        fill='currentColor'
      />
      <path
        d='M48.1613 13.8741C49.004 12.5507 49.4253 10.8857 49.4253 8.96464C49.4253 7.64123 49.2147 6.4459 48.8354 5.42132C48.4562 4.35405 47.9084 3.45755 47.1921 2.73181C46.4758 2.00607 45.591 1.4084 44.5376 1.02418C43.4842 0.639967 42.3044 0.426514 40.9982 0.426514H24.3545V23.9064H28.3574V4.48213H40.9982C42.3886 4.48213 43.4842 4.86634 44.2848 5.59208C45.0432 6.31782 45.4646 7.47047 45.4646 9.00733C45.4646 10.5442 45.0853 11.6968 44.369 12.508C43.6106 13.3191 42.515 13.7033 41.0403 13.7033H31.349V17.332H40.029L45.8859 23.8637H51.6164L44.6218 16.7343C46.1387 16.1367 47.3185 15.1975 48.1613 13.8741Z'
        fill='currentColor'
      />
      <path
        d='M69.2725 8.02712L69.6938 8.71017L78.5845 23.8654H83.4722L71.5057 3.75806L69.2725 8.02712Z'
        fill='currentColor'
      />
      <path
        d='M70.2821 1.66494L70.0715 1.32341C69.8186 0.896504 69.5658 0.597669 69.2709 0.341525C68.9759 0.0853814 68.5967 0 68.1332 0C67.6697 0 67.2905 0.128072 66.9955 0.341525C66.6585 0.597669 66.4056 0.896504 66.1528 1.32341L52.8379 23.8214H57.4728L67.3748 6.83051L70.2821 1.66494Z'
        fill='currentColor'
      />
      <path
        d='M106.815 3.54384C105.803 2.51926 104.624 1.75083 103.233 1.19585C101.843 0.640875 100.326 0.342041 98.6825 0.342041H85.2412V23.8219H98.6825C100.326 23.8219 101.843 23.5231 103.233 22.8827C104.624 22.285 105.803 21.4312 106.815 20.364C107.826 19.2967 108.584 18.016 109.174 16.5645C109.722 15.113 110.017 13.5335 110.017 11.8258C110.017 10.1182 109.722 8.58134 109.174 7.17255C108.584 5.80644 107.826 4.56842 106.815 3.54384ZM105.424 15.113C105.045 16.0522 104.539 16.8633 103.907 17.5464C103.275 18.2294 102.475 18.7417 101.59 19.1259C100.705 19.5101 99.7359 19.7236 98.6825 19.7236H89.2862V4.48303H98.6825C99.7359 4.48303 100.705 4.6538 101.59 5.03801C102.475 5.42223 103.233 5.93452 103.907 6.61757C104.539 7.30062 105.045 8.06905 105.424 9.00824C105.804 9.94744 105.972 10.972 105.972 12.082C105.972 13.1919 105.761 14.1738 105.424 15.113Z'
        fill='currentColor'
      />
      <path
        d='M133.148 10.2029H120.297V13.6608H133.148V10.2029Z'
        fill='currentColor'
      />
      <path
        d='M117.348 19.7663V4.48303H134.034V0.342041H113.303V23.8646H134.034V19.7663H117.348Z'
        fill='currentColor'
      />
      <path
        d='M141.491 21.6015C141.491 22.1992 141.281 22.7542 140.859 23.1384C140.438 23.5653 139.932 23.7788 139.3 23.7788C138.71 23.7788 138.205 23.5653 137.783 23.1384C137.404 22.7115 137.193 22.1992 137.193 21.6015C137.193 21.0039 137.404 20.4489 137.783 20.0647C138.162 19.6378 138.668 19.4243 139.3 19.4243C139.89 19.4243 140.438 19.6378 140.859 20.0647C141.281 20.4916 141.491 21.0039 141.491 21.6015Z'
        fill='currentColor'
      />
      <path
        d='M154.51 23.5223C152.908 23.5223 151.434 23.2235 150.085 22.6258C148.737 22.0282 147.557 21.1743 146.546 20.1498C145.535 19.0825 144.776 17.8445 144.228 16.393C143.681 14.9415 143.386 13.362 143.386 11.697C143.386 9.9894 143.681 8.45253 144.228 7.04374C144.776 5.63495 145.577 4.43961 146.546 3.45773C147.557 2.47584 148.737 1.70741 150.085 1.15243C151.434 0.597453 152.908 0.341309 154.51 0.341309H166.94V4.39692H154.51C153.498 4.39692 152.529 4.56768 151.644 4.9519C150.76 5.33612 150.001 5.8484 149.411 6.48876C148.821 7.12912 148.274 7.94025 147.936 8.83675C147.599 9.73325 147.389 10.7578 147.389 11.8678C147.389 12.9351 147.557 13.9596 147.936 14.8988C148.274 15.838 148.779 16.6491 149.411 17.2895C150.043 17.9725 150.802 18.4848 151.644 18.8691C152.529 19.2533 153.456 19.424 154.51 19.424H166.94V23.4796L154.51 23.5223Z'
        fill='currentColor'
      />
      <path
        d='M180.636 23.5223C179.034 23.5223 177.56 23.2235 176.211 22.6258C174.863 22.0282 173.683 21.1743 172.672 20.1498C171.661 19.0825 170.902 17.8445 170.354 16.393C169.807 14.9415 169.512 13.362 169.512 11.697C169.512 9.9894 169.807 8.45253 170.354 7.04374C170.902 5.63495 171.703 4.43961 172.672 3.45773C173.683 2.47584 174.863 1.70741 176.211 1.15243C177.56 0.597453 179.034 0.341309 180.636 0.341309H186.198C187.841 0.341309 189.358 0.597453 190.748 1.15243C192.139 1.70741 193.319 2.47584 194.288 3.50042C195.257 4.4823 196.057 5.67764 196.605 7.08643C197.153 8.49522 197.406 10.0321 197.406 11.697C197.406 13.362 197.111 14.9415 196.563 16.393C196.015 17.8445 195.215 19.0825 194.246 20.1498C193.234 21.217 192.054 22.0282 190.706 22.6258C189.358 23.2235 187.841 23.5223 186.198 23.5223H180.636ZM186.24 19.4667C187.293 19.4667 188.262 19.296 189.147 18.9117C190.032 18.5275 190.79 18.0152 191.422 17.3322C192.054 16.6491 192.56 15.838 192.939 14.9415C193.276 14.0023 193.487 12.9777 193.487 11.9105C193.487 10.8432 193.319 9.81863 192.939 8.87944C192.602 7.94025 192.097 7.17181 191.422 6.48876C190.79 5.8484 190.032 5.29342 189.147 4.9519C188.262 4.56768 187.293 4.39692 186.24 4.39692H180.678C179.666 4.39692 178.697 4.56768 177.813 4.9519C176.928 5.33612 176.169 5.8484 175.579 6.48876C174.989 7.12912 174.442 7.94025 174.105 8.83675C173.767 9.73325 173.557 10.7578 173.557 11.8678C173.557 12.9351 173.725 13.9596 174.105 14.8988C174.442 15.838 174.947 16.6491 175.579 17.2895C176.211 17.9725 176.97 18.4848 177.813 18.8691C178.697 19.2533 179.624 19.424 180.678 19.424L186.24 19.4667Z'
        fill='currentColor'
      />
      <path
        d='M227.955 23.523L223.573 7.21521L217.084 22.3277C216.873 22.7973 216.578 23.1815 216.241 23.4804C215.904 23.7365 215.525 23.8646 215.019 23.8646C214.092 23.8646 213.376 23.3523 212.955 22.3277L206.508 7.21521L202.126 23.523H198.207L204.106 1.66542C204.359 0.555467 205.033 0.000488281 206.086 0.000488281C206.508 0.000488281 206.887 0.12856 207.266 0.342014C207.645 0.555467 207.898 0.896993 208.109 1.36659L215.188 17.7598L222.267 1.36659C222.646 0.470086 223.278 0.000488281 224.205 0.000488281C225.174 0.000488281 225.806 0.555467 226.143 1.66542L232 23.523H227.955Z'
        fill='currentColor'
      />
    </>
  ),
};
