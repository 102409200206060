import React from 'react';
import { Menu } from '@mui/material';

import { Button } from '../button';
import { IOption } from '@trader/types';
import { Icon } from '../icon';
import { TIconsName } from '@trader/assets';
import { Tooltip } from '../tooltip';
import { StyledIcon, MenuItem } from './styled';

interface IDropdownMenu {
  buttonTitle: string;
  itemType: 'text' | 'icon';
  items: Array<IOption>;
  selectedOption?: IOption;
  onChange: (option: IOption) => void;
  isChartLayout: boolean;
}

export const DropdownMenu: React.FC<IDropdownMenu> = ({
  buttonTitle,
  items,
  selectedOption,
  onChange,
  itemType,
  isChartLayout,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleSelect = (option: IOption) => {
    onChange(option);
    setAnchorEl(null);
  };

  const toggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        variant='text'
        id='basic-button'
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={toggleOpen}
      >
        <Tooltip title={buttonTitle}>
          {selectedOption?.title ? (
            itemType !== 'icon' ? (
              selectedOption?.title
            ) : (
              <StyledIcon $isChartLayout={isChartLayout}>
                <Icon iconType={selectedOption?.title as TIconsName} />
              </StyledIcon>
            )
          ) : (
            buttonTitle
          )}
        </Tooltip>
      </Button>
      <Menu
        id='basic-menu'
        open={isOpen}
        anchorEl={anchorEl}
        onClose={toggleOpen}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {items.map(item => (
          <MenuItem
            $isChartLayout={isChartLayout}
            key={item.value}
            onClick={() => handleSelect(item)}
          >
            {itemType === 'icon' ? (
              <Icon iconType={item.title as TIconsName} />
            ) : (
              item.title
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
