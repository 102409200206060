import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { TTradingAccountEntity, useMst } from '@trader/store';
import { EConditionType, useBusinessLogic, useNavigation } from '@trader/hooks';
import { hasCopierFunctionality } from '@trader/constants';
import { EChallenge } from '@trader/types';

import { TopItemPart } from '../topItemPart';
import { BottomItemPart } from '../bottomItemPart';

import * as Styled from './styled';

interface IAccount {
  onClose?: () => void;
  tradingAccount: TTradingAccountEntity;
  toggleOpen?: (accountId: number) => void;
  openAccId?: number | null;
  isNestedAcc?: boolean;
}

export const Account: React.FC<IAccount> = observer(
  ({ onClose, tradingAccount, isNestedAcc, toggleOpen, openAccId }) => {
    const location = useLocation();
    const store = useMst();
    const { isScoreLevelLow } = useBusinessLogic(EConditionType.ScoreLevel);
    const { navigateTo } = useNavigation();

    const allAccounts =
      store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

    const changeTradingAccount = async (
      isActive: boolean,
      tradingId: number
    ) => {
      if (isActive || isNestedAcc) {
        return;
      }

      await store.entities.tradingAccounts.changeTradingAccountAsync.run({
        tradingId,
        navigation: location?.state?.disableTradingNavigation
          ? undefined
          : navigateTo,
      });

      onClose && onClose();
    };

    const renderSlaves = useMemo(() => {
      if (!hasCopierFunctionality) {
        return null;
      }

      if (tradingAccount.tradingId === openAccId) {
        const masterAcc = store.user.getAccountChallenge(openAccId);

        const slavesTradingAccounts = allAccounts.filter(acc => {
          const challenge = store.user.getAccountChallenge(acc.tradingId);
          return (
            challenge?.hierarchyType === EChallenge.Slave &&
            challenge?.masterId === masterAcc?.id
          );
        });

        return slavesTradingAccounts.map(slaveAcc => (
          <Styled.Slaves>
            <Account
              isNestedAcc
              key={slaveAcc.tradingId}
              tradingAccount={slaveAcc}
            />
          </Styled.Slaves>
        ));
      }
    }, [openAccId, allAccounts.length]);

    return (
      <Styled.Item
        $challengeType={
          store.user.getAccountChallenge(tradingAccount.tradingId)
            ?.hierarchyType
        }
        isSingleIcon
        variant='text'
        disabled={isScoreLevelLow}
        onClick={() => {
          changeTradingAccount(
            tradingAccount.isInUse,
            tradingAccount.tradingId
          );
        }}
        $isActive={tradingAccount.isInUse}
        key={tradingAccount.tradingId}
      >
        <TopItemPart
          toggleOpen={toggleOpen}
          openAccId={openAccId}
          tradingAccount={tradingAccount}
        />
        <BottomItemPart tradingAccount={tradingAccount} />
        {renderSlaves}
      </Styled.Item>
    );
  }
);
