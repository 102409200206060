import React from 'react';
import * as icons from './icons';

interface IIconField<T extends string> {
  className: `icon ${T}`;
  width: string;
  height: string;
  viewBox: string;
  body: React.ReactElement;
}

type TIconsModules = typeof icons;

export type TIconsName = keyof TIconsModules;

export type TIcons = {
  [IconKey in TIconsName]: IIconField<IconKey>;
};

export const Icons: TIcons = {
  ...icons,
};
