import styled, { css } from 'styled-components';

import { Button, Wrapper } from '@trader/components';
import { headerHeight } from '@trader/constants';

export const Divider = styled('div')<{
  $spacingSide?: 'right' | 'left' | 'both';
}>`
  ${({ theme, $spacingSide }) => css`
    width: 1px;
    height: ${headerHeight}px;
    background-color: ${theme.palette.background.default};
    margin-right: ${$spacingSide === 'right' || $spacingSide === 'both'
      ? '18px'
      : '0'};
    margin-left: ${$spacingSide === 'left' || $spacingSide === 'both'
      ? '18px'
      : '0'};
  `};
`;

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const Content = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `};
`;

export const AddFunds = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
    padding: 18px;

    &:hover {
      background-color: transparent;
    }
  `};
`;
