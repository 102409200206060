import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  TInstrumentEntity,
  TModalStore,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import { defaultIcon } from '@trader/constants';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IOrderResponse {
  symbol: string;
  volume: number;
  price: number;
  isClose: boolean;
  billType: string;
  orderId: number;
}

export const OrderResponse: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const modal: TModalStore = store.ui.modal;

  const model: IOrderResponse = {
    symbol: modal.options.get('symbol') ?? '',
    volume: modal.options.get('volume') ?? 0,
    price: modal.options.get('price') ?? 0,
    isClose: modal.options.get('isClose') ?? false,
    billType: modal.options.get('billType')?.toUpperCase() ?? 'BUY',
    orderId: modal.options.get('orderId') ?? 0,
  };

  if (model.orderId > 0) {
    const positionMetric =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(model.orderId);

    if (positionMetric) {
      model.billType = positionMetric.side;
    }
  }

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const subTitle = model.isClose
    ? translate(`COMMON.LABELS.CLOSE_${model.billType.toUpperCase()}`)
    : translate(`COMMON.LABELS.OPENED_${model.billType.toUpperCase()}`);

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    model.symbol
  );

  return (
    <Styled.Root $isInvestmentAccProduct={isInvestmentAccProduct}>
      <Styled.Header>
        <Styled.Title>{translate('COMMON.LABELS.ORDER_PLACED')}</Styled.Title>
      </Styled.Header>
      <img
        src={instrument?.iconUrl || defaultIcon}
        alt={model.symbol}
        width={30}
        height={30}
      />
      <Styled.VolumeContainer>
        {subTitle}
        <Styled.Volume>
          <Styled.Success>{model.volume}</Styled.Success>
        </Styled.Volume>
      </Styled.VolumeContainer>
      <Styled.Instrument>{model.symbol}</Styled.Instrument>
      <Styled.Price>{model.price ? `@${model.price}` : ''}</Styled.Price>
      <Styled.Status>
        <Styled.Success>{translate('COMMON.LABELS.SUCCESS')}</Styled.Success>
      </Styled.Status>
    </Styled.Root>
  );
});
