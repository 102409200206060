import React, { useState } from 'react';

import { Tooltip } from '../tooltip';

import * as Styled from './styled';
import { observer } from 'mobx-react-lite';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

interface ITradingCentralInfo {
  indicatorsInfo: Array<string> | string;
}

export const TradingCentralInfo: React.FC<ITradingCentralInfo> = observer(
  ({ indicatorsInfo }) => {
    const store = useMst();
    const [isOpenView, setIsOpenView] = useState(true);
    const { translate } = useI18next();

    const layoutType = store.pages.trading.layout.layoutType;
    const isDisplayIndicators = store.user.settings.isDisplayedByUser;

    if (!isDisplayIndicators || !indicatorsInfo || layoutType !== 'single') {
      return null;
    }

    if (typeof indicatorsInfo === 'string') {
      return (
        <Styled.Root $isOpenView={isOpenView}>
          <Styled.Paragraph>{indicatorsInfo}</Styled.Paragraph>
        </Styled.Root>
      );
    }

    if (!isOpenView) {
      return (
        <Styled.Root $isOpenView={isOpenView}>
          <Styled.Title $isOpenView={isOpenView}>
            {translate('CHART.TRADING_CENTRAL_PREFERENCE_INTRADAY_RISE')}
          </Styled.Title>
          <Styled.ToggleButton
            $isOpenView={isOpenView}
            variant='text'
            onClick={() => setIsOpenView(!isOpenView)}
            iconType='minimize'
          >
            {translate('COMMON.LABELS.MAXIMIZE')}
          </Styled.ToggleButton>
        </Styled.Root>
      );
    }

    return (
      <Styled.Root $isOpenView={isOpenView}>
        <Styled.ToggleButton
          $isOpenView={isOpenView}
          variant='text'
          onClick={() => setIsOpenView(!isOpenView)}
          iconType='maximize'
        >
          {translate('COMMON.LABELS.MINIMIZE')}
        </Styled.ToggleButton>
        <Styled.ContentContainer>
          <Styled.Title $isOpenView={isOpenView}>
            {translate('CHART.TRADING_CENTRAL_PREFERENCE_INTRADAY_RISE')}
          </Styled.Title>
          {indicatorsInfo.map(i => (
            <Tooltip key={i} title={i}>
              <Styled.Paragraph>{i}</Styled.Paragraph>
            </Tooltip>
          ))}
        </Styled.ContentContainer>
        <Styled.ContentContainer>
          <Styled.Title $isOpenView={isOpenView}>
            {translate('COMMON.LABELS.LEGEND')}
          </Styled.Title>
          <Styled.Paragraph>
            <span className='resistance'>R1, R2, R3</span>{' '}
            {translate('CHART.LINES_REPRESENT_RESISTANCE')}
          </Styled.Paragraph>
          <Styled.Paragraph>
            <span className='support'>S1, S2, S3</span>{' '}
            {translate('CHART.LINES_REPRESENT_SUPPORT_LEVELS')}
          </Styled.Paragraph>
          <Tooltip
            title={translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT_TOOLTIP')}
          >
            <Styled.Paragraph>
              <span className='pivot'>{translate('COMMON.LABELS.PIVOT')}</span>
              {translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT')}
            </Styled.Paragraph>
          </Tooltip>
        </Styled.ContentContainer>
      </Styled.Root>
    );
  }
);
