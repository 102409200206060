import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { TradingInitializer, PlaceOrder } from '@trader/trading';
import { useMst } from '@trader/store';

import * as Styled from '../../styled';

export const OrderSection: FC = observer(() => {
  const store = useMst();

  if (!store.ui.sidebar.rightBar.orderSection.isOpen) {
    return null;
  }

  const openedSectionsCount = store.ui.sidebar.rightBarOpenedSectionsCount();

  return (
    <Styled.Section
      $openedSectionsCount={openedSectionsCount}
      $withBottomSpacing={openedSectionsCount > 1}
    >
      <TradingInitializer>
        <PlaceOrder />
      </TradingInitializer>
    </Styled.Section>
  );
});
