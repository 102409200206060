import { useEffect } from 'react';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { ESocketUpdateAction, IChallengeMessage } from '@trader/types';
import { MODAL_TYPES } from '@trader/constants';
import { useHub } from './core/useHub';
import {
  IChallengeEntitySnapshotOut,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';

import { useChallengesLogic } from '../challengesLogic';
import { useNavigation } from '../useNavigation';

export const useChallenges = () => {
  const store = useMst();
  const { navigateTo } = useNavigation();
  const { challengesLogic } = useChallengesLogic();

  const idToken = store.auth.tokens.idToken;

  const { unsubscribe, subscribe } = useHub<IChallengeMessage>({
    url: '/inboxes',
    hub: EConnectionHub.Inboxes,
    subscriber: EConnectionHubSubscriber.Challenges,
    onMessage: message => {
      if (message.updateAction === ESocketUpdateAction.Create) {
        store.entities.challenges.getChallengesAsync.run();
      }
      if (message.updateAction === ESocketUpdateAction.Update) {
        challengesLogic(message);

        if (store.user.profile.tradingId === message.accountId) {
          store.user.runInAction(() => {
            store.user.profile.blockedDueToDailyTarget =
              message.blockedDueToDailyTarget;
          });
        }

        store.entities.challenges.update(message.id, {
          status: message.status,
          result: message.result,
          blockedDueToDailyTarget: message.blockedDueToDailyTarget,
        });
      }
      if (message.updateAction === ESocketUpdateAction.Delete) {
        // you are on deleted account right now and account must be changed
        if (message.accountId === store.user.tradingAccount?.tradingId) {
          const newChallenge = store.entities.challenges
            .getAll<IChallengeEntitySnapshotOut>()
            ?.find(
              challenge =>
                challenge.status !== 'DISABLED' && challenge.id !== message.id
            );

          if (!newChallenge) {
            store.ui.modal.open(MODAL_TYPES.resultChallenge, {
              isActiveChallenge: false,
              shouldHideCloseButton: true,
              result: 'EmptyAccounts',
            });
            return;
          }

          if (newChallenge) {
            const newAccount =
              store.entities.tradingAccounts.get<TTradingAccountEntity>(
                newChallenge.accountId
              );

            store.entities.tradingAccounts.changeTradingAccountAsync.run({
              tradingId: newAccount?.tradingId || 0,
              navigation: navigateTo,
            });
          }
        }

        store.entities.challenges.delete(message.id);
      }
    },
    invokedName: 'OnChallenge',
  });

  useEffect(() => {
    if (store.auth.isAuth) {
      subscribe();
    }
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [store.auth.isAuth, idToken]);
};
