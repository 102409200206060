import React from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';

import { StableDesign } from './stableDesign';
import { BetaDesign } from './betaDesign';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';

interface IInstrumentWithLayout extends TInstrumentEntity {
  layoutNumber?: EChartLayouts;
}
export interface IInstrumentDetailsHeader {
  instrument?: IInstrumentWithLayout;
  layout?: EChartLayoutTypes;
  layoutNumber?: EChartLayouts;
  isMuliBands?: boolean;
  searchPlaceholder?: string;
  searchCategory?: string;
  onChangeSymbol?: (symbol: string) => void;
}

export const InstrumentDetailsHeader: React.FC<IInstrumentDetailsHeader> =
  observer(props => {
    const store = useMst();

    if (store.app.isBetaDesignEnabled()) {
      return <BetaDesign {...props} />;
    }

    return <StableDesign {...props} />;
  });
