import { getIconBaseProps } from './utils';

export const trading = {
  ...getIconBaseProps('trading'),
  width: '19px',
  height: '20px',
  viewBox: '0 0 19 20',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5.12129 1.36768L5.18994 9.25041L1.5 9.22495L1.55149 15.137'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.69236 4.54524L5.12256 1.36771L1.55276 4.54524'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.7775 13.6271C10.7775 13.0748 10.3298 12.6271 9.77747 12.6271H6.57041C6.10572 12.6271 5.729 12.9624 5.729 13.376V18.3676C5.729 18.9199 6.17672 19.3676 6.729 19.3676H9.77746C10.3297 19.3676 10.7775 18.9199 10.7775 18.3676V13.6271Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.3496 7.38416H13.984C13.5193 7.38416 13.1426 7.71947 13.1426 8.13311V18.3674C13.1426 18.9197 13.5903 19.3674 14.1426 19.3674H17.191C17.7433 19.3674 18.191 18.9197 18.191 18.3674V8.13311C18.191 7.71947 17.8143 7.38416 17.3496 7.38416Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
