import { getIconBaseProps } from './utils';

export const inProgress = {
  ...getIconBaseProps('inProgress'),
  width: '1em',
  height: '1em',
  viewBox: '0 0 36 36',
  body: (
    <path
      d='M30.7278 30.728L27.8998 27.9C30.1902 25.6098 31.6157 22.5963 31.9333 19.373C32.251 16.1496 31.4412 12.9159 29.6418 10.2227C27.8425 7.52948 25.165 5.54348 22.0656 4.60308C18.9662 3.66268 15.6365 3.82605 12.6441 5.06536C9.65157 6.30467 7.18138 8.54324 5.65436 11.3997C4.12734 14.2561 3.63797 17.5536 4.26965 20.7303C4.90133 23.9071 6.61496 26.7666 9.11857 28.8215C11.6222 30.8765 14.7609 31.9998 17.9998 32L17.9998 36C13.8354 36 9.79987 34.556 6.58078 31.9141C3.36168 29.2722 1.15821 25.5959 0.345813 21.5115C-0.466588 17.4271 0.162349 13.1874 2.12546 9.51474C4.08857 5.84209 7.26439 2.96374 11.1118 1.37012C14.9592 -0.223494 19.2402 -0.433773 23.2252 0.775115C27.2103 1.984 30.6529 4.53726 32.9665 7.99984C35.2801 11.4624 36.3215 15.6201 35.9133 19.7644C35.5051 23.9088 33.6725 27.7833 30.7278 30.728Z'
      fill='currentColor'
    />
  ),
};
