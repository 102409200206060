import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${() => css`
    justify-content: flex-end;
    align-items: center;
    margin: 5px;
  `};
`;

interface IBox {
  $isLower?: boolean;
  $width: number;
}

export const Box = styled(Wrapper)<IBox>`
  ${({ $isLower, $width }) => css`
    border-radius: 5px;
    background: #c3d7f15e;
    margin: 0 5px;
    justify-content: center;
    width: ${$width}px;
    ${conditionApply(
      [$isLower],
      css`
        background: #e402021f;

        & > p {
          color: #f73f4a;
        }
      `
    )};
  `};
`;

export const Info = styled(Typography)`
  ${() => css`
    font-size: 12px;
    font-weight: 700;
    color: #45b1ff;
    padding: 3px 0;
  `};
`;
