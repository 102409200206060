import { getIconBaseProps } from './utils';

export const activeAcc = {
  ...getIconBaseProps('activeAcc'),
  width: '13px',
  height: '13px',
  viewBox: '0 0 13 13',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='6.5' cy='6.5' r='6.5' fill='url(#paint0_radial_1963_7228)' />
      <circle cx='6.5' cy='6.5' r='3.5' fill='#08E3B1' />;
      <defs>
        <radialGradient
          id='paint0_radial_1963_7228'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(6.5 6.5) scale(6.5)'
        >
          <stop offset='0.76' stopColor='#CAF9EE' />
          <stop offset='1' stopColor='#CAF9EE' stopOpacity='0' />
        </radialGradient>
      </defs>
      ;
    </>
  ),
};
