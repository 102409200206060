import { HubConnection } from '@microsoft/signalr';

export enum EConnectionHub {
  Account = 'account',
  Quotes = 'quotes',
  Positions = 'positions',
  PriceAlerts = 'priceAlerts',
  TradingAccount = 'tradingAccount',
  TradingView = 'tradingView',
  Layouts = 'layouts',
  Inboxes = 'inboxes',
  MuliBands = 'strategy',
  Auth = 'Auth',
}

export enum EConnectionHubSubscriber {
  Positions,
  PositionsExchangeRate,
  PositionsCurrentPrice,
  PriceAlertsCurrentPrice,
  PriceAlertsCreation,
  Quotes,
  TradingView,
  Layouts,
  Challenges,
  TradingAccount,
  TradingAccountPositionsCurrentPrice,
  TradingAccountPositionsExchangeRate,
  Orders,
  CustomerAccount,
  MuliBands,
  TradeResults,
  Auth,
}

export type TConnectionCache = {
  lastIdToken: string;
  subscribers: {
    [key in EConnectionHub]: { [sk in EConnectionHubSubscriber]: true };
  };
  connections: { [key in EConnectionHub]: null | HubConnection };
};
