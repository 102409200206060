import { getIconBaseProps } from '../utils';

export const shares = {
  ...getIconBaseProps('shares'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8.33333 10.2222H3.88889C3.39797 10.2222 3 10.6201 3 11.1111V18.2222H8.33333V10.2222Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.1111 7.55566H13.6666V18.2223H19V8.44455C19 7.95363 18.602 7.55566 18.1111 7.55566Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7778 4H9.22226C8.73134 4 8.33337 4.39797 8.33337 4.88889V18.2222H13.6667V4.88889C13.6667 4.39797 13.2687 4 12.7778 4Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
