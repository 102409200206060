import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { useResponsiveDesign } from '@trader/hooks';
import {
  layoutMargin,
  minimizedBottomBarHeight,
  bottomBarHeight,
} from '@trader/constants';
import {
  ExpandedContent,
  Progress,
  Tabs,
  THeightMode,
} from '@trader/components';

import { IAssetDetails } from '../index';
import * as Styled from './styled';

const AlertsListLazy = React.lazy(() =>
  import('@trader/components').then(({ AlertsList }) => ({
    default: AlertsList,
  }))
);
const PositionsListLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsList }) => ({
    default: PositionsList,
  }))
);
const PendingOrdersListLazy = React.lazy(() =>
  import('@trader/components').then(({ PendingOrdersList }) => ({
    default: PendingOrdersList,
  }))
);
const PositionsHistoryLazy = React.lazy(() =>
  import('@trader/modals').then(({ PositionsHistory }) => ({
    default: PositionsHistory,
  }))
);

const maxHeight = 650;
const minHeight = 120;

export const BetaDesign: React.FC<IAssetDetails> = observer(
  ({ selectedTab, navigationKey = 'assetsView' }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { bottomBar } = useResponsiveDesign();

    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const height = store.ui.sidebar.bottomBar.height;

    const positions = store.entities.positionsMetrics.getAll();
    const orders = store.entities.ordersMetrics.getAll();
    const alerts = store.entities.alerts.getAll();

    const positionsLabel = positions.length
      ? `${translate('COMMON.LABELS.POSITIONS')} ${positions.length}`
      : translate('COMMON.LABELS.POSITIONS');

    const ordersLabel = orders.length
      ? `${translate('COMMON.LABELS.PENDING_ORDERS')} ${orders.length}`
      : translate('COMMON.LABELS.PENDING_ORDERS');

    const alertsLabel = alerts.length
      ? `${translate('COMMON.LABELS.PRICE_ALERTS')} ${alerts.length}`
      : translate('COMMON.LABELS.PRICE_ALERTS');

    useEffect(() => {
      if (bottomBar.shouldMinimizedHeight) {
        store.ui.sidebar.updateBottomBarHeight(minimizedBottomBarHeight);
      } else {
        store.ui.sidebar.updateBottomBarHeight(bottomBarHeight);
      }
    }, [bottomBar.shouldMinimizedHeight]);

    const tabs = () => {
      const items = [
        {
          label: positionsLabel,
          value: 'positions',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsListLazy />
            </React.Suspense>
          ),
        },
        {
          label: translate('COMMON.LABELS.HISTORY'),
          value: 'history',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsHistoryLazy isDefaultHeaderBg />
            </React.Suspense>
          ),
        },
      ];

      if (!isInvestmentAccProduct) {
        items.push({
          label: ordersLabel,
          value: 'pending-orders',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PendingOrdersListLazy />
            </React.Suspense>
          ),
        });
      }

      items.push({
        label: alertsLabel,
        value: 'price-alerts',
        children: (
          <React.Suspense fallback={<Progress />}>
            <AlertsListLazy />
          </React.Suspense>
        ),
      });

      return items;
    };

    if (store.ui.sidebar.bottomBar.isShrinked) {
      return (
        <Styled.Shrinked>
          <Styled.Toggle
            $isShrinked
            iconType='minimize'
            onClick={store.ui.sidebar.expandBottomBar}
          />
          <Tabs
            tabsPosition='top'
            tabs={tabs()}
            navigationKey={navigationKey}
            preselectedTab={selectedTab}
            onChange={() => {
              store.ui.sidebar.expandBottomBar();
              store.ui.sidebar.updateBottomBarHeight(
                bottomBar.shouldMinimizedHeight
                  ? minimizedBottomBarHeight
                  : bottomBarHeight
              );
            }}
            marginBottom='0px'
          />
        </Styled.Shrinked>
      );
    }

    return (
      <ExpandedContent<THeightMode>
        height={height}
        minHeight={minHeight}
        maxHeight={maxHeight}
        disabled={bottomBar.shouldDisabledChangingHeight}
        onUpdateHeight={store.ui.sidebar.updateBottomBarHeight}
        sx={{
          marginTop: `${layoutMargin}px`,
          '.MuiTabs-root': {
            padding: '0 8px',
            marginTop: '-8px',
          },
        }}
      >
        <Styled.Toggle
          $isShrinked={false}
          iconType='maximize'
          onClick={store.ui.sidebar.shrinkBottomBar}
        />
        <Styled.Title>
          {translate('COMMON.LABELS.ORDERS_AND_ALERTS')}
        </Styled.Title>
        <Tabs
          tabsPosition='top'
          tabs={tabs()}
          navigationKey={navigationKey}
          preselectedTab={selectedTab}
          rootStyles={{ marginTop: '8px', overflow: 'hidden' }}
          marginBottom='0px'
        />
      </ExpandedContent>
    );
  }
);
