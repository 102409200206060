import { useMemo } from 'react';
import { Stack } from '@mui/material';

import { Checkbox, Icon } from '@trader/components';
import { EDateFilterTypes } from '@trader/types';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IProps {
  selectedFilterType: EDateFilterTypes | null;
  onFilterTypeClick: (filterType: EDateFilterTypes) => void;
}

export const FilterList = ({
  selectedFilterType,
  onFilterTypeClick,
}: IProps) => {
  const { translate } = useI18next();

  const filters: { type: EDateFilterTypes; label: string }[] = useMemo(
    () => [
      {
        type: EDateFilterTypes.THIS_MONTH,
        label: translate('FILTERS.THIS_MONTH'),
      },
      {
        type: EDateFilterTypes.PAST_MONTH,
        label: translate('FILTERS.PAST_MONTH'),
      },
      {
        type: EDateFilterTypes.THIS_QUARTER,
        label: translate('FILTERS.THIS_QUARTER'),
      },
      {
        type: EDateFilterTypes.PAST_QUARTER,
        label: translate('FILTERS.PAST_QUARTER'),
      },
      {
        type: EDateFilterTypes.THIS_YEAR,
        label: translate('FILTERS.THIS_YEAR'),
      },
      {
        type: EDateFilterTypes.PAST_YEAR,
        label: translate('FILTERS.PAST_YEAR'),
      },
      {
        type: EDateFilterTypes.CUSTOM,
        label: translate('FILTERS.SET_YOUR_OWN'),
      },
    ],
    []
  );

  return (
    <Stack spacing='4px' marginTop='4px' padding='0 0 0 12px'>
      {filters.map(filter => (
        <Checkbox
          key={filter.type}
          label={filter.label}
          checked={filter.type === selectedFilterType}
          size='small'
          fontSize='11px'
          sx={{ padding: '0', margin: '3px 5px 0 0' }}
          icon={<Styled.UncheckedCheckboxIcon />}
          checkedIcon={
            <Styled.CheckedCheckboxIcon>
              <Icon iconType='checkmark' />
            </Styled.CheckedCheckboxIcon>
          }
          shouldHideControlInfo
          onChange={() => onFilterTypeClick(filter.type)}
        />
      ))}
    </Stack>
  );
};
