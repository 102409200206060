import React from 'react';
import { useI18next } from '@trader/services';
import * as Styled from './styled';

interface IPurchaseType {
  value: string;
}

export const PurchaseType: React.FC<IPurchaseType> = ({ value }) => {
  const { translate } = useI18next();
  return (
    <Styled.Type $isBuy={value === 'Buy'}>
      {translate(`COMMON.LABELS.${(value as string).toUpperCase()}`)}
    </Styled.Type>
  );
};
