export const sortDir = {
  asc: 'Askending',
  desc: 'Descending',
} as const;

type TSortDirKeys = keyof typeof sortDir;
export type TSortDirection = (typeof sortDir)[TSortDirKeys];

export const Direction = {
  vertical: 'vertical',
  horizontal: 'horizontal',
} as const;

type TKeysDirection = keyof typeof Direction;
export type TDirection = (typeof Direction)[TKeysDirection];

export const SortOrderDirection = {
  asc: sortDir.asc,
  desc: sortDir.desc,
} as const;

type TKeysSortOrderDirection = keyof typeof SortOrderDirection;
export type TSortOrderDirection =
  (typeof SortOrderDirection)[TKeysSortOrderDirection];
