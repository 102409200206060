import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    flex-direction: column;
    padding: 20px 25px;
    background-color: ${theme.palette.common.white};

    table {
      width: 100%;
    }

    table * {
      color: ${theme.palette.common.black}!important;
    }

    p {
      color: ${theme.palette.common.black}!important;
    }

    a {
      background-color: ${theme.palette.common.black}!important;
      color: ${theme.palette.common.white}!important;
    }
  `}
`;

export const Header = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Divider = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.palette.background.default};
    margin: 15px 0;
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 24px;
    font-weight: 600;
  `}
`;

export const SectionTitle = styled(Typography)`
  ${() => css`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  `}
`;

export const Subtitle = styled(Typography)`
  ${() => css`
    font-size: 16px;
    font-weight: 500;
  `}
`;

export const Explanation = styled(Typography)`
  ${() => css`
    font-size: 12px;
    margin: 8px 0 0 0;
  `}
`;

export const Label = styled('td')`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: 600;
    background-color: ${theme.palette.common.white};
  `}
`;

export const Value = styled('td')`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: 500;
    background-color: ${theme.palette.common.white};
  `}
`;

export const Chart = styled('div')`
  ${() => css`
    height: 200px;

    .recharts-responsive-container {
      font-size: 13px;
    }
  `}
`;
