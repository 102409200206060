import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    flex-direction: column;
    align-items: center;

    form {
      padding: 0 17px;

      > div:first-child {
        width: 100%;
      }

      > div:nth-child(2) {
        width: 100%;
      }
    }

    .MuiOutlinedInput-root {
      padding-right: 14px !important;
    }
  `};
`;

export const ControllerWrapper = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    div:first-child {
      margin-bottom: 10px;
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    width: 100%;
    padding: 11px 17px 8px 17px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 14px;
  `};
`;

export const ApplyChanges = styled(Button)`
  ${({ theme }) => css`
    background: ${theme.palette.primary.dark};
    color: ${theme.palette.common.white};
    font-weight: 400;
    font-size: 14px;
    padding: 0 10px;
    height: 45px;
    margin: 10px 0;

    :hover {
      background: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
    }
  `};
`;
