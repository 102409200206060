import { useTheme } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';

import { IPurchaseList } from '../index';
import * as Styled from './styled';

interface IStylingMap {
  title: string;
  subtitle: string;
  label: {
    text: string;
    color: string;
    backgroundColor: string;
  };
  detailsLink: string;
}

interface IPurchaseListDescription {
  purchaseType: IPurchaseList['purchaseType'];
}

export const PurchaseListDescription = ({
  purchaseType,
}: IPurchaseListDescription) => {
  const theme = useTheme();
  const { translate } = useI18next();

  const stylingMap: Record<IPurchaseList['purchaseType'], IStylingMap> = {
    evaluationChallenges: {
      title: translate('PURCHASE_CHALLENGES.EVALUATION_CHALLENGES_TITLE'),
      subtitle: translate('PURCHASE_CHALLENGES.EVALUATION_CHALLENGES_SUBTITLE'),
      label: {
        text: translate(
          'PURCHASE_CHALLENGES.EVALUATION_CHALLENGES_TITLE_LABEL'
        ),
        color: theme.palette.green['800'],
        backgroundColor: theme.palette.green['700'],
      },
      detailsLink: 'https://challenges.trade.com/standard-challenge',
    },

    instantChallenges: {
      title: translate('PURCHASE_CHALLENGES.INSTANT_CHALLENGES_TITLE'),
      subtitle: translate('PURCHASE_CHALLENGES.INSTANT_CHALLENGES_SUBTITLE'),
      label: {
        text: translate('PURCHASE_CHALLENGES.INSTANT_CHALLENGES_TITLE_LABEL'),
        color: theme.palette.purple.main,
        backgroundColor: theme.palette.purple.light,
      },
      detailsLink: 'https://challenges.trade.com/instant-challenge',
    },
  };
  const { title, subtitle, label, detailsLink } = stylingMap[purchaseType];

  return (
    <Styled.Root>
      <Wrapper alignItems='center'>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Label
          $color={label.color}
          $backgroundColor={label.backgroundColor}
        >
          <Typography>{label.text}</Typography>
        </Styled.Label>
      </Wrapper>
      <Styled.Subtitle>
        {subtitle}{' '}
        <Styled.Link href={detailsLink} target='_blank'>
          {translate('COMMON.LABELS.LEARN_MORE')}
        </Styled.Link>
      </Styled.Subtitle>
    </Styled.Root>
  );
};
