import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const MultiplierSummary: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const multiplier = backTesting.getMultiplier();

  if (!multiplier) {
    return null;
  }

  return (
    <Styled.Summary>
      <Styled.Item>
        {translate('MULI_BANDS.TOTAL_PROFIT')}
        <span>{multiplier.totalProfit}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.DAYS_PROFIT')}
        <span>{multiplier.profitDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.DAYS_LOSS')}
        <span>{multiplier.lossDays}</span>
      </Styled.Item>

      <Styled.Item>
        {translate('MULI_BANDS.NO_HIT_DAYS')}
        <span>{multiplier.daysWoHit}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.RETURN_TO_VWAP_DAYS')}
        <span>{multiplier.returnedToVwapDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.NOT_RETURN_TO_VWAP_DAYS')}
        <span>{multiplier.notReturnedToVwapDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.RETURN_TO_VWAP_LOSS')}
        <span>{multiplier.returnedToVwapWithLoss}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.RETURN_TO_LOWER_BAND_LOSS')}
        <span>{multiplier.returnedToLowerBandWithLoss}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.MAX_CONS_WIN_DAYS')}
        <span>{multiplier.maxConsecutiveWinDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.MAX_CONS_LOSS_DAYS')}
        <span>{multiplier.maxConsecutiveLossDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.MAX_CONS_SL_DAYS')}
        <span>{multiplier.maxConsecutiveSlDays}</span>
      </Styled.Item>
      <Styled.Item>
        {translate('MULI_BANDS.MAX_PROFIT')}
        <span>{multiplier.maxProfit}</span>
      </Styled.Item>
    </Styled.Summary>
  );
});
