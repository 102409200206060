import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { BottomBar, Challenges, Modals, TopBar } from '@trader/containers';
import { Progress, Typography } from '@trader/components';
import { useI18next } from '@trader/services';
import { EChallenge, EChallengeStatus } from '@trader/types';
import { useMst } from '@trader/store';
import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';

import { useRenderCellActions } from './hooks';
import { BulksAction } from './components';
import * as Styled from './styled';

export const Copier: React.FC = observer(() => {
  const { translate } = useI18next();
  const store = useMst();
  const { navigateTo } = useNavigation();

  const {
    renderRightGeneralAction,
    renderRightChosenAction,
    renderLeftAction,
  } = useRenderCellActions();

  const shouldShowLoader =
    store.pages.copier.removeAccountAsync.inProgress ||
    store.pages.copier.assignMasterAsync.inProgress ||
    store.pages.copier.assignSlaveAsync.inProgress ||
    (store.entities.challenges.getChallengesAsync.inProgress &&
      !store.entities.challenges.getChallengesAsync.hasEverBeenRun);

  const nonAssignedChallenges = store.pages.copier.getChallengesByType(
    EChallenge.NonAssigned
  );
  const masterChallenges = store.pages.copier.getChallengesByType(
    EChallenge.Master
  );

  const assignedChallenges = [
    ...masterChallenges,
    ...store.pages.copier.getChallengesByType(EChallenge.Slave),
  ];

  const activeChallenge = store.user.getActiveAccountChallenge();

  useEffect(() => {
    store.entities.tradingAccounts.getTradingAccountsAsync.run();
    store.entities.challenges.getChallengesAsync.run();
  }, []);

  useEffect(() => {
    if (activeChallenge?.status === EChallengeStatus.Disable) {
      navigateTo(NAVIGATE_TO.main.myAccount, {
        state: {
          disableMultipleLayouts: true,
          disableTradingNavigation: true,
        },
      });
    }
  }, [activeChallenge?.status]);

  return (
    <Styled.Root>
      <TopBar />
      <Styled.Main>
        <Styled.Title>
          <Typography>{translate('COPIER.TITLE')}</Typography>
          {shouldShowLoader && <Progress size={20} />}
        </Styled.Title>
        <Styled.Body>
          {nonAssignedChallenges.length ? (
            <Challenges
              dividedChallenges={nonAssignedChallenges}
              shouldTriggerGetChallenges={false}
              rightActionWidth={320}
              leftAction={renderLeftAction}
              rightAction={renderRightGeneralAction}
            />
          ) : (
            <Challenges
              dividedChallenges={[]}
              shouldTriggerGetChallenges={false}
              rightActionWidth={0}
              isMasterEmpty={false}
            />
          )}

          <BulksAction />
          {assignedChallenges.length ? (
            <Challenges
              shouldDisplayAccTypeIcon
              shouldDisplayId
              dividedChallenges={assignedChallenges}
              shouldTriggerGetChallenges={false}
              rightActionWidth={40}
              rightAction={renderRightChosenAction}
            />
          ) : (
            <Challenges
              dividedChallenges={[]}
              shouldTriggerGetChallenges={false}
              rightActionWidth={0}
              isMasterEmpty={true}
            />
          )}
        </Styled.Body>
      </Styled.Main>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
