/* eslint-disable @typescript-eslint/naming-convention,no-magic-numbers */
import { getBollingerBandsValues } from './bollingerBands';
import { getMovingAverageValues } from './movingAverage';
import { getSuperTrendValues } from './superTrend';
import { IContext, PineJS } from '../charting_library';
import { getInputValues } from './inputValues';
import { getEntryTrigger } from '../utils/getEntryTrigger';

interface IConditions {
  BB: ReturnType<typeof getBollingerBandsValues>['values'];
  MA: ReturnType<typeof getMovingAverageValues>['values'];
  ST: ReturnType<typeof getSuperTrendValues>['values'];
  context: IContext;
  Pine: PineJS;
  inputValues: ReturnType<typeof getInputValues>;
}

// Example usage
const accountBalance = 8596.18; // Your total account balance
const riskPercentage = 1; // The percentage of your account balance you're willing to risk

export const getConditions = ({
  BB,
  ST,
  MA,
  Pine,
  context,
  inputValues,
}: IConditions) => {
  const maBasis = MA.MAShort;
  const maSignal = MA.MALong;
  const trendDirection = ST.trendDirection;
  const barsConfirm = inputValues.barsConfirm;
  const takeProfitTrigger = inputValues.takeProfitTrigger;

  const close = Pine.Std.close(context);
  const open = Pine.Std.open(context);
  const averagePrice = Pine.Std.hl2(context);
  const positionSizeLong = calculateLongPositionSize(
    open,
    (open - close) / open
  );
  const positionSizeShort = calculateShortPositionSize(
    open,
    (open - close) / open
  );

  // CONDITIONS LONG
  const entryTriggerLong = getEntryTrigger({
    type: 'long',
    entryTrigger: inputValues.entryTrigger,
    Pine,
    context,
  });
  const isCondPositionLong = positionSizeLong > 0;

  const isCondSuperTrendLong = trendDirection === 0;
  const isCondSuperTrendLongSignal =
    barsConfirm === barCrossunderCounter(trendDirection, 0);

  const isCondMaLong = barsConfirm === barCrossoverCounter(maSignal, maBasis);

  const isCondBasisLong =
    barsConfirm === barCrossoverCounter(entryTriggerLong, maBasis);
  const isCondSignalLong =
    barsConfirm === barCrossoverCounter(entryTriggerLong, maSignal);

  const isCondBBLong1 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBLower);
  const isCondBBLong2 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBLowerOne);
  const isCondBBLong3 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBLowerTwo);

  const isCondBBLong4 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBUpper);
  const isCondBBLong5 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBUpperOne);
  const isCondBBLong6 =
    barsConfirm === barCrossoverCounter(entryTriggerLong, BB.BBUpperTwo);

  // TAKE PROFIT LONG CONDITIONS
  const takeTriggerLong = getEntryTrigger({
    type: 'long',
    entryTrigger: takeProfitTrigger,
    Pine,
    context,
  });
  const isCondTakePriceLong = averagePrice < close;
  const isCondTakeLong1 =
    crossover(takeTriggerLong, BB.BBUpper) && isCondPositionLong;
  const isCondTakeLong2 =
    crossover(takeTriggerLong, BB.BBUpperOne) && isCondPositionLong;
  const isCondTakeLong3 =
    crossover(takeTriggerLong, BB.BBUpperTwo) && isCondPositionLong;

  // CONDITIONS SHORT
  const entryTriggerShort = getEntryTrigger({
    type: 'short',
    entryTrigger: inputValues.entryTrigger,
    Pine,
    context,
  });
  const isCondPositionShort = positionSizeShort < 0;

  const isCondSuperTrendShort = trendDirection !== 0;
  const isCondSuperTrendShortSignal =
    barsConfirm === barCrossoverCounter(trendDirection, 0);

  const isCondMaShort = barsConfirm === barCrossunderCounter(maSignal, maBasis);

  const isCondBasisShort =
    barsConfirm === barCrossunderCounter(entryTriggerShort, maBasis);
  const isCondSignalShort =
    barsConfirm === barCrossunderCounter(entryTriggerShort, maSignal);

  const isCondBBShort1 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBUpper);
  const isCondBBShort2 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBUpperOne);
  const isCondBBShort3 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBUpperTwo);
  const isCondBBShort4 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBLower);
  const isCondBBShort6 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBLowerOne);
  const isCondBBShort5 =
    barsConfirm === barCrossunderCounter(entryTriggerShort, BB.BBLowerTwo);

  // TAKE PROFIT SHORT CONDITIONS
  const takeTriggerShort = getEntryTrigger({
    type: 'short',
    entryTrigger: takeProfitTrigger,
    Pine,
    context,
  });
  const isCondTakePriceShort = averagePrice > close;
  const isCondTakeShort1 =
    crossunder(takeTriggerShort, BB.BBLower) && isCondPositionShort;
  const isCondTakeShort2 =
    crossunder(takeTriggerShort, BB.BBLowerOne) && isCondPositionShort;
  const isCondTakeShort3 =
    crossunder(takeTriggerShort, BB.BBLowerTwo) && isCondPositionShort;

  // STOP LOSS CONDITIONS
  const isCondStopSuperTrendLong =
    barCrossoverCounter(trendDirection, 0) === 1 && isCondPositionLong;
  const isCondStopSuperTrendShort =
    barCrossunderCounter(trendDirection, 0) === 1 && isCondPositionShort;
  const isCondStopFlexSuperTrendLong =
    counterEvent(isCondPositionLong, isCondSuperTrendShort) === 1;
  const isCondStopFlexMaLong =
    counterEvent(isCondPositionLong, isCondMaShort) === 1;
  const isCondStopFlexSuperTrendShort =
    counterEvent(isCondPositionShort, isCondSuperTrendLong) === 1;
  const isCondStopFlexMaShort =
    counterEvent(isCondPositionShort, isCondMaLong) === 1;

  return {
    isCondStopSuperTrendLong,
    isCondStopSuperTrendShort,
    isCondStopFlexSuperTrendLong,
    isCondStopFlexMaLong,
    isCondStopFlexSuperTrendShort,
    isCondStopFlexMaShort,
    isCondPositionShort,
    isCondPositionLong,
    isCondTakePriceLong,
    isCondTakeLong1,
    isCondTakeLong2,
    isCondTakeLong3,
    isCondTakePriceShort,
    isCondTakeShort1,
    isCondTakeShort2,
    isCondTakeShort3,
    maBasis,
    maSignal,
    entryTriggerLong,
    entryTriggerShort,
    isCondSuperTrendLong,
    isCondSuperTrendLongSignal,
    isCondMaLong,
    isCondBasisLong,
    isCondSignalLong,
    isCondBBLong1,
    isCondBBLong2,
    isCondBBLong3,
    isCondBBLong4,
    isCondBBLong5,
    isCondBBLong6,
    isCondSuperTrendShort,
    isCondSuperTrendShortSignal,
    isCondMaShort,
    isCondBasisShort,
    isCondSignalShort,
    isCondBBShort1,
    isCondBBShort2,
    isCondBBShort3,
    isCondBBShort4,
    isCondBBShort6,
    isCondBBShort5,
  };
};

const barCrossoverCounter = (signalPrice: number, basePrice: number) => {
  const isBcond = signalPrice > basePrice;
  let bcount = 0;
  bcount = isBcond ? bcount + 1 : 0;
  return bcount;
};
const barCrossunderCounter = (signalPrice: number, basePrice: number) => {
  const isScond = signalPrice < basePrice;
  let scount = 0;
  scount = isScond ? scount + 1 : 0;
  return scount;
};
const crossover = (x: number, y: number) => x > y;
const crossunder = (x: number, y: number) => x < y;

const counterEvent = (enent: boolean, enent2: boolean) => {
  let scount = 0;
  scount = enent && enent2 ? scount + 1 : 0;
  return scount;
};

// Calculate Long Position Size Function
function calculateLongPositionSize(entryPrice, stopLossPrice) {
  const dollarsAtRisk = accountBalance * (riskPercentage / 100);
  const priceDifference = entryPrice - stopLossPrice;
  return dollarsAtRisk / priceDifference;
}

// Calculate Short Position Size Function
function calculateShortPositionSize(entryPrice, stopLossPrice) {
  const dollarsAtRisk = accountBalance * (riskPercentage / 100);
  const priceDifference = stopLossPrice - entryPrice;
  return dollarsAtRisk / priceDifference;
}
