import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import { useNavigation } from './useNavigation';

export const usePrefetchInformation = () => {
  const { navigateTo } = useNavigation();
  const store = useMst();
  const { currentLng } = useI18next();

  useEffect(() => {
    store.pages.trading.getPrefetchInformationAsync.run({
      navigation: navigateTo,
    });
  }, [currentLng]);
};
