import { useMediaQuery, useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { EChartLayoutTypes } from '@trader/types';

const breakpoints = {
  standardDesktop: '(max-width:1600px)',
  largeDesktop: '(max-width:1920px)',
  smallDesktop: '(max-width:1280px)',
  threeInRowAvailable: '(min-width:2560px)',
  noLimitation: '(min-width:2900px)',
};

export const useResponsiveDesign = () => {
  const store = useMst();

  const layoutType = store.pages.trading.layout.layoutType;
  const isTwoInRowLayoutSelected = layoutType === EChartLayoutTypes.TwoRow;
  const isThreeInRowLayoutSelected = layoutType === EChartLayoutTypes.ThreeRow;
  const isFourLayoutSelected = layoutType === EChartLayoutTypes.Four;
  const isTwoInRowOrFourSelected =
    isTwoInRowLayoutSelected || isFourLayoutSelected;

  const theme = useTheme();
  const isScreenLowerThanLd = useMediaQuery(breakpoints.largeDesktop);
  const isScreenLowerThanSd = useMediaQuery(breakpoints.standardDesktop);
  const isScreenLowerThanXl = useMediaQuery(theme.breakpoints.down('xl'));
  const isScreenLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isScreenLowerThanSmallDesktop = useMediaQuery(breakpoints.smallDesktop);
  const isScreenSuitableForThreeInRow = useMediaQuery(
    breakpoints.threeInRowAvailable
  );
  const isScreenSuitableForNoLimitation = useMediaQuery(
    breakpoints.noLimitation
  );

  return {
    instrumentDetailsHeader: {
      shouldApplyColumnDesign:
        (isTwoInRowOrFourSelected && isScreenLowerThanLd) ||
        (isThreeInRowLayoutSelected && !isScreenSuitableForNoLimitation) ||
        isScreenLowerThanSmallDesktop,
      shouldMinimizeRightSideActionBar:
        (isTwoInRowOrFourSelected && isScreenLowerThanLd) ||
        isScreenLowerThanSmallDesktop,
    },
    layoutSelection: {
      shouldHideWholeSelection: isScreenLowerThanMd,
      shouldHideThreeInRows: !isScreenSuitableForThreeInRow,
      shouldHideThreeInColumns: isScreenLowerThanSd,
      shouldHideFour: isScreenLowerThanXl,
    },
    leftBar: {
      shouldMinimizedWidth:
        (isTwoInRowOrFourSelected && isScreenLowerThanLd) ||
        isScreenLowerThanXl,
      shouldDisabledChangingWidth:
        (isTwoInRowOrFourSelected && isScreenLowerThanLd) ||
        isScreenLowerThanXl,
      shouldHideColumnSelection:
        (isTwoInRowOrFourSelected && isScreenLowerThanLd) ||
        isScreenLowerThanXl,
    },
    bottomBar: {
      shouldMinimizedHeight: isScreenLowerThanSd,
      shouldDisabledChangingHeight: isScreenLowerThanSd,
    },
  };
};
