import styled, { css } from 'styled-components';
import { MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material';
import { Wrapper } from '@trader/components';

interface IMenuItem extends MenuItemProps {
  $isChartLayout: boolean;
}

interface IStyledIcon {
  $isChartLayout: boolean;
}

export const StyledIcon = styled(Wrapper)<IStyledIcon>`
  ${({ theme, $isChartLayout }) =>
    $isChartLayout &&
    css`
      stroke: ${theme.palette.chartMenu.main};
    `};
`;
export const MenuItem = styled(MuiMenuItem)<IMenuItem>`
  ${({ theme, $isChartLayout }) =>
    $isChartLayout &&
    css`
      background-color: ${theme.palette.common.white} !important;
      stroke: ${theme.palette.chartMenu.disabled};

      &:hover {
        stroke: ${theme.palette.chartMenu.main};
      }
    `};
`;
