import React from 'react';
import { FormControlProps, FormControl, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import { conditionApply } from '@trader/utils';
import { IInputProps } from './index';

export const Input = styled(TextField)<IInputProps>`
  ${({ theme, disabled }) => css`
    color: ${theme.palette.primary.main};

    & > div {
      ${conditionApply(
        [disabled],
        `
        background-color: #f5f5f5;
        & input.Mui-disabled {
          color: #e0e0e0
        }`
      )}
    }
  `}
`;

interface IInputFormControlProps extends FormControlProps {
  fullWidth?: boolean;
}

export const InputFormControl = styled<React.FC<IInputFormControlProps>>(
  ({ fullWidth, ...rest }) => <FormControl {...rest} />
)`
  ${({ fullWidth }) => css`
    ${conditionApply(
      [fullWidth],
      `
      width: 100%`
    )}
  `}
`;
