import { useEffect } from 'react';

import { useMst } from '@trader/store';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

interface IAuth {
  sessionId: string;
}

/**
 * Custom hook that handles authentication logic.
 * It subscribes to the authentication hub and logs out the user if the session ID changes.
 */
export const useAuth = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken;

  const { unsubscribe, subscribe } = useHub<IAuth>({
    url: '/auth',
    hub: EConnectionHub.Auth,
    subscriber: EConnectionHubSubscriber.Auth,
    onMessage: message => {
      if (message.sessionId === store.auth.tokens.sessionId) {
        store.auth.logOut();
      }
    },
    invokedName: 'signOut',
  });

  useEffect(() => {
    if (store.auth.isAuth) {
      subscribe();
    }
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [store.auth.isAuth, idToken]);
};
