import { Components } from '@mui/material/styles/components';

export const TooltipOverride: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      fontSize: 12,
      fontWeight: 500,
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
      borderRadius: 4,
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
    },
  },
};
