const diffDivider = 2;

type TGetSpreadDifference = (
  difference: number,
  askValue: number,
  bidValue: number
) => { ask: number; bid: number };

export const getSpreadDifference: TGetSpreadDifference = (
  difference: number,
  askValue: number,
  bidValue: number
) => {
  const dividedSpreadDiff = difference / diffDivider;
  const bid = bidValue + dividedSpreadDiff;
  const ask = askValue - dividedSpreadDiff;

  return {
    ask,
    bid,
  };
};
