import React, { useEffect } from 'react';
import { useMst } from '@trader/store';

import {
  IChartingLibraryWidget,
  ResolutionString,
  TimeFramePeriodBack,
} from '../../charting_library';

import { useResetHistoricalBarsInterval } from './useResetHistoricalBarsInterval';
import { useUpdateMultipliers } from './useUpdateMultipliers';
import { useDataBox } from './useDataBox';
import { useStrips } from './useStrips';
import { useOrders } from './useOrders';
import { usePositions } from './usePositions';
import { invertedResolutionObject } from '../../datafeed';

export const useMuliBands = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();
  const idToken = store.auth.tokens.idToken;
  const symbol = store.pages.muliBands.symbol;
  const indicators = store.pages.muliBands.indicators;

  const { clearStrips } = useStrips(widget);
  const { clearOrders } = useOrders(widget);
  const { clearPositions } = usePositions(widget);

  useUpdateMultipliers(widget, isMuliBands);
  useResetHistoricalBarsInterval(widget, isMuliBands);
  useDataBox(widget, isMuliBands);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      indicators.forEach(indicator => {
        const study = widget?.current
          ?.activeChart()
          .getAllStudies()
          .find(s => s.name === indicator.name);
        if (indicator.isActive) {
          !study && widget?.current?.activeChart().createStudy(indicator.name);
        } else {
          study && widget?.current?.activeChart().removeEntity(study.id);
        }
      });
    });
  }, [JSON.stringify(indicators)]);

  useEffect(() => {
    return () => {
      clearOrders();
      clearStrips();
      clearPositions();
    };
  }, [symbol, idToken]);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      widget?.current
        ?.activeChart()
        ?.onSymbolChanged()
        .subscribe(null, () => {
          widget?.current?.activeChart().setTimeFrame({
            val: {
              type: 'period-back' as TimeFramePeriodBack['type'],
              value: '1M',
            },
            res: invertedResolutionObject.Minute2 as ResolutionString,
          });
        });
    });
  }, []);
};
