import { getIconBaseProps } from './utils';

export const circleCheckmark = {
  ...getIconBaseProps('circleCheckmark'),
  width: '15px',
  height: '15px',
  viewBox: '0 0 15 15',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.66659 6.05542L6.7777 8.94431L5.33325 7.49986'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
