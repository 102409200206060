import { Icon, IHeadCell } from '@trader/components';
import { useMemo } from 'react';
import { useI18next } from '@trader/services';
import { useExtendItemWidthDependsLng } from '@trader/hooks';

type TUseGetCells = (
  leftAction?: (challengeId: number) => JSX.Element,
  rightActionWidth?: number,
  shouldDisplayAccTypeIcon?: boolean,
  shouldDisplayId?: boolean,
  shouldDisplayStatus?: boolean,
  shouldDisplayResult?: boolean
) => {
  cells: Array<IHeadCell>;
};

export const useGetCells: TUseGetCells = (
  leftAction,
  rightActionWidth,
  shouldDisplayAccTypeIcon,
  shouldDisplayId,
  shouldDisplayStatus,
  shouldDisplayResult
) => {
  const { translate, currentLng } = useI18next();
  const { getExtendedWidth } = useExtendItemWidthDependsLng();

  const getCells = useMemo(() => {
    const cells: Array<IHeadCell> = [
      {
        id: 'accountId',
        label: translate('COMMON.LABELS.ACCOUNT'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'challengeAmount',
        label: translate('COMMON.LABELS.CHALLENGE'),
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'payout',
        label: translate('COMMON.LABELS.PAYOUT'),
        minWidth: 80,
        align: 'center',
      },
      {
        id: 'balance',
        label: translate('COMMON.LABELS.EQUITY'),
        minWidth: getExtendedWidth('70'),
        align: 'center',
      },
      {
        id: 'profitLoss24',
        label: 'P/L',
        minWidth: 135,
        align: 'center',
      },
      {
        id: 'profitLossOverall',
        label: 'OVERALL P/L',
        minWidth: 135,
        align: 'center',
      },
      {
        id: 'tradingDays',
        label: translate('COMMON.LABELS.MIN_TRADING_DAYS'),
        minWidth: getExtendedWidth('150'),
        align: 'center',
      },
      //TODO: temporary hide column (it's customer needs)

      // {
      //   id: 'remainingTime',
      //   label: translate('COMMON.LABELS.REMAINING'),
      //   minWidth: 80,
      //   align: 'center',
      // },
      {
        id: 'goalReached',
        label: translate('COMMON.LABELS.GOAL_REACHED'),
        minWidth: getExtendedWidth('120'),
        align: 'center',
      },
    ];

    if (shouldDisplayResult) {
      cells.push({
        id: 'result',
        label: translate('COMMON.LABELS.RESULT'),
        minWidth: 80,
        align: 'center',
      });
    }

    if (shouldDisplayStatus) {
      cells.unshift({
        id: 'status',
        label: translate('COMMON.LABELS.STATUS'),
        minWidth: 70,
        align: 'center',
      });
    }

    if (shouldDisplayId) {
      cells.unshift({
        id: 'id',
        label: 'Id',
        minWidth: 30,
        align: 'center',
      });
    }

    cells.unshift({
      id: 'dailyTargetProfit',
      label: <Icon iconType='dailyTargetProfit' />,
      minWidth: 50,
      align: 'center',
    });

    if (shouldDisplayAccTypeIcon) {
      cells.unshift({
        id: 'accountIcon',
        label: '',
        minWidth: 50,
        align: 'center',
      });
    }

    cells.unshift({
      id: 'trade',
      label: 'trade',
      minWidth: 100,
      align: 'center',
    });

    if (leftAction) {
      cells.unshift({
        id: 'leftAction',
        label: '',
        minWidth: 45,
        align: 'end',
      });
    }
    if (rightActionWidth) {
      cells.push({
        id: 'rightAction',
        label: '',
        minWidth: rightActionWidth,
        align: 'center',
      });
    }

    return cells;
  }, [
    currentLng,
    shouldDisplayResult,
    shouldDisplayStatus,
    shouldDisplayId,
    shouldDisplayAccTypeIcon,
    leftAction,
    rightActionWidth,
  ]);

  return { cells: getCells };
};
