import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';

export const Container = styled(Wrapper)`
  ${() => css`
    align-items: center;
  `};
`;

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    position: relative;
    background: white;
    justify-content: center;
    width: 35px;
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
  `};
`;

interface IBox {
  $selected?: boolean;
  $open: boolean;
}

export const Main = styled(Wrapper)<IBox>`
  ${({ $open }) => css`
    position: absolute;
    background: white;
    z-index: 10;
    width: 35px;
    flex-direction: column;
    bottom: ${$open ? '-205px' : 0};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px 0;
    box-shadow: 5px 4px 10px 6px rgba(0, 0, 0, 0.1);
  `};
`;

export const Box = styled(Wrapper)<IBox>`
  ${() => css`
    background: white;
    padding: 8px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: rgba(140, 118, 118, 0.3);
    }
  `};
`;
