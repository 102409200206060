import { getIconBaseProps } from './utils';

export const dataBox = {
  ...getIconBaseProps('dataBox'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M14.125 1H1.875C1.39175 1 1 1.39175 1 1.875V14.125C1 14.6082 1.39175 15 1.875 15H14.125C14.6082 15 15 14.6082 15 14.125V1.875C15 1.39175 14.6082 1 14.125 1Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 7.125H11.5'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 4.5H11.5'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 15L4.5 1'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
