import { requestService, TAsyncActionOptions } from '@trader/services';
import { serverRoutes } from './routes';

export class Server {
  routes = serverRoutes;

  async pingServer(options?: TAsyncActionOptions): Promise<void> {
    await requestService.get<void>(this.routes.ping, {
      ...options?.cancelToken,
    });
  }
}
