import styled, { css } from 'styled-components';
import {
  Box,
  FormControlLabel,
  Radio as RadioCore,
  RadioGroup,
} from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup';

import { Direction, TDirection } from '@trader/themes';

import { ContainerControlInfo } from '../controlInfo/styled';

export const LabelContainer = styled(FormControlLabel)`
  width: 100%;
  margin-left: -9px;
  user-select: none;
`;

export const CheckedIcon = styled.span`
  ${props => css`
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${props.theme.palette.grey[400]};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: ${props.theme.palette.common.black};
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-right: -2px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: ${props.theme.palette.common.white};
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`;

export const Icon = styled.span`
  ${props => css`
    box-sizing: border-box;
    border: 1px solid ${props.theme.palette.grey[400]};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: ${props.theme.palette.common.white};
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-right: -2px;

    input:hover ~ & {
      border-color: ${props.theme.palette.grey[500]};
    }

    input:disabled ~ & {
      border-color: ${props.theme.palette.grey[300]};
    }
  `}
`;

export const RadioContainer = styled('div')`
  width: 100%;

  ${ContainerControlInfo} {
    margin-top: -9px;
  }
`;

export const Radio = styled(RadioCore)`
  &:hover {
    background-color: transparent;
  }

  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const TextContainer = styled(Box)`
  padding-top: 7px;
  padding-right: 30px;
  font-size: 16px;
`;

interface IRadioButtonGroupContainer extends RadioGroupProps {
  direction?: TDirection;
}

export const RadioGroupContainer = styled(
  RadioGroup
)<IRadioButtonGroupContainer>`
  ${({ direction }) =>
    css`
      flex-direction: ${direction === Direction.vertical ? 'column' : 'row'};
    `}
`;
