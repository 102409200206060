import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  hasCopierFunctionality,
  isChallengeAccountType,
} from '@trader/constants';
import { useI18next } from '@trader/services';
import { TChallengeEntity, TTradingAccountEntity, useMst } from '@trader/store';
import { Wrapper } from '@trader/components';
import { EChallenge } from '@trader/types';

import * as Styled from './styled';

interface ITopItemPart {
  tradingAccount: TTradingAccountEntity;
  toggleOpen?: (accountId: number) => void;
  openAccId?: number | null;
}

export const TopItemPart: React.FC<ITopItemPart> = observer(
  ({ tradingAccount, toggleOpen, openAccId }) => {
    const { translate } = useI18next();
    const store = useMst();

    const renderChallengeInfoInCondition = useMemo(() => {
      if (isChallengeAccountType) {
        return `${tradingAccount.currencySymbol}${
          store.user.getAccountChallenge(tradingAccount.tradingId)
            ?.challengeAmount || 0
        }`;
      }
      return tradingAccount.platformLogin;
    }, []);

    const renderCopierToggle = useMemo(() => {
      if (!hasCopierFunctionality) {
        return null;
      }
      const challenge = store.user.getAccountChallenge(
        tradingAccount.tradingId
      );

      if (challenge?.hierarchyType !== EChallenge.Master) {
        return null;
      }

      const isSomeSlave = store.entities.challenges
        .getAll<TChallengeEntity>()
        .some(slave => slave.masterId === challenge.id);

      if (!isSomeSlave) {
        return null;
      }

      return (
        <Styled.DropDownToggle
          isSingleIcon
          variant='text'
          iconType={
            openAccId === tradingAccount.tradingId ? 'minimize' : 'maximize'
          }
          onClick={e => {
            e.stopPropagation();
            toggleOpen && toggleOpen(tradingAccount.tradingId);
          }}
        />
      );
    }, [openAccId]);

    return (
      <Styled.TopText>
        {renderChallengeInfoInCondition}
        <Wrapper>
          {tradingAccount.isInUse && (
            <span>{translate('COMMON.LABELS.ACTIVE')}</span>
          )}
          {renderCopierToggle}
        </Wrapper>
      </Styled.TopText>
    );
  }
);
