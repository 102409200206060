import { getIconBaseProps } from './utils';

export const networkQuality = {
  ...getIconBaseProps('networkQuality'),
  width: '27px',
  height: '21px',
  viewBox: '0 0 27 21',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M4.7474 12.8828H1.76125C1.25197 12.8828 0.839111 13.2969 0.839111 13.8078V19.5743C0.839111 20.0851 1.25197 20.4992 1.76125 20.4992H4.7474C5.25668 20.4992 5.66953 20.0851 5.66953 19.5743V13.8078C5.66953 13.2969 5.25668 12.8828 4.7474 12.8828Z'
        fill='currentColor'
      />
      <path
        d='M11.5778 9.99023H8.59168C8.08239 9.99023 7.66954 10.4043 7.66954 10.9152V19.5751C7.66954 20.0859 8.08239 20.5 8.59168 20.5H11.5778C12.0871 20.5 12.5 20.0859 12.5 19.5751V10.9152C12.5 10.4043 12.0871 9.99023 11.5778 9.99023Z'
        fill='currentColor'
      />
      <path
        d='M18.4082 5.75977H15.4221C14.9128 5.75977 14.5 6.17388 14.5 6.68472V19.5757C14.5 20.0866 14.9128 20.5007 15.4221 20.5007H18.4082C18.9175 20.5007 19.3304 20.0866 19.3304 19.5757V6.68472C19.3304 6.17388 18.9175 5.75977 18.4082 5.75977Z'
        fill='currentColor'
      />
      <path
        d='M25.2387 0.5H22.2525C21.7432 0.5 21.3304 0.914115 21.3304 1.42495V19.575C21.3304 20.0859 21.7432 20.5 22.2525 20.5H25.2387C25.748 20.5 26.1608 20.0859 26.1608 19.575V1.42495C26.1608 0.914115 25.748 0.5 25.2387 0.5Z'
        fill='currentColor'
      />
    </>
  ),
};
