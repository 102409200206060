import { getIconBaseProps } from './utils';

export const longRight = {
  ...getIconBaseProps('longRight'),
  width: '26px',
  height: '12px',
  viewBox: '0 0 26 12',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM25.5303 6.53033C25.8232 6.23744 25.8232 5.76257 25.5303 5.46967L20.7574 0.696701C20.4645 0.403807 19.9896 0.403807 19.6967 0.696701C19.4038 0.989594 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM1 6.75L25 6.75L25 5.25L1 5.25L1 6.75Z'
      fill='currentColor'
    />
  ),
};
