import { getIconBaseProps } from './utils';

export const signOut = {
  ...getIconBaseProps('signOut'),
  width: '18px',
  height: '20px',
  viewBox: '0 0 18 20',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M12.7892 1.36768H3.49996C2.39539 1.36768 1.49996 2.26311 1.49996 3.36768V17.3677C1.49996 18.4722 2.39539 19.3677 3.49996 19.3677H12.7892'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.918 13.7427L17.3048 10.3677M17.3048 10.3677L13.918 6.99268M17.3048 10.3677H6.01559'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
