import { getIconBaseProps } from './utils';

export const upload = {
  ...getIconBaseProps('upload'),
  width: '15px',
  height: '13px',
  viewBox: '0 0 15 13',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M4.93833 3.37331C4.74237 3.54946 4.43862 3.54946 4.24265 3.37331C4.04669 3.19715 4.04669 2.92412 4.24265 2.74797L7.14295 0.140921L7.17234 0.114499L7.19194 0.0968835H7.21153V0.0792683H7.23113V0.0616531C7.30952 0.0264228 7.3977 0 7.48588 0C7.57407 0 7.66225 0.0176152 7.73084 0.0616531H7.75044V0.0792683H7.77003V0.0968835H7.77983L7.79943 0.114499L7.82882 0.140921L10.7291 2.74797C10.9251 2.92412 10.9251 3.19715 10.7291 3.37331C10.5429 3.54946 10.2294 3.54946 10.0334 3.37331L7.966 1.51491V8.2351C7.966 8.48171 7.75044 8.67548 7.47609 8.67548C7.20173 8.67548 6.98617 8.48171 6.98617 8.2351V1.51491L4.91874 3.37331H4.93833ZM14.0017 8.44648C14.0017 8.19986 14.2173 8.0061 14.4917 8.0061C14.766 8.0061 14.9816 8.19986 14.9816 8.44648V10.939C14.9816 11.5027 14.7268 12.0224 14.3055 12.3923C13.894 12.7622 13.3159 13 12.6888 13H2.2928C1.66571 13 1.08761 12.771 0.676081 12.3923C0.264554 12.0224 0 11.5027 0 10.939V8.44648C0 8.19986 0.215562 8.0061 0.489914 8.0061C0.764266 8.0061 0.979828 8.19986 0.979828 8.44648V10.939C0.979828 11.2649 1.1268 11.5556 1.36196 11.7669C1.59712 11.9783 1.93026 12.1104 2.283 12.1104H12.6986C13.0611 12.1104 13.3844 11.9783 13.6196 11.7669C13.8548 11.5556 14.0017 11.2561 14.0017 10.939V8.44648Z'
      fill='currentColor'
    />
  ),
};
