import React from 'react';

import { Button } from '../button';

import * as Styled from './styled';
import { localStorageService } from '@trader/services';

interface IErrorHandlerProps {
  title?: React.ReactNode;
  buttonText?: React.ReactNode;
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ErrorHandler: React.FC<IErrorHandlerProps> = ({
  title,
  buttonText,
  onBtnClick,
  ...otherProps
}) => (
  <Styled.ErrorHandler {...otherProps}>
    <Styled.ErrorHandlerTitle>{title}</Styled.ErrorHandlerTitle>
    <Button onClick={onBtnClick}>{buttonText}</Button>
  </Styled.ErrorHandler>
);

ErrorHandler.defaultProps = {
  title: 'We are sorry, but something went wrong while loading the app',
  buttonText: 'Reload application',
  onBtnClick: () => {
    localStorageService.clear();
    window.location.reload();
  },
};
