import { schema } from 'normalizr';

const instrument = new schema.Entity(
  'instruments',
  {},
  {
    idAttribute: 'symbol',
  }
);

export const instrumentsSchema = [instrument];
