import React, { useEffect } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import { Progress } from '@trader/components';
import { TAvailableChallengeEntity, useMst } from '@trader/store';
import { BottomBar, Modals, TopBar } from '@trader/containers';
import { EChallengeEvaluationType } from '@trader/types';
import { useI18next } from '@trader/services';

import { PurchaseList } from './components/purchaseList';
import * as Styled from './styled';

export const PurchaseChallenges: React.FC = observer(() => {
  const { translate } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const store = useMst();
  const evaluationChallenges = store.entities.availableChallenges
    .getAll<TAvailableChallengeEntity>()
    .filter(ch => ch.evaluationType === EChallengeEvaluationType.Evaluation);
  const instantChallenges = store.entities.availableChallenges
    .getAll<TAvailableChallengeEntity>()
    .filter(ch => ch.evaluationType === EChallengeEvaluationType.Instant);
  const isLoading =
    store.entities.availableChallenges.getAvailableChallengesAsync.inProgress;

  useEffect(() => {
    store.entities.availableChallenges.getAvailableChallengesAsync.run();
  }, []);

  return (
    <Styled.Root>
      <TopBar />
      <Styled.Body>
        {!isMobile && (
          <Styled.Title>{translate('PURCHASE_CHALLENGES.TITLE')}</Styled.Title>
        )}
        {isLoading ? (
          <Progress size={25} color='primary' />
        ) : (
          <Stack direction='column' spacing='75px'>
            <PurchaseList
              purchaseType='evaluationChallenges'
              items={evaluationChallenges}
            />
            <PurchaseList
              purchaseType='instantChallenges'
              items={instantChallenges}
            />
          </Stack>
        )}
      </Styled.Body>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
