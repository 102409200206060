import { TChallengeEntity, useMst } from '@trader/store';
import { EChallengeResult, IChallengeMessage } from '@trader/types';
import { MODAL_TYPES } from '@trader/constants';

export const useChallengesLogic = () => {
  const store = useMst();

  const challengesLogic = (message: IChallengeMessage) => {
    const activeChallenge = store.user.getActiveAccountChallenge();

    const isFailed =
      message.result === EChallengeResult.Failed ||
      message.result === EChallengeResult.Cancelled;

    if (activeChallenge?.id === message.id) {
      if (activeChallenge.result !== message.result) {
        if (isFailed) {
          // do Failed for using account
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: true,
            shouldHideCloseButton: true,
            result: message.result,
          });
        }
        if (message.result === EChallengeResult.Completed) {
          // do Success for using account
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: true,
            shouldHideCloseButton: true,
            result: message.result,
          });
        }
      }
    } else {
      const challenge = store.entities.challenges.get<TChallengeEntity>(
        message.id
      );
      if (challenge?.result !== message.result) {
        if (isFailed) {
          // do Failed for not using account
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: false,
            result: message.result,
            challengeId: message.id,
          });
        }
        if (message.result === EChallengeResult.Completed) {
          // do Success for not using account
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: false,
            result: message.result,
            challengeId: message.id,
          });
        }
      }
    }
  };

  return {
    challengesLogic,
  };
};
