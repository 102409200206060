import { useMemo } from 'react';

import {
  formatByPipSize,
  getBuyLossRateWithAmplitude,
  getBuyProfitRateWithAmplitude,
  getDefaultPipsBaseOnTickSize,
  getLimitPrice,
  getLossPipsFromRate,
  getProfitPipsFromRate,
  getSellLossRateWithAmplitude,
  getSellProfitRateWithAmplitude,
  handleIndicesPipSize,
} from '@trader/utils';
import {
  TAddProtectionType,
  TPlaceOrderSide,
  TPlaceOrderType,
} from '@trader/types';
import { defaultPipSize } from '@trader/constants';
import { TInstrumentEntity, useMst } from '@trader/store';

interface IState {
  side: TPlaceOrderSide;
  symbol: string;
  stopLoss?: number | null;
  takeProfit?: number | null;
  pendingPrice?: number | null;
  amount?: number | null;
}

export const useGetTradingDefaultFormValues = ({
  stopLoss,
  takeProfit,
  pendingPrice,
  side,
  symbol,
  amount,
}: IState) => {
  const store = useMst();

  const entity = store.entities.instruments.get<TInstrumentEntity | undefined>(
    symbol
  );

  return useMemo(() => {
    if (!entity) {
      return {};
    }

    const pipSize = handleIndicesPipSize(
      entity.pipSize || defaultPipSize,
      entity.category || undefined,
      entity.minOrderSizeIncrement
    );

    const ask = +formatByPipSize(entity.ask, pipSize);
    const bid = +formatByPipSize(entity.bid, pipSize);

    const price = pendingPrice
      ? formatByPipSize(pendingPrice, pipSize)
      : getLimitPrice(
          entity.stopsLevel,
          entity.tickSize,
          entity.pipSize,
          bid,
          ask,
          side
        );

    const takeProfitValue = String(
      takeProfit ||
        (side === 'Buy'
          ? getBuyProfitRateWithAmplitude(
              entity.stopsLevel,
              entity.tickSize,
              pipSize,
              pendingPrice ? pendingPrice : ask,
              entity.pipSize,
              entity?.category || undefined
            )
          : getSellProfitRateWithAmplitude(
              entity.stopsLevel,
              entity.tickSize,
              pipSize,
              pendingPrice ? pendingPrice : bid,
              entity.pipSize,
              entity?.category || undefined
            ))
    );

    const stopLossValue = String(
      stopLoss ||
        (side === 'Buy'
          ? getBuyLossRateWithAmplitude(
              entity.stopsLevel,
              entity.tickSize,
              pipSize,
              pendingPrice ? pendingPrice : ask,
              entity.pipSize,
              entity?.category || undefined
            )
          : getSellLossRateWithAmplitude(
              entity.stopsLevel,
              entity.tickSize,
              pipSize,
              pendingPrice ? pendingPrice : bid,
              entity.pipSize,
              entity?.category || undefined
            ))
    );

    const initialPips = getDefaultPipsBaseOnTickSize({
      pipSize,
      stopsLevel: entity.stopsLevel,
      tickSize: entity.tickSize,
      category: entity.category,
    });

    const profitPips = takeProfit
      ? getProfitPipsFromRate(
          takeProfitValue,
          entity.tickSize,
          side,
          ask,
          bid,
          pipSize,
          entity?.category || undefined,
          entity.pipSize
        ).toString()
      : initialPips;

    const lossPips = stopLoss
      ? getLossPipsFromRate(
          stopLossValue,
          entity?.tickSize,
          side,
          bid,
          ask,
          pipSize,
          entity?.category || undefined,
          entity.pipSize
        ).toString()
      : initialPips;

    return {
      amount: amount ? amount.toString() : entity.minOrderSize.toString(),
      price: formatByPipSize(price, pipSize),
      takeProfit: formatByPipSize(takeProfitValue, pipSize),
      stopLoss: formatByPipSize(stopLossValue, pipSize),
      takeProfitPips: profitPips,
      stopLossPips: lossPips,
      isTakeProfit: !!Number(takeProfit),
      isStopLoss: !!Number(stopLoss),
      takeProfitType: 'Rate' as TAddProtectionType,
      stopLossType: 'Rate' as TAddProtectionType,
      orderType: (pendingPrice ? 'EntryLimit' : 'Market') as TPlaceOrderType,
      ask: entity.ask,
      bid: entity.bid,
      side,
    };
  }, [entity?.symbol]);
};
