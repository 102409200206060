import { MouseEvent } from 'react';

import * as Styled from './styled';

interface IProps {
  label: string;
  isFilterApplied: boolean;
  popoverId?: string;
  isStatic?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const FilterTrigger = ({
  label,
  popoverId,
  isFilterApplied,
  isStatic,
  onClick,
}: IProps) => {
  return (
    <Styled.Trigger
      aria-describedby={popoverId}
      $isStatic={isStatic}
      onClick={isStatic ? undefined : onClick}
    >
      {label}
      <Styled.FilterIcon iconType='filter' $isApplied={isFilterApplied} />
    </Styled.Trigger>
  );
};
