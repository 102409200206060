import React from 'react';
import { OutlinedTextFieldProps, FormControlProps } from '@mui/material';

import { ControlInfo } from '../../../controlInfo';

import * as Styled from './styled';

export interface IInputProps
  extends Omit<
    OutlinedTextFieldProps,
    | 'variant'
    | 'error'
    | 'helperText'
    | 'size'
    | 'margin'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'placeholder'
  > {
  shouldHideErrorOnInput?: boolean;
  isError?: boolean;
  shouldHideControlInfo?: boolean;
  helperText?: string;
  inputFormControl?: Omit<FormControlProps, 'variant'>;
  fullWidth?: boolean;
}

export const TextField: React.FC<IInputProps> = ({
  disabled,
  label,
  helperText,
  isError,
  shouldHideErrorOnInput,
  value,
  onChange,
  name,
  color,
  shouldHideControlInfo,
  inputFormControl,
  fullWidth,
  ...rest
}) => {
  return (
    <Styled.InputFormControl
      {...inputFormControl}
      fullWidth={fullWidth}
      variant='outlined'
    >
      <Styled.Input
        error={isError}
        value={value || ''}
        label={label}
        onChange={onChange}
        color={color}
        name={name}
        disabled={disabled}
        fullWidth={fullWidth}
        {...rest}
      />
      {!shouldHideControlInfo && (
        <ControlInfo helperText={helperText} isError={isError} />
      )}
    </Styled.InputFormControl>
  );
};
