import { requestService, TAsyncActionOptions } from '@trader/services';

import { challengeRoutes } from './routes';
import {
  IAssignMasterBody,
  IAssignSlaveBody,
  IAvailableChallenge,
  IChallenge,
} from './models';

export class Challenge {
  routes = challengeRoutes;

  async getChallenges(options: TAsyncActionOptions): Promise<IChallenge[]> {
    const resp = await requestService.get<IChallenge[]>(
      this.routes.getChallenges,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getAvailableChallenges(
    options: TAsyncActionOptions
  ): Promise<IAvailableChallenge[]> {
    const resp = await requestService.get<IAvailableChallenge[]>(
      this.routes.getAvailableChallenges,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async assignMaster(
    options: TAsyncActionOptions,
    body: IAssignMasterBody
  ): Promise<void> {
    const resp = await requestService.post<void>(
      this.routes.assignMaster,
      body,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async assignSlave(
    options: TAsyncActionOptions,
    body: IAssignSlaveBody
  ): Promise<void> {
    const resp = await requestService.post<void>(
      this.routes.assignSlave,
      body,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async removeSlave(options: TAsyncActionOptions, id: number): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.unAssignSlave,
      { slaveIds: [id] },
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async removeMaster(options: TAsyncActionOptions, id: number): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.unAssignMaster,
      { masterIds: [id] },
      {
        params: { id },
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }
}
