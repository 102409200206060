import { getIconBaseProps } from './utils';

export const plus = {
  ...getIconBaseProps('plus'),
  width: '9px',
  height: '9px',
  viewBox: '0 0 9 9',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1 4.5H4.5M4.5 4.5H8M4.5 4.5V8M4.5 4.5V1'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
