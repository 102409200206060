import React, { useContext, useState } from 'react';

import { useI18next } from '@trader/services';
import { Popover } from '@trader/components';

import { CloseAllOptionsSelection } from './closeAllPositionsSelection';
import * as Styled from './styled';
import { useMst } from '@trader/store';
import { observer } from 'mobx-react-lite';
import { MODAL_TYPES } from '@trader/constants';
import { TradingNotificationsContext } from '@trader/contexts';

export const CloseAllPositions: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { activateIsCloseAll } = useContext(TradingNotificationsContext);

  const [shouldShowContent, setShouldShowContent] = useState(false);

  const isBetaDesign = store.app.isBetaDesignEnabled();

  return (
    <Popover
      behavior='click'
      elevation={4}
      showContent={shouldShowContent}
      anchorOrigin={{
        vertical: -5,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      trigger={
        <Styled.Trigger
          onClick={() => {
            if (isBetaDesign) {
              setShouldShowContent(true);
            } else {
              store.ui.modal.open(MODAL_TYPES.removeAllMetrics, {
                shouldHideCloseButton: true,
                removingType: 'positions',
              });
            }
          }}
        >
          {translate('COMMON.LABELS.CLOSE_ALL')}
        </Styled.Trigger>
      }
      content={
        <CloseAllOptionsSelection
          onClose={() => {
            activateIsCloseAll();
            setShouldShowContent(false);
          }}
        />
      }
    />
  );
});
