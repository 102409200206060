import { getIconBaseProps } from './utils';

export const trdfxLogo = {
  ...getIconBaseProps('trdfxLogo'),
  width: '155px',
  height: '31px',
  viewBox: '0 0 155 31',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8.8881 30.3496V2.16783H0V0H20.2475V2.16783H11.0559V30.3496H8.8881Z'
        fill='currentColor'
      />
      <path
        d='M25.9967 30.3496V0H36.9225C38.7146 0 40.3043 0.404661 41.6917 1.21398C43.1081 2.02331 44.2209 3.12167 45.0302 4.50908C45.8395 5.86759 46.2442 7.41397 46.2442 9.14824C46.2442 10.8825 45.854 12.4 45.0736 13.7007C44.322 15.0014 43.267 16.0275 41.9085 16.779C40.5789 17.5016 39.0614 17.8629 37.3561 17.8629H28.1645V30.3496H25.9967ZM44.9001 30.3496L37.3561 17.2993L39.1771 16.172L47.4148 30.3496H44.9001ZM28.1645 15.6951H37.6162C38.8591 15.6951 39.9575 15.4205 40.9113 14.8713C41.8652 14.3221 42.6022 13.5562 43.1225 12.5734C43.6717 11.5907 43.9463 10.4778 43.9463 9.23495C43.9463 7.87644 43.6428 6.66246 43.0358 5.593C42.4288 4.52354 41.5761 3.68531 40.4778 3.07832C39.4083 2.47133 38.1799 2.16783 36.7925 2.16783H28.1645V15.6951Z'
        fill='currentColor'
      />
      <path
        d='M53.1792 30.3496V0H64.2351C66.2006 0 68.0361 0.375757 69.7414 1.12727C71.4468 1.87879 72.9353 2.9338 74.2071 4.2923C75.5078 5.6219 76.5195 7.21164 77.2421 9.06152C77.9936 10.9114 78.3694 12.9492 78.3694 15.1748C78.3694 17.3715 77.9936 19.4093 77.2421 21.2881C76.5195 23.138 75.5078 24.7422 74.2071 26.1007C72.9353 27.4303 71.4468 28.4708 69.7414 29.2223C68.0361 29.9738 66.2006 30.3496 64.2351 30.3496H53.1792ZM55.347 28.9188L54.74 28.1818H64.0183C65.6659 28.1818 67.2123 27.8638 68.6575 27.2279C70.1316 26.592 71.4179 25.696 72.5162 24.5398C73.6146 23.3547 74.4817 21.9818 75.1176 20.4209C75.7535 18.8312 76.0715 17.0825 76.0715 15.1748C76.0715 13.2671 75.7535 11.5328 75.1176 9.97201C74.4817 8.38227 73.6146 7.00931 72.5162 5.85314C71.4179 4.66806 70.1316 3.75757 68.6575 3.12167C67.2123 2.48578 65.6659 2.16783 64.0183 2.16783H54.61L55.347 1.51748V28.9188Z'
        fill='currentColor'
      />
      <path
        d='M84.147 30.3496V0H92.7316V30.3496H84.147ZM88.2225 19.2937V12.4H103.918V19.2937H88.2225ZM88.2225 7.41397V0H105.608V7.41397H88.2225Z'
        fill='currentColor'
      />
      <path
        d='M128.761 30.3496L119.353 16.7356L107.299 0H117.662L126.853 13.4405L139.123 30.3496H128.761ZM107.083 30.3496L118.659 13.8307L125.986 17.2559L117.011 30.3496H107.083ZM127.547 16.2587L120.263 12.9636L128.761 0H138.69L127.547 16.2587Z'
        fill='currentColor'
      />
      <path
        d='M142.23 15.1607L150.121 3.68506H142.649L134.839 15.1612L135.57 16.2367L142.649 26.4473H150.447L142.23 15.1607Z'
        fill='#8959E7'
      />
      <path
        d='M145.767 15.1428L152.343 5.85303H146.117L139.608 15.1432L140.217 16.0139L146.117 24.2796H152.615L145.767 15.1428Z'
        fill='#A57DF4'
      />
      <path
        d='M149.522 15.1285L154.783 7.5874H149.802L144.595 15.1289L145.082 15.8356L149.802 22.5454H155L149.522 15.1285Z'
        fill='#BC9AFE'
      />
    </>
  ),
};
