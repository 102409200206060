import { getIconBaseProps } from './utils';

export const popular = {
  ...getIconBaseProps('popular'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M4 18V5.55556M4 13.8674C8.72727 10.329 12.2727 17.4058 17 13.8674V5.02145C12.2727 8.55985 8.72727 1.48305 4 5.02145V13.8674Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
