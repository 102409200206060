import {
  IContext,
  MappedObject,
  PineJS,
  StudyBooleanInputInfo,
  StudyColorerPlotInfo,
  StudyFilledAreaInfo,
  StudyInputInfo,
  StudyLinePlotInfo,
  StudyNumericInputInfo,
  StudyPlotInformation,
  StudyShapesPlotInfo,
  StudyStylesInfo,
  StudyTextInputInfo,
} from '../charting_library';
import { typeOptions } from './defaultInputs';
import { baseDefaultsStyles } from '../constants';

const movingAverage = {
  basis: 'basis',
  signal: 'signal',
  basisBgArea: 'basisBgArea',
  signalBgArea: 'signalBgArea',
  averageBgArea: 'averageBgArea',
  shortSignal: 'shortSignal',
  longSignal: 'longSignal',
  shorBgArea: 'shorBgArea',
  longBgArea: 'longBgArea',
  sellSignalShape: 'sellSignalShape',
  buySignalShape: 'buySignalShape',
};

export const movingAveragePlots: StudyPlotInformation[] = [
  {
    id: movingAverage.basis,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: movingAverage.signal,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: movingAverage.basisBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: movingAverage.basis,
    palette: movingAverage.basisBgArea,
  },
  {
    id: movingAverage.signalBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: movingAverage.signal,
    palette: movingAverage.signalBgArea,
  },
  {
    id: movingAverage.averageBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: movingAverage.averageBgArea,
    palette: movingAverage.averageBgArea,
  },
  {
    id: movingAverage.shortSignal,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: movingAverage.longSignal,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: movingAverage.shorBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: movingAverage.shortSignal,
    palette: movingAverage.shorBgArea,
  },
  {
    id: movingAverage.longBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: movingAverage.longSignal,
    palette: movingAverage.longBgArea,
  },
  {
    id: movingAverage.sellSignalShape,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: movingAverage.buySignalShape,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
];

export const movingAverageInputs: StudyInputInfo[] = [
  {
    id: 'movingAverageCrossStrategy',
    name: '[MA] Use MA Cross strategy',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: true,
  },
  {
    id: 'movingAverageBasisType',
    name: '[MA] Basis MA type',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'sma',
    options: typeOptions,
  },
  {
    id: 'movingAverageSignalType',
    name: '[MA] Signal MA type',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'wma',
    options: typeOptions,
  },
  {
    id: 'movingAverageRatio',
    name: '[MA] Ratio',
    type: 'float' as StudyNumericInputInfo['type'],
    defval: 1.08,
    step: 0.02,
    min: 0.01,
  },
  {
    id: 'movingAverageMultiplier',
    name: '[MA] Multiplier',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 89,
  },
];

export const movingAverageFilledAreas: StudyFilledAreaInfo[] = [
  {
    id: movingAverage.averageBgArea,
    objAId: movingAverage.signal,
    objBId: movingAverage.basis,
    title: '[MA] Background',
    type: 'plot_plot' as StudyFilledAreaInfo['type'],
    palette: movingAverage.averageBgArea,
  },
];

export const movingAveragePallets = {
  [movingAverage.averageBgArea]: {
    valToIndex: {
      0: 0,
      1: 1,
    },
    colors: {
      0: {
        name: 'Color 0',
      },
      1: {
        name: 'Color 1',
      },
    },
  },
  [movingAverage.shorBgArea]: {
    colors: {
      1: {
        name: 'Color',
      },
    },
  },
  [movingAverage.longBgArea]: {
    colors: {
      1: {
        name: 'Color',
      },
    },
  },
  [movingAverage.basisBgArea]: {
    colors: {
      0: {
        name: 'Color 0',
      },
      1: {
        name: 'Color 1',
      },
    },
  },
  [movingAverage.signalBgArea]: {
    colors: {
      0: {
        name: 'Color 0',
      },
      1: {
        name: 'Color 1',
      },
    },
  },
};

export const movingAverageDefaults = {
  filledAreasStyle: {
    [movingAverage.averageBgArea]: {
      color: 'yellow',
      visible: true,
      transparency: 0,
    },
  },
  palettes: {
    [movingAverage.averageBgArea]: {
      colors: {
        0: {
          color: 'rgba(227,13,37,0.39)',
          width: 1,
          style: 0,
        },
        1: {
          color: 'rgba(91,206,52,0.24)',
          width: 1,
          style: 0,
        },
      },
    },
    [movingAverage.shorBgArea]: {
      colors: {
        1: {
          color: 'rgb(227,13,37)',
          width: 4,
          style: 0,
        },
      },
    },
    [movingAverage.longBgArea]: {
      colors: {
        1: {
          color: 'rgb(77,180,43)',
          width: 4,
          style: 0,
        },
      },
    },
    [movingAverage.basisBgArea]: {
      colors: {
        0: {
          color: 'rgba(227,13,37,0.39)',
          width: 1,
          style: 0,
        },
        1: {
          color: 'rgba(91,206,52,0.24)',
          width: 1,
          style: 0,
        },
      },
    },
    [movingAverage.signalBgArea]: {
      colors: {
        0: {
          color: 'rgba(227,13,37,0.39)',
          width: 1,
          style: 0,
        },
        1: {
          color: 'rgba(91,206,52,0.24)',
          width: 1,
          style: 0,
        },
      },
    },
  },
  styles: {
    [movingAverage.basis]: {
      ...baseDefaultsStyles,
      color: 'rgba(227,13,37,0.39)',
    },
    [movingAverage.signal]: {
      ...baseDefaultsStyles,
      color: 'rgba(91,206,52,0.24)',
    },
    [movingAverage.shortSignal]: {
      ...baseDefaultsStyles,
      plottype: 6,
      color: 'rgba(227,13,37,0.39)',
    },
    [movingAverage.longSignal]: {
      ...baseDefaultsStyles,
      plottype: 6,
      color: 'rgba(91,206,52,0.24)',
    },
    [movingAverage.sellSignalShape]: {
      color: 'rgba(227,13,37,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_up',
      location: 'BelowBar',
      visible: true,
    },
    [movingAverage.buySignalShape]: {
      color: 'rgba(77,180,43,0)',
      textColor: '#000',
      transparency: 0,
      plottype: 'shape_label_down',
      location: 'AboveBar',
      visible: true,
    },
  },
};

export const movingAverageStyles: MappedObject<StudyStylesInfo> = {
  [movingAverage.basis]: {
    histogramBase: 0,
    title: '[MA] Basis',
  },
  [movingAverage.signal]: {
    histogramBase: 0,
    title: '[MA] Signal',
  },
  [movingAverage.shortSignal]: {
    histogramBase: 0,
    title: '[MA] Short Signal',
  },
  [movingAverage.longSignal]: {
    histogramBase: 0,
    title: '[MA] Long Signal',
  },
  [movingAverage.sellSignalShape]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[Entry] Short, MA signal crossing MA basis',
    title: 'Short Signal Shape',
  },
  [movingAverage.buySignalShape]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    text: '[Entry] Long, MA signal crossing MA basis',
    title: 'Long Signal Shape',
  },
};

interface IMovingAverage {
  context: IContext;
  Pine: PineJS;
  MAShort: number;
  MALong: number;
  isMaCrossingStrategy: boolean;
}

export const getMovingAverageValues = ({
  context,
  Pine,
  MAShort,
  MALong,
  isMaCrossingStrategy,
}: IMovingAverage) => {
  const value = MAShort - MALong;
  const colorIndex = value > 0 ? 1 : 0;

  const isCrossingMA = Pine.Std.cross(MALong, MAShort, context);

  let signal: 'sell' | 'buy' | null = null;
  if (isCrossingMA) {
    signal = MAShort < MALong ? 'sell' : MAShort > MALong ? 'buy' : null;
  }

  const isSellSignalVisible = signal === 'sell' ? 1 : NaN;
  const isBuySignalVisible = signal === 'buy' ? 1 : NaN;

  return {
    values: {
      MAShort,
      MALong,
      isCrossingMA,
      isSellSignalVisible,
      isBuySignalVisible,
      signal,
      colorIndex,
    },
    result: [
      MAShort,
      MALong,
      colorIndex,
      colorIndex,
      colorIndex,
      MAShort,
      MALong,
      isSellSignalVisible,
      isBuySignalVisible,
      isMaCrossingStrategy ? isSellSignalVisible : NaN,
      isMaCrossingStrategy ? isBuySignalVisible : NaN,
    ],
  };
};
