import { Instance, types } from 'mobx-state-tree';
import { createCollectionModel } from '../../utils/collectionModel';
import { getPositionsHistoryAsync } from './actions';

export const positionHistoryModel = types.model('positionHistoryModel', {
  category: types.string,
  closeTime: types.string,
  comment: types.string,
  commission: types.number,
  currency: types.string,
  currencySymbol: types.string,
  displayName: types.string,
  entryPrice: types.number,
  exitPrice: types.number,
  fee: types.number,
  iconUrl: types.maybeNull(types.string),
  openTime: types.string,
  pipSize: types.number,
  pl: types.number,
  positionId: types.number,
  quantity: types.number,
  side: types.string,
  stopLoss: types.number,
  swap: types.number,
  symbol: types.string,
  takeProfit: types.number,
  change: types.optional(types.number, 0),
});

interface IPositionsHistoryAsync {
  getPositionsHistoryAsync: typeof getPositionsHistoryAsync;
}

export const positionsHistory = createCollectionModel<
  typeof positionHistoryModel,
  IPositionsHistoryAsync
>(positionHistoryModel, {
  getPositionsHistoryAsync,
});

export type TPositionsHistoryEntity = Instance<typeof positionsHistory>;
export type TPositionHistoryEntity = Instance<typeof positionHistoryModel>;
