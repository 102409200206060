import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, IOption, TOptions } from '@trader/types';
import { Autocomplete } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import {
  convertFromOption,
  convertToOption,
  roundDecimals,
} from '@trader/utils';

import * as Styled from './styled';

export const MultiplierFilter: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const [options, setOptions] = useState<Record<string, IOption>>({});

  const backTesting = store.pages.muliBands.backTesting;

  const symbol = convertFromOption(backTesting.getSymbolOption());

  const value = backTesting.selectedMultiplierOption
    ? [backTesting.selectedMultiplierOption]
    : [];

  const onChange = (event: IChangeFieldEvent<TOptions>) => {
    backTesting.runInAction(() => {
      backTesting.selectedMultiplierOption = event.target.value[0];
    });
  };

  useEffect(() => {
    for (
      let multiplier = backTesting.lowestMultiplier;
      multiplier <= backTesting.highestMultiplier;
      multiplier += backTesting.multiplierStep
    ) {
      const roundedMultiplier = roundDecimals(multiplier).toString();

      setOptions(prev => ({
        ...prev,
        [roundedMultiplier]: { ...convertToOption(roundedMultiplier)[0] },
      }));
    }
  }, [symbol]);

  return (
    <Styled.Content>
      <Autocomplete
        shouldHideControlInfo
        placeholder={translate('MULI_BANDS.MULTIPLIER')}
        value={value}
        options={Object.values(options)}
        shouldHideInput
        withCheckbox={false}
        onChange={onChange}
        size='small'
      />
    </Styled.Content>
  );
});
