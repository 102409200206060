import { useEffect } from 'react';

import { inAppMessagingService, IInAppMessage } from '@trader/services';
import { useMst } from '@trader/store';

/**
 * Custom hook that sets up in-app notifications.
 */
export const useInAppNotifications = () => {
  const store = useMst();

  const handleMessage = (message: IInAppMessage) => {
    store.notifications.add({
      message: message.body,
      options: {
        variant: 'info',
        autoHideDuration: 6000,
      },
    });

    // If the user is currently viewing the "Price Alerts" page,
    // fetch the updated list of price alerts to ensure the data is current
    // after a price alert notification has been triggered.
    if (store.pages.trading.assetsView === 'price-alerts') {
      store.entities.alerts.getAlertsAsync.run();
    }
  };

  useEffect(() => {
    inAppMessagingService.handleMessaging(handleMessage);
  }, []);
};
