import React from 'react';
import * as Styled from './styled';
import { observer } from 'mobx-react-lite';

import { Tabs, ModalTitle, Progress } from '@trader/components';
import { isChallengeAccountType } from '@trader/constants';
import { useI18next } from '@trader/services';

import {
  PositionsHistory,
  PurchaseHistory,
  TransfersHistory,
} from './components';

export { PositionsHistory } from './components';

export const MyHistory: React.FC = observer(() => {
  const { translate } = useI18next();
  const tabs = [
    {
      value: 'general',
      label: translate('COMMON.LABELS.POSITIONS'),
      children: <PositionsHistory shouldShowFooter />,
    },
    {
      value: 'change-password',
      label: translate('COMMON.LABELS.TRANSFERS'),
      children: <TransfersHistory />,
    },
  ];

  if (isChallengeAccountType) {
    tabs.push({
      value: 'purchase-history',
      label: translate('COMMON.LABELS.PURCHASED_HISTORY'),
      children: (
        <React.Suspense fallback={<Progress />}>
          <PurchaseHistory />
        </React.Suspense>
      ),
    });
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.MY_HISTORY')} />
      <Tabs marginBottom='0' tabsPosition='top' tabs={tabs} />
    </Styled.Root>
  );
});
