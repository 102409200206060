import React from 'react';
import { observer } from 'mobx-react-lite';

import { AssetDetails, InstrumentLayouts } from '@trader/containers';

import * as Styled from './styled';

export const Dashboard: React.FC = observer(() => {
  return (
    <Styled.Root>
      <InstrumentLayouts />
      <Styled.Asset>
        <AssetDetails />
      </Styled.Asset>
    </Styled.Root>
  );
});
