import React from 'react';

import { getPercentOfSecondNumber } from '@trader/utils';
import { TPlaceOrderSide } from '@trader/types';

import * as Styled from './styled';

interface IChangePercentage {
  entryValue: number;
  exitValue: number;
  side: TPlaceOrderSide;
}

export const ChangePercentage: React.FC<IChangePercentage> = ({
  entryValue,
  exitValue,
  side,
}) => {
  const percentage =
    side === 'Buy'
      ? getPercentOfSecondNumber(exitValue, entryValue)
      : getPercentOfSecondNumber(entryValue, exitValue);

  return (
    <Styled.Change
      $isAboveZero={+percentage > 0}
      $isEqualZero={+percentage === 0}
    >
      {percentage}%
    </Styled.Change>
  );
};
