export const defaultInputs = {
  source: 'close', // index 0
  barsConfirm: 2, // index 1
  entryTrigger: 'close', // index 2
  superTrendConfirm: true, // index 3
  superTrendFactor: 3, // index 4
  superTrendLength: 20, // index 5
  movingAverageCrossStrategy: true, // index 6
  movingAverageBasisType: 'sma', // index 7
  movingAverageSignalType: 'wma', // index 8
  movingAverageRatio: 1.08, // index 9
  movingAverageMultiplier: 89, // index 10
  bollingerBandsType: 'wma', // index 11
  bollingerBandsLength: 30, // index 12
  bollingerBandsStdDev: 3, // index 13
  bollingerBandsLongOrders: 'lower3', // index 14
  bollingerBandsLongOrdersSecond: 'lower2', // index 15
  bollingerBandsLongOrdersThird: 'off', // index 16
  bollingerBandsShortOrders: 'upper3', // index 17
  bollingerBandsShortOrdersSecond: 'upper2', // index 18
  bollingerBandsShortOrdersThird: 'off', // index 19
  takeProfitTrigger: 'close', // index 20
  useTpOrdersOne: false, // index 21
  useTpOrdersTwo: false, // index 22
  useTpOrderThree: true, // index 23
  TpLongQty: 5, // index 24
  TpShortQty: 5, // index 25
  useLimitOrders: true, // index 26
  LoLength: 12, // index 27
  LoMultiplier: 0.1, // index 28
  stopLossTriger: 'open/close median', // index 29
  stopLossType: 'ATR', // index 30
  stopLossLength: 12, // index 31
  stopLossLongMult: 6, // index 32
  stopLossShortMult: 4.3, // index 33
} as const;

export const typeOptions = [
  'alma',
  'ema',
  'sma',
  'swma',
  'vwma',
  'wma',
  'linreg',
  'high',
  'low',
  'percentrank',
];
