import { IInstrumentBE } from '@trader/api';
import {
  dateFormats,
  getNextDay,
  getTimeDifference,
  getUTCDate,
  isDateInTimeInterval,
} from '@trader/utils';

class InstrumentHelpers {
  /**
   * Checks if trading for an instrument is currently unavailable due to holidays or session hours.
   */
  isInstrumentTradingUnavailable(
    holidays: IInstrumentBE['holidays'] | undefined,
    sessions: IInstrumentBE['sessions'] | undefined
  ) {
    const utcDate = getUTCDate();

    if (holidays && holidays.length > 0) {
      for (const holiday of holidays) {
        const formattedDate = dateFormats(utcDate, 'YYYY-MM-DD');
        if (holiday === formattedDate) {
          const nextDay = getNextDay(getUTCDate());
          return {
            isUnavailable: true,
            openIn: getTimeDifference(utcDate, nextDay),
          };
        }
      }
    }

    if (sessions && sessions.length > 0) {
      const currentDayIndex = utcDate.getDay();
      const tradingHours = sessions[currentDayIndex]?.tradingHours || [];

      let isInTradingHours = false;
      let nextAvailableDate: null | Date = null;

      for (const hours of tradingHours) {
        if (isDateInTimeInterval(utcDate, hours.from, hours.to)) {
          isInTradingHours = true;
          break;
        } else {
          const fromDate = getUTCDate();
          const fromHours = +hours.from.split(':')[0];
          const fromMinutes = +hours.from.split(':')[1];
          fromDate.setHours(fromHours, fromMinutes);

          if (nextAvailableDate === null) {
            nextAvailableDate = fromDate;
          } else {
            if (nextAvailableDate > fromDate) {
              nextAvailableDate = fromDate;
            }
          }
        }
      }

      if (!isInTradingHours && nextAvailableDate !== null) {
        /* Handle the case when next available trading date will be in next day. */
        if (utcDate > nextAvailableDate) {
          const lastDayIndex = 6;
          const nextDayIndex =
            currentDayIndex === lastDayIndex ? 0 : currentDayIndex + 1;
          const hours = sessions[nextDayIndex]?.tradingHours;

          if (hours && hours.length > 0) {
            const fromDate = getUTCDate();
            const fromHours = +hours[0].from.split(':')[0];
            const fromMinutes = +hours[0].from.split(':')[1];
            fromDate.setDate(utcDate.getDate() + 1);
            fromDate.setHours(fromHours, fromMinutes);

            nextAvailableDate = fromDate;
          }
        }

        return {
          isUnavailable: true,
          openIn: getTimeDifference(utcDate, nextAvailableDate),
        };
      }
    }

    return {
      isUnavailable: false,
      openIn: '',
    };
  }
}

export const instrumentHelpers = new InstrumentHelpers();
