import { getIconBaseProps } from './utils';

export const orders = {
  ...getIconBaseProps('orders'),
  width: '18px',
  height: '18px',
  viewBox: '0 0 18 18',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8.11111 2.7778H2.58025C2.16114 2.7778 1.7592 2.94429 1.46284 3.24065C1.16649 3.537 1 3.93894 1 4.35805V15.4198C1 15.8389 1.16649 16.2408 1.46284 16.5372C1.7592 16.8335 2.16114 17 2.58025 17H13.642C14.0611 17 14.463 16.8335 14.7594 16.5372C15.0557 16.2408 15.2222 15.8389 15.2222 15.4198V9.88891'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0367 1.50846C14.3622 1.18292 14.8037 1.00003 15.2641 1.00003C15.7245 1.00003 16.166 1.18292 16.4916 1.50846C16.8171 1.834 17 2.27553 17 2.73592C17 3.1963 16.8171 3.63783 16.4916 3.96338L8.71768 11.7373L5.44446 12.5556L6.26276 9.28236L14.0367 1.50846Z'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
