import React from 'react';

import { EmptyData } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const NoMastersAccount: React.FC = () => {
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <EmptyData title={translate('COPIER.NO_MASTER_ACC')} />
    </Styled.Root>
  );
};
