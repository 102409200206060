import { ThemeOptions } from '@mui/material';
import {
  SelectOverride,
  TooltipOverride,
  TypographyOverride,
} from '../overridedComponents';
import { breakpoints, lightPallet, typography } from '../constants';
import { customSpacing, IThemeOptions } from './index';

export const lightTheme = (options?: IThemeOptions): ThemeOptions => {
  return {
    components: {
      MuiTypography: TypographyOverride,
      MuiTooltip: TooltipOverride,
      MuiSelect: SelectOverride(options),
    },
    breakpoints,
    typography: options?.typography ? options.typography : typography,
    palette: options?.palette ? options.palette : lightPallet,
    spacing: customSpacing,
  };
};
