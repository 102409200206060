import { getIconBaseProps } from './utils';

export const lightning = {
  ...getIconBaseProps('lightning'),
  width: '11px',
  height: '14px',
  viewBox: '0 0 11 14',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M0.353284 6.78065L7.62651 0.230168C7.91822 -0.0325553 8.4043 0.238005 8.27384 0.590486L6.47708 5.44514C6.33644 5.82513 6.65049 6.21755 7.09523 6.21755H9.83082C10.4113 6.21755 10.702 6.84966 10.2916 7.21935L3.01834 13.7698C2.72663 14.0326 2.24055 13.762 2.371 13.4095L4.16776 8.55486C4.3084 8.17487 3.99436 7.78245 3.54962 7.78245H0.814024C0.233523 7.78245 -0.0571917 7.15034 0.353284 6.78065Z'
      fill='#A259DB'
    />
  ),
};
