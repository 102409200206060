import styled, { css } from 'styled-components';
import { conditionApply } from '@trader/utils';
import { Wrapper } from '@trader/components';
import { IconButton } from '@mui/material';

export const Input = styled('input')`
  ${({ disabled, theme }) => css`
    width: 100%;
    margin: 0 10px;
    border: none;
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    background-color: transparent;
    color: ${theme.palette.text.primary};

    ${theme.breakpoints.down('sm')} {
      font-size: 15px;
    }

    &:focus {
      border: none;
      outline: none;
      appearance: none;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    ${conditionApply(
      [disabled],
      css`
        background: transparent;
        color: ${theme.palette.gray[700]};
      `
    )};
  `}
`;

export const RootInput = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;
export const Main = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;

interface IButtonAction {
  $isMinus?: boolean;
}

export const ButtonAction = styled(IconButton)<IButtonAction>`
  ${({ $isMinus, theme, disabled }) => css`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 30px;
    font-size: 25px;
    color: ${$isMinus
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};
    border: 1px solid
      ${$isMinus
        ? theme.palette.purchaseButtons.red
        : theme.palette.purchaseButtons.green};
    ${conditionApply(
      [disabled],
      `
         border: 1px solid ${theme.palette.gray.main};
         color:${theme.palette.gray.main};
        `
    )};

    ${theme.breakpoints.down('sm')} {
      width: 30px;
      height: 30px;
    }
  `}
`;

export const InputError = styled(Wrapper)`
  ${() => css`
    color: red;
    text-align: center;
    font-size: 12px;
    height: 23px;
    line-height: 18px;
  `}
`;
