import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { StableDesign } from './stableDesign';
import { BetaDesign } from './betaDesign';

interface ILeftBar {
  tabsList: string[];
}

export const LeftBar: React.FC<ILeftBar> = observer(({ tabsList }) => {
  const store = useMst();

  if (store.app.isBetaDesignEnabled()) {
    return <BetaDesign />;
  }

  return <StableDesign tabsList={tabsList} />;
});
