import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Child = styled(Wrapper)`
  ${() => css`
    position: relative;
    width: 100%;
    height: 100%;
  `};
`;

export const Box = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background: ${theme.palette.background.paper};
  `};
`;
