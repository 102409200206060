import { FC } from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { defaultIcon } from '@trader/constants';
import { getInstrumentDifference } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useResponsiveDesign } from '@trader/hooks';
import { EChartLayouts } from '@trader/types';
import { ActionButtons, Tooltip, Wrapper } from '@trader/components';

import * as Styled from './styled';
import { IInstrumentDetailsHeader } from '../index';
import { RightSideActionBar } from './components/rightSideActionBar';

export const BetaDesign: FC<IInstrumentDetailsHeader> = observer(
  ({
    instrument: propInstrument,
    isMuliBands,
    onChangeSymbol,
    searchCategory,
  }) => {
    const store = useMst();
    const symbol = store.pages.trading.getInstrumentSymbolByLayout();
    const storeInstrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    const instrument = propInstrument || {
      ...storeInstrument,
      layoutNumber: EChartLayouts.FirstLayout,
    };

    const {
      instrumentDetailsHeader: {
        shouldApplyColumnDesign,
        shouldMinimizeRightSideActionBar,
      },
    } = useResponsiveDesign();

    if (!instrument?.symbol) {
      return null;
    }

    const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
      getInstrumentDifference(
        instrument.ask,
        instrument.close,
        instrument.pipSize
      );

    return (
      <Styled.Header
        $shouldApplyColumnDesign={shouldApplyColumnDesign}
        $isMuliBands={!!isMuliBands}
      >
        <Wrapper>
          <Stack
            direction={shouldApplyColumnDesign ? 'column' : 'row'}
            spacing='8px'
            alignItems={shouldApplyColumnDesign ? undefined : 'center'}
          >
            <Wrapper alignItems='flex-start'>
              <img
                src={instrument.iconUrl || defaultIcon}
                alt='instrument icon'
                width='30px'
                height='30px'
              />
              <Tooltip title={instrument.description}>
                <Styled.Symbol>{instrument.symbol}</Styled.Symbol>
              </Tooltip>
            </Wrapper>
            <Styled.Difference $isAboveZero={isGrow}>
              {openCloseDifference} ({openCloseDifferenceInPercent}%)
            </Styled.Difference>
          </Stack>
          <ActionButtons
            direction={shouldApplyColumnDesign ? 'column' : 'row'}
            symbol={instrument.symbol}
            ask={instrument.ask}
            bid={instrument.bid}
            pipSize={instrument.pipSize}
          />
        </Wrapper>
        <RightSideActionBar
          instrument={instrument}
          isMinimized={shouldMinimizeRightSideActionBar}
          isMuliBands={isMuliBands}
          searchCategory={searchCategory}
          onChangeSymbol={onChangeSymbol}
        />
      </Styled.Header>
    );
  }
);
