import styled, { css } from 'styled-components';
import { Wrapper, Typography } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: ${modalHorizontalPadding}px;
    width: 1047px;
    height: 443px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    text-align: start;
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.background.default};
    margin-bottom: 10px;
  `}
`;
