import { getIconBaseProps } from './utils';

export const failed = {
  ...getIconBaseProps('failed'),
  width: '52px',
  height: '52px',
  viewBox: '0 0 52 52',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='26' cy='26' r='24.5' stroke='#FF5050' strokeWidth='3' />
      <path
        d='M35 35L17 17'
        stroke='#FF5050'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.0001 17L17 35.0001'
        stroke='#FF5050'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
