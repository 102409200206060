import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, TOptions } from '@trader/types';
import {
  shouldDisplayEdgeX,
  shouldDisplayMuliBands,
  tradingViewTypes,
} from '@trader/constants';
import { themes } from '@trader/themes';
import { useMst } from '@trader/store';
import {
  useI18next,
  getLanguageOption,
  getLanguageOptions,
  LANGUAGES,
} from '@trader/services';

import { Indicator } from './components';

import * as Styled from './styled';

const MINIMUM_LANGUAGE_OPTIONS = 2;

export const General: React.FC = observer(() => {
  const store = useMst();
  const { translate, changeLng } = useI18next();

  const [state, setState] = useState({
    theme: [
      {
        title: translate(`COMMON.LABELS.${store.app.themeMode.toUpperCase()}`),
        value: store.app.themeMode,
      },
    ],
    chart: [
      {
        title: translate(`COMMON.LABELS.${store.app.chartType.toUpperCase()}`),
        value: store.app.chartType,
      },
    ],
    language: [getLanguageOption()],
    isTradingCentral: store.user.settings.isDisplayedByUser,
    isEdgeX: store.user.settings.isEdgeXDisplayedByUser,
    isEdgeZone: store.user.settings.isEdgeZoneDisplayedByUser,
    isEdited: false,
  });

  const themeOptions: TOptions = themes.map(t => ({
    title: translate(`COMMON.LABELS.${t.toUpperCase()}`),
    value: t,
  }));

  const chartOptions: TOptions = tradingViewTypes.map(c => ({
    title: translate(`COMMON.LABELS.${c.toUpperCase()}`),
    value: c,
  }));

  const languageOptions: TOptions = getLanguageOptions();
  const hasLanguageSwitcher: boolean =
    languageOptions.length >= MINIMUM_LANGUAGE_OPTIONS;

  const handleChange = (
    event: IChangeFieldEvent<TOptions> | React.ChangeEvent<HTMLInputElement>
  ) => {
    const switchers = ['isTradingCentral', 'isEdgeX', 'isEdgeZone'];
    const value = !switchers.includes(event.target.name)
      ? event.target.value
      : (event as React.ChangeEvent<HTMLInputElement>).target.checked;

    setState(prev => ({
      ...prev,
      isEdited: true,
      [event.target.name]: value,
    }));
  };

  const save = () => {
    const translations = LANGUAGES[state.language[0].value].resource;
    const languageTranslatedKey = LANGUAGES[state.language[0].value].name;
    const translatedLanguageOption = {
      title: translations.LANGUAGES[`${languageTranslatedKey.toUpperCase()}`],
      value: state.language[0].value,
    };

    setState(prev => ({
      ...prev,
      language: [translatedLanguageOption],
      isEdited: false,
    }));

    const settings = {
      isTradingCentral: state.isTradingCentral,
      isEdgeX: state.isEdgeX,
      isEdgeZone: state.isEdgeZone,
    };
    store.app.toggleThemeMode(state.theme[0].value);
    store.app.changeChartType(state.chart[0].value);
    store.user.updateSettingsAsync.run(settings);
    changeLng(state.language[0].value);
  };

  return (
    <Styled.Root>
      <Styled.Container>
        <Styled.Skin>
          <Styled.Title>{translate('COMMON.LABELS.SKIN')}</Styled.Title>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.THEME')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='theme'
              value={[
                {
                  title: translate(
                    `COMMON.LABELS.${state.theme[0].value.toUpperCase()}`
                  ),
                  value: state.theme[0].value,
                },
              ]}
              options={themeOptions}
              onChange={handleChange}
            />
          </Styled.Item>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.CHART')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='chart'
              value={[
                {
                  title: translate(
                    `COMMON.LABELS.${state.chart[0].value.toUpperCase()}`
                  ),
                  value: state.chart[0].value,
                },
              ]}
              options={chartOptions}
              onChange={handleChange}
            />
          </Styled.Item>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.LANGUAGE')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='language'
              disabled={!hasLanguageSwitcher}
              value={state.language}
              options={languageOptions}
              onChange={handleChange}
            />
          </Styled.Item>
        </Styled.Skin>
        <Styled.TradingCentral>
          <Styled.Title>
            {translate('COMMON.LABELS.TRADING_CENTRAL')}
          </Styled.Title>
          <Indicator
            shouldDisplay
            disabled={!store.user.settings.isCrmTradingCentral}
            checked={state.isTradingCentral}
            name='isTradingCentral'
            leftLabel='COMMON.LABELS.TRADING_CENTRAL'
            innerHtml='COMMON.LABELS.TRADING_CENTRAL_IS_DISABLED'
            onChange={handleChange}
          />
          <Indicator
            shouldDisplay={shouldDisplayEdgeX}
            disabled={!store.user.settings.isCrmEdgeX}
            checked={state.isEdgeX}
            name='isEdgeX'
            leftLabel='COMMON.LABELS.EDGE_X_INDICATOR'
            innerHtml='COMMON.LABELS.EDGE_X_INDICATOR_IS_DISABLED'
            onChange={handleChange}
          />
          <Indicator
            shouldDisplay={shouldDisplayMuliBands}
            disabled={!store.user.settings.isCrmEdgeZone}
            checked={state.isEdgeZone}
            name='isEdgeZone'
            leftLabel='COMMON.LABELS.MULI_BANDS_STRATEGY'
            innerHtml='COMMON.LABELS.MULI_BANDS_INDICATOR_IS_DISABLED'
            onChange={handleChange}
          />
        </Styled.TradingCentral>
      </Styled.Container>
      <Styled.Container>
        <Styled.Save disabled={!state.isEdited} onClick={save}>
          {translate('COMMON.LABELS.SAVE_CHANGES')}
        </Styled.Save>
      </Styled.Container>
    </Styled.Root>
  );
});
