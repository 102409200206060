import styled, { css } from 'styled-components';

import { Typography } from '../typography';
import { Wrapper } from '../wrapper';
import { Button } from '../button';
import { conditionApply } from '@trader/utils';

interface IOpenView {
  $isOpenView: boolean;
}

export const Root = styled(Wrapper)<IOpenView>`
  ${({ theme, $isOpenView }) => css`
    justify-content: space-between;
    position: relative;
    padding: 10px 15px 0 15px;
    flex-wrap: wrap;
    align-items: flex-start;
    border-top: 1px solid ${theme.palette.background.default};

    ${conditionApply(
      [!$isOpenView],
      css`
        align-items: center;
      `
    )};

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const ToggleButton = styled(Button)<IOpenView>`
  ${({ $isOpenView }) => css`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    ${conditionApply(
      [!$isOpenView],
      css`
        position: inherit;
      `
    )};
  `};
`;
export const ContentContainer = styled(Wrapper)`
  ${() => css`
    width: 47%;
    max-width: 700px;
    flex-direction: column;
  `};
`;

export const Paragraph = styled(Typography)`
  ${() => css`
    font-weight: 500;
    font-size: 10px;

    > span {
      font-weight: 600;
    }

    .resistance {
      color: rgb(49, 146, 26);
    }

    .support {
      color: rgb(219, 46, 39);
    }

    .pivot {
      color: rgb(101, 67, 186);
    }
  `};
`;
export const Title = styled(Typography)<IOpenView>`
  ${({ $isOpenView }) => css`
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 4px;

    ${conditionApply(
      [!$isOpenView],
      css`
        margin-bottom: 0;
      `
    )};
  `};
`;
