import { IError } from '@trader/services';
import { EErrorTypes } from './requestErrorType';

export const analyzeErrorType = (error: IError): EErrorTypes => {
  if (error.message === EErrorTypes.CANCEL_REQUEST) {
    return EErrorTypes.CANCEL_REQUEST;
  }

  if (error.type === EErrorTypes.INNER_ERROR && error.message === '10019') {
    return EErrorTypes.INSUFFICIENT_FUNDS;
  }

  return error.type;
};
