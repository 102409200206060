import { IIndicators } from '@trader/api';
import { getIndicatorInfoUsingIndicator } from '@trader/utils';
import { baseDefaultsStyles, baseStyles, defaultStudy } from '../constants';

interface IPlot {
  id: string;
  type: string;
}

interface IStyles {
  [key: string]: {
    title: string;
    histogramBase: number;
    isHidden: boolean;
  };
}

interface IDefaultsStyles {
  [key: string]: {
    color: string;
    linestyle: number;
    linewidth: number;
    plottype: number;
    visible: boolean;
    trackPrice: boolean;
  };
}

export interface ITCStydy {
  prices: Array<number>;
  plots: Array<IPlot>;
  styles: IStyles;
  defaults: {
    styles: IDefaultsStyles;
  };
}

export const getTradingCentral = (response: IIndicators): ITCStydy => {
  const indicators = response?.feeds[0]?.chartLevel;
  const pivotPrice = indicators?.pivot;
  const TC: ITCStydy = {
    ...defaultStudy,
    prices: pivotPrice ? [pivotPrice] : [],
  };

  for (const key in indicators) {
    if (key !== 'last' && indicators[key] !== pivotPrice) {
      const info = getIndicatorInfoUsingIndicator(key);

      TC.prices = [...TC.prices, indicators[key]];
      TC.plots = [...TC.plots, { id: key, type: 'line' }];
      TC.styles = {
        ...TC.styles,
        [key]: {
          ...baseStyles,
          title: info.title,
        },
      };
      TC.defaults.styles = {
        ...TC.defaults.styles,
        [key]: {
          ...baseDefaultsStyles,
          color: info.color,
        },
      };
    }
  }
  return TC;
};
