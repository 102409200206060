import { baseDefaultsStyles, baseStyles } from './styles';

export const defaultStudy = {
  prices: [],
  plots: [
    {
      id: 'pivot',
      type: 'line',
    },
  ],
  styles: {
    pivot: {
      ...baseStyles,
      title: 'Pivot',
    },
  },
  defaults: {
    styles: {
      pivot: {
        ...baseDefaultsStyles,
        color: 'rgb(101, 67, 186)',
      },
    },
  },
};
