import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import { EChartLayouts } from '@trader/types';
import { conditionApply } from '@trader/utils';
import { layoutChildrenRadius } from '@trader/constants';

interface IRoot {
  $alignment?: string;
  $layout?: string;
  $selectedLayout?: string;
  $instrumentLayout?: EChartLayouts;
}

export const Child = styled(Wrapper)<IRoot>`
  ${({ theme, $layout, $selectedLayout, $instrumentLayout }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    ${conditionApply(
      [$layout !== 'single'],
      css`
        border: 1px solid ${theme.palette.background.paper};
      `
    )}
    ${conditionApply(
      [$layout !== 'single' && $selectedLayout === $instrumentLayout],
      css`
        border: 1px solid ${theme.palette.gray.dark};
        border-radius: ${layoutChildrenRadius}px;
      `
    )}
    ${conditionApply(
      [$layout === 'twoColumn'],
      css`
        height: 50%;
      `
    )}
    ${conditionApply(
      [$layout === 'threeColumn'],
      css`
        height: 33%;
      `
    )}
    ${conditionApply(
      [$layout === 'four'],
      css`
        height: 50%;
        width: 49%;
      `
    )}
    ${theme.breakpoints.down('sm')} {
      background: ${theme.palette.background.default};
    }
  `};
`;

export const Box = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 30%;
    height: 200px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 10px;
    background: ${theme.palette.grey.main};
    cursor: pointer;
  `};
`;
