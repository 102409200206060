import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import * as Styled from './styled';
import { useI18next } from '@trader/services';

export const ChangingMuliBandsMultiplier: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands = store.pages.muliBands;

  const onCallback = store.ui.modal.options.get('callback');

  const proceed = async () => {
    if (onCallback) {
      await onCallback.submit();
      muliBands.clearBands();
      muliBands.toggleHasMultiplierChanged(false);
    }
    store.ui.modal.close();
  };

  return (
    <Styled.Root>
      <Styled.Content>
        {translate('COMMON.LABELS.MULI_BANDS_DISCONNECT_ORDERS')}
      </Styled.Content>
      <Styled.Body>
        <Styled.Cta
          size='medium'
          $isFailed
          onClick={() => store.ui.modal.close()}
        >
          {translate('COMMON.LABELS.CANCEL')}
        </Styled.Cta>
        <Styled.Cta
          loading={muliBands.deleteMuliBandOrdersAsync.inProgress}
          disabled={muliBands.deleteMuliBandOrdersAsync.inProgress}
          size='medium'
          onClick={proceed}
        >
          {translate('COMMON.LABELS.PROCEED')}
        </Styled.Cta>
      </Styled.Body>
    </Styled.Root>
  );
});
