import { useTradingNotifications } from './useTradingNotifications';
import { TradingNotificationsContext } from './createContext';

export { TradingNotificationsContext } from './createContext';

export const TradingNotificationsContextProvider = ({ children }) => {
  const tradingNotificationsContext = useTradingNotifications();

  return (
    <TradingNotificationsContext.Provider value={tradingNotificationsContext}>
      {children}
    </TradingNotificationsContext.Provider>
  );
};
