import { getIconBaseProps } from './utils';

export const marketOpened = {
  ...getIconBaseProps('marketOpened'),
  width: '15px',
  height: '16px',
  viewBox: '0 0 15 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle
        cx='7.5'
        cy='7.58008'
        r='7.5'
        fill='url(#paint0_radial_1383_7364)'
      />
      <circle cx='7.49989' cy='7.57997' r='4.03846' fill='#26A69A' />
      <defs>
        <radialGradient
          id='paint0_radial_1383_7364'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(7.5 7.58008) scale(7.5)'
        >
          <stop offset='0.76' stopColor='#CFEFEC' />
          <stop offset='1' stopColor='#E1F7F5' stopOpacity='0' />
        </radialGradient>
      </defs>
    </>
  ),
};
