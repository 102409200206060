import React from 'react';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Adornment } from './styled';

export interface IPasswordAdornment {
  isPasswordShown: boolean;
  onClickShowPassword: () => void;
}

export const PasswordAdornment: React.FC<IPasswordAdornment> = ({
  isPasswordShown,
  onClickShowPassword,
}) => {
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Adornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={onClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
      >
        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </Adornment>
  );
};
