import { EThemes } from '@trader/themes';

export const getSystemTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return EThemes.Dark;
  }
  return EThemes.Light;
};
