import {
  StudyBooleanInputInfo,
  StudyInputInfo,
  StudyNumericInputInfo,
  StudyTextInputInfo,
} from '../charting_library';

export const takeProfitInputs: StudyInputInfo[] = [
  {
    id: 'takeProfitTrigger',
    name: 'Take-profit trigger',
    type: 'text' as StudyTextInputInfo['type'],
    defval: 'close',
    // isHidden: true,

    options: ['close', 'high/low', 'high/low median', 'open/close median'],
  },
  {
    id: 'useTpOrdersOne',
    name: 'Use TP orders',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: false,
    // isHidden: true,
  },
  {
    id: 'useTpOrdersTwo',
    name: 'Use TP orders 2',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: false,
    // isHidden: true,
  },
  {
    id: 'useTpOrderThree',
    name: 'Use TP orders 3',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: true,
    // isHidden: true,
  },
  {
    id: 'TpLongQty',
    name: '[TP] ↗️ Long qty %',
    type: 'integer' as StudyNumericInputInfo['type'],
    isHidden: true,
    defval: 5,
    min: 0,
    max: 100,
    step: 2,
  },
  {
    id: 'TpShortQty',
    name: '[TP] ↘️ Short qty %',
    type: 'integer' as StudyNumericInputInfo['type'],
    isHidden: true,
    defval: 5,
    min: 0,
    max: 100,
    step: 2,
  },
];
