import { getIconBaseProps } from './utils';

export const notAllowed = {
  ...getIconBaseProps('notAllowed'),
  width: '52px',
  height: '52px',
  viewBox: '0 0 52 52',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='26' cy='26' r='24.5' stroke='#FF5050' strokeWidth='3' />
      <path
        d='M42.9999 9.00005L9.5 42.5'
        stroke='#FF5050'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
