import React from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { defaultIcon, small } from '@trader/constants';
import { IconButton, Wrapper } from '@trader/components';

import * as Styled from './styled';

interface IModalTitle {
  symbol: string;
}

export const PlaceOrderHeader: React.FC<IModalTitle> = observer(
  ({ symbol }) => {
    const store = useMst();
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    return (
      <Styled.Root>
        <Wrapper alignItems='center'>
          <img
            src={instrument.iconUrl || defaultIcon}
            alt='instrument icon'
            width={small}
            height={small}
            style={{ marginRight: '8px' }}
          />
          <Styled.Title>{symbol}</Styled.Title>
        </Wrapper>
        <Styled.CloseIcon>
          <IconButton
            iconType='remove'
            onClick={() => store.ui.sidebar.closeRightBarOrderSection()}
          />
        </Styled.CloseIcon>
      </Styled.Root>
    );
  }
);
