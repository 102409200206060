import { initializeApp } from 'firebase/app';

export class FirebaseService {
  private readonly _config: Record<string, string>;

  constructor(config: Record<string, string>) {
    this._config = config;
  }

  initialize() {
    initializeApp(this._config);
  }
}

export const firebaseService = new FirebaseService({
  apiKey: import.meta.env.VITE_FCM_API_KEY,
  projectId: import.meta.env.VITE_FCM_PROJECT_ID,
  appId: import.meta.env.VITE_FCM_APP_ID,
  measurementId: import.meta.env.VITE_FCM_MEASUREMENT_ID,
  messagingSenderId: import.meta.env.VITE_FCM_MESSAGING_SENDER_ID,
  authDomain: import.meta.env.VITE_FCM_AUTH_DOMAIN,
  storageBucket: import.meta.env.VITE_FCM_STORAGE_BUCKET,
});
