import React from 'react';
import styled, { css } from 'styled-components';
import { AppBarProps, Backdrop as MuiBackdrop } from '@mui/material';

import { Wrapper } from '../wrapper';
import {
  layoutMargin,
  headerHeight,
  layoutChildrenRadius,
} from '@trader/constants';

interface IAppBar extends AppBarProps {
  children?: React.ReactNode;
}

export const Backdrop = styled(MuiBackdrop)`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal + 1};
  `}
`;

export const AppBar = styled<React.FC<IAppBar>>(({ children, ...rest }) => (
  <Wrapper {...rest} ref={null}>
    {children}
  </Wrapper>
))`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    min-height: ${headerHeight}px;
    margin-bottom: ${layoutMargin}px;

    ${theme.breakpoints.down('sm')} {
      background: transparent;
    }
  `}
`;

export const Toolbar = styled(Wrapper)`
  ${({ theme }) => css`
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${theme.breakpoints.down('sm')} {
      padding: 0 5px;
    }
  `}
`;
