import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;
