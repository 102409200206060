import { getIconBaseProps } from './utils';

export const dailyTargetProfit = {
  ...getIconBaseProps('dailyTargetProfit'),
  width: '17px',
  height: '17px',
  viewBox: '0 0 17 17',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M0.75 5L15.75 5'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9441 0.75V2.47222'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.05591 0.75V2.47222'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 3.33331C0.75 2.38215 1.52107 1.61108 2.47222 1.61108H14.5278C15.4789 1.61108 16.25 2.38215 16.25 3.33331V14.5278C16.25 15.4789 15.4789 16.25 14.5278 16.25H2.47222C1.52107 16.25 0.75 15.4789 0.75 14.5278V3.33331Z'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7499 8L8.59611 11.7838L6.86536 10.2072L3.75 13.0451'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7505 11.0271V8H9.37549'
        stroke='#848484'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
