import styled, { css } from 'styled-components';

import { Button, Typography, Wrapper } from '@trader/components';

interface IItem {
  $isActive: boolean;
}

export const Item = styled(Button)<IItem>`
  ${({ theme, $isActive }) => css`
    border: 1px solid
      ${$isActive ? theme.palette.green[400] : theme.palette.gray.main};
    background: ${$isActive ? theme.palette.green[300] : 'transparent'};
    margin-bottom: 10px;
    border-radius: 8px;
    height: 45px;
    width: 100%;
    justify-content: space-between;

    color: ${$isActive
      ? theme.palette.primary.main
      : theme.palette.common.black};

    svg {
      color: ${theme.palette.primary.main};
    }

    & > span {
      width: 100%;
    }
  `}
`;

export const Content = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    font-size: 10px;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  `}
`;

export const ChallengeBody = styled(Wrapper)`
  ${({ theme }) => css`
    justify-content: space-between;
    font-size: 12px;
    align-items: center;
    color: ${theme.palette.common.black};

    > div {
      display: flex;
      align-items: center;
      height: 15px;

      > p {
        margin-left: 5px;
        height: 15px;
        padding: 0 6px;
        font-size: 10px;
        border-radius: 2px;
      }

      > svg {
        color: ${theme.palette.common.black};
        width: 15px;
        height: 15px;
      }
    }
  `}
`;
