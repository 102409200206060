import { getIconBaseProps } from './utils';

export const pipsTradeLogo = {
  ...getIconBaseProps('pipsTradeLogo'),
  width: '139px',
  height: '37px',
  viewBox: '0 0 139 37',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M29.1111 5.59624C28.7432 4.11272 27.9038 2.86794 26.641 1.9475C25.8152 1.23126 24.8827 0.756776 23.8782 0.450462C23.8707 0.441453 23.7971 0.417428 23.686 0.385896C22.8016 0.126129 21.8857 0 20.9637 0H5.84471C5.81318 0.0435446 5.76213 0.0855877 5.75312 0.133637C5.45731 1.87843 0.796536 28.1794 0.0577787 32.3612C0.0352557 32.4933 -0.0773598 32.663 0.0908126 32.7546C0.228954 32.8312 0.377607 32.7336 0.496228 32.651C1.3506 32.0564 2.21399 31.4738 2.98578 30.771C3.74406 30.2005 4.44828 29.5698 5.08794 28.8686C5.69306 28.331 6.25914 27.756 6.73212 27.0968C7.5895 26.1568 8.34327 25.1433 8.92587 24.0081C9.8328 22.6042 10.4064 21.0636 10.8088 19.4524C11.2292 18.4614 11.4379 17.4103 11.6752 16.3683C11.9019 15.3728 12.0536 14.3682 11.98 13.3412C11.9485 12.8952 11.8253 12.6024 11.2593 12.6385C10.8463 12.664 8.9499 12.649 8.74418 12.6475C8.59403 12.646 8.32826 12.7391 8.41985 12.3997C8.55649 12.2871 13.5371 5.72687 14.1002 4.94757C14.2744 4.72985 14.405 4.71934 14.5867 4.9746C14.902 5.41755 19.821 11.8096 20.3976 12.5874C19.4141 12.2511 18.5177 12.1024 17.5973 12.3817C16.809 12.5168 16.1483 12.8787 15.6212 13.4808C13.8449 14.9794 13.8915 17.9059 15.7203 19.6311C16.6318 20.4645 17.6934 20.8534 18.9336 20.7137C20.1694 20.493 21.3691 20.1702 22.4517 19.5035C24.1139 18.7602 25.5239 17.6941 26.6425 16.2557C26.7101 16.1956 26.7882 16.143 26.8422 16.0725C29.2492 12.9358 30.066 9.43868 29.1141 5.59624H29.1111Z'
        fill='url(#paint0_linear_93_3922)'
      />
      <path
        d='M133.956 21.0197C132.545 21.0197 131.388 20.6968 130.487 20.051C129.601 19.3901 129.105 18.4964 129 17.37H130.622C130.697 18.0609 131.02 18.6241 131.591 19.0597C132.177 19.4802 132.958 19.6905 133.934 19.6905C134.79 19.6905 135.458 19.4877 135.939 19.0822C136.435 18.6767 136.683 18.1735 136.683 17.5727C136.683 17.1522 136.547 16.8067 136.277 16.5364C136.007 16.266 135.661 16.0557 135.241 15.9055C134.835 15.7403 134.279 15.5676 133.573 15.3874C132.657 15.147 131.914 14.9067 131.343 14.6664C130.772 14.4261 130.284 14.0731 129.879 13.6075C129.488 13.1269 129.293 12.4886 129.293 11.6925C129.293 11.0917 129.473 10.536 129.834 10.0253C130.194 9.51467 130.705 9.10914 131.366 8.80874C132.026 8.50835 132.777 8.35815 133.619 8.35815C134.94 8.35815 136.007 8.6961 136.818 9.37198C137.629 10.0328 138.064 10.949 138.124 12.1206H136.547C136.502 11.3996 136.217 10.8214 135.691 10.3858C135.181 9.93522 134.475 9.70993 133.573 9.70993C132.777 9.70993 132.132 9.89767 131.636 10.2732C131.14 10.6487 130.892 11.1143 130.892 11.67C130.892 12.1506 131.035 12.5486 131.321 12.8641C131.621 13.1645 131.989 13.4048 132.424 13.585C132.86 13.7502 133.446 13.938 134.182 14.1482C135.068 14.3886 135.774 14.6214 136.3 14.8467C136.825 15.072 137.276 15.4024 137.651 15.838C138.027 16.2735 138.222 16.8518 138.237 17.5727C138.237 18.2336 138.057 18.8269 137.696 19.3526C137.336 19.8632 136.833 20.2688 136.187 20.5692C135.541 20.8695 134.798 21.0197 133.956 21.0197Z'
        fill='currentColor'
      />
      <path
        d='M126.358 14.0581C126.358 14.5988 126.343 15.0119 126.313 15.2972H116.107C116.152 16.2285 116.377 17.0245 116.783 17.6854C117.188 18.3462 117.722 18.8494 118.382 19.1949C119.043 19.5253 119.764 19.6905 120.545 19.6905C121.567 19.6905 122.423 19.4427 123.114 18.947C123.82 18.4514 124.285 17.783 124.51 16.9419H126.178C125.877 18.1435 125.231 19.1273 124.24 19.8933C123.264 20.6443 122.032 21.0197 120.545 21.0197C119.389 21.0197 118.352 20.7644 117.436 20.2537C116.52 19.7281 115.799 18.9921 115.273 18.0458C114.763 17.0846 114.507 15.9656 114.507 14.6889C114.507 13.4123 114.763 12.2933 115.273 11.3321C115.784 10.3708 116.497 9.63483 117.414 9.12416C118.33 8.61349 119.374 8.35815 120.545 8.35815C121.717 8.35815 122.738 8.61349 123.609 9.12416C124.495 9.63483 125.171 10.3257 125.637 11.1969C126.118 12.053 126.358 13.0067 126.358 14.0581ZM124.758 14.0131C124.773 13.0969 124.586 12.3158 124.195 11.67C123.82 11.0241 123.301 10.536 122.64 10.2056C121.98 9.87514 121.259 9.70993 120.478 9.70993C119.306 9.70993 118.307 10.0854 117.481 10.8364C116.655 11.5874 116.197 12.6463 116.107 14.0131H124.758Z'
        fill='currentColor'
      />
      <path
        d='M99.304 14.6665C99.304 13.4048 99.5593 12.3009 100.07 11.3546C100.581 10.3934 101.279 9.65742 102.165 9.14675C103.066 8.62106 104.08 8.35821 105.207 8.35821C106.288 8.35821 107.264 8.62106 108.136 9.14675C109.007 9.67244 109.645 10.3558 110.051 11.1969V4.16772H111.628V20.8396H110.051V18.091C109.675 18.9471 109.059 19.653 108.203 20.2087C107.347 20.7495 106.341 21.0198 105.184 21.0198C104.058 21.0198 103.044 20.757 102.143 20.2313C101.257 19.7056 100.558 18.9621 100.047 18.0009C99.5518 17.0396 99.304 15.9281 99.304 14.6665ZM110.051 14.689C110.051 13.6977 109.848 12.8266 109.442 12.0756C109.037 11.3246 108.481 10.7463 107.775 10.3408C107.084 9.93528 106.318 9.73252 105.477 9.73252C104.606 9.73252 103.825 9.92777 103.134 10.3183C102.443 10.7088 101.895 11.2795 101.489 12.0305C101.099 12.7665 100.904 13.6451 100.904 14.6665C100.904 15.6728 101.099 16.559 101.489 17.325C101.895 18.0759 102.443 18.6542 103.134 19.0597C103.825 19.4502 104.606 19.6455 105.477 19.6455C106.318 19.6455 107.084 19.4427 107.775 19.0372C108.481 18.6317 109.037 18.0534 109.442 17.3024C109.848 16.5515 110.051 15.6803 110.051 14.689Z'
        fill='currentColor'
      />
      <path
        d='M84.1011 14.6664C84.1011 13.4048 84.3489 12.3008 84.8445 11.3546C85.3552 10.3933 86.0536 9.65736 86.9398 9.14669C87.841 8.621 88.8548 8.35815 89.9813 8.35815C91.1528 8.35815 92.1591 8.62851 93.0002 9.16922C93.8564 9.70993 94.4722 10.4008 94.8477 11.2419V8.53839H96.4247V20.8395H94.8477V18.1134C94.4571 18.9545 93.8338 19.653 92.9777 20.2087C92.1366 20.7494 91.1303 21.0197 89.9587 21.0197C88.8473 21.0197 87.841 20.7569 86.9398 20.2312C86.0536 19.7055 85.3552 18.962 84.8445 18.0008C84.3489 17.0395 84.1011 15.9281 84.1011 14.6664ZM94.8477 14.6889C94.8477 13.6977 94.6449 12.8265 94.2394 12.0755C93.8338 11.3245 93.2781 10.7463 92.5722 10.3408C91.8813 9.93522 91.1153 9.73245 90.2742 9.73245C89.403 9.73245 88.622 9.92771 87.9311 10.3182C87.2402 10.7087 86.692 11.2795 86.2864 12.0305C85.8959 12.7664 85.7007 13.6451 85.7007 14.6664C85.7007 15.6727 85.8959 16.5589 86.2864 17.3249C86.692 18.0759 87.2402 18.6541 87.9311 19.0597C88.622 19.4502 89.403 19.6454 90.2742 19.6454C91.1153 19.6454 91.8813 19.4427 92.5722 19.0371C93.2781 18.6316 93.8338 18.0534 94.2394 17.3024C94.6449 16.5514 94.8477 15.6802 94.8477 14.6889Z'
        fill='currentColor'
      />
      <path
        d='M78.2365 10.7241C78.5819 9.95813 79.1076 9.36486 79.8136 8.9443C80.5345 8.52375 81.4132 8.31348 82.4495 8.31348V9.95813H82.0215C80.88 9.95813 79.9638 10.266 79.2728 10.8818C78.5819 11.4976 78.2365 12.5265 78.2365 13.9684V20.8399H76.6594V8.53877H78.2365V10.7241Z'
        fill='currentColor'
      />
      <path
        d='M74.1692 5.09167V6.39839H69.7984V20.8398H68.2214V6.39839H63.8281V5.09167H74.1692Z'
        fill='currentColor'
      />
      <path
        d='M57.7053 21.0197C56.2934 21.0197 55.1369 20.6968 54.2357 20.051C53.3496 19.3901 52.8539 18.4964 52.7488 17.37H54.3709C54.446 18.0609 54.7689 18.6241 55.3397 19.0597C55.9254 19.4802 56.7065 19.6905 57.6827 19.6905C58.5389 19.6905 59.2072 19.4877 59.6879 19.0822C60.1835 18.6767 60.4313 18.1735 60.4313 17.5727C60.4313 17.1522 60.2962 16.8067 60.0258 16.5364C59.7555 16.266 59.41 16.0557 58.9895 15.9055C58.5839 15.7403 58.0282 15.5676 57.3223 15.3874C56.4061 15.147 55.6626 14.9067 55.0918 14.6664C54.5211 14.4261 54.033 14.0731 53.6274 13.6075C53.2369 13.1269 53.0417 12.4886 53.0417 11.6925C53.0417 11.0917 53.2219 10.536 53.5824 10.0253C53.9428 9.51467 54.4535 9.10914 55.1144 8.80874C55.7752 8.50835 56.5262 8.35815 57.3673 8.35815C58.6891 8.35815 59.7555 8.6961 60.5665 9.37198C61.3776 10.0328 61.8132 10.949 61.8732 12.1206H60.2962C60.2511 11.3996 59.9657 10.8214 59.44 10.3858C58.9294 9.93522 58.2235 9.70993 57.3223 9.70993C56.5262 9.70993 55.8804 9.89767 55.3847 10.2732C54.8891 10.6487 54.6413 11.1143 54.6413 11.67C54.6413 12.1506 54.7839 12.5486 55.0693 12.8641C55.3697 13.1645 55.7377 13.4048 56.1733 13.585C56.6088 13.7502 57.1946 13.938 57.9306 14.1482C58.8167 14.3886 59.5227 14.6214 60.0483 14.8467C60.574 15.072 61.0246 15.4024 61.4001 15.838C61.7756 16.2735 61.9709 16.8518 61.9859 17.5727C61.9859 18.2336 61.8056 18.8269 61.4452 19.3526C61.0847 19.8632 60.5815 20.2688 59.9357 20.5692C59.2898 20.8695 58.5464 21.0197 57.7053 21.0197Z'
        fill='currentColor'
      />
      <path
        d='M39.8214 11.2419C40.2119 10.4158 40.8277 9.73245 41.6688 9.19175C42.5249 8.63602 43.5388 8.35815 44.7103 8.35815C45.8217 8.35815 46.8206 8.621 47.7067 9.14669C48.6079 9.65736 49.3063 10.3933 49.802 11.3546C50.3126 12.3008 50.568 13.4048 50.568 14.6664C50.568 15.9281 50.3126 17.0395 49.802 18.0008C49.3063 18.962 48.6079 19.7055 47.7067 20.2312C46.8206 20.7569 45.8217 21.0197 44.7103 21.0197C43.5538 21.0197 42.5475 20.7494 41.6913 20.2087C40.8352 19.653 40.2119 18.962 39.8214 18.136V26.6521H38.2668V8.53839H39.8214V11.2419ZM48.9684 14.6664C48.9684 13.6451 48.7656 12.7664 48.3601 12.0305C47.9696 11.2795 47.4289 10.7087 46.7379 10.3182C46.047 9.92771 45.266 9.73245 44.3949 9.73245C43.5538 9.73245 42.7803 9.93522 42.0743 10.3408C41.3834 10.7463 40.8352 11.3245 40.4297 12.0755C40.0241 12.8265 39.8214 13.6976 39.8214 14.6889C39.8214 15.6802 40.0241 16.5514 40.4297 17.3024C40.8352 18.0534 41.3834 18.6316 42.0743 19.0371C42.7803 19.4427 43.5538 19.6454 44.3949 19.6454C45.266 19.6454 46.047 19.4502 46.7379 19.0597C47.4289 18.6541 47.9696 18.0759 48.3601 17.3249C48.7656 16.5589 48.9684 15.6727 48.9684 14.6664Z'
        fill='currentColor'
      />
      <path
        d='M33.9071 6.218C33.5916 6.218 33.3213 6.10535 33.096 5.88006C32.8707 5.65476 32.7581 5.3769 32.7581 5.04647C32.7581 4.71603 32.8707 4.44568 33.096 4.2354C33.3213 4.01011 33.5916 3.89746 33.9071 3.89746C34.2225 3.89746 34.4928 4.01011 34.7181 4.2354C34.9434 4.44568 35.0561 4.71603 35.0561 5.04647C35.0561 5.3769 34.9434 5.65476 34.7181 5.88006C34.4928 6.10535 34.2225 6.218 33.9071 6.218ZM34.6956 8.53854V20.8397H33.1185V8.53854H34.6956Z'
        fill='currentColor'
      />
      <path
        d='M137.84 27.625V34.3234H136.496V29.9665L134.701 34.3234H133.684L131.88 29.9665V34.3234H130.537V27.625H132.062L134.193 32.6056L136.323 27.625H137.84Z'
        fill='currentColor'
      />
      <path
        d='M126.111 34.3904C125.484 34.3904 124.908 34.2433 124.384 33.949C123.859 33.6547 123.443 33.2484 123.136 32.7302C122.829 32.2056 122.676 31.6138 122.676 30.9549C122.676 30.3023 122.829 29.7169 123.136 29.1987C123.443 28.6741 123.859 28.2647 124.384 27.9704C124.908 27.6761 125.484 27.5289 126.111 27.5289C126.744 27.5289 127.32 27.6761 127.838 27.9704C128.363 28.2647 128.776 28.6741 129.076 29.1987C129.383 29.7169 129.537 30.3023 129.537 30.9549C129.537 31.6138 129.383 32.2056 129.076 32.7302C128.776 33.2484 128.363 33.6547 127.838 33.949C127.314 34.2433 126.738 34.3904 126.111 34.3904ZM126.111 33.1909C126.514 33.1909 126.869 33.1013 127.176 32.9222C127.483 32.7366 127.723 32.4743 127.896 32.1352C128.069 31.7962 128.155 31.4027 128.155 30.9549C128.155 30.507 128.069 30.1168 127.896 29.7841C127.723 29.445 127.483 29.1859 127.176 29.0068C126.869 28.8277 126.514 28.7381 126.111 28.7381C125.708 28.7381 125.35 28.8277 125.036 29.0068C124.729 29.1859 124.489 29.445 124.317 29.7841C124.144 30.1168 124.057 30.507 124.057 30.9549C124.057 31.4027 124.144 31.7962 124.317 32.1352C124.489 32.4743 124.729 32.7366 125.036 32.9222C125.35 33.1013 125.708 33.1909 126.111 33.1909Z'
        fill='currentColor'
      />
      <path
        d='M115.31 30.9645C115.31 30.3056 115.457 29.717 115.751 29.1988C116.052 28.6742 116.458 28.2679 116.97 27.98C117.488 27.6857 118.067 27.5386 118.707 27.5386C119.455 27.5386 120.111 27.7305 120.674 28.1144C121.237 28.4982 121.63 29.0292 121.854 29.7074H120.309C120.156 29.3875 119.938 29.1476 119.657 28.9876C119.382 28.8277 119.062 28.7477 118.697 28.7477C118.307 28.7477 117.958 28.8405 117.651 29.026C117.35 29.2052 117.114 29.4611 116.941 29.7938C116.775 30.1264 116.691 30.5167 116.691 30.9645C116.691 31.406 116.775 31.7962 116.941 32.1353C117.114 32.468 117.35 32.7271 117.651 32.9126C117.958 33.0917 118.307 33.1813 118.697 33.1813C119.062 33.1813 119.382 33.1013 119.657 32.9414C119.938 32.7751 120.156 32.532 120.309 32.2121H121.854C121.63 32.8966 121.237 33.4308 120.674 33.8147C120.117 34.1921 119.462 34.3809 118.707 34.3809C118.067 34.3809 117.488 34.2369 116.97 33.949C116.458 33.6547 116.052 33.2485 115.751 32.7303C115.457 32.2121 115.31 31.6235 115.31 30.9645Z'
        fill='currentColor'
      />
      <path
        d='M113.738 34.3905C113.495 34.3905 113.293 34.3169 113.133 34.1698C112.98 34.0162 112.903 33.8275 112.903 33.6036C112.903 33.3796 112.98 33.1941 113.133 33.047C113.293 32.8934 113.495 32.8167 113.738 32.8167C113.974 32.8167 114.17 32.8934 114.323 33.047C114.477 33.1941 114.553 33.3796 114.553 33.6036C114.553 33.8275 114.477 34.0162 114.323 34.1698C114.17 34.3169 113.974 34.3905 113.738 34.3905Z'
        fill='currentColor'
      />
      <path
        d='M109.379 28.7094V30.3887H111.634V31.454H109.379V33.2293H111.922V34.3233H108.035V27.6154H111.922V28.7094H109.379Z'
        fill='currentColor'
      />
      <path
        d='M103.498 27.625C104.202 27.625 104.819 27.7625 105.35 28.0376C105.888 28.3127 106.3 28.7062 106.588 29.218C106.883 29.7234 107.03 30.312 107.03 30.9838C107.03 31.6555 106.883 32.2441 106.588 32.7495C106.3 33.2485 105.888 33.6356 105.35 33.9107C104.819 34.1858 104.202 34.3234 103.498 34.3234H101.157V27.625H103.498ZM103.45 33.1814C104.154 33.1814 104.698 32.9894 105.082 32.6056C105.466 32.2217 105.657 31.6811 105.657 30.9838C105.657 30.2864 105.466 29.7426 105.082 29.3524C104.698 28.9557 104.154 28.7574 103.45 28.7574H102.5V33.1814H103.45Z'
        fill='currentColor'
      />
      <path
        d='M98.3947 33.047H95.7269L95.2854 34.3233H93.8748L96.2835 27.6154H97.8477L100.256 34.3233H98.8361L98.3947 33.047ZM98.03 31.9722L97.0608 29.17L96.0915 31.9722H98.03Z'
        fill='currentColor'
      />
      <path
        d='M91.5941 34.3234L90.1163 31.7131H89.4829V34.3234H88.1394V27.625H90.6537C91.1719 27.625 91.6133 27.7178 91.978 27.9033C92.3427 28.0824 92.6146 28.3287 92.7937 28.6422C92.9792 28.9493 93.072 29.2948 93.072 29.6787C93.072 30.1201 92.9441 30.5199 92.6881 30.8782C92.4322 31.2301 92.0516 31.4732 91.5462 31.6075L93.1488 34.3234H91.5941ZM89.4829 30.7055H90.6057C90.9704 30.7055 91.2423 30.6191 91.4214 30.4464C91.6005 30.2672 91.6901 30.0209 91.6901 29.7074C91.6901 29.4004 91.6005 29.1636 91.4214 28.9973C91.2423 28.8246 90.9704 28.7382 90.6057 28.7382H89.4829V30.7055Z'
        fill='currentColor'
      />
      <path
        d='M87.1593 27.625V28.7094H85.3743V34.3234H84.0308V28.7094H82.2458V27.625H87.1593Z'
        fill='currentColor'
      />
      <path
        d='M79.5902 29.0068L76.2986 36.8376H74.8687L76.0203 34.1889L73.8899 29.0068H75.3965L76.7688 32.7207L78.1603 29.0068H79.5902Z'
        fill='currentColor'
      />
      <path
        d='M69.3435 29.7842C69.5162 29.5283 69.753 29.3204 70.0536 29.1604C70.3607 29.0005 70.7094 28.9205 71.0997 28.9205C71.5539 28.9205 71.9634 29.0325 72.328 29.2564C72.6991 29.4803 72.9902 29.8002 73.2013 30.216C73.4188 30.6255 73.5276 31.1021 73.5276 31.6459C73.5276 32.1897 73.4188 32.6727 73.2013 33.095C72.9902 33.5108 72.6991 33.8339 72.328 34.0642C71.9634 34.2945 71.5539 34.4097 71.0997 34.4097C70.703 34.4097 70.3543 34.3329 70.0536 34.1794C69.7594 34.0194 69.5226 33.8147 69.3435 33.5652V34.3233H68V27.2219H69.3435V29.7842ZM72.1553 31.6459C72.1553 31.326 72.0881 31.0509 71.9538 30.8206C71.8258 30.5839 71.6531 30.4048 71.4355 30.2832C71.2244 30.1617 70.9941 30.1009 70.7446 30.1009C70.5015 30.1009 70.2712 30.1648 70.0536 30.2928C69.8425 30.4144 69.6698 30.5935 69.5354 30.8302C69.4075 31.0669 69.3435 31.3452 69.3435 31.6651C69.3435 31.985 69.4075 32.2633 69.5354 32.5C69.6698 32.7367 69.8425 32.919 70.0536 33.047C70.2712 33.1686 70.5015 33.2293 70.7446 33.2293C70.9941 33.2293 71.2244 33.1654 71.4355 33.0374C71.6531 32.9094 71.8258 32.7271 71.9538 32.4904C72.0881 32.2537 72.1553 31.9722 72.1553 31.6459Z'
        fill='currentColor'
      />
      <defs>
        <linearGradient
          id='paint0_linear_93_3922'
          x1='1.97133'
          y1='29.232'
          x2='27.9929'
          y2='4.73119'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#26B573' />
          <stop offset='1' stopColor='#46B85A' />
        </linearGradient>
      </defs>
    </>
  ),
};
