import { getIconBaseProps } from './utils';

export const withdraw = {
  ...getIconBaseProps('withdraw'),
  width: '17px',
  height: '17px',
  viewBox: '0 0 19 19',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M15.5714 3.98438C16.9127 3.98438 18 5.02904 18 6.31771V15.651C18 16.9397 16.9127 17.9844 15.5714 17.9844H3.42857C2.08731 17.9844 1 16.9397 1 15.651V6.31771C1 5.02904 2.08731 3.98438 3.42857 3.98438M9.50014 1.01562V11.5708M9.50014 11.5708L5.85714 8.22984M9.50014 11.5708L13.1429 8.22992'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
