import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: 0,
    padding: '0 8px',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#e5e8ec',
    margin: '10px 0',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowMB5: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '5px',
  },
  rowBB1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid black',
    paddingTop: '5px',
    marginBottom: '5px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  column_MT_5: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    width: '100%',
  },
  column_MT_10: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    width: '100%',
  },
  column_MT_20: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
  },
  column_MT_30: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    width: '100%',
  },
  column_MT_50: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    width: '100%',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: '10%',
  },
  colPositions: {
    display: 'flex',
    flexDirection: 'column',
    width: '7%',
  },
  col6_100: {
    display: 'flex',
    flexDirection: 'column',
    width: '6%',
  },
  col8_100: {
    display: 'flex',
    flexDirection: 'column',
    width: '8%',
  },
  col9_100: {
    display: 'flex',
    flexDirection: 'column',
    width: '9%',
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    width: '16.66%',
  },
  col3: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
  },
  col4: {
    display: 'flex',
    flexDirection: 'row',
    width: '33.33%',
  },
  col5: {
    display: 'flex',
    flexDirection: 'row',
    width: '41.66%',
  },
  col6: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
  },
  col9: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
  },
  col11: {
    display: 'flex',
    flexDirection: 'column',
    width: '91.66%',
  },
  svgContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0',
    marginRight: '0',
    width: '20%',
  },
  col12: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  h1: {
    fontFamily: 'Open Sans',
    margin: 0,
    fontSize: 15,
    fontWeight: 'bold',
  },
  h2: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    width: '100%',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  h3: {
    fontFamily: 'Open Sans',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'medium',
    margin: 0,
    fontSize: 11,
    width: '100%',
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '5px',
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  accountStatementSummary: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
    minWidth: '90px',
  },
  value: {
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    marginLeft: '10px',
    fontSize: '8px',
  },
  label50: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
    minWidth: '50px',
  },
  labelRight150: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
    width: '100%',
    maxWidth: '120px',
    textAlign: 'left',
  },
  space: {
    fontFamily: 'Open Sans',
    marginLeft: '10px',
    fontSize: '8px',
    fontWeight: 'bold',
    color: 'white',
  },
  b: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
  },
  bRight: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
    textAlign: 'right',
  },
  b8: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
  },
  tdb: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '8px',
  },
  chartBalanceArea: {
    width: '100%',
    position: 'relative',
  },
  chartBalance: {
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '10px',
    position: 'absolute',
    transform: 'rotate(-90deg)',
    top: '50px',
    left: '-20px',
  },
  chartTransactions: {
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '10px',
    color: 'rgb(1, 120, 250)',
    textAlign: 'center',
    width: '100%',
    marginTop: '-40px',
  },
  span: {
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  spanRight: {
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '8px',
    textAlign: 'right',
  },
  span8: {
    fontSize: '8px',
  },
  span8Italic: {
    fontSize: '8px',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  explanation: {
    fontSize: '7px',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    marginTop: '4px',
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCellHeader: {
    marginBottom: 4,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '8px',
  },
  tableCell: {
    marginBottom: 2,
    fontFamily: 'Open Sans',
    fontSize: '8px',
  },
});
