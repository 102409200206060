import React from 'react';
import { Box as MuiBox, BoxProps } from '@mui/material';

export const Wrapper = React.forwardRef<HTMLDivElement | null, BoxProps>(
  (props, ref) => {
    const { children, display = 'flex', ...rest } = props;
    return (
      <MuiBox ref={ref} display={display} {...rest}>
        {children}
      </MuiBox>
    );
  }
);
