import { useNavigate, NavigateFunction } from 'react-router-dom';

interface IReturnNavigation {
  goBackNavigate: () => void;
  navigateTo: NavigateFunction;
}

const goPageBack = -1;

export function useNavigation(): IReturnNavigation {
  const navigateTo = useNavigate();

  const goBackNavigate = () => {
    navigateTo(goPageBack);
  };

  // const tradingNavigation = (
  //   searchingParams: IUrlTradingParameters,
  //   pathname?: string
  // ): void => {
  //   const parameters = {};
  //
  //   // eslint-disable-next-line
  //   for (const key in tradingParameters) {
  //     const value = searchingParams[key] || searchParams.get(key);
  //     //TODO: temporary solution only for Indices category
  //     if (key === 'tab') {
  //       localStorageService.set(LOCAL_STORAGE_KEYS.category, value);
  //     }
  //     if (value) {
  //       parameters[key] = value;
  //     }
  //   }
  //   navigateTo({
  //     pathname: pathname || location.pathname,
  //     search: createSearchParams(parameters).toString(),
  //   });
  // };

  return {
    goBackNavigate,
    navigateTo,
  };
}
