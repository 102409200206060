import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { localStorageService, useI18next } from '@trader/services';
import { Progress } from '@trader/components';
import { useMst } from '@trader/store';
import { ISsoParams } from '@trader/api';
import { images } from '@trader/assets';
import {
  EAccountTypeNames,
  LOCAL_STORAGE_KEYS,
  NAVIGATE_TO,
} from '@trader/constants';

import * as Styled from './styled';

export const AutoLogin: React.FC = observer(() => {
  const store = useMst();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { translate } = useI18next();
  const title = translate('SIGN_IN.WELCOME_BACK');

  const updateTradingAccount = (cognitoId?: string) => {
    const platformLogin = searchParams.get('platformLogin');
    const isDemo = searchParams.get('isDemo');

    if (cognitoId) {
      const tradingAccountsFromLocalStorage = localStorageService.get(
        LOCAL_STORAGE_KEYS.tradingAccounts
      );
      const tradingAccounts =
        tradingAccountsFromLocalStorage &&
        JSON.parse(tradingAccountsFromLocalStorage);

      const updatedTradingAccount = {
        [cognitoId]: {
          platformLogin,
          accountType:
            isDemo === 'true' ? EAccountTypeNames.Demo : EAccountTypeNames.Live,
        },
      };

      const updatedTradingAccounts = tradingAccounts
        ? {
            ...tradingAccounts,
            ...updatedTradingAccount,
          }
        : updatedTradingAccount;

      if (platformLogin !== null && isDemo !== null) {
        localStorageService.set(
          LOCAL_STORAGE_KEYS.tradingAccounts,
          JSON.stringify(updatedTradingAccounts)
        );
      }
    }
  };

  const ssoHandle = async () => {
    const ssoToken = searchParams.get('ssoToken');

    if (ssoToken) {
      if (store.auth.isAuth) {
        store.auth.logOut();
      }

      const body: ISsoParams = {
        ssoToken: ssoToken,
      };

      try {
        const resp = await store.auth.ssoAsync.run(body);
        updateTradingAccount(resp?.cognitoId);
        if (store.auth.isAuth === true) {
          navigate(NAVIGATE_TO.main.dashboard);
        } else {
          navigate(NAVIGATE_TO.auth.signIn);
        }
      } catch (error) {
        navigate(NAVIGATE_TO.auth.signIn);
      }
    }
  };

  useEffect(() => {
    ssoHandle();
  }, []);

  return (
    <Styled.Root>
      <LazyLoadImage alt='background' src={images.loginBackground} />
      <Styled.Main>
        <Styled.Logo iconType={store.app.getLoginIconBasedOnTheme()} />
        <Styled.Title>{title}</Styled.Title>
        <Progress />
      </Styled.Main>
    </Styled.Root>
  );
});
