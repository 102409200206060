import { getIconBaseProps } from './utils';

export const watchlist = {
  ...getIconBaseProps('watchlist'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M13.4311 7.62663L11.3696 3.23257C11.2241 2.92248 10.7759 2.92248 10.6304 3.23257L8.56889 7.62663C8.5096 7.75301 8.38778 7.84003 8.2472 7.85642L3.35961 8.42616C3.01469 8.46637 2.87616 8.88553 3.13118 9.11739L6.74469 12.4028C6.84863 12.4973 6.89516 12.6381 6.86757 12.7746L5.90836 17.5208C5.84067 17.8557 6.20333 18.1148 6.50642 17.948L10.8012 15.5844C10.9247 15.5165 11.0753 15.5165 11.1988 15.5844L15.4936 17.948C15.7967 18.1148 16.1593 17.8557 16.0916 17.5208L15.1324 12.7746C15.1048 12.6381 15.1514 12.4973 15.2553 12.4028L18.8688 9.11739C19.1238 8.88553 18.9853 8.46637 18.6404 8.42616L13.7528 7.85642C13.6122 7.84003 13.4904 7.75301 13.4311 7.62663Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
