/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IAnyModelType,
  IOptionalIType,
  types,
  cast,
  detach,
} from 'mobx-state-tree';
import { normalize, Schema } from 'normalizr';
import { TMergeStrategyType } from '../collectionModel';

type TCollections = {
  [k: string]: IOptionalIType<IAnyModelType, [undefined]>;
};

export function createEntitiesModel<T extends TCollections>(collections: T) {
  const entityModal = types.model(collections).actions(self => ({
    merge(
      normalizedEntities: { [key: string]: Record<string | number, unknown> },
      mergeStrategy?: TMergeStrategyType,
      shouldClearBeforeMerge?: boolean
    ) {
      const updateStrategy: TMergeStrategyType = mergeStrategy || 'assign';

      Object.entries(normalizedEntities).forEach(([key, normalizedEntity]) => {
        const storeEntity = self[key] as any;
        if (!storeEntity) {
          return;
        }

        shouldClearBeforeMerge && detach(storeEntity.collection);

        Object.entries(normalizedEntity).forEach(([nestedKey, value]) => {
          if (storeEntity.has(nestedKey)) {
            storeEntity.update(nestedKey, value, updateStrategy);
          } else {
            storeEntity.collection.set(String(nestedKey), value);
          }
        });
      });
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    normalizeMerge(data: any, schema: Schema, shouldClearBeforeMerge) {
      if (data !== undefined) {
        const { result, entities } = normalize(data, schema);
        if (!Object.values(entities).length) {
          const key = schema[0].key;
          self.merge({ [key]: {} }, 'assign', shouldClearBeforeMerge);
        } else {
          self.merge(entities, 'assign', shouldClearBeforeMerge);
        }

        return result;
      }
    },
  }));

  return types.optional<typeof entityModal>(
    entityModal,
    cast({})
  ) as IOptionalIType<typeof entityModal, [undefined]>; // cast fix TS, or use as any
}
