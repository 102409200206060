import styled, { css } from 'styled-components';
import { Stack } from '@mui/material';

import { Typography, Wrapper } from '@trader/components';

export const Label = styled(Typography)`
  ${() => css`
    font-size: 10px;
    font-weight: 400;
  `};
`;

export const Value = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 700;
  `};
`;

export const Portfolio = styled('div')`
  ${() => css`
    width: 226px;
    border-radius: 5px;
  `};
`;

export const PortfolioLabel = styled(Typography)`
  ${() => css`
    font-size: 13px;
    font-weight: 400;
  `};
`;

export const PortfolioValue = styled(Typography)`
  ${() => css`
    font-size: 13px;
    font-weight: 500;
  `};
`;

export const PortfolioHeader = styled('div')`
  ${({ theme }) => css`
    padding: 10px 14px;
    border-bottom: 1px solid ${theme.palette.gray.main};
  `};
`;

export const PortfolioTitle = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 700;
  `};
`;

export const PortfolioContent = styled(Stack)`
  ${() => css`
    padding: 12px 14px;
  `};
`;

export const PortfolioItem = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
  `};
`;
