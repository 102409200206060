import { getIconBaseProps } from './utils';

export const positions = {
  ...getIconBaseProps('positions'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M7 12L4 15L1 12'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 4L12 1L15 4'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15L12 1'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 1V15'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
