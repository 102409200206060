import React from 'react';

import { Icon } from '@trader/components';
import { TIconsName } from '@trader/assets';

import * as Styled from './styled';

interface ITableLegend {
  items: { iconType: TIconsName; text: string }[];
}

export const TableLegend: React.FC<ITableLegend> = ({ items }) => {
  return (
    <Styled.Main>
      {items.map(item => (
        <Styled.ItemType key={item.text}>
          <Icon iconType={item.iconType} />
          {item.text}
        </Styled.ItemType>
      ))}
    </Styled.Main>
  );
};
