import { useFormContext } from 'react-hook-form';

import { IInitialTradingFormValues } from '@trader/types';

export const useTriggerTradingFormFields = () => {
  const { trigger } = useFormContext<IInitialTradingFormValues>();

  const triggerAllPriceRelatedFields = () => {
    trigger([
      'takeProfit',
      'takeProfitPips',
      'stopLoss',
      'stopLossPips',
      'price',
      'amount',
    ]);
  };

  const triggerProfitFields = () => {
    trigger(['takeProfit', 'takeProfitPips']);
  };

  const triggerLossFields = () => {
    trigger(['stopLoss', 'stopLossPips']);
  };

  const triggerPriceField = () => {
    trigger(['price']);
  };

  return {
    triggerAllPriceRelatedFields,
    triggerProfitFields,
    triggerLossFields,
    triggerPriceField,
  };
};
