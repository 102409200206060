import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { IOption } from '@trader/types';
import { DropdownMenu } from '../dropdownMenu';
import { useMst } from '@trader/store';
import { Tooltip } from '../tooltip';

interface IChartTiming {
  options: Array<IOption>;
  onChange: (option: IOption) => void;
  isDisplay: boolean;
  selectedOption: IOption;
  layoutType: string;
}

export const ChartTiming: React.FC<IChartTiming> = observer(
  ({ options, onChange, isDisplay, selectedOption, layoutType }) => {
    const store = useMst();

    const isAdvancedChart = store.app.chartType === 'advanced';

    if (!isDisplay || isAdvancedChart) {
      return null;
    }

    return (
      <React.Fragment>
        {layoutType === 'single' && (
          <Styled.Root className='chart-timing'>
            {options.map(option => (
              <Tooltip title={option.id} key={option.value}>
                <Styled.Item
                  $isSelected={option.value === selectedOption.value}
                  onClick={() => onChange(option)}
                  key={option.value}
                >
                  {option.title}
                </Styled.Item>
              </Tooltip>
            ))}
          </Styled.Root>
        )}
        <Styled.Dropdown
          className='chart-timing-dropdown'
          $isSingle={layoutType === 'single'}
        >
          <DropdownMenu
            buttonTitle='Period'
            itemType='text'
            items={options}
            onChange={onChange}
            selectedOption={selectedOption}
            isChartLayout={false}
          />
        </Styled.Dropdown>
      </React.Fragment>
    );
  }
);
