import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { Switcher } from '@trader/components';
import { useI18next } from '@trader/services';
import { betaMaxSideBarWidth, sideBarWidth } from '@trader/constants';

export const BetaDesignSwitcher = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const handleToggleDesignType = () => {
    const isSwitchingToStableDesign = store.app.isBetaDesignEnabled();
    if (isSwitchingToStableDesign) {
      store.ui.sidebar.updateLeftBarWidth(sideBarWidth);
    } else {
      store.ui.sidebar.updateLeftBarWidth(betaMaxSideBarWidth);
    }
    store.app.toggleDesignType();
  };

  return (
    <Switcher
      checked={store.app.isBetaDesignEnabled()}
      onChange={handleToggleDesignType}
      leftLabel={translate('COMMON.LABELS.BETA')}
    />
  );
});
