import React, { forwardRef } from 'react';

import { EOrderSide, IPositionMessage } from '@trader/types';
import { useI18next } from '@trader/services';
import { getNetPl } from '@trader/utils';

import * as Styled from '../styled';
import { INotificationProps } from '../types';

export const PositionDeleted: React.FC<INotificationProps<IPositionMessage>> =
  forwardRef<HTMLDivElement, INotificationProps<IPositionMessage>>(
    ({ closeSnackbar, message }, ref) => {
      const { translate } = useI18next();
      const pl = getNetPl(message?.profit, '');

      const side = EOrderSide[message?.side];
      const translatedSide = side
        ? translate(`COMMON.LABELS.${side.toUpperCase()}`)
        : side;

      return (
        <Styled.NotificationRoot ref={ref}>
          <Styled.Close onClick={closeSnackbar} iconType='remove' />
          <Styled.SuccessIcon iconType='challengeCompleted' />
          <Styled.Content>
            <Styled.Title>
              {translate('NOTIFICATIONS.POSITION_CLOSED')}
            </Styled.Title>
            <Styled.Text>
              {translatedSide} {message?.volume} {message?.symbol} at{' '}
              {message?.openPrice}
            </Styled.Text>
            <Styled.Text $plColor={pl.textColor}>
              {translate('COMMON.LABELS.PROFIT')}: <span>{pl.stringValue}</span>
            </Styled.Text>
          </Styled.Content>
        </Styled.NotificationRoot>
      );
    }
  );
