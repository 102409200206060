import { Instance, types } from 'mobx-state-tree';
import { EDateFilterTypes } from '@trader/types';

const initialState = {
  positionsHistory: {
    date: {
      completedFrom: '',
      completedTo: '',
      openedFrom: '',
      openedTo: '',
      filterType: null,
    },
  },
};

const dateFiltersModel = types.model({
  completedFrom: types.optional(types.string, ''),
  completedTo: types.optional(types.string, ''),
  openedFrom: types.optional(types.string, ''),
  openedTo: types.optional(types.string, ''),
  filterType: types.maybeNull(
    types.union(
      types.literal(EDateFilterTypes.THIS_MONTH),
      types.literal(EDateFilterTypes.PAST_MONTH),
      types.literal(EDateFilterTypes.THIS_QUARTER),
      types.literal(EDateFilterTypes.PAST_QUARTER),
      types.literal(EDateFilterTypes.THIS_YEAR),
      types.literal(EDateFilterTypes.PAST_YEAR),
      types.literal(EDateFilterTypes.CUSTOM)
    )
  ),
});

const filtersModel = types
  .model({
    positionsHistory: types.model('positionsHistoryFiltersModel', {
      date: dateFiltersModel,
    }),
  })
  .actions(self => ({
    resetDateFilters(filterName: TFilterName) {
      self[filterName] = initialState[filterName];
    },
    setDateFilters(
      filterName: TFilterName,
      filters: Partial<
        Record<
          'completedFrom' | 'completedTo' | 'openedFrom' | 'openedTo',
          string
        >
      >,
      filterType: EDateFilterTypes | null
    ) {
      self[filterName].date = {
        ...initialState[filterName].date,
        ...filters,
        filterType,
      };
    },
  }));

export const filters = types.optional(filtersModel, initialState);
export type TDateFilters = keyof Instance<typeof dateFiltersModel>;
export type TFilterName = keyof typeof initialState;
