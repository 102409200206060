import styled, { css } from 'styled-components';

interface IColorValue {
  $value: number;
}

export const Value = styled('span')<IColorValue>`
  ${({ theme, $value }) => css`
    color: ${$value >= 0
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
  `};
`;
