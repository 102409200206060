import React from 'react';
import { useI18next } from '@trader/services';
import * as Styled from './styled';
import { observer } from 'mobx-react-lite';

export const Statistics: React.FC = observer(() => {
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <Styled.Title>{translate('COMMON.LABELS.STATISTICS')}</Styled.Title>
    </Styled.Root>
  );
});
