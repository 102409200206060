import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';

interface IValue {
  $isPositive?: boolean;
  $isNegative?: boolean;
  $isProgress?: boolean;
  $hasBg?: boolean;
}

export const Value = styled(Typography)<IValue>`
  ${({ $isPositive, $isNegative, $isProgress, $hasBg, theme }) => css`
    font-size: 12px;
    font-weight: 600;
    flex-direction: column;
    text-transform: capitalize;
    max-width: 70px;
    color: ${theme.palette.text.primary};

    ${conditionApply(
      [$isPositive],
      css`
        color: ${theme.palette.common.white};
        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.purchaseButtons.green};
            padding: 1px 6px;
            border-radius: 5px;
          `
        )}
      `
    )}

    ${conditionApply(
      [$isNegative],
      css`
        color: ${theme.palette.common.white};
        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.purchaseButtons.red};
            padding: 1px 6px;
            border-radius: 2px;
          `
        )}
      `
    )}

    ${conditionApply(
      [$isProgress],
      css`
        color: ${theme.palette.common.white};
        ${conditionApply(
          [$hasBg],
          css`
            background: ${theme.palette.yellow.main};
            padding: 1px 6px;
            border-radius: 2px;
          `
        )}
      `
    )}
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
    text-transform: capitalize;
  `}
`;
