import React from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';
import { formatByPipSize } from '@trader/utils';
import { useI18next } from '@trader/services';
import { TPlaceOrderSide } from '@trader/types';

import { IActionButtons } from '../index';
import * as Styled from './styled';

export const StableDesign: React.FC<IActionButtons> = observer(
  ({
    layout,
    actionsStyles,
    actionButtonStyles,
    buttonTitleStyles,
    buttonPriceStyles,
    buttonVariant = 'contained',
    ...rest
  }) => {
    const store = useMst();
    const { translate } = useI18next();

    const instrument = store.entities.instruments.get<TInstrumentEntity>(
      rest.symbol
    );

    const layoutType: string = layout || store.pages.trading.layout.layoutType;
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

    const handleClick = (side: TPlaceOrderSide) => () => {
      store.ui.modal.open(MODAL_TYPES.placeOrder, {
        side,
        symbol: rest.symbol,
      });
    };

    return (
      <Styled.Actions
        className='action_buttons'
        $layout={layoutType}
        style={actionsStyles}
      >
        <Styled.ActionButton
          $isAsk
          $isInvestmentAccProduct={isInvestmentAccProduct}
          variant={buttonVariant}
          disabled={isInvestmentAccProduct}
          $layout={layoutType}
          style={actionButtonStyles}
          hasChildrenWrapper={false}
          onClick={handleClick('Sell')}
        >
          <Styled.ButtonText>
            {layoutType !== 'threeRow' && (
              <Styled.ButtonTitle style={buttonTitleStyles} $isAsk>
                {translate('COMMON.LABELS.SELL')}
              </Styled.ButtonTitle>
            )}
            <Styled.ButtonPrice
              style={buttonPriceStyles}
              $layout={layoutType}
              $isAsk
            >
              {formatByPipSize(instrument?.ask || rest.ask, rest.pipSize)}
            </Styled.ButtonPrice>
          </Styled.ButtonText>
        </Styled.ActionButton>
        <Styled.ActionButton
          style={actionButtonStyles}
          variant={buttonVariant}
          $isInvestmentAccProduct={isInvestmentAccProduct}
          $layout={layoutType}
          hasChildrenWrapper={false}
          onClick={handleClick('Buy')}
        >
          <Styled.ButtonText>
            {layoutType !== 'threeRow' && (
              <Styled.ButtonTitle style={buttonTitleStyles}>
                {translate('COMMON.LABELS.BUY')}
              </Styled.ButtonTitle>
            )}
            <Styled.ButtonPrice style={buttonPriceStyles} $layout={layoutType}>
              {formatByPipSize(instrument?.bid || rest.bid, rest.pipSize)}
            </Styled.ButtonPrice>
          </Styled.ButtonText>
        </Styled.ActionButton>
      </Styled.Actions>
    );
  }
);
