import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { EChartLayouts, ETradingView } from '@trader/types';
import { useMst, TInstrumentEntity } from '@trader/store';
import { TradingCentralInfo } from '@trader/components';
import { useI18next } from '@trader/services';
import { api, IIndicators } from '@trader/api';

import { CandleChart } from '../../../candleChart';
import { AdvanceChart } from '../../../advanceChart';

import * as Styled from './styled';

export const Chart: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const [indicatorsInfo, setIndicatorsInfo] = useState<Array<string> | string>(
    ''
  );

  const isAdvancedChart = store.app.chartType === ETradingView.Advanced;

  const getIndicatorsInfo = useCallback(
    (indicators: IIndicators | undefined) => {
      if (indicators) {
        const indicator = indicators?.feeds[indicators?.feeds?.length - 1];
        if (!indicators.feeds.length) {
          setIndicatorsInfo(
            translate('COMMON.LABELS.INSTRUMENT_NOT_COVERED_TRADING_CENTRAL')
          );
        } else if (indicator) {
          const title = indicator.title;
          const pivot = indicator.paragraphs[0];
          const preference = indicator.paragraphs[1];
          const scenario = indicator.paragraphs[2];
          const comment = indicator.paragraphs[3];
          const date = new Date(indicator.time).toUTCString();

          const arrayParagraphs = [
            title,
            pivot,
            preference,
            scenario,
            comment,
            date,
          ];

          setIndicatorsInfo(arrayParagraphs);
        } else {
          setIndicatorsInfo('');
        }
      }
    },
    [symbol]
  );

  useEffect(() => {
    symbol &&
      (async function () {
        const response = await api.Historical.getIndicators(symbol);
        getIndicatorsInfo(response);
      })();

    return () => setIndicatorsInfo('');
  }, [symbol]);

  if (!instrument) {
    return <Styled.Child />;
  }

  return (
    <Styled.Box>
      <Styled.Child>
        {isAdvancedChart ? (
          <AdvanceChart
            layoutNumber={EChartLayouts.FirstLayout}
            instrumentSymbol={symbol}
            onIndicators={getIndicatorsInfo}
          />
        ) : (
          <CandleChart
            layoutNumber={EChartLayouts.FirstLayout}
            symbol={symbol}
            pipSize={instrument.pipSize}
            onIndicators={getIndicatorsInfo}
          />
        )}
      </Styled.Child>
      {!store.app.isBetaDesignEnabled() && (
        <TradingCentralInfo indicatorsInfo={indicatorsInfo} />
      )}
    </Styled.Box>
  );
});
