import { ReactNode } from 'react';
import { Stack as MuiStack, useMediaQuery } from '@mui/material';
import styled, { css, useTheme } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    padding: 0 27px;
  `}
`;

export const Stack = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiStack
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? '40px' : '10px'}
    >
      {children}
    </MuiStack>
  );
};
