import { getIconBaseProps } from './utils';

export const myAccount = {
  ...getIconBaseProps('myAccount'),
  width: '21px',
  height: '20px',
  viewBox: '0 0 21 20',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5.49979 17.3674C5.49979 15.7106 7.73836 14.3674 10.4998 14.3674C13.2612 14.3674 15.4998 15.7106 15.4998 17.3674'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5002 11.3674C12.157 11.3674 13.5002 10.0243 13.5002 8.36743C13.5002 6.71058 12.157 5.36743 10.5002 5.36743C8.8433 5.36743 7.50015 6.71058 7.50015 8.36743C7.50015 10.0243 8.8433 11.3674 10.5002 11.3674Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 19.3677C15.4706 19.3677 19.5 15.3382 19.5 10.3677C19.5 5.39711 15.4706 1.36768 10.5 1.36768C5.52944 1.36768 1.5 5.39711 1.5 10.3677C1.5 15.3382 5.52944 19.3677 10.5 19.3677Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
