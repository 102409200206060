import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { BetaDesign } from './betaDesign';
import { StableDesign } from './stableDesign';

export interface IAssetDetails {
  selectedTab?: string;
  navigationKey?: string;
}

export const AssetDetails: React.FC<IAssetDetails> = observer(props => {
  const store = useMst();

  if (store.app.isBetaDesignEnabled()) {
    return <BetaDesign {...props} />;
  }

  return <StableDesign {...props} />;
});
