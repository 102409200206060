// https://www.tradingview.com/charting-library-docs/latest/connecting_data/Trading-Sessions/
import { ISession } from '@trader/api';
import { addZeroToDate } from '@trader/utils';

const lastDayInAWeek = 7;

const replace = (value: string) => value.replace(':', '');

export const getTradingSession = (sessions: ISession[]) => {
  return sessions?.reduce((acc: string, curr: ISession, index: number) => {
    const day = index + 1;
    const isLastDay = day === lastDayInAWeek;
    const hasNoSession = !curr.quotesHours.length;

    const time = hasNoSession
      ? '0000-0000' // add default time to avoid log errors
      : curr.quotesHours.reduce(
          (timeAcc, hour) =>
            `${timeAcc}${timeAcc ? ',' : ''}${replace(hour.from)}-${replace(
              hour.to
            )}`,
          ''
        );

    return `${acc}${time}:${day}${isLastDay ? '' : '|'}`;
  }, '');
};

export const getTradingHoliday = (sessions: ISession[]) => {
  const sessionIndex = new Date().getUTCDay();
  const currentTradingSession = sessions[sessionIndex].quotesHours;

  if (!currentTradingSession.length) {
    const year = new Date().getUTCFullYear();
    const month = addZeroToDate(new Date().getUTCMonth() + 1);
    const day = addZeroToDate(new Date().getUTCDate());
    return `${year}${month}${day}`;
  }
  return '';
};
