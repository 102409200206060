import { requestService, TAsyncActionOptions } from '@trader/services';
import { alertRoutes } from './routes';
import { ICreateAlertBody, IAlertFE } from './models';
import { IItemsBE } from '@trader/types';

export class Alert {
  routes = alertRoutes;

  async createAlert(
    body: ICreateAlertBody,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.post<void>(this.routes.alert, body, {
      ...options.cancelToken,
    });
    return resp?.data;
  }

  async editAlert(
    body: ICreateAlertBody,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.put<void>(this.routes.alert, body, {
      ...options.cancelToken,
    });
    return resp?.data;
  }

  async getAlert(
    alertId: number,
    options: TAsyncActionOptions
  ): Promise<IAlertFE> {
    const resp = await requestService.get<IAlertFE>(
      this.routes.getAlert(alertId),
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getAlerts(options: TAsyncActionOptions): Promise<IItemsBE<IAlertFE>> {
    const resp = await requestService.get<IItemsBE<IAlertFE>>(
      this.routes.getAlerts,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async deleteAlert(
    alertId: number,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.getAlert(alertId),
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }
}
