/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/ban-ts-comment */
import { createTheme, Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { PaletteOptions } from '@mui/material/styles/createPalette';

import { lightTheme } from './lightTheme';
import { darkTheme } from './darkTheme';
import { EThemes } from '../models';

interface ICustomColors {
  light: string;
  main: string;
  dark: string;
  disabled?: string;
  '50'?: string;
  '100'?: string;
  '150'?: string;
  '200'?: string;
  '250'?: string;
  '300'?: string;
  '350'?: string;
  '400'?: string;
  '450'?: string;
  '500'?: string;
  '550'?: string;
  '600'?: string;
  '650'?: string;
  '700'?: string;
  '750'?: string;
  '800'?: string;
  '850'?: string;
  '900'?: string;
  '950'?: string;
  '1000'?: string;
}

interface ICustomPurchaseButtonsColors {
  light: string;
  main: string;
  dark: string;
  red?: string;
  green?: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    black: ICustomColors;
    silver: ICustomColors;
    gray: ICustomColors;
    white: ICustomColors;
    maroon: ICustomColors;
    red: ICustomColors;
    purple: ICustomColors;
    green: ICustomColors;
    lime: ICustomColors;
    olive: ICustomColors;
    yellow: ICustomColors;
    navy: ICustomColors;
    blue: ICustomColors;
    teal: ICustomColors;
    aqua: ICustomColors;
    menu: ICustomColors;
    boxShadow: ICustomColors;
    tab: ICustomColors;
    purchaseButtons: ICustomPurchaseButtonsColors;
    chartMenu: ICustomColors;
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    silver?: PaletteOptions['primary'];
    gray?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    maroon?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    lime?: PaletteOptions['primary'];
    olive?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
    navy?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    teal?: PaletteOptions['primary'];
    aqua?: PaletteOptions['primary'];
    menu?: PaletteOptions['primary'];
    boxShadow?: PaletteOptions['primary'];
    // @ts-ignore
    tab?: PaletteOptions['tab'];
    // @ts-ignore
    purchaseButtons?: PaletteOptions['purchaseButtons'];
    // @ts-ignore
    chartMenu?: PaletteOptions['chartMenu'];
  }
}

export interface IThemeOptions {
  typography?: TypographyOptions;
  palette: PaletteOptions;
}

export const customSpacing = 4;

export const getTheme = (mode?: EThemes, options?: IThemeOptions): Theme => {
  const theme =
    mode === EThemes.Dark ? darkTheme(options) : lightTheme(options);

  return createTheme(theme);
};
