import { getIconBaseProps } from '../utils';

export const digital = {
  ...getIconBaseProps('digital'),
  width: '18px',
  height: '19px',
  viewBox: '0 0 18 19',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M13.8771 5.95801C13.8771 4.64308 13.3414 3.38201 12.3877 2.45221C11.4341 1.52241 10.1407 1.00006 8.79204 1.00006C7.4434 1.00006 6.14999 1.52241 5.19635 2.45221C4.24271 3.38201 3.70697 4.64308 3.70697 5.95801C3.70697 11.7423 1.16443 14.3484 1.16443 14.3484H16.4197C16.4197 14.3484 13.8771 11.7423 13.8771 5.95801Z'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6994 17.2085C10.5056 17.4985 10.2275 17.7392 9.89282 17.9065C9.55816 18.0739 9.17873 18.1619 8.79252 18.1619C8.40632 18.1619 8.02689 18.0739 7.69222 17.9065C7.35756 17.7392 7.07941 17.4985 6.88562 17.2085'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5297 1.01276C15.8447 2.00369 16.4193 3.31829 16.5832 4.82853'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.05358 1.01276C1.73857 2.00369 1.16391 3.31829 1 4.82853'
        strokeWidth='1.2'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
