export const paths = {
  matchAll: '*',

  auth: {
    root: '/auth',
    signUp: 'sign-up',
    signIn: 'sign-in',
    resetPassword: 'reset-password',
    autoLogin: 'auth/auto-login',
  },

  main: {
    root: '/',
    dashboard: 'dashboard',
    trading: 'trading',
    assets: 'data-assets',
    myAccount: 'my-account',
    muliBands: 'muli-bands',
    copier: 'copier',
    purchaseChallenges: 'purchase-challenges',
    accountStatementReport: 'account-statement-report',
  },

  common: {
    notFound: '*',
    accessDenied: 'access-denied',
  },
} as const;

export const NAVIGATE_TO = {
  auth: {
    root: `${paths.auth.root}`,
    signIn: `${paths.auth.root}/${paths.auth.signIn}`,
    signUp: `${paths.auth.root}/${paths.auth.signUp}`,
    autoLogin: `${paths.auth.root}/${paths.auth.autoLogin}`,
  },
  main: {
    home: `${paths.main.root}`,
    assets: `${paths.main.root}${paths.main.assets}`,
    myAccount: `${paths.main.root}${paths.main.myAccount}`,
    muliBands: `${paths.main.root}${paths.main.muliBands}`,
    copier: `${paths.main.root}${paths.main.copier}`,
    dashboard: `${paths.main.root}${paths.main.dashboard}`,
    trading: `${paths.main.root}${paths.main.trading}`,
    purchaseChallenges: `${paths.main.root}${paths.main.purchaseChallenges}`,
    accountStatementReport: `${paths.main.root}${paths.main.accountStatementReport}`,
  },
} as const;
