import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { IInstrumentItems } from '@trader/api';
import { useI18next } from '@trader/services';

import { Instrument } from './components/instrument';

import * as Styled from './styled';

export const RelatedInstruments: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const [instruments, setInstruments] = useState<
    IInstrumentItems['instruments']
  >([]);

  useEffect(() => {
    instrument &&
      (async function () {
        const res = await instrument.getRelatedInstrumentsAsync.run(symbol);
        setInstruments(res as IInstrumentItems['instruments']);
      })();
    return () => setInstruments([]);
  }, [symbol]);

  if (!instrument) {
    return <Styled.Root />;
  }

  return (
    <Styled.Root>
      <Styled.Main>
        <Styled.Title>
          {translate('COMMON.LABELS.RELATED_INSTRUMENTS')} -{' '}
          {instrument.displayName}
        </Styled.Title>
        <Styled.SubTitle>
          {translate('COMMON.LABELS.RELATED_INSTRUMENTS_DESCRIPTION')}
        </Styled.SubTitle>
        <Styled.Instruments>
          {instruments.map(i => (
            <Instrument instrument={i} key={i.symbol} />
          ))}
        </Styled.Instruments>
      </Styled.Main>
    </Styled.Root>
  );
});
