import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
  `};
`;

interface IActionButton {
  isAsk?: boolean;
  isActive?: boolean;
  $isDisabled?: boolean;
}

export const ButtonTitle = styled(Typography)<IActionButton>`
  ${({ theme, isActive, isAsk }) => css`
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    color: ${isAsk
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};

    ${conditionApply(
      [isActive],
      css`
        color: ${theme.palette.white.main};
      `
    )};

    ${theme.breakpoints.down('sm')} {
      font-size: 11px;
    }
  `};
`;

export const ButtonPrice = styled(Typography)<IActionButton>`
  ${({ theme, isActive, isAsk }) => css`
    font-size: 15px;
    font-weight: 700;
    color: ${isAsk
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};

    ${conditionApply(
      [isActive],
      css`
        font-weight: 600;
        color: ${theme.palette.white.main};
      `
    )};

    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  `};
`;

export const ActionButton = styled(Button)<IActionButton>`
  ${({ isAsk, isActive, $isDisabled, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 160px;
    padding: 4px 0;
    border-radius: 5px;

    ${conditionApply(
      [$isDisabled],
      css`
        cursor: not-allowed;
        box-shadow: none !important;
      `
    )}

    &:hover {
      ${conditionApply(
        [($isDisabled && isActive) || !$isDisabled],
        css`
          background: ${isAsk
            ? theme.palette.purchaseButtons.red
            : theme.palette.purchaseButtons.green};

          p {
            color: ${theme.palette.white.main};
          }
        `
      )}
      ${conditionApply(
        [$isDisabled && !isActive],
        css`
          background: transparent;
        `
      )}
    }

    border: 1px solid
      ${isAsk
        ? theme.palette.purchaseButtons.red
        : theme.palette.purchaseButtons.green};

    ${theme.breakpoints.down('sm')} {
      width: 45%;
      height: 45px;
    }

    ${conditionApply(
      [isActive],
      `
        background: ${
          isAsk
            ? theme.palette.purchaseButtons.red
            : theme.palette.purchaseButtons.green
        };
        border: 1px solid ${
          isAsk
            ? theme.palette.purchaseButtons.red
            : theme.palette.purchaseButtons.green
        };
        &:hover {
          background: ${
            isAsk
              ? theme.palette.purchaseButtons.red
              : theme.palette.purchaseButtons.green
          };
          border: 1px solid ${
            isAsk
              ? theme.palette.purchaseButtons.red
              : theme.palette.purchaseButtons.green
          };
        };
        `
    )};
  `};
`;
