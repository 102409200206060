import { EChartLayoutTypes, EChartLayouts } from '@trader/types';

export const LAYOUTS_TYPES = {
  single: {
    iconType: 'singleLayout',
    type: EChartLayoutTypes.Single,
    title: 'single chart',
    chartHeight: 485,
    layouts: [EChartLayouts.FirstLayout],
  },
  twoRow: {
    iconType: 'twoRowLayout',
    type: EChartLayoutTypes.TwoRow,
    title: 'two charts in a row',
    chartHeight: 790,
    layouts: [EChartLayouts.FirstLayout, EChartLayouts.SecondLayout],
  },
  threeRow: {
    iconType: 'threeRowLayout',
    type: EChartLayoutTypes.ThreeRow,
    title: 'three charts in a row',
    chartHeight: 790,
    layouts: [
      EChartLayouts.FirstLayout,
      EChartLayouts.SecondLayout,
      EChartLayouts.ThirdLayout,
    ],
  },
  twoColumn: {
    iconType: 'twoColumnLayout',
    type: EChartLayoutTypes.TwoColumn,
    title: 'two charts in a column',
    chartHeight: 350,
    layouts: [EChartLayouts.FirstLayout, EChartLayouts.SecondLayout],
  },
  threeColumn: {
    iconType: 'threeColumnLayout',
    type: EChartLayoutTypes.ThreeColumn,
    title: 'three charts in a column',
    chartHeight: 200,
    layouts: [
      EChartLayouts.FirstLayout,
      EChartLayouts.SecondLayout,
      EChartLayouts.ThirdLayout,
    ],
  },
  four: {
    iconType: 'fourLayout',
    type: EChartLayoutTypes.Four,
    title: 'four charts',
    chartHeight: 350,
    layouts: [
      EChartLayouts.FirstLayout,
      EChartLayouts.SecondLayout,
      EChartLayouts.ThirdLayout,
      EChartLayouts.FourthLayout,
    ],
  },
} as const;
