import { getIconBaseProps } from './utils';

export const checkmark = {
  ...getIconBaseProps('checkmark'),
  width: '7px',
  height: '6px',
  viewBox: '0 0 7 6',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M0.998047 3L2.66471 5L5.99805 1'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
