import { PaletteOptions } from '@mui/material/styles/createPalette';

export const darkPallet: PaletteOptions = {
  mode: 'dark',
  primary: {
    light: '#686868',
    main: '#F3B53D',
    dark: '#686868',
  },
  yellow: {
    light: '#0061F7',
    main: '#F3B5DD',
    dark: '#0061F7',
    '100': '#11BADA',
  },
  green: {
    light: '#74a150',
    main: '#31b228',
    dark: '#225d34',
    '100': '#24d917',
  },
};
