import { useEffect, useCallback } from 'react';

const defaultDelay = 400;

type TUseDebounce = (
  effect: () => void,
  dependencies: Array<unknown>,
  delay?: number
) => void;

export const useDebounce: TUseDebounce = (
  effect,
  dependencies,
  delay = defaultDelay
): void => {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};
