import { getIconBaseProps } from '../utils';

export const indices = {
  ...getIconBaseProps('indices'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M19 6L11.6154 13.5L8.53846 10.375L3 16'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 12V6H13'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
