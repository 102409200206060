import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Search = styled(Wrapper)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;
