import { useMst } from '@trader/store';
import { useEffect } from 'react';
import { convertSecondsToMs, getTimeInSeconds } from '@trader/utils';

const checkTimeBeforeExpire = 5;

/**
 * Custom hook that automatically updates authentication tokens before they expire.
 * It checks the remaining time before token expiration and refreshes the token if necessary.
 */
export const useAuthTokensAutoUpdate = () => {
  const store = useMst();
  const expiresIn = store.auth.tokens.expiresIn;
  const authorizedTimestamp = store.auth.tokens.authorizedSessionTimestamp;

  const handleRefreshToken = () => {
    store.auth.authRefreshTokenAsync.run();
  };

  useEffect(() => {
    if (authorizedTimestamp && expiresIn) {
      const expiresTime = authorizedTimestamp + expiresIn;
      const currentTime = getTimeInSeconds();
      const shouldUpdate = currentTime > expiresTime - checkTimeBeforeExpire;

      if (shouldUpdate) {
        handleRefreshToken();
      } else {
        const delay = expiresTime - (currentTime + checkTimeBeforeExpire);
        const timeoutId = setTimeout(
          handleRefreshToken,
          convertSecondsToMs(delay)
        );

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [authorizedTimestamp, expiresIn]);
};
