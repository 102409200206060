import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { muliBandsCategory, pingStrategyInterval } from '@trader/constants';
import { IChangeFieldEvent, IOption, TOptions } from '@trader/types';
import { Controller, Form } from '@trader/components';
import { useI18next } from '@trader/services';
import { useDebounce } from '@trader/hooks';
import { getTime } from '@trader/utils';
import { useMst } from '@trader/store';

import { useBackTestingFormValidation } from './formValidation';

import { SessionRange } from './sessionRange';
import { StopLoss } from './stopLoss';
import { Quantity } from './quantity';

import * as Styled from './styled';

export interface IBackTestingInitialValues {
  symbol: IOption[];
  quantity: number;
  lowestMultiplier: number;
  highestMultiplier: number;
  multiplierStep: number;
  oneTradePerSession: boolean;
  allowAveraging: boolean;
  isSessionRange: boolean;
  isSl: boolean;
  sl: number;
  from: Date;
  to: Date;
  sessionFrom: Date | undefined;
  sessionTo: Date | undefined;
}

export const BackTesting: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const validationSchema = useBackTestingFormValidation();

  const [searchedValue, setSearchedValue] = useState('');
  const [options, setOptions] = useState<IOption[]>([]);

  const idToken = store.auth.tokens.idToken;

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const defaultFormValues = {
    symbol: backTesting.getSymbolOption(),
    lowestMultiplier: backTesting.lowestMultiplier,
    highestMultiplier: backTesting.highestMultiplier,
    multiplierStep: backTesting.multiplierStep,
    from: backTesting.from,
    to: backTesting.to,
    isSl: !!backTesting.sl,
    sl: backTesting.sl,
    isSessionRange: !!backTesting.sessionFrom,
    sessionFrom: backTesting.sessionFrom || undefined,
    sessionTo: backTesting.sessionTo || undefined,
    oneTradePerSession: backTesting.oneTradePerSession,
    allowAveraging: backTesting.allowAveraging,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      backTesting.pingBackTestAsync.run();
    }, pingStrategyInterval);

    return () => {
      clearInterval(interval);
    };
  }, [backTesting.id, backTesting.isOpen, idToken]);

  useDebounce(async () => {
    if (searchedValue) {
      const instruments =
        await store.entities.instruments.getInstrumentsSearchAsync.run({
          value: searchedValue,
          category: muliBandsCategory,
        });
      setOptions(
        instruments?.length
          ? instruments?.map(i => ({ title: i, value: i }))
          : []
      );
    }
  }, [searchedValue]);

  const handleSubmit = (data: IBackTestingInitialValues) => {
    const {
      symbol,
      sessionTo,
      sessionFrom,
      isSessionRange,
      isSl,
      sl,
      ...backTestConfig
    } = data;

    backTesting.startBackTestingAsync.run({
      symbolOption: symbol[0],
      backTestConfig: {
        ...backTestConfig,
        sessionRange:
          isSessionRange && sessionFrom && sessionTo
            ? {
                from: getTime(sessionFrom),
                to: getTime(sessionTo),
              }
            : undefined,
        sl: isSl ? sl : undefined,
      },
    });
  };

  const customSymbolChange = async (event: IChangeFieldEvent<TOptions>) => {
    if (event.target.value[0]) {
      await store.entities.instruments.getInstrumentSpecificationAsync.run(
        event.target.value[0].value
      );
      backTesting.runInAction(() => {
        backTesting.symbolOption = event.target.value[0];
      });
    }
  };

  return (
    <Styled.Root>
      <Styled.Title>{translate('MULI_BANDS.BACK_TEST_CONFIG')}</Styled.Title>
      <Form<IBackTestingInitialValues>
        onSubmit={(_reset, data) => {
          handleSubmit(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        <Controller
          type='autocompleteField'
          placeholder={translate('COMMON.LABELS.SYMBOL')}
          name='symbol'
          isSearchable
          shouldHideInput
          withCheckbox={false}
          onHandeSearchableValue={setSearchedValue}
          onCustomChange={customSymbolChange}
          options={options}
        />
        <Quantity />
        <Controller
          type='textField'
          label={translate('MULI_BANDS.LOWEST_MULTIPLIER')}
          name='lowestMultiplier'
        />
        <Controller
          type='textField'
          label={translate('MULI_BANDS.HIGHEST_MULTIPLIER')}
          name='highestMultiplier'
        />
        <Controller
          type='textField'
          label={translate('MULI_BANDS.MULTIPLIER_STEP')}
          name='multiplierStep'
        />
        <Controller
          type='datePickerField'
          label={translate('MULI_BANDS.FROM_DATE')}
          name='from'
        />
        <Controller
          type='datePickerField'
          label={translate('MULI_BANDS.TO_DATE')}
          name='to'
        />
        <SessionRange />
        <StopLoss />
        <Controller
          type='switcherField'
          label={translate('MULI_BANDS.ALLOW_AVERAGE')}
          name='allowAveraging'
        />
        <Controller
          type='switcherField'
          label={translate('MULI_BANDS.ONE_TRADE_PER_SESSION')}
          name='oneTradePerSession'
        />
        <Styled.ApplyChanges
          loading={backTesting.isPending}
          disabled={backTesting.isPending}
          fullWidth
          type='submit'
        >
          {translate('COMMON.LABELS.EXECUTE')}
        </Styled.ApplyChanges>
      </Form>
    </Styled.Root>
  );
});
