import { Instance, types } from 'mobx-state-tree';

import { TPlaceOrderSide, TPlaceOrderType } from '@trader/types';
import { runInAction } from '@trader/utils';
import {
  cancelAllOrdersAsync,
  cancelOrderAsync,
  editOrderAsync,
  getOrdersMetricsAsync,
} from './actions';

import { createCollectionModel } from '../../utils/collectionModel';

export const orderMetricModel = types
  .model('orderMetricModel', {
    symbol: types.string,
    iconUrl: types.maybeNull(types.string),
    pipSize: types.number,
    orderId: types.identifier,
    type: types.frozen<TPlaceOrderType>('Market'),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    quantity: types.number,
    margin: types.number,
    limitPrice: types.maybeNull(types.number),
    stopPrice: types.maybeNull(types.number),
    takeProfit: types.maybeNull(types.number),
    stopLoss: types.maybeNull(types.number),
    editOrderAsync,
    cancelOrderAsync,
  })
  .views(() => ({}))
  .actions(() => ({
    runInAction,
  }));

interface IOrderMetricsAsync {
  getOrdersMetricsAsync: typeof getOrdersMetricsAsync;
  cancelAllOrdersAsync: typeof cancelAllOrdersAsync;
}

export const ordersMetrics = createCollectionModel<
  typeof orderMetricModel,
  IOrderMetricsAsync
>(orderMetricModel, {
  getOrdersMetricsAsync,
  cancelAllOrdersAsync,
});

export type TOrderMetricEntity = Instance<typeof orderMetricModel>;
