import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  values: {
    // extra-small
    xs: 426,
    // small
    sm: 769,
    // medium
    md: 1110,
    // large
    lg: 1201,
    // extra-large
    xl: 1441,
  },
};
