import { schema } from 'normalizr';

const orderMetric = new schema.Entity(
  'ordersMetrics',
  {},
  {
    idAttribute: 'orderId',
  }
);

export const ordersMetricsSchema = [orderMetric];
