import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

import { calculateSizes, conditionApply } from '@trader/utils';
import { Typography, Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

interface IWrapper {
  $width: number;
  $height: number;
  $staticHeight?: boolean;
}

export const Main = styled(Box)<IWrapper>`
  ${({ $width, $height, $staticHeight, theme }) => css`
    position: relative;
    width: ${calculateSizes('width', $width)};
    height: ${$staticHeight
      ? `${$height}px`
      : calculateSizes('height', $height)};
    margin-top: 4px;

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
      padding-top: ${layoutMargin}px;
      border-radius: ${layoutMargin}px;
      background: ${theme.palette.background.paper};
    }

    ${conditionApply(
      [$staticHeight],
      css`
        border-radius: 4px;
        overflow: hidden;
      `
    )}

    ${conditionApply(
      [!$staticHeight],
      css`
        ${theme.breakpoints.down('sm')} {
          height: ${calculateSizes('height', $height)};
        }
      `
    )}
  `};
`;

export const Symbol = styled(Typography)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    border-bottom-right-radius: 4px;
    color: ${theme.palette.black.main};
    background: ${theme.palette.primary.light};
    z-index: 2;
  `};
`;

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;

    .chart-timing-dropdown {
      display: none;
    }

    ${theme.breakpoints.up('sm')} {
      .chart-timing {
        display: none;
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
      padding-top: ${layoutMargin}px;
      border-radius: ${layoutChildrenRadius}px;
      background: ${theme.palette.background.paper};

      > div:first-child {
        margin: 0;
      }

      .chart-timing {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        > div {
          margin: 0;
          padding: 4px 7px;
        }
      }
    }
  `};
`;
