import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  ScrollingList,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import {
  convertFromOption,
  dateFormats,
  formatTableRowValueByPipSize,
} from '@trader/utils';
import { EOrderSide, EStripName, IBackTestingPosition } from '@trader/types';

import { MultiplierFilter } from '../multiplierFilter';
import { MultiplierSummary } from '../multiplierSummary';

import * as Styled from './styled';
import { useExportCsv } from '@trader/hooks';
import { fullyFinishedBackTest } from '@trader/constants';
import { BandFilter } from '../bandFilter';

export const ListOfTrades: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { downloadCSV } = useExportCsv();

  const backTesting = store.pages.muliBands.backTesting;
  const listOfTrades = backTesting.getListOfTrades();

  const percentFinished =
    +store.pages.muliBands.backTesting.percentFinished.toFixed();

  const symbol = convertFromOption(backTesting.getSymbolOption());
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const cells: Array<IHeadCell> = [
    {
      id: 'side',
      label: translate('COMMON.LABELS.SIDE'),
      minWidth: 40,
      align: 'start',
    },
    {
      id: 'bandId',
      label: translate('MULI_BANDS.BAND'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'profit',
      label: translate('COMMON.LABELS.PROFIT'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'openPrice',
      label: translate('COMMON.LABELS.OPEN_PRICE'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'closePrice',
      label: translate('COMMON.LABELS.CLOSE_PRICE'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'openTime',
      label: translate('COMMON.LABELS.OPEN_TIME'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'closeTime',
      label: translate('COMMON.LABELS.CLOSE_TIME'),
      minWidth: 100,
      align: 'center',
    },
  ];

  const renderRowItemsCb: IRenderRowItemsCallBacks<IBackTestingPosition> =
    useMemo(
      () => ({
        profit: item =>
          formatTableRowValueByPipSize(item.value, instrument.pipSize),
        side: item => EOrderSide[item.value as EOrderSide],
        bandId: item => EStripName[item.value as EStripName],
        openPrice: item =>
          formatTableRowValueByPipSize(item.value, instrument.pipSize),
        closePrice: item =>
          formatTableRowValueByPipSize(item.value, instrument.pipSize),
        openTime: item =>
          dateFormats(new Date(item.value as string), 'dd/mm/yyyy hh:mm:ss'),
        closeTime: item =>
          dateFormats(new Date(item.value as string), 'dd/mm/yyyy hh:mm:ss'),
      }),
      []
    );

  const getCsvData = () => {
    const allPositions = backTesting
      .getAllMultipliers()
      .map(m => m.positions)
      .flat();

    return {
      data: {
        headers: cells.map(cell => ({
          label: cell.label as string,
          id: cell.id,
        })),
        rows: allPositions.map(position => ({
          ...position,
          side: EOrderSide[position.side],
          bandId: EStripName[position.bandId],
          openTime: dateFormats(
            new Date(position.openTime),
            'dd/mm/yyyy hh:mm:ss'
          ),
          closeTime: dateFormats(
            new Date(position.closeTime),
            'dd/mm/yyyy hh:mm:ss'
          ),
        })),
      },
      fileName: `List of trades ${dateFormats(
        new Date(),
        'dd/mm/yyyy hh:mm:ss'
      )} `,
    };
  };

  return (
    <Styled.Content>
      {percentFinished === fullyFinishedBackTest && (
        <Styled.Download size='small' onClick={() => downloadCSV(getCsvData())}>
          Download csv
        </Styled.Download>
      )}
      <Styled.Filters>
        <MultiplierFilter />
        <BandFilter />
      </Styled.Filters>
      <MultiplierSummary />
      <ScrollingList<IBackTestingPosition>
        rows={listOfTrades}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
      />
    </Styled.Content>
  );
});
