import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 450px;
    padding: 15px 45px;
    flex-direction: column;

    ${theme.breakpoints.down('sm')} {
      padding: 12px 25px;
    }
  `}
`;
