import { AxiosError, AxiosRequestConfig } from 'axios';

export const responseStatusCodes = {
  400: 400,
  401: 401,
  403: 403,
  422: 422,
  500: 500,
} as const;

export const errorStatuses = {
  NotFound: 'NotFound',
  BadRequest: 'BadRequest',
  NotAuthorized: 'NotAuthorized',
  Unauthenticated: 'Unauthenticated',
  InternalError: 'InternalError',
  AlreadyExists: 'AlreadyExists',
  InvalidArgument: 'InvalidArgument',
  InvalidResponse: 'InvalidResponse',
  PermissionDenied: 'PermissionDenied',
  Unimplemented: 'Unimplemented',
  Unavailable: 'Unavailable',
  TradingFinaltoUnauthenticated: 'TradingFinaltoUnauthenticated',
  TechnicalWorks: 'TechnicalWorks',
  Cancelled: 'Cancelled',
} as const;

export enum EErrorTypes {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  INNER_ERROR = 'InnerError',
  UNKNOWN = 'UNKNOWN',
}

export interface IError {
  type: EErrorTypes;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfIError(object: any): object is IError {
  return (
    object?.type &&
    typeof object.type === 'string' &&
    object?.message &&
    typeof object.message === 'string'
  );
}

export interface IHttpClientResponseError extends Omit<AxiosError, 'response'> {
  response: {
    config: AxiosRequestConfig;
    data?: {
      errors: Array<IError>;
      requestId: string;
      status: keyof typeof errorStatuses;
    };
    status: keyof typeof responseStatusCodes;
  };
}
