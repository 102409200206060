import styled, { css } from 'styled-components';
import {
  layoutChildrenRadius,
  layoutMargin,
  sideBarWidth,
} from '@trader/constants';
import { conditionApply } from '@trader/utils';
import { Wrapper } from '../wrapper';
import { Button } from '../button';

interface IDrawer {
  $isOpen: boolean;
}

export const ToggleButton = styled(Button)`
  ${() => css`
    display: flex;
    align-items: center;
    padding: 5px;
    min-width: 30px;
    justify-content: flex-start;
  `}
`;

export const Drawer = styled(Wrapper)<IDrawer>`
  ${({ $isOpen, theme }) => css`
    background-color: ${theme.palette.common.white};
    border-radius: ${layoutChildrenRadius}px;
    flex-direction: column;
    width: 54px;
    height: 100%;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 8px;
    margin-left: ${layoutMargin}px;
    background-color: ${theme.palette.common.white};

    ${conditionApply(
      [$isOpen],
      css`
        width: ${sideBarWidth}px;
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })};
        overflow-x: hidden;
      `
    )};

    ${conditionApply(
      [!$isOpen],
      css`
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        overflow-x: hidden;
      `
    )}
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  }
  `}
`;
