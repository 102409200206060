import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  `}
`;
