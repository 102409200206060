import React from 'react';
import { Icon } from '../icon';
import { IconButtonProps, IconButton as IconButtonMui } from '@mui/material';
import { TIconsName } from '@trader/assets';

interface IIconButton extends IconButtonProps {
  iconType?: TIconsName;
}

export const IconButton: React.FC<IIconButton> = ({
  color = 'inherit',
  size = 'large',
  onClick,
  iconType,
  children,
  ...rest
}) => {
  return (
    <IconButtonMui onClick={onClick} size={size} color={color} {...rest}>
      {children && children}
      {iconType && <Icon iconType={iconType} />}
    </IconButtonMui>
  );
};
