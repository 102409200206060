import { useState } from 'react';

import { BalanceDetails, Icon } from '@trader/components';

import * as Styled from './styled';

export const StableDesign = () => {
  const [isOpenBalance, setIsOpenBalance] = useState(true);

  const handleToggleBalance = () => {
    setIsOpenBalance(prev => !prev);
  };

  return (
    <Styled.Root>
      {isOpenBalance && (
        <Styled.Toggle
          isSingleIcon
          variant='text'
          onClick={handleToggleBalance}
        >
          <Icon iconType={'currency'} />
          <Icon style={{ width: 7 }} iconType={'arrowRight'} />
        </Styled.Toggle>
      )}
      <BalanceDetails isShowDetails={isOpenBalance} />
      {!isOpenBalance && (
        <Styled.Toggle
          isSingleIcon
          variant='text'
          onClick={handleToggleBalance}
        >
          <Icon style={{ width: 7 }} iconType={'arrowLeft'} />
          <Icon iconType={'currency'} />
        </Styled.Toggle>
      )}
    </Styled.Root>
  );
};
