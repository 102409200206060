export const defaultSubCategoryName = 'All';
export const defaultCategories = [
  {
    id: 'Watchlist',
    name: 'Watchlist',
    isDefault: true,
    iconUrl: 'watchlist',
    subcategories: [],
    hasInstrumentsLayout: true,
  },
  {
    id: 'Popular',
    name: 'Popular',
    isDefault: true,
    iconUrl: 'popular',
    subcategories: [],
    hasInstrumentsLayout: true,
  },
];

export const modifyIconsPerCategory = {
  Commodities: 'commodities',
  Cryptos: 'cryptos',
  Digital: 'cryptos',
  ETF: 'etf',
  ETFs: 'etf',
  'A-Z': 'etf',
  Forex: 'forex',
  FX: 'forex',
  Indices: 'indices',
  Shares: 'shares',
  Synthetics: 'synthetics',
  Watchlist: 'watchlist',
  Popular: 'popular',
  Positions: 'positions',
  Orders: 'orders',
  Alerts: 'alerts',
};

export const cfdTabs = ['Watchlist', 'Popular'];
export const dmaTabs = ['Watchlist', 'Popular'];
