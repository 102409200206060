import { getIconBaseProps } from './utils';

export const challengeFailed = {
  ...getIconBaseProps('challengeFailed'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        stroke='#F73F4A'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.16659 11.1111C9.16659 11.7555 8.64425 12.2778 7.99992 12.2778C7.35559 12.2778 6.83325 11.7555 6.83325 11.1111C6.83325 10.4668 7.35559 9.94446 7.99992 9.94446C8.64425 9.94446 9.16659 10.4668 9.16659 11.1111Z'
        fill='#F73F4A'
      />
      <path
        d='M8 8L8 4.88889'
        stroke='#F73F4A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
