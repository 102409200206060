import { getIconBaseProps } from './utils';

export const challengeCompleted = {
  ...getIconBaseProps('challengeCompleted'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        stroke='#26B573'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3334 6.44446L7.2223 9.55557L5.66675 8.00001'
        stroke='#26B573'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
