import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { api, IIndicators } from '@trader/api';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const TradingCentral: React.FC = observer(() => {
  const { translate } = useI18next();
  const [indicators, setIndicators] = useState<Array<string> | string>('');

  const store = useMst();
  const symbol = store.ui.sidebar.rightBar.infoSection.symbol;

  const formatIndicators = (ind: IIndicators) => {
    const indicator = ind?.feeds[ind?.feeds?.length - 1];

    if (!ind.feeds.length) {
      return translate('COMMON.LABELS.INSTRUMENT_NOT_COVERED_TRADING_CENTRAL');
    }

    if (indicator) {
      const title = indicator.title;
      const pivot = indicator.paragraphs[0];
      const preference = indicator.paragraphs[1];
      const scenario = indicator.paragraphs[2];
      const comment = indicator.paragraphs[3];
      const date = new Date(indicator.time).toUTCString();

      return [title, pivot, preference, scenario, comment, date];
    }

    return '';
  };

  const fetchIndicators = async () => {
    const response = await api.Historical.getIndicators(symbol);
    response && setIndicators(formatIndicators(response));
  };

  useEffect(() => {
    if (symbol) {
      fetchIndicators();
    }
  }, [symbol]);

  if (!indicators) {
    return null;
  }

  if (typeof indicators === 'string') {
    return <Styled.Paragraph>{indicators}.</Styled.Paragraph>;
  }

  return (
    <>
      <Styled.Title style={{ width: '80%' }}>
        {translate('CHART.TRADING_CENTRAL_PREFERENCE_INTRADAY_RISE')}
      </Styled.Title>
      {indicators.map(i => (
        <Styled.Paragraph key={i}>{i}</Styled.Paragraph>
      ))}
      <Styled.Title style={{ marginTop: '20px' }}>
        {translate('COMMON.LABELS.LEGEND')}
      </Styled.Title>
      <Styled.Paragraph>
        <span className='resistance'>R1, R2, R3</span>{' '}
        {translate('CHART.LINES_REPRESENT_RESISTANCE')}
      </Styled.Paragraph>
      <Styled.Paragraph>
        <span className='support'>S1, S2, S3</span>{' '}
        {translate('CHART.LINES_REPRESENT_SUPPORT_LEVELS')}
      </Styled.Paragraph>
      <Styled.Paragraph>
        <span className='pivot'>{translate('COMMON.LABELS.PIVOT')}</span>{' '}
        {translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT')}
      </Styled.Paragraph>
    </>
  );
});
