import { requestService, TAsyncActionOptions } from '@trader/services';
import { defaultPageNumber, defaultPageSize } from '@trader/constants';

import { instrumentRoutes } from './routes';
import {
  TExchangeRateInstrumentsBE,
  IGetExchangeRateInstrumentsBody,
  IGetInstrumentsParams,
  IInstrumentItems,
  IInstrumentSpecificationBE,
  IInstrumentTradersTrendBE,
} from './models/instrument';

export class Instrument {
  routes = instrumentRoutes;

  async getInstruments(
    params: IGetInstrumentsParams,
    options: TAsyncActionOptions
  ): Promise<IInstrumentItems> {
    const resp = await requestService.get<IInstrumentItems>(
      this.routes.getInstruments,
      {
        params: {
          pageSize: defaultPageSize,
          pageNumber: params.pageNumber || defaultPageNumber,
          category: params.category,
          search: params.search,
          view: params.view,
        },
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getRelatedInstruments(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IInstrumentItems> {
    const resp = await requestService.get<IInstrumentItems>(
      this.routes.getRelatedInstruments,
      {
        ...options.cancelToken,
        params: { symbol },
      }
    );

    return resp?.data;
  }

  async getInstrumentSpecification(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IInstrumentSpecificationBE> {
    const resp = await requestService.get<IInstrumentSpecificationBE>(
      this.routes.getInstrumentSpecification,
      {
        ...options.cancelToken,
        params: { symbol },
      }
    );

    return resp?.data;
  }

  async getInstrumentTradersTrend(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<IInstrumentTradersTrendBE> {
    const resp = await requestService.get<IInstrumentTradersTrendBE>(
      this.routes.getInstrumentTradersTrend,
      {
        ...options.cancelToken,
        params: { symbol },
      }
    );

    return resp?.data;
  }

  /**
   * Return the list of instruments that should be subscribed
   * to get the exchange rate for calculating position details.
   */
  async getExchangeRateInstruments(
    body: IGetExchangeRateInstrumentsBody[]
  ): Promise<TExchangeRateInstrumentsBE> {
    const resp = await requestService.post<TExchangeRateInstrumentsBE>(
      this.routes.getExchangeRateInstruments,
      body
    );

    return resp?.data;
  }

  async addInstrumentToFavorite(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.post<void>(
      this.routes.favorite,
      {},
      {
        params: { symbol },
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async deleteInstrumentFromFavorite(
    symbol: string,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.delete<void>(
      this.routes.favorite,
      {},
      {
        params: { symbol },
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }
}
