import { getIconBaseProps } from '../utils';

export const etf = {
  ...getIconBaseProps('etf'),
  width: '28px',
  height: '28px',
  viewBox: '0 0 28 28',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M4.08482 14.9513C6.31801 18.8229 11.264 20.1494 15.132 17.9142C16.9147 16.884 18.1572 15.2771 18.7603 13.4649C18.8459 13.2078 18.6788 12.9409 18.4132 12.8882L15.9823 12.4058C15.5106 12.3122 15.0529 12.5928 14.8168 13.0121C14.4531 13.658 13.9171 14.2177 13.2292 14.6153C11.1814 15.7986 8.56293 15.0964 7.38065 13.0467C6.19837 10.997 6.89999 8.3761 8.94776 7.19271C8.99061 7.16795 9.03372 7.14401 9.07705 7.12089C9.48961 6.90081 9.77609 6.46772 9.70994 6.0045L9.35233 3.50034C9.31476 3.23724 9.06006 3.06142 8.80539 3.13665C8.20334 3.31448 7.61252 3.56579 7.04491 3.8938C3.1769 6.12908 1.85162 11.0797 4.08482 14.9513Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0861 9.36814C15.0067 9.16174 14.9102 8.95886 14.7963 8.76128C14.2772 7.8614 13.4813 7.22123 12.5784 6.88633C12.1231 6.71745 11.7719 6.30284 11.7952 5.8174L11.8894 3.86147C11.9157 3.31449 12.3986 2.89737 12.9313 3.02226C15.0304 3.51439 16.9291 4.84039 18.0921 6.85666C18.5105 7.58192 18.8039 8.34505 18.9792 9.1211C19.1035 9.67151 18.6535 10.1569 18.0898 10.1507L16.0809 10.1288C15.6265 10.1239 15.2495 9.79263 15.0861 9.36814Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
