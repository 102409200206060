import { getIconBaseProps } from './utils';

export const paymentServiceProvider = {
  ...getIconBaseProps('paymentServiceProvider'),
  width: '299px',
  height: '42px',
  viewBox: '0 0 299 42',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <g filter='url(#filter0_d_3808_20817)'>
        <rect
          x='179'
          y='8.96631'
          width='23.5893'
          height='23.5893'
          rx='3'
          fill='#E9FFF9'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M191.59 21.1259C191.53 21.1304 191.22 21.1488 190.529 21.1488C189.98 21.1488 189.59 21.1324 189.453 21.1259C187.331 21.0325 185.746 20.663 185.746 20.2206C185.746 19.7782 187.331 19.4092 189.453 19.3143V20.7579C189.592 20.7679 189.99 20.7913 190.539 20.7913C191.198 20.7913 191.528 20.7639 191.588 20.7584V19.3153C193.706 19.4097 195.286 19.7792 195.286 20.2206C195.286 20.662 193.706 21.0315 191.588 21.1254L191.59 21.1259ZM191.589 19.1651V17.8734H194.545V15.9036H186.497V17.8734H189.453V19.1646C187.051 19.275 185.244 19.7508 185.244 20.321C185.244 20.8913 187.051 21.3666 189.453 21.4775V25.6168H191.589V21.476C193.986 21.3656 195.789 20.8903 195.789 20.3205C195.789 19.7508 193.987 19.2755 191.589 19.1646L191.589 19.1651Z'
          fill='#50AF95'
        />
      </g>
      <g filter='url(#filter1_d_3808_20817)'>
        <rect
          x='270.954'
          y='9.66113'
          width='23.5893'
          height='23.5893'
          rx='3'
          fill='#DBF5FF'
        />
        <path
          d='M284.961 15.2117C283.534 15.2116 282.377 16.4085 282.377 17.8854C282.377 18.7639 282.648 18.8401 282.775 19.3199C282.849 19.5996 283 19.8721 282.987 20.1617C282.976 20.3763 282.931 20.6323 282.77 20.7662C282.615 20.8966 282.352 20.9203 282.135 20.9157C281.923 20.9113 281.537 20.7984 281.521 20.7438C281.17 19.5849 280.261 18.6728 279.088 18.672C277.661 18.6719 276.504 19.8694 276.504 21.3466C276.504 22.8237 277.661 24.0212 279.088 24.0211C280.136 24.0205 281.04 23.3468 281.476 22.3607C281.495 22.3187 281.966 22.0102 282.249 21.9941C282.459 21.9821 282.725 22.0225 282.848 22.1996C283.111 22.5809 282.914 23.182 282.835 23.6059C282.744 24.0994 282.438 24.523 282.437 25.0256C282.437 26.5027 283.594 27.7002 285.021 27.7001C286.448 27.7002 287.605 26.5027 287.605 25.0256C287.605 23.5484 286.448 22.3509 285.021 22.351C284.754 22.3572 284.505 22.2373 284.333 22.0856C284.083 21.8654 283.92 21.784 283.889 21.5823C283.863 21.4178 283.854 21.0722 283.999 20.8955C284.218 20.6289 284.626 20.5599 284.961 20.56C286.388 20.5607 287.544 19.3626 287.544 17.8855C287.544 16.4086 286.387 15.2117 284.961 15.2118L284.961 15.2117Z'
          fill='#27AFE6'
        />
      </g>
      <g filter='url(#filter2_d_3808_20817)'>
        <rect
          x='248.106'
          y='6.41699'
          width='28.2223'
          height='28.4047'
          rx='3'
          fill='#EFEFEF'
        />
        <path
          d='M262.178 12.0979L262.066 12.4817V23.6167L262.178 23.7294L267.314 20.6742L262.178 12.0979Z'
          fill='#343434'
        />
        <path
          d='M262.179 12.0981L257.043 20.6744L262.179 23.7297V18.325V12.0981Z'
          fill='#8C8C8C'
        />
        <path
          d='M262.179 24.7072L262.115 24.7849V28.7513L262.179 28.9372L267.317 21.6536L262.179 24.7072Z'
          fill='#3C3C3B'
        />
        <path
          d='M262.179 28.9372V24.7072L257.043 21.6536L262.179 28.9372Z'
          fill='#8C8C8C'
        />
        <path
          d='M262.178 23.7291L267.314 20.6739L262.178 18.3245V23.7291Z'
          fill='#141414'
        />
        <path
          d='M257.043 20.6739L262.179 23.7291V18.3245L257.043 20.6739Z'
          fill='#393939'
        />
      </g>
      <g filter='url(#filter3_d_3808_20817)'>
        <rect
          x='197.214'
          y='6.41699'
          width='28.4047'
          height='28.4047'
          rx='3'
          fill='#DEEBFF'
        />
        <path
          d='M211.365 20.5503L210.27 23.7468H216.123C216.201 23.7445 216.277 23.7691 216.334 23.8155C216.392 23.8618 216.425 23.9259 216.428 23.9937V24.0775L215.919 25.5986C215.873 25.7468 215.714 25.8475 215.537 25.8411H206.581L208.082 21.41L206.403 21.8509L206.785 20.8369L208.464 20.396L210.576 14.1792C210.624 14.032 210.781 13.932 210.957 13.9367H213.222C213.3 13.9343 213.376 13.959 213.434 14.0053C213.491 14.0516 213.525 14.1158 213.527 14.1836V14.2674L211.746 19.5141L213.426 19.0732L213.069 20.1314L211.365 20.5503Z'
          fill='#345D9D'
        />
      </g>
      <g filter='url(#filter4_d_3808_20817)'>
        <rect
          x='220.243'
          y='4'
          width='33.2395'
          height='33.2395'
          rx='3'
          fill='#FFEAD0'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M242.962 18.5083C243.227 16.7407 241.881 15.7906 240.04 15.1568L240.637 12.7625L239.18 12.3993L238.599 14.7305C238.215 14.635 237.822 14.5449 237.431 14.4557L238.016 12.109L236.559 11.7458L235.962 14.1394C235.645 14.0672 235.333 13.9958 235.031 13.9206L235.033 13.9131L233.023 13.4112L232.635 14.9679C232.635 14.9679 233.717 15.2158 233.694 15.231C234.284 15.3783 234.391 15.769 234.373 16.0787L233.693 18.8064C233.734 18.8167 233.786 18.8316 233.844 18.855L233.802 18.8444L233.802 18.8444C233.766 18.8354 233.729 18.8261 233.69 18.8169L232.737 22.638C232.665 22.8173 232.482 23.0864 232.069 22.9842C232.084 23.0054 231.01 22.7198 231.01 22.7198L230.286 24.3882L232.183 24.861C232.391 24.9131 232.596 24.9666 232.799 25.0195L232.799 25.0196C232.941 25.0566 233.082 25.0933 233.222 25.1291L232.619 27.5509L234.075 27.9141L234.672 25.518C235.07 25.626 235.456 25.7256 235.834 25.8194L235.239 28.2042L236.696 28.5674L237.3 26.1501C239.785 26.6205 241.654 26.4309 242.441 24.1829C243.075 22.373 242.409 21.3291 241.102 20.6483C242.054 20.4287 242.771 19.8023 242.963 18.5084L242.962 18.5081L242.962 18.5083ZM239.632 23.1758C239.219 24.8363 236.62 24.1498 235.43 23.8357C235.323 23.8075 235.228 23.7822 235.146 23.762L235.947 20.5536C236.046 20.5784 236.167 20.6056 236.305 20.6365L236.305 20.6365C237.535 20.9125 240.056 21.4781 239.632 23.1758H239.632ZM236.551 19.1495C237.543 19.4141 239.706 19.9913 240.082 18.4821H240.083C240.467 16.9386 238.365 16.4732 237.338 16.2459C237.223 16.2203 237.121 16.1978 237.038 16.1771L236.312 19.0869C236.381 19.104 236.461 19.1254 236.551 19.1495Z'
          fill='#F7931A'
        />
      </g>
      <g clip-path='url(#clip0_3808_20817)'>
        <path
          d='M30.7643 31.0333H24.9756L28.5928 9.38794H34.3816L30.7643 31.0333Z'
          fill='#0066B3'
        />
        <path
          d='M20.1029 9.38787L14.5819 24.2756L13.9275 21.0676L11.9761 11.3335C11.9761 11.3335 11.7381 9.38208 9.22748 9.38208H0.107089L0 9.74689C0 9.74689 2.79026 10.3144 6.05647 12.2195L11.0896 31.0275H17.1223L26.3319 9.38787H20.097H20.1029Z'
          fill='#0066B3'
        />
        <path
          d='M65.6454 31.0333H70.9642L66.3296 9.38794H61.6712C59.5235 9.38794 59 11.0035 59 11.0035L50.3674 31.0391H56.4001L57.6078 27.8253H64.9672L65.6454 31.0391V31.0333ZM59.2736 23.3781L62.3138 15.277L64.0272 23.3781H59.2736Z'
          fill='#0066B3'
        />
        <path
          d='M50.8136 14.5937L51.6406 9.94387C51.6406 9.94387 49.0883 9 46.4349 9C43.5613 9 36.7374 10.2218 36.7374 16.1688C36.7374 21.7625 44.7452 21.832 44.7452 24.7679C44.7452 27.7037 37.5643 27.1768 35.1905 25.3296L34.3279 30.1879C34.3279 30.1879 36.9159 31.4097 40.8662 31.4097C44.8166 31.4097 50.7779 29.4178 50.7779 23.9977C50.7779 18.5777 42.6987 17.8481 42.6987 15.3986C42.6987 12.9492 48.3387 13.2677 50.8136 14.5937Z'
          fill='#0066B3'
        />
        <path
          d='M13.9275 21.0734L11.9761 11.3394C11.9761 11.3394 11.7381 9.38794 9.22748 9.38794H0.107089L0 9.75275C0 9.75275 4.38469 10.6387 8.59685 13.951C12.6186 17.1184 13.9275 21.0676 13.9275 21.0676V21.0734Z'
          fill='#FAA634'
        />
      </g>
      <path
        d='M119.271 19.5675C119.271 14.9348 121.457 10.8152 124.865 8.14453C122.351 6.17871 119.18 5 115.728 5C107.596 5 101 11.524 101 19.5675C101 27.6109 107.596 34.1349 115.728 34.1349C119.18 34.1349 122.351 32.9562 124.865 30.9904C121.461 28.3236 119.271 24.2001 119.271 19.5675Z'
        fill='#E30D17'
      />
      <path
        d='M119.271 19.5675C119.271 24.2001 121.456 28.3197 124.865 30.9904C128.27 28.3236 130.459 24.2001 130.459 19.5675C130.459 14.9348 128.274 10.8152 124.865 8.14453C121.46 10.8113 119.271 14.9348 119.271 19.5675Z'
        fill='#EB610A'
      />
      <path
        d='M133.999 5C130.547 5 127.376 6.17871 124.862 8.14453C128.267 10.8113 130.456 14.9348 130.456 19.5675C130.456 24.2001 128.271 28.3197 124.862 30.9904C127.372 32.9562 130.547 34.1349 133.999 34.1349C142.135 34.1349 148.727 27.6109 148.727 19.5675C148.727 11.524 142.135 5 133.999 5Z'
        fill='#F69F00'
      />
      <defs>
        <filter
          id='filter0_d_3808_20817'
          x='175'
          y='4.96631'
          width='31.5894'
          height='31.5894'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3808_20817'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3808_20817'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_3808_20817'
          x='266.954'
          y='5.66113'
          width='31.5894'
          height='31.5894'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3808_20817'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3808_20817'
            result='shape'
          />
        </filter>
        <filter
          id='filter2_d_3808_20817'
          x='244.106'
          y='2.41699'
          width='36.2222'
          height='36.4048'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3808_20817'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3808_20817'
            result='shape'
          />
        </filter>
        <filter
          id='filter3_d_3808_20817'
          x='193.214'
          y='2.41699'
          width='36.4048'
          height='36.4048'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3808_20817'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3808_20817'
            result='shape'
          />
        </filter>
        <filter
          id='filter4_d_3808_20817'
          x='216.243'
          y='0'
          width='41.2397'
          height='41.2395'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3808_20817'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3808_20817'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_3808_20817'>
          <rect
            width='70.9642'
            height='22.4097'
            fill='white'
            transform='translate(0 9)'
          />
        </clipPath>
      </defs>
    </>
  ),
};
