import { getIconBaseProps } from './utils';

export const priceAlert = {
  ...getIconBaseProps('priceAlert'),
  width: '23px',
  height: '24px',
  viewBox: '0 0 23 24',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M17.8815 7.50001C17.8815 5.7761 17.1792 4.1228 15.9289 2.90381C14.6787 1.68482 12.983 1 11.2149 1C9.44675 1 7.75105 1.68482 6.50081 2.90381C5.25056 4.1228 4.54818 5.7761 4.54818 7.50001C4.54818 15.0834 1.21484 18.5 1.21484 18.5H21.2149C21.2149 18.5 17.8815 15.0834 17.8815 7.50001Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7V13'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 10H14'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 22C13.7459 22.3802 13.3813 22.6958 12.9425 22.9152C12.5038 23.1345 12.0063 23.25 11.5 23.25C10.9937 23.25 10.4962 23.1345 10.0575 22.9152C9.61873 22.6958 9.25406 22.3802 9 22'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.7373 1.0166C20.4613 2.31575 21.2147 4.03923 21.4296 6.01919'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.69231 1.0166C1.96828 2.31575 1.21489 4.03923 1 6.01919'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
