import {
  CustomIndicator,
  RawStudyMetaInfo,
  StudyLinePlotInfo,
} from '../charting_library';
import { baseDefaultsStyles } from '../constants';

export const tradingCentral = (): CustomIndicator => ({
  name: 'Trading Central',
  metainfo: {
    _metainfoVersion: 51,

    id: 'Trading central@tv-basicstudies-1' as unknown as RawStudyMetaInfo['id'],
    name: 'TradingCentral',
    description: 'Trading Central',
    shortDescription: 'Trading Central',
    isCustomIndicator: true,
    is_price_study: true,

    format: {
      type: 'price',
    },
    inputs: [],
    plots: [
      {
        id: 'pivot',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'resistance1',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'resistance2',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'resistance3',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'support1',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'support2',
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: 'support3',
        type: 'line' as StudyLinePlotInfo['type'],
      },
    ],
    styles: {
      pivot: {
        histogramBase: 0,
        isHidden: true,
        title: 'Pivot',
      },
      resistance1: {
        histogramBase: 0,
        isHidden: true,
        title: 'R2',
      },
      resistance2: {
        histogramBase: 0,
        isHidden: true,
        title: 'R2',
      },
      resistance3: {
        histogramBase: 0,
        isHidden: true,
        title: 'R3',
      },
      support1: {
        histogramBase: 0,
        isHidden: true,
        title: 'S1',
      },
      support2: {
        histogramBase: 0,
        isHidden: true,
        title: 'S2',
      },
      support3: {
        histogramBase: 0,
        isHidden: true,
        title: 'S3',
      },
    },
    defaults: {
      styles: {
        pivot: {
          ...baseDefaultsStyles,
          trackPrice: true,
          visible: true,
          color: 'rgb(101, 67, 186)',
        },
        resistance1: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(49, 146, 26)',
        },
        resistance2: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(49, 146, 26)',
        },
        resistance3: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(49, 146, 26)',
        },
        support1: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(219, 46, 39)',
        },
        support2: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(219, 46, 39)',
        },
        support3: {
          ...baseDefaultsStyles,
          visible: true,
          trackPrice: true,
          color: 'rgb(219, 46, 39)',
        },
      },
      inputs: {},
    },
  },
  constructor: function () {
    this.main = function (context, input) {
      this.context = context;
      this.input = input;

      // eslint-disable-next-line
      const result = (context.symbol.info as any).tradingCentral;

      return result;
    };
  },
});
