import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { IconButton } from '@trader/components';
import { useMst } from '@trader/store';

import { Section } from '../../styled';
import { KeyStatistics } from './components/keyStatistics';
import { TradingCentral } from './components/tradingCentral';
import * as Styled from './styled';

export const InfoSection: FC = observer(() => {
  const { translate } = useI18next();
  const store = useMst();

  if (!store.ui.sidebar.rightBar.infoSection.isOpen) {
    return null;
  }

  const openedSectionsCount = store.ui.sidebar.rightBarOpenedSectionsCount();
  const isKeyStatisticsOpened =
    store.ui.sidebar.isRightBarKeyStatisticsOpened();
  const isTradingCentralOpened =
    store.ui.sidebar.isRightBarTradingCentralOpened();

  return (
    <Section $openedSectionsCount={openedSectionsCount}>
      <Styled.Root>
        <Styled.Header>
          <div>
            {isKeyStatisticsOpened && translate('COMMON.LABELS.KEY_STATISTICS')}
            {isTradingCentralOpened &&
              translate('COMMON.LABELS.TRADING_CENTRAL')}
          </div>
          <IconButton
            iconType='remove'
            onClick={() => store.ui.sidebar.closeRightBarInfoSection()}
          />
        </Styled.Header>
        <Styled.Content>
          {isKeyStatisticsOpened && <KeyStatistics />}
          {isTradingCentralOpened && <TradingCentral />}
        </Styled.Content>
      </Styled.Root>
    </Section>
  );
});
