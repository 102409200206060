import { getIconBaseProps } from './utils';

export const arrowRight = {
  ...getIconBaseProps('arrowRight'),
  width: '8px',
  height: '13px',
  viewBox: '0 0 8 13',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1 0.857142L7 6.42857L1 12'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
