import { IConditionsTypes, useNavigation } from '@trader/hooks';
import { TTradingAccountEntity, useMst } from '@trader/store';

import { TApplyCondition } from './index';
import { EAccountTypeNames } from '@trader/constants';

export const useWholeBusinessLogic = (
  conditions: IConditionsTypes
): TApplyCondition => {
  const store = useMst();
  const { navigateTo } = useNavigation();

  const isDemoInUse = store.user.isDemoAccInUse();
  const demoAccount = store.entities.tradingAccounts
    .getAll<TTradingAccountEntity>()
    .find(acc => acc.accountType === EAccountTypeNames.Demo);

  const {
    isRegistrationCompleted,
    isScoreLevelLow,
    isTradingAllowed,
    isDepositAllowed,
  } = conditions;

  return () => {
    if (isRegistrationCompleted) {
      return {
        status: isDemoInUse ? 'passed' : 'failed',
        action: () => {
          window.open(import.meta.env.VITE_REGISTRATION_LINK);
        },
      };
    }

    if (isScoreLevelLow) {
      !isDemoInUse &&
        store.entities.tradingAccounts.changeTradingAccountAsync.run({
          tradingId: demoAccount?.tradingId || 0,
          navigation: navigateTo,
        });
      return {
        status: 'failed',
      };
    }

    if (isTradingAllowed || isDepositAllowed) {
      return {
        status: 'failed',
        action: () => {
          window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        },
      };
    }

    return {
      status: 'passed',
    };
  };
};
