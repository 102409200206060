export const baseStyles = {
  histogramBase: 0,
  isHidden: true,
} as const;

export const baseDefaultsStyles = {
  linestyle: 0,
  visible: false,
  linewidth: 1,
  plottype: 2,
  trackPrice: false,
} as const;
