import { schema } from 'normalizr';

const positionHistory = new schema.Entity(
  'positionsHistory',
  {},
  {
    idAttribute: 'positionId',
  }
);

export const positionsHistorySchema = [positionHistory];
