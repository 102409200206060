import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;
