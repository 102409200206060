import { useEffect } from 'react';

import { getAccountTypeForConnection, productId } from '@trader/constants';
import { IMessage } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useGetPageVisibility } from '../useGetPageVisibility';
import { useHub } from './core/useHub';
import { formatByPipSize, getSpreadDifference } from '@trader/utils';

export const useLayouts = (symbol: string) => {
  const store = useMst();
  const isVisible = useGetPageVisibility();

  const idToken = store.auth.tokens.idToken;
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const handleSubscribe = () => {
    if (symbol && platformLogin && accountType) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnQuote',
          symbol,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });
    }
  };

  const handleUnsubscribe = () => {
    if (platformLogin && accountType) {
      unsubscribe(async connection => {
        await connection?.send(
          'UnsubscribeFromQuote',
          symbol,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });
    }
  };

  const handleUpdatePrice = (message: IMessage) => {
    const currentInstrument = store.entities.instruments.get<TInstrumentEntity>(
      message.s
    );
    const { ask, bid } = getSpreadDifference(
      currentInstrument.spreadDiff,
      message.a,
      message.b
    );
    if (ask !== currentInstrument.ask) {
      store.entities.instruments.update(message.s, {
        updateAskType:
          formatByPipSize(ask, currentInstrument.pipSize) >
          formatByPipSize(currentInstrument.ask, currentInstrument.pipSize)
            ? 'up'
            : 'down',
        ask: +formatByPipSize(ask, currentInstrument.pipSize),
      });
    }

    if (bid !== currentInstrument.bid) {
      store.entities.instruments.update(message.s, {
        updateBidType:
          formatByPipSize(bid, currentInstrument.pipSize) >
          formatByPipSize(currentInstrument.bid, currentInstrument.pipSize)
            ? 'up'
            : 'down',
        bid: +formatByPipSize(bid, currentInstrument.pipSize),
      });
    }
  };

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: EConnectionHub.Layouts,
    subscriber: EConnectionHubSubscriber.Layouts,
    onMessage: handleUpdatePrice,
    invokedName: 'OnQuote',
  });

  useEffect(() => {
    symbol && handleSubscribe();
    return () => handleUnsubscribe();
  }, [symbol, platformLogin, idToken]);

  useEffect(() => {
    if (isVisible) {
      handleSubscribe();
    } else {
      handleUnsubscribe();
    }
  }, [isVisible]);
};
