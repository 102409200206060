import styled, { css } from 'styled-components';

import { Icon, Typography, Wrapper } from '@trader/components';

export const NotificationRoot = styled(Wrapper)`
  ${({ theme }) => css`
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #00000026;
    position: relative;
    padding: 14px;
    background: ${theme.palette.green[600]};
    width: 305px;
    min-height: 45px;
  `};
`;

export const Close = styled(Icon)`
  ${() => css`
    position: absolute;
    top: 9px;
    right: 9px;
  `};
`;

export const Content = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    p:nth-child(3) {
      border-top: 1px solid ${theme.palette.background.default};
      margin-top: 5px;
      padding-top: 5px;
    }
  `};
`;

export const SuccessIcon = styled(Icon)`
  ${() => css`
    margin-right: 8px;
    width: 20px;
    height: 20px;
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 700;
  `};
`;

export const Text = styled(Typography)<{ $plColor: string }>`
  ${({ $plColor }) => css`
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    display: flex;
    justify-content: space-between;

    span {
      color: ${$plColor};
    }
  `};
`;
