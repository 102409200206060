import { IContext, IPineSeries, PineJS } from '../charting_library';

interface IGetType {
  context: IContext;
  source: IPineSeries;
  Pine: PineJS;
}

export const getType = ({ context, Pine, source }: IGetType) => {
  const result = {
    alma: (length: number) => Pine.Std.alma(source, length, 1, 1),
    ema: (length: number) => Pine.Std.ema(source, length, context),
    sma: (length: number) => Pine.Std.sma(source, length, context),
    swma: () => Pine.Std.swma(source, context),
    vwma: (length: number) => Pine.Std.vwma(source, length, context),
    wma: (length: number) => Pine.Std.wma(source, length, context),
    linreg: (length: number) => Pine.Std.linreg(source, length, 1),
    high: () => Pine.Std.high(context),
    low: () => Pine.Std.low(context),
    percentrank: (length: number) => Pine.Std.percentrank(source, length),
  };

  return result;
};

export type TGetTypeResult = ReturnType<typeof getType>;
