import { TPlaceOrderSide } from '@trader/types';
import { defaultPipSize } from '@trader/constants';
import {
  getBuyLossRateWithAmplitude,
  getBuyProfitRateWithAmplitude,
} from './takeProfit';
import {
  getSellLossRateWithAmplitude,
  getSellProfitRateWithAmplitude,
} from './stopLoss';

const maxTakeProfitRateFallback = 999999999.99;

export const getMinTakeProfitRate = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  side: TPlaceOrderSide,
  bidPrice: number | string | undefined,
  formattingPipSize: number
) => {
  return side === 'Buy'
    ? getBuyProfitRateWithAmplitude(
        stopsLevel,
        tickSize,
        pipSize,
        bidPrice,
        formattingPipSize,
        undefined
      )
    : +(pipSize ?? defaultPipSize);
};

export const getMaxTakeProfitRate = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  side: TPlaceOrderSide,
  askPrice: number | string | undefined,
  formattingPipSize: number
) => {
  return side === 'Buy'
    ? maxTakeProfitRateFallback
    : getSellProfitRateWithAmplitude(
        stopsLevel,
        tickSize,
        pipSize,
        askPrice,
        formattingPipSize,
        undefined
      );
};

export const getMinStopLoss = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  side: TPlaceOrderSide,
  askPrice: number | string | undefined,
  formattingPipSize: number
) => {
  return side === 'Buy'
    ? +(pipSize ?? defaultPipSize)
    : getSellLossRateWithAmplitude(
        stopsLevel,
        tickSize,
        pipSize ?? defaultPipSize,
        askPrice,
        formattingPipSize ?? defaultPipSize,
        undefined
      );
};

export const getMaxStopLoss = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  side: TPlaceOrderSide,
  bidPrice: number | string | undefined,
  formattingPipSize?: number
) => {
  return side === 'Buy'
    ? getBuyLossRateWithAmplitude(
        stopsLevel,
        tickSize,
        pipSize,
        bidPrice,
        formattingPipSize ?? pipSize,
        undefined
      )
    : maxTakeProfitRateFallback;
};
