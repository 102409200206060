import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';
import { useMst } from '@trader/store';

interface IUnauthorizedOnlyContainer {
  element: React.ReactElement;
}

export const UnauthorizedOnlyContainer: React.FC<IUnauthorizedOnlyContainer> =
  observer(({ element }) => {
    const store = useMst();
    const { navigateTo } = useNavigation();
    const location = useLocation();

    // Keep this for add check did the userIdentities is confirmed in system
    // if no (but it already register) - go home for example
    // if yes (he can't see login page)

    useEffect(() => {
      if (store.auth.isAuth === true) {
        navigateTo(NAVIGATE_TO.main.dashboard);
      }
      if (location.pathname === NAVIGATE_TO.auth.root) {
        navigateTo(NAVIGATE_TO.auth.signIn);
      }
    }, [store.auth.isAuth, location.pathname]);

    return store.auth.isAuth === false ? element : null;
  });
