import { getIconBaseProps } from './utils';

export const capitalmarketsLogo = {
  ...getIconBaseProps('capitalmarketsLogo'),
  width: '187px',
  height: '35px',
  viewBox: '0 0 187 35',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 15.4006L8.62797 6.52116H25.8292V0.176758H6.17334L0 6.52116V15.4006Z'
        fill='#0271F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 24.3024L12.4818 11.8086L16.9241 16.256L21.4758 11.8086L25.8292 16.256L16.9241 25.1166L12.4818 20.6966L0 33.0193V24.3024Z'
        fill='#0271F5'
      />
      <path
        d='M35.2151 0.936157H47.709V4.26263H39.013V9.16066H46.7923V12.4609H39.013V20.1092H35.2151V0.936157Z'
        fill='#0271F5'
      />
      <path
        d='M57.2682 6.20105C57.792 6.20105 58.281 6.27963 58.735 6.43678C59.189 6.59394 59.5382 6.79475 59.7827 7.03922L58.3683 9.84183C57.9143 9.52752 57.3991 9.37036 56.8229 9.37036C55.88 9.37036 55.1247 9.76325 54.5572 10.549C53.9897 11.3348 53.706 12.4174 53.706 13.7969V20.1094H50.0652V6.54155H53.2607C53.348 7.58926 53.4266 8.3052 53.4964 8.68935C53.898 7.86865 54.4219 7.24876 55.068 6.82968C55.7141 6.41059 56.4475 6.20105 57.2682 6.20105Z'
        fill='#0271F5'
      />
      <path
        d='M60.5432 13.3255C60.5432 11.9111 60.8619 10.6625 61.4992 9.57991C62.1366 8.49728 62.9879 7.66348 64.053 7.07851C65.1182 6.49354 66.2707 6.20105 67.5105 6.20105C68.7503 6.20105 69.8722 6.49354 70.9286 7.07851C71.985 7.66348 72.8319 8.49728 73.4693 9.57991C74.1067 10.6625 74.4253 11.9111 74.4253 13.3255C74.4253 14.7399 74.1067 15.9884 73.4693 17.071C72.8319 18.1536 71.985 18.9831 70.9286 19.5593C69.8722 20.1356 68.7328 20.4237 67.5105 20.4237C66.2881 20.4237 65.1182 20.1356 64.053 19.5593C62.9879 18.9831 62.1366 18.1536 61.4992 17.071C60.8619 15.9884 60.5432 14.7399 60.5432 13.3255ZM64.315 13.3255C64.315 14.5303 64.6162 15.4864 65.2186 16.1936C65.821 16.9008 66.5763 17.2544 67.4843 17.2544C68.3923 17.2544 69.1431 16.9008 69.7368 16.1936C70.3305 15.4864 70.6274 14.5303 70.6274 13.3255C70.6274 12.1206 70.3305 11.1602 69.7368 10.4443C69.1431 9.72833 68.3923 9.37036 67.4843 9.37036C66.5763 9.37036 65.821 9.72833 65.2186 10.4443C64.6162 11.1602 64.315 12.1206 64.315 13.3255Z'
        fill='#0271F5'
      />
      <path
        d='M84.8493 6.20105C86.351 6.20105 87.5384 6.69434 88.4115 7.68094C89.2846 8.66753 89.7211 10.1038 89.7211 11.9896V20.1094H86.0803V13.1683C86.0803 10.6887 85.2596 9.44894 83.6182 9.44894C82.7451 9.44894 82.0423 9.78508 81.5097 10.4574C80.9771 11.1296 80.7108 12.0769 80.7108 13.2993V20.1094H77.0701V6.54155H80.2656C80.3529 7.60672 80.4315 8.33139 80.5013 8.71555C81.3744 7.03922 82.8237 6.20105 84.8493 6.20105Z'
        fill='#0271F5'
      />
      <path
        d='M102.32 19.3234C101.43 20.0568 100.19 20.4235 98.6007 20.4235C97.1339 20.4235 95.9509 20.0349 95.0516 19.2579C94.1523 18.4808 93.7027 17.0883 93.7027 15.0802V9.60589H91.293V6.54134H93.7027V2.37671H97.3435V6.54134H101.43V9.60589H97.3435V14.4777C97.3435 15.3857 97.5006 16.0755 97.8149 16.5469C98.1293 17.0184 98.6356 17.2542 99.3341 17.2542C99.9453 17.2542 100.522 17.0708 101.063 16.7041L102.32 19.3234Z'
        fill='#0271F5'
      />
      <path
        d='M111.724 6.20107C112.824 6.20107 113.841 6.48046 114.775 7.03924C115.71 7.59802 116.452 8.41872 117.002 9.50135C117.552 10.584 117.827 11.8587 117.827 13.3255C117.827 14.7923 117.552 16.0626 117.002 17.1365C116.452 18.2104 115.71 19.0268 114.775 19.5855C113.841 20.1443 112.824 20.4237 111.724 20.4237C110.816 20.4237 109.982 20.2316 109.223 19.8475C108.463 19.4633 107.847 18.8696 107.376 18.0664C107.254 19.0617 107.184 19.7427 107.166 20.1094H103.971V0.176758H107.612V8.2441C108.101 7.56309 108.699 7.05234 109.406 6.71183C110.113 6.37133 110.886 6.20107 111.724 6.20107ZM110.886 17.3068C111.829 17.3068 112.593 16.9488 113.178 16.2329C113.763 15.5169 114.055 14.5478 114.055 13.3255C114.055 12.1032 113.767 11.1515 113.191 10.4705C112.615 9.78947 111.846 9.44897 110.886 9.44897C109.925 9.44897 109.183 9.76764 108.555 10.405C107.926 11.0424 107.612 12.0158 107.612 13.3255C107.612 14.6351 107.93 15.6261 108.568 16.2984C109.205 16.9706 109.978 17.3068 110.886 17.3068Z'
        fill='#0271F5'
      />
      <path
        d='M127.674 6.20105C128.198 6.20105 128.687 6.27963 129.141 6.43678C129.595 6.59394 129.944 6.79475 130.189 7.03922L128.774 9.84183C128.32 9.52752 127.805 9.37036 127.229 9.37036C126.286 9.37036 125.531 9.76325 124.963 10.549C124.396 11.3348 124.112 12.4174 124.112 13.7969V20.1094H120.471V6.54155H123.667C123.754 7.58926 123.833 8.3052 123.902 8.68935C124.304 7.86865 124.828 7.24876 125.474 6.82968C126.12 6.41059 126.853 6.20105 127.674 6.20105Z'
        fill='#0271F5'
      />
      <path
        d='M130.949 13.3255C130.949 11.9111 131.267 10.6625 131.905 9.57991C132.542 8.49728 133.393 7.66348 134.459 7.07851C135.524 6.49354 136.676 6.20105 137.916 6.20105C139.156 6.20105 140.278 6.49354 141.334 7.07851C142.391 7.66348 143.237 8.49728 143.875 9.57991C144.512 10.6625 144.831 11.9111 144.831 13.3255C144.831 14.7399 144.512 15.9884 143.875 17.071C143.237 18.1536 142.391 18.9831 141.334 19.5593C140.278 20.1356 139.138 20.4237 137.916 20.4237C136.694 20.4237 135.524 20.1356 134.459 19.5593C133.393 18.9831 132.542 18.1536 131.905 17.071C131.267 15.9884 130.949 14.7399 130.949 13.3255ZM134.72 13.3255C134.72 14.5303 135.022 15.4864 135.624 16.1936C136.227 16.9008 136.982 17.2544 137.89 17.2544C138.798 17.2544 139.549 16.9008 140.142 16.1936C140.736 15.4864 141.033 14.5303 141.033 13.3255C141.033 12.1206 140.736 11.1602 140.142 10.4443C139.549 9.72833 138.798 9.37036 137.89 9.37036C136.982 9.37036 136.227 9.72833 135.624 10.4443C135.022 11.1602 134.72 12.1206 134.72 13.3255Z'
        fill='#0271F5'
      />
      <path
        d='M155.622 11.9635L161.097 20.1094H156.723L153.16 14.7137L151.117 16.9663V20.1094H147.477V0.176758H151.117V12.5397L156.277 6.54158H160.494L155.622 11.9635Z'
        fill='#0271F5'
      />
      <path
        d='M168.718 6.20105C169.922 6.20105 170.992 6.46734 171.926 6.99993C172.86 7.53251 173.585 8.28337 174.1 9.2525C174.615 10.2216 174.873 11.3348 174.873 12.5921C174.873 13.1508 174.82 13.7183 174.716 14.2946H165.705C165.863 15.3074 166.229 16.0713 166.806 16.5864C167.382 17.1016 168.098 17.3591 168.953 17.3591C169.564 17.3591 170.11 17.2238 170.59 16.9531C171.071 16.6825 171.442 16.2765 171.704 15.7352L174.375 17.3067C174.008 18.3021 173.306 19.0704 172.267 19.6117C171.228 20.153 170.071 20.4237 168.796 20.4237C167.521 20.4237 166.439 20.153 165.391 19.6117C164.343 19.0704 163.501 18.2584 162.864 17.1758C162.226 16.0932 161.907 14.7922 161.907 13.2731C161.907 11.7539 162.226 10.4879 162.864 9.42275C163.501 8.35758 164.335 7.55434 165.365 7.01302C166.395 6.47171 167.513 6.20105 168.718 6.20105ZM168.613 9.16082C167.897 9.16082 167.286 9.38783 166.779 9.84183C166.273 10.2958 165.932 10.9332 165.758 11.7539H171.153C171.084 10.9157 170.817 10.274 170.355 9.82874C169.892 9.38346 169.311 9.16082 168.613 9.16082Z'
        fill='#0271F5'
      />
      <path
        d='M184.485 6.20105C185.009 6.20105 185.498 6.27963 185.952 6.43678C186.406 6.59394 186.755 6.79475 187 7.03922L185.585 9.84183C185.131 9.52752 184.616 9.37036 184.04 9.37036C183.097 9.37036 182.342 9.76325 181.774 10.549C181.207 11.3348 180.923 12.4174 180.923 13.7969V20.1094H177.282V6.54155H180.478C180.565 7.58926 180.644 8.3052 180.713 8.68935C181.115 7.86865 181.639 7.24876 182.285 6.82968C182.931 6.41059 183.665 6.20105 184.485 6.20105Z'
        fill='#0271F5'
      />
      <path
        d='M181.401 30.7548C181.401 31.0789 181.324 31.3532 181.171 31.5777C181.02 31.7994 180.794 31.9685 180.493 32.0851C180.192 32.1988 179.816 32.2556 179.367 32.2556C179.035 32.2556 178.749 32.2343 178.51 32.1917C178.274 32.149 178.036 32.078 177.794 31.9785V30.9041C178.053 31.0206 178.33 31.1172 178.625 31.194C178.924 31.2679 179.185 31.3048 179.41 31.3048C179.663 31.3048 179.843 31.2679 179.951 31.194C180.062 31.1172 180.118 31.0178 180.118 30.8955C180.118 30.8159 180.095 30.7449 180.049 30.6823C180.007 30.617 179.913 30.5445 179.768 30.4649C179.623 30.3825 179.396 30.2759 179.086 30.1451C178.787 30.0201 178.541 29.8936 178.348 29.7657C178.158 29.6378 178.016 29.4871 177.922 29.3137C177.831 29.1375 177.785 28.9144 177.785 28.6443C177.785 28.2038 177.956 27.8726 178.297 27.6509C178.641 27.4264 179.1 27.3141 179.674 27.3141C179.97 27.3141 180.251 27.3439 180.518 27.4036C180.788 27.4633 181.066 27.5585 181.35 27.6893L180.958 28.6273C180.722 28.525 180.498 28.4411 180.288 28.3757C180.081 28.3104 179.869 28.2777 179.653 28.2777C179.462 28.2777 179.319 28.3032 179.222 28.3544C179.126 28.4056 179.077 28.4837 179.077 28.5889C179.077 28.6657 179.101 28.7339 179.15 28.7936C179.201 28.8533 179.298 28.9201 179.44 28.994C179.585 29.065 179.796 29.1574 180.075 29.2711C180.345 29.3819 180.579 29.4985 180.778 29.6207C180.977 29.7401 181.131 29.8893 181.239 30.0684C181.347 30.2446 181.401 30.4734 181.401 30.7548Z'
        fill='currentColor'
      />
      <path
        d='M176.289 31.2196C176.431 31.2196 176.569 31.2054 176.702 31.177C176.839 31.1485 176.974 31.113 177.107 31.0704V32.0382C176.968 32.1008 176.794 32.1519 176.587 32.1917C176.382 32.2343 176.158 32.2557 175.913 32.2557C175.629 32.2557 175.373 32.2102 175.146 32.1192C174.921 32.0254 174.744 31.8648 174.613 31.6374C174.485 31.4072 174.421 31.0874 174.421 30.6781V28.38H173.799V27.83L174.515 27.3951L174.89 26.3889H175.721V27.4037H177.056V28.38H175.721V30.6781C175.721 30.86 175.773 30.9965 175.875 31.0874C175.98 31.1755 176.118 31.2196 176.289 31.2196Z'
        fill='currentColor'
      />
      <path
        d='M171.087 27.3141C171.528 27.3141 171.907 27.3994 172.225 27.5699C172.544 27.7376 172.79 27.9821 172.963 28.3032C173.136 28.6244 173.223 29.0167 173.223 29.48V30.111H170.149C170.163 30.4777 170.273 30.7662 170.477 30.9765C170.685 31.184 170.972 31.2878 171.339 31.2878C171.643 31.2878 171.921 31.2565 172.174 31.194C172.427 31.1315 172.687 31.0377 172.954 30.9126V31.9188C172.719 32.0353 172.471 32.1206 172.213 32.1746C171.957 32.2286 171.646 32.2556 171.279 32.2556C170.801 32.2556 170.378 32.1675 170.008 31.9913C169.642 31.8151 169.353 31.5464 169.143 31.1855C168.935 30.8245 168.832 30.3697 168.832 29.8211C168.832 29.264 168.925 28.8007 169.113 28.4312C169.303 28.0588 169.568 27.7802 169.906 27.5955C170.244 27.4079 170.638 27.3141 171.087 27.3141ZM171.096 28.2393C170.843 28.2393 170.632 28.3203 170.465 28.4823C170.3 28.6443 170.204 28.8987 170.179 29.2455H172.004C172.001 29.0522 171.965 28.8803 171.897 28.7296C171.832 28.579 171.732 28.4596 171.599 28.3715C171.468 28.2834 171.3 28.2393 171.096 28.2393Z'
        fill='currentColor'
      />
      <path
        d='M165.007 25.5361V28.5036C165.007 28.6827 165 28.8618 164.986 29.0408C164.972 29.2199 164.956 29.399 164.939 29.578H164.956C165.044 29.453 165.134 29.3293 165.225 29.2071C165.319 29.0849 165.418 28.9669 165.523 28.8532L166.858 27.4036H168.325L166.431 29.4715L168.44 32.1703H166.939L165.566 30.2389L165.007 30.6866V32.1703H163.707V25.5361H165.007Z'
        fill='currentColor'
      />
      <path
        d='M162.402 27.3141C162.467 27.3141 162.543 27.3184 162.628 27.3269C162.716 27.3326 162.787 27.3411 162.841 27.3525L162.743 28.5719C162.7 28.5576 162.639 28.5477 162.56 28.542C162.483 28.5335 162.416 28.5292 162.359 28.5292C162.192 28.5292 162.028 28.5505 161.869 28.5932C161.713 28.6358 161.572 28.7054 161.447 28.8021C161.322 28.8959 161.222 29.021 161.148 29.1773C161.077 29.3308 161.042 29.5198 161.042 29.7444V32.1704H159.741V27.4036H160.726L160.918 28.2052H160.982C161.076 28.0432 161.193 27.8954 161.332 27.7618C161.474 27.6253 161.635 27.5173 161.814 27.4377C161.995 27.3553 162.192 27.3141 162.402 27.3141Z'
        fill='currentColor'
      />
      <path
        d='M156.416 27.3055C157.055 27.3055 157.546 27.4448 157.887 27.7234C158.228 28.0019 158.399 28.4255 158.399 28.9939V32.1703H157.49L157.239 31.5223H157.205C157.068 31.6928 156.929 31.8321 156.787 31.9401C156.645 32.0481 156.481 32.1277 156.297 32.1789C156.112 32.23 155.887 32.2556 155.623 32.2556C155.342 32.2556 155.089 32.2016 154.864 32.0936C154.642 31.9856 154.467 31.8207 154.34 31.599C154.212 31.3745 154.148 31.0902 154.148 30.7463C154.148 30.2403 154.325 29.868 154.681 29.6292C155.036 29.3876 155.569 29.254 156.28 29.2284L157.107 29.2029V28.9939C157.107 28.7438 157.041 28.5605 156.911 28.4439C156.78 28.3274 156.598 28.2691 156.365 28.2691C156.135 28.2691 155.909 28.3018 155.687 28.3672C155.465 28.4326 155.243 28.515 155.022 28.6145L154.591 27.7362C154.844 27.6026 155.127 27.4974 155.44 27.4207C155.755 27.3439 156.081 27.3055 156.416 27.3055ZM157.107 29.9618L156.604 29.9788C156.183 29.9902 155.89 30.0655 155.725 30.2048C155.563 30.3441 155.482 30.5274 155.482 30.7548C155.482 30.9538 155.54 31.0959 155.657 31.1812C155.774 31.2636 155.926 31.3048 156.113 31.3048C156.392 31.3048 156.626 31.2224 156.817 31.0575C157.01 30.8927 157.107 30.6582 157.107 30.354V29.9618Z'
        fill='currentColor'
      />
      <path
        d='M148.968 32.1703L147.467 27.2799H147.428C147.434 27.3965 147.443 27.5727 147.454 27.8086C147.468 28.0417 147.481 28.2904 147.492 28.5548C147.504 28.8191 147.509 29.0579 147.509 29.271V32.1703H146.328V25.9369H148.128L149.603 30.7036H149.628L151.193 25.9369H152.992V32.1703H151.76V29.2199C151.76 29.0238 151.763 28.7978 151.769 28.542C151.777 28.2861 151.787 28.0431 151.799 27.8129C151.81 27.5798 151.818 27.405 151.824 27.2885H151.786L150.178 32.1703H148.968Z'
        fill='currentColor'
      />
      <path
        d='M142.589 32.1703H141.289V25.5361H142.589V32.1703Z'
        fill='currentColor'
      />
      <path
        d='M137.963 27.3055C138.603 27.3055 139.093 27.4448 139.434 27.7234C139.775 28.0019 139.946 28.4255 139.946 28.9939V32.1703H139.038L138.786 31.5223H138.752C138.615 31.6928 138.476 31.8321 138.334 31.9401C138.192 32.0481 138.028 32.1277 137.844 32.1789C137.659 32.23 137.434 32.2556 137.17 32.2556C136.889 32.2556 136.636 32.2016 136.411 32.0936C136.189 31.9856 136.015 31.8207 135.887 31.599C135.759 31.3745 135.695 31.0902 135.695 30.7463C135.695 30.2403 135.872 29.868 136.228 29.6292C136.583 29.3876 137.116 29.254 137.827 29.2284L138.654 29.2029V28.9939C138.654 28.7438 138.588 28.5605 138.458 28.4439C138.327 28.3274 138.145 28.2691 137.912 28.2691C137.682 28.2691 137.456 28.3018 137.234 28.3672C137.012 28.4326 136.791 28.515 136.569 28.6145L136.138 27.7362C136.391 27.6026 136.674 27.4974 136.987 27.4207C137.302 27.3439 137.628 27.3055 137.963 27.3055ZM138.654 29.9618L138.151 29.9788C137.73 29.9902 137.437 30.0655 137.272 30.2048C137.11 30.3441 137.029 30.5274 137.029 30.7548C137.029 30.9538 137.088 31.0959 137.204 31.1812C137.321 31.2636 137.473 31.3048 137.66 31.3048C137.939 31.3048 138.173 31.2224 138.364 31.0575C138.557 30.8927 138.654 30.6582 138.654 30.354V29.9618Z'
        fill='currentColor'
      />
      <path
        d='M134.224 31.2196C134.366 31.2196 134.504 31.2054 134.638 31.177C134.774 31.1485 134.909 31.113 135.043 31.0704V32.0382C134.903 32.1008 134.73 32.1519 134.523 32.1917C134.318 32.2343 134.093 32.2557 133.849 32.2557C133.565 32.2557 133.309 32.2102 133.081 32.1192C132.857 32.0254 132.679 31.8648 132.548 31.6374C132.421 31.4072 132.357 31.0874 132.357 30.6781V28.38H131.734V27.83L132.45 27.3951L132.826 26.3889H133.657V27.4037H134.992V28.38H133.657V30.6781C133.657 30.86 133.708 30.9965 133.811 31.0874C133.916 31.1755 134.054 31.2196 134.224 31.2196Z'
        fill='currentColor'
      />
      <path
        d='M130.848 27.4036V32.1703H129.547V27.4036H130.848ZM130.199 25.5361C130.393 25.5361 130.559 25.5816 130.698 25.6726C130.838 25.7607 130.907 25.927 130.907 26.1714C130.907 26.413 130.838 26.5807 130.698 26.6745C130.559 26.7655 130.393 26.811 130.199 26.811C130.003 26.811 129.836 26.7655 129.696 26.6745C129.56 26.5807 129.492 26.413 129.492 26.1714C129.492 25.927 129.56 25.7607 129.696 25.6726C129.836 25.5816 130.003 25.5361 130.199 25.5361Z'
        fill='currentColor'
      />
      <path
        d='M126.673 27.3141C127.21 27.3141 127.644 27.523 127.974 27.9408C128.306 28.3587 128.472 28.9712 128.472 29.7785C128.472 30.3185 128.394 30.7733 128.238 31.1428C128.082 31.5095 127.866 31.7866 127.59 31.9742C127.314 32.1618 126.997 32.2556 126.639 32.2556C126.409 32.2556 126.211 32.2272 126.046 32.1704C125.882 32.1107 125.741 32.0353 125.624 31.9444C125.508 31.8506 125.407 31.7511 125.322 31.6459H125.253C125.276 31.7596 125.293 31.8762 125.305 31.9955C125.316 32.1149 125.322 32.2315 125.322 32.3452V34.2681H124.021V27.4036H125.079L125.262 28.0218H125.322C125.407 27.8939 125.511 27.776 125.633 27.668C125.755 27.56 125.901 27.4747 126.072 27.4122C126.245 27.3468 126.446 27.3141 126.673 27.3141ZM126.255 28.3544C126.028 28.3544 125.847 28.4013 125.714 28.4951C125.58 28.5889 125.482 28.7296 125.42 28.9172C125.36 29.1048 125.327 29.3422 125.322 29.6292V29.7699C125.322 30.0769 125.35 30.337 125.407 30.5502C125.467 30.7634 125.565 30.9254 125.701 31.0362C125.84 31.1471 126.031 31.2025 126.272 31.2025C126.471 31.2025 126.635 31.1471 126.763 31.0362C126.891 30.9254 126.986 30.7634 127.048 30.5502C127.114 30.3342 127.146 30.0712 127.146 29.7614C127.146 29.2953 127.074 28.9442 126.929 28.7083C126.784 28.4724 126.56 28.3544 126.255 28.3544Z'
        fill='currentColor'
      />
      <path
        d='M120.696 27.3055C121.335 27.3055 121.826 27.4448 122.167 27.7234C122.508 28.0019 122.678 28.4255 122.678 28.9939V32.1703H121.77L121.519 31.5223H121.485C121.348 31.6928 121.209 31.8321 121.067 31.9401C120.925 32.0481 120.761 32.1277 120.576 32.1789C120.392 32.23 120.167 32.2556 119.903 32.2556C119.621 32.2556 119.368 32.2016 119.144 32.0936C118.922 31.9856 118.747 31.8207 118.619 31.599C118.491 31.3745 118.427 31.0902 118.427 30.7463C118.427 30.2403 118.605 29.868 118.96 29.6292C119.316 29.3876 119.849 29.254 120.559 29.2284L121.386 29.2029V28.9939C121.386 28.7438 121.321 28.5605 121.19 28.4439C121.06 28.3274 120.878 28.2691 120.645 28.2691C120.414 28.2691 120.188 28.3018 119.967 28.3672C119.745 28.4326 119.523 28.515 119.302 28.6145L118.871 27.7362C119.124 27.6026 119.407 27.4974 119.719 27.4207C120.035 27.3439 120.36 27.3055 120.696 27.3055ZM121.386 29.9618L120.883 29.9788C120.463 29.9902 120.17 30.0655 120.005 30.2048C119.843 30.3441 119.762 30.5274 119.762 30.7548C119.762 30.9538 119.82 31.0959 119.937 31.1812C120.053 31.2636 120.205 31.3048 120.393 31.3048C120.672 31.3048 120.906 31.2224 121.097 31.0575C121.29 30.8927 121.386 30.6582 121.386 30.354V29.9618Z'
        fill='currentColor'
      />
      <path
        d='M115.924 26.9474C115.671 26.9474 115.447 26.9972 115.251 27.0967C115.057 27.1933 114.894 27.334 114.76 27.5188C114.63 27.7035 114.53 27.9266 114.462 28.1881C114.394 28.4496 114.36 28.7438 114.36 29.0707C114.36 29.5113 114.414 29.8879 114.522 30.2006C114.632 30.5104 114.803 30.7477 115.033 30.9126C115.263 31.0746 115.561 31.1556 115.924 31.1556C116.177 31.1556 116.43 31.1272 116.683 31.0704C116.939 31.0135 117.216 30.9325 117.515 30.8273V31.9359C117.239 32.0496 116.968 32.1306 116.7 32.1789C116.433 32.2301 116.133 32.2556 115.801 32.2556C115.158 32.2556 114.63 32.1235 114.215 31.8591C113.802 31.5919 113.497 31.2196 113.298 30.7421C113.099 30.2617 113 29.7017 113 29.0622C113 28.5903 113.063 28.1583 113.191 27.766C113.319 27.3738 113.507 27.0341 113.754 26.747C114.001 26.46 114.307 26.2382 114.671 26.0819C115.035 25.9256 115.453 25.8474 115.924 25.8474C116.234 25.8474 116.544 25.8872 116.854 25.9668C117.166 26.0435 117.465 26.1501 117.749 26.2866L117.323 27.361C117.09 27.2501 116.855 27.1535 116.619 27.0711C116.383 26.9886 116.152 26.9474 115.924 26.9474Z'
        fill='currentColor'
      />
      <path
        d='M107.712 27.3141C108.153 27.3141 108.533 27.3994 108.851 27.5699C109.169 27.7376 109.415 27.9821 109.588 28.3032C109.762 28.6244 109.849 29.0167 109.849 29.48V30.111H106.774C106.789 30.4777 106.898 30.7662 107.103 30.9765C107.31 31.184 107.597 31.2878 107.964 31.2878C108.268 31.2878 108.547 31.2565 108.8 31.194C109.053 31.1315 109.313 31.0377 109.58 30.9126V31.9188C109.344 32.0353 109.097 32.1206 108.838 32.1746C108.582 32.2286 108.271 32.2556 107.904 32.2556C107.427 32.2556 107.003 32.1675 106.634 31.9913C106.267 31.8151 105.979 31.5464 105.768 31.1855C105.561 30.8245 105.457 30.3697 105.457 29.8211C105.457 29.264 105.551 28.8007 105.738 28.4312C105.929 28.0588 106.193 27.7802 106.531 27.5955C106.87 27.4079 107.263 27.3141 107.712 27.3141ZM107.721 28.2393C107.468 28.2393 107.258 28.3203 107.09 28.4823C106.925 28.6443 106.83 28.8987 106.804 29.2455H108.629C108.626 29.0522 108.591 28.8803 108.523 28.7296C108.457 28.579 108.358 28.4596 108.224 28.3715C108.093 28.2834 107.926 28.2393 107.721 28.2393Z'
        fill='currentColor'
      />
      <path
        d='M101.731 32.2556C101.199 32.2556 100.766 32.0481 100.43 31.6331C100.098 31.2153 99.9316 30.6027 99.9316 29.7955C99.9316 28.9797 100.101 28.3629 100.439 27.9451C100.777 27.5244 101.219 27.3141 101.765 27.3141C101.992 27.3141 102.193 27.3453 102.366 27.4079C102.54 27.4704 102.689 27.5542 102.814 27.6594C102.942 27.7646 103.05 27.8825 103.138 28.0133H103.181C103.163 27.9223 103.142 27.7887 103.117 27.6125C103.094 27.4334 103.082 27.2501 103.082 27.0625V25.5361H104.387V32.1703H103.389L103.138 31.5521H103.082C103 31.6829 102.896 31.8022 102.771 31.9103C102.649 32.0154 102.503 32.0993 102.332 32.1618C102.162 32.2243 101.961 32.2556 101.731 32.2556ZM102.187 31.2195C102.542 31.2195 102.793 31.1144 102.937 30.904C103.085 30.6909 103.163 30.3711 103.172 29.9447V29.804C103.172 29.3407 103.101 28.9868 102.959 28.7424C102.817 28.4951 102.552 28.3714 102.166 28.3714C101.879 28.3714 101.654 28.4951 101.492 28.7424C101.33 28.9897 101.249 29.3464 101.249 29.8125C101.249 30.2787 101.33 30.6297 101.492 30.8657C101.657 31.1016 101.889 31.2195 102.187 31.2195Z'
        fill='currentColor'
      />
      <path
        d='M96.8959 27.3055C97.5355 27.3055 98.0258 27.4448 98.3669 27.7234C98.708 28.0019 98.8785 28.4255 98.8785 28.9939V32.1703H97.9704L97.7188 31.5223H97.6847C97.5483 31.6928 97.409 31.8321 97.2669 31.9401C97.1247 32.0481 96.9613 32.1277 96.7766 32.1789C96.5918 32.23 96.3672 32.2556 96.1029 32.2556C95.8215 32.2556 95.5685 32.2016 95.344 32.0936C95.1223 31.9856 94.9475 31.8207 94.8195 31.599C94.6916 31.3745 94.6277 31.0902 94.6277 30.7463C94.6277 30.2403 94.8053 29.868 95.1606 29.6292C95.5159 29.3876 96.0489 29.254 96.7595 29.2284L97.5866 29.2029V28.9939C97.5866 28.7438 97.5213 28.5605 97.3905 28.4439C97.2598 28.3274 97.0778 28.2691 96.8448 28.2691C96.6145 28.2691 96.3886 28.3018 96.1669 28.3672C95.9451 28.4326 95.7234 28.515 95.5017 28.6145L95.0711 27.7362C95.3241 27.6026 95.6069 27.4974 95.9196 27.4207C96.2351 27.3439 96.5605 27.3055 96.8959 27.3055ZM97.5866 29.9618L97.0835 29.9788C96.6629 29.9902 96.3701 30.0655 96.2052 30.2048C96.0432 30.3441 95.9622 30.5274 95.9622 30.7548C95.9622 30.9538 96.0205 31.0959 96.137 31.1812C96.2535 31.2636 96.4056 31.3048 96.5932 31.3048C96.8718 31.3048 97.1063 31.2224 97.2967 31.0575C97.49 30.8927 97.5866 30.6582 97.5866 30.354V29.9618Z'
        fill='currentColor'
      />
      <path
        d='M93.6385 27.3141C93.7039 27.3141 93.7792 27.3184 93.8645 27.3269C93.9526 27.3326 94.0237 27.3411 94.0777 27.3525L93.9796 28.5719C93.937 28.5576 93.8759 28.5477 93.7963 28.542C93.7195 28.5335 93.6527 28.5292 93.5959 28.5292C93.4282 28.5292 93.2648 28.5505 93.1056 28.5932C92.9492 28.6358 92.8085 28.7054 92.6835 28.8021C92.5584 28.8959 92.4589 29.021 92.385 29.1773C92.314 29.3308 92.2784 29.5198 92.2784 29.7444V32.1704H90.978V27.4036H91.9629L92.1548 28.2052H92.2187C92.3125 28.0432 92.4291 27.8954 92.5684 27.7618C92.7105 27.6253 92.8711 27.5173 93.0501 27.4377C93.2321 27.3553 93.4282 27.3141 93.6385 27.3141Z'
        fill='currentColor'
      />
      <path
        d='M88.428 32.1703H87.1062V27.0369H85.4136V25.9369H90.1206V27.0369H88.428V32.1703Z'
        fill='currentColor'
      />
      <path
        d='M78.6047 27.4973H79.3637L80.3955 30.209C80.4551 30.3682 80.5106 30.5216 80.5617 30.6695C80.6129 30.8144 80.6584 30.9537 80.6982 31.0873C80.738 31.2209 80.7692 31.3502 80.792 31.4753H80.8218C80.8616 31.3332 80.9185 31.147 80.9924 30.9167C81.0663 30.6837 81.1459 30.4463 81.2311 30.2047L82.2075 27.4973H82.9707L80.9369 32.8567C80.8289 33.1438 80.7024 33.3939 80.5575 33.6071C80.4154 33.8203 80.242 33.9837 80.0373 34.0974C79.8327 34.2139 79.5854 34.2722 79.2954 34.2722C79.1618 34.2722 79.0439 34.2637 78.9416 34.2466C78.8392 34.2324 78.7511 34.2154 78.6772 34.1955V33.6284C78.7398 33.6426 78.8151 33.6554 78.9032 33.6668C78.9941 33.6781 79.0879 33.6838 79.1846 33.6838C79.3608 33.6838 79.5129 33.6497 79.6408 33.5815C79.7715 33.5161 79.8838 33.4195 79.9776 33.2916C80.0714 33.1637 80.1524 33.0116 80.2206 32.8354L80.4765 32.1788L78.6047 27.4973Z'
        fill='currentColor'
      />
      <path
        d='M74.7043 25.5361V27.1947C74.7043 27.3851 74.6987 27.5699 74.6873 27.749C74.6788 27.928 74.6717 28.0673 74.666 28.1668H74.7043C74.8323 27.9564 75.017 27.7788 75.2586 27.6338C75.5002 27.4889 75.8086 27.4164 76.1838 27.4164C76.7694 27.4164 77.2355 27.6196 77.5823 28.0261C77.9319 28.4297 78.1067 29.0309 78.1067 29.8296C78.1067 30.3554 78.0271 30.7989 77.868 31.1599C77.7088 31.5208 77.4842 31.7937 77.1943 31.9785C76.9044 32.1632 76.5619 32.2556 76.1668 32.2556C75.7973 32.2556 75.4931 32.1874 75.2544 32.0509C75.0184 31.9117 74.8365 31.7425 74.7086 31.5436H74.6532L74.5082 32.1703H73.9966V25.5361H74.7043ZM76.0687 28.009C75.7305 28.009 75.4618 28.0758 75.2629 28.2094C75.0639 28.3402 74.9204 28.5391 74.8323 28.8063C74.747 29.0707 74.7043 29.4061 74.7043 29.8125V29.8509C74.7043 30.4393 74.8024 30.8898 74.9985 31.2025C75.1947 31.5123 75.5514 31.6672 76.0687 31.6672C76.5036 31.6672 76.829 31.508 77.0451 31.1897C77.2639 30.8713 77.3734 30.4166 77.3734 29.8253C77.3734 29.2227 77.2654 28.7694 77.0493 28.4652C76.8362 28.1611 76.5093 28.009 76.0687 28.009Z'
        fill='currentColor'
      />
      <path
        d='M68.0437 32.2556C67.4525 32.2556 66.9835 32.0538 66.6367 31.6502C66.2928 31.2465 66.1208 30.6468 66.1208 29.8509C66.1208 29.0465 66.2971 28.4397 66.6495 28.0304C67.002 27.6182 67.471 27.4121 68.0565 27.4121C68.3038 27.4121 68.5198 27.4448 68.7046 27.5102C68.8894 27.5756 69.0485 27.6637 69.1821 27.7745C69.3157 27.8825 69.428 28.0062 69.519 28.1455H69.5701C69.5588 28.0574 69.5474 27.9366 69.536 27.7831C69.5246 27.6296 69.519 27.5045 69.519 27.4079V25.5361H70.2267V32.1703H69.6554L69.5488 31.5052H69.519C69.4308 31.6445 69.3186 31.771 69.1821 31.8847C69.0485 31.9984 68.8879 32.0893 68.7003 32.1575C68.5156 32.2229 68.2967 32.2556 68.0437 32.2556ZM68.1546 31.6672C68.6549 31.6672 69.0087 31.5251 69.2162 31.2409C69.4237 30.9566 69.5275 30.5345 69.5275 29.9746V29.8467C69.5275 29.2526 69.428 28.7964 69.229 28.478C69.0329 28.1597 68.6748 28.0005 68.1546 28.0005C67.7197 28.0005 67.3943 28.1682 67.1782 28.5036C66.9622 28.8362 66.8542 29.291 66.8542 29.868C66.8542 30.4421 66.9608 30.8856 67.174 31.1982C67.39 31.5109 67.7169 31.6672 68.1546 31.6672Z'
        fill='currentColor'
      />
      <path
        d='M63.2773 27.4121C63.6753 27.4121 64.0164 27.5002 64.3006 27.6765C64.5849 27.8527 64.8023 28.1 64.953 28.4183C65.1036 28.7338 65.1789 29.1033 65.1789 29.5269V29.966H61.9514C61.9599 30.5146 62.0963 30.9324 62.3607 31.2195C62.625 31.5066 62.9974 31.6502 63.4777 31.6502C63.7733 31.6502 64.0349 31.6231 64.2622 31.5691C64.4896 31.5151 64.7256 31.4355 64.97 31.3304V31.9529C64.7341 32.058 64.4996 32.1348 64.2665 32.1831C64.0363 32.2314 63.7634 32.2556 63.4479 32.2556C62.9988 32.2556 62.6065 32.1646 62.2711 31.9827C61.9386 31.798 61.6799 31.5279 61.4952 31.1726C61.3104 30.8173 61.218 30.3824 61.218 29.868C61.218 29.3648 61.3019 28.93 61.4696 28.5633C61.6401 28.1938 61.8789 27.9095 62.1859 27.7106C62.4957 27.5116 62.8595 27.4121 63.2773 27.4121ZM63.2688 27.992C62.8908 27.992 62.5895 28.1156 62.3649 28.3629C62.1404 28.6102 62.0068 28.9555 61.9642 29.399H64.4371C64.4342 29.1204 64.3902 28.876 64.3049 28.6656C64.2225 28.4524 64.096 28.2876 63.9254 28.171C63.7549 28.0517 63.536 27.992 63.2688 27.992Z'
        fill='currentColor'
      />
      <path
        d='M60.0288 27.4121C60.1226 27.4121 60.2207 27.4178 60.323 27.4292C60.4253 27.4377 60.5163 27.4505 60.5959 27.4675L60.5063 28.1241C60.4296 28.1042 60.3443 28.0886 60.2505 28.0772C60.1567 28.0659 60.0686 28.0602 59.9862 28.0602C59.7986 28.0602 59.6209 28.0986 59.4532 28.1753C59.2884 28.2492 59.1434 28.3572 59.0183 28.4993C58.8933 28.6386 58.7952 28.8077 58.7241 29.0067C58.6531 29.2028 58.6175 29.4217 58.6175 29.6633V32.1703H57.9055V27.4974H58.4939L58.5706 28.3586H58.6005C58.6971 28.1852 58.8137 28.0275 58.9501 27.8854C59.0865 27.7404 59.2443 27.6253 59.4234 27.54C59.6053 27.4547 59.8071 27.4121 60.0288 27.4121Z'
        fill='currentColor'
      />
      <path
        d='M54.8008 27.4121C55.1987 27.4121 55.5398 27.5002 55.8241 27.6765C56.1083 27.8527 56.3257 28.1 56.4764 28.4183C56.627 28.7338 56.7024 29.1033 56.7024 29.5269V29.966H53.4748C53.4833 30.5146 53.6198 30.9324 53.8841 31.2195C54.1485 31.5066 54.5208 31.6502 55.0012 31.6502C55.2968 31.6502 55.5583 31.6231 55.7857 31.5691C56.0131 31.5151 56.249 31.4355 56.4934 31.3304V31.9529C56.2575 32.058 56.023 32.1348 55.7899 32.1831C55.5597 32.2314 55.2868 32.2556 54.9713 32.2556C54.5222 32.2556 54.13 32.1646 53.7946 31.9827C53.462 31.798 53.2033 31.5279 53.0186 31.1726C52.8338 30.8173 52.7415 30.3824 52.7415 29.868C52.7415 29.3648 52.8253 28.93 52.993 28.5633C53.1636 28.1938 53.4023 27.9095 53.7093 27.7106C54.0191 27.5116 54.383 27.4121 54.8008 27.4121ZM54.7923 27.992C54.4142 27.992 54.1129 28.1156 53.8884 28.3629C53.6638 28.6102 53.5302 28.9555 53.4876 29.399H55.9605C55.9576 29.1204 55.9136 28.876 55.8283 28.6656C55.7459 28.4524 55.6194 28.2876 55.4489 28.171C55.2783 28.0517 55.0594 27.992 54.7923 27.992Z'
        fill='currentColor'
      />
      <path
        d='M50.0341 32.1618L49.2027 29.4416C49.1658 29.3251 49.1302 29.2114 49.0961 29.1005C49.0649 28.9868 49.035 28.8788 49.0066 28.7765C48.981 28.6713 48.9568 28.5747 48.9341 28.4866C48.9142 28.3956 48.8972 28.3175 48.8829 28.2521H48.8531C48.8417 28.3175 48.8261 28.3956 48.8062 28.4866C48.7863 28.5747 48.7621 28.6713 48.7337 28.7765C48.7081 28.8817 48.6797 28.9925 48.6484 29.1091C48.6172 29.2228 48.5816 29.3379 48.5418 29.4544L47.6721 32.1618H46.8748L45.5872 27.4889H46.3205L46.9942 30.0684C47.0396 30.2389 47.0823 30.4081 47.1221 30.5758C47.1647 30.7406 47.2002 30.8969 47.2287 31.0448C47.2599 31.1897 47.2827 31.3162 47.2969 31.4242H47.331C47.348 31.3532 47.3665 31.2679 47.3864 31.1684C47.4091 31.0689 47.4333 30.9637 47.4589 30.8529C47.4873 30.7392 47.5172 30.6269 47.5484 30.5161C47.5797 30.4024 47.611 30.2958 47.6422 30.1963L48.5035 27.4889H49.2667L50.0981 30.192C50.1407 30.3285 50.1819 30.4706 50.2217 30.6184C50.2644 30.7662 50.3027 30.9097 50.3368 31.049C50.3709 31.1855 50.3951 31.3077 50.4093 31.4157H50.4434C50.4548 31.319 50.4761 31.1997 50.5074 31.0575C50.5386 30.9154 50.5742 30.7591 50.614 30.5885C50.6566 30.418 50.7007 30.2446 50.7461 30.0684L51.4283 27.4889H52.1489L50.857 32.1618H50.0341Z'
        fill='currentColor'
      />
      <path
        d='M45.0072 29.8253C45.0072 30.209 44.9575 30.5516 44.858 30.8529C44.7585 31.1541 44.615 31.4085 44.4274 31.616C44.2398 31.8235 44.0124 31.9827 43.7452 32.0936C43.4808 32.2016 43.181 32.2556 42.8456 32.2556C42.5329 32.2556 42.2458 32.2016 41.9843 32.0936C41.7257 31.9827 41.5011 31.8235 41.3107 31.616C41.1231 31.4085 40.9767 31.1541 40.8715 30.8529C40.7692 30.5516 40.718 30.209 40.718 29.8253C40.718 29.3137 40.8047 28.8788 40.9781 28.5207C41.1515 28.1597 41.3988 27.8854 41.72 27.6978C42.044 27.5073 42.4292 27.4121 42.8754 27.4121C43.3018 27.4121 43.6741 27.5073 43.9925 27.6978C44.3137 27.8882 44.5624 28.1639 44.7386 28.5249C44.9177 28.8831 45.0072 29.3165 45.0072 29.8253ZM41.4514 29.8253C41.4514 30.2005 41.5011 30.526 41.6006 30.8017C41.7001 31.0774 41.8536 31.2906 42.0611 31.4412C42.2686 31.5919 42.5357 31.6672 42.8626 31.6672C43.1867 31.6672 43.4524 31.5919 43.6599 31.4412C43.8703 31.2906 44.0252 31.0774 44.1247 30.8017C44.2241 30.526 44.2739 30.2005 44.2739 29.8253C44.2739 29.453 44.2241 29.1318 44.1247 28.8617C44.0252 28.5889 43.8717 28.3785 43.6642 28.2307C43.4567 28.0829 43.1881 28.009 42.8584 28.009C42.3723 28.009 42.0156 28.1696 41.7882 28.4908C41.5636 28.812 41.4514 29.2568 41.4514 29.8253Z'
        fill='currentColor'
      />
      <path
        d='M37.461 25.9369C38.2654 25.9369 38.8523 26.0946 39.2219 26.4102C39.5942 26.7228 39.7804 27.1719 39.7804 27.7575C39.7804 28.0218 39.7363 28.2734 39.6482 28.5121C39.5629 28.748 39.4237 28.957 39.2304 29.1389C39.0371 29.3179 38.7827 29.4601 38.4672 29.5652C38.1545 29.6676 37.7708 29.7187 37.316 29.7187H36.5571V32.1703H35.8323V25.9369H37.461ZM37.3928 26.5551H36.5571V29.0962H37.235C37.6329 29.0962 37.9641 29.0536 38.2284 28.9683C38.4956 28.8831 38.696 28.7452 38.8296 28.5548C38.9632 28.3615 39.03 28.1057 39.03 27.7873C39.03 27.3723 38.8978 27.0639 38.6335 26.8621C38.3691 26.6574 37.9556 26.5551 37.3928 26.5551Z'
        fill='currentColor'
      />
    </>
  ),
};
