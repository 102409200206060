import React from 'react';
import * as Styled from './styled';

export interface IControlInfoProps {
  className?: string;
  dataId?: string;
  isError?: boolean | undefined;
  helperText?: string | undefined;
  isDisabled?: boolean | undefined;
  isHide?: boolean;
}

const baseClassName = 'ControlInfo';

export const ControlInfo: React.FC<IControlInfoProps> = props => {
  const {
    isError = false,
    helperText = '',
    isDisabled = false,
    isHide = false,
    dataId,
  } = props;
  const errorClassName = isError ? `${baseClassName}-error` : '';
  const disabledClassName = isDisabled ? `${baseClassName}-disabled` : '';
  const classNameFromProps = props.className || '';
  const className = `${classNameFromProps} ${baseClassName} ${errorClassName} ${disabledClassName}`;
  const setId = dataId ? { id: dataId } : {};
  if (isHide) return null;

  return (
    <Styled.ContainerControlInfo
      $isError={isError}
      $isDisabled={isDisabled}
      className={className}
      {...setId}
    >
      {helperText}
    </Styled.ContainerControlInfo>
  );
};
