import { requestService, TAsyncActionOptions } from '@trader/services';

import { categoryRoutes } from './routes';
import { ICategoryBE } from './models/category';

export class Category {
  routes = categoryRoutes;

  async getCategories(
    options: TAsyncActionOptions
  ): Promise<Array<ICategoryBE>> {
    const resp = await requestService.get<Array<ICategoryBE>>(
      this.routes.getCategories,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }
}
