import { IMessage } from '@trader/types';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useHub } from './core/useHub';

export const useTradingView = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleUpdate = (_message: IMessage) => {};

  const { subscribe, unsubscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: EConnectionHub.TradingView,
    subscriber: EConnectionHubSubscriber.TradingView,
    onMessage: handleUpdate,
    invokedName: 'OnQuote',
  });

  return {
    subscribe,
    unsubscribe,
  };
};
