import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { IPurchasesHistoryBE, IPurchaseHistoryBE } from '@trader/api';
import { dateFormats } from '@trader/utils';
import {
  EmptyData,
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  ScrollingList,
} from '@trader/components';
import { useI18next } from '@trader/services';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  purchases: IPurchasesHistoryBE['purchases'];
  totalCount: number;
}

export const PurchaseHistory: React.FC = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();
  const [state, setState] = useState<IState>({
    isFetchingMore: false,
    isLoading: true,
    purchases: [],
    totalCount: 0,
  });

  useEffect(() => {
    (async function () {
      const response = await store.user.getPurchaseHistoryAsync.run();
      if (response) {
        setState(prev => ({
          ...prev,
          totalCount: response.totalCount,
          isLoading: false,
          purchases: response.purchases,
        }));
      }
    })();
  }, []);

  const formatDate = 1000;

  const fetchMoreHistory = async (pageNumber: number) => {
    setState(prev => ({ ...prev, isFetchingMore: true }));
    const response = await store.user.getTransfersHistoryAsync.run({
      pageNumber,
    });
    if (response) {
      setState(prev => ({
        ...prev,
        transfers: [...state.purchases, ...response.transfers],
      }));
    }
  };

  const renderRowItemsCallBacks: IRenderRowItemsCallBacks<IPurchaseHistoryBE> =
    useMemo(() => {
      const typeTranslationMap: Record<IPurchaseHistoryBE['type'], string> = {
        Payment: translate('COMMON.LABELS.PAYMENT'),
        Payout: translate('COMMON.LABELS.PAYOUT'),
      };
      const statusTranslationMap: Record<IPurchaseHistoryBE['status'], string> =
        {
          Success: translate('COMMON.LABELS.SUCCESS'),
          Failure: translate('COMMON.LABELS.FAILURE'),
          Pending: translate('COMMON.LABELS.PENDING'),
          Canceled: translate('COMMON.LABELS.CANCELED'),
          TimeOut: translate('COMMON.LABELS.TIME_OUT'),
        };

      return {
        id: item => item.value,
        value: item => item.value,
        status: item =>
          statusTranslationMap[item.value as IPurchaseHistoryBE['status']],
        type: item =>
          typeTranslationMap[item.value as IPurchaseHistoryBE['type']],
        comment: item => item.value,
        'date.seconds': item =>
          dateFormats(
            new Date((item?.value as number) * formatDate),
            'Mm dd, yyyy',
            currentLng
          ),
      };
    }, [currentLng]);

  const cells: Array<IHeadCell> = [
    {
      id: 'id',
      label: translate('COMMON.LABELS.PURCHASED_ID'),
      minWidth: 40,
      align: 'start',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.AMOUNT'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'status',
      label: translate('COMMON.LABELS.STATUS'),
      minWidth: 70,
      align: 'center',
    },
    {
      id: 'type',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: 70,
      align: 'start',
    },
    {
      id: 'date.seconds',
      label: translate('COMMON.LABELS.DATE'),
      minWidth: 120,
      align: 'start',
    },
    {
      id: 'comment',
      label: translate('COMMON.LABELS.COMMENT'),
      minWidth: 180,
      align: 'start',
    },
  ];

  if (!state.isFetchingMore && store.user.getTransfersHistoryAsync.inProgress) {
    return <Progress />;
  }

  if (!state.purchases.length && !state.isLoading) {
    return <EmptyData title={translate('COMMON.LABELS.NO_HISTORY')} />;
  }

  return (
    <ScrollingList<IPurchaseHistoryBE>
      rows={state.purchases}
      headCells={cells}
      renderRowItemsCallBacks={renderRowItemsCallBacks}
      totalCount={state.totalCount}
      fetchMore={fetchMoreHistory}
    />
  );
});
