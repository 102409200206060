import React from 'react';

import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IRemainingTime {
  value?: string | number;
  hasTitle?: boolean;
}

const days14 = 14;
const day1 = 1;

export const RemainingTime: React.FC<IRemainingTime> = ({
  value,
  hasTitle,
}) => {
  const { translate } = useI18next();

  const currentValue = value as number;
  const isPositive = currentValue > days14;
  const isProgress = currentValue <= days14 && currentValue > day1;
  const isNegative = currentValue === day1;

  return (
    <React.Fragment>
      <Styled.Value
        $isPositive={isPositive}
        $isNegative={isNegative}
        $isProgress={isProgress}
        $hasBg
      >
        {currentValue < day1
          ? translate('COMMON.LABELS.COMPLETED')
          : currentValue}{' '}
        {currentValue < day1
          ? ''
          : currentValue === 1
          ? translate('COMMON.LABELS.DAY')
          : translate('COMMON.LABELS.DAYS')}
      </Styled.Value>
      {hasTitle && (
        <Styled.Title>{translate('COMMON.LABELS.REMAINING')}</Styled.Title>
      )}
    </React.Fragment>
  );
};
