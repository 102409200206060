import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;
export const TextWrapper = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const TotalValue = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 12px;
    font-weight: 500;
    white-space: normal;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 12px;
    font-weight: 500;
    margin-right: 3px;
  `}
`;
export const Value = styled(Typography)`
  ${() => css`
    font-size: 12px;
    font-weight: 400;
  `}
`;

export const FundsTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.red.main};
    font-size: 12px;
    margin-right: 3px;
    font-weight: 500;
  `}
`;
export const FundsButton = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.blue.main};
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  `}
`;
