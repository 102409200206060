import styled, { css } from 'styled-components';
import { Typography } from '@trader/components';

export const Root = styled('div')`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 408px;
    flex-direction: column;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
      height: 440px;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.light};
    font-size: 14px;
  `}
`;
