import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Button as MuiButton, ButtonProps } from '@mui/material';

import { IconSide } from '@trader/themes';
import { conditionApply } from '@trader/utils';
import { Icon, StyledIcon } from '../icon';
import { ICommonStyledPropsWithIcon } from '@trader/types';

interface IButtonProps extends ButtonProps, ICommonStyledPropsWithIcon {
  loading?: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Button = styled<React.FC<IButtonProps>>(
  ({ $iconSide, $iconType, $isLabel, $singleIcon, loading, ...rest }) => (
    <MuiButton
      {...rest}
      disabled={Boolean(rest.disabled)}
      component={$isLabel ? 'label' : 'button'}
    />
  )
)`
  ${({ $iconSide, $singleIcon, theme }) => {
    return css`
      box-shadow: none;
      color: ${theme.palette.text.primary};

      ${conditionApply($singleIcon, 'padding: 0; min-width: auto;')}
      ${StyledIcon} {
        display: flex;
      }

      ${conditionApply(
        [$iconSide === IconSide.left, !$singleIcon],
        css`
          ${StyledIcon} {
            margin-right: 10px;
            margin-left: 5px;
          }
        `
      )}

      ${conditionApply(
        [$iconSide === IconSide.right, !$singleIcon],
        css`
          ${StyledIcon} {
            margin-left: 10px;
            margin-right: 5px;
          }
        `
      )}
    `;
  }};
`;

export const ButtonText = styled('span')`
  ${() => css`
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-transform: initial;
  `}
`;

export const LoadingIcon = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
  margin-left: 10px;
`;
