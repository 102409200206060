import styled, { css } from 'styled-components';

interface IChange {
  $isEqualZero: boolean;
  $isAboveZero: boolean;
}

export const Change = styled('span')<IChange>`
  ${({ theme, $isAboveZero, $isEqualZero }) => css`
    color: ${$isEqualZero
      ? 'initial'
      : $isAboveZero
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
  `};
`;
