import { getIconBaseProps } from './utils';

export const heromarketsLogo = {
  ...getIconBaseProps('heromarketsLogo'),
  width: '174px',
  height: '26px',
  viewBox: '0 0 174 26',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M26.102 11.7636H36.7209V14.0612H26.102V11.7636ZM36.2655 6.23682H39.0185V19.6294H36.2655V6.23682ZM23.6802 6.23682H26.4332V19.6294H23.6802V6.23682Z'
        fill='currentColor'
      />
      <path
        d='M43.9244 14.0405V17.3317H54.2535V19.6294H41.1714V6.23682H54.2328V8.53446H43.9244V11.8671H52.3698V14.0405H43.9244Z'
        fill='currentColor'
      />
      <path
        d='M56.0542 19.6294V6.23682H65.1413C66.1763 6.23682 67.0767 6.37861 67.8426 6.66116C68.6084 6.9437 69.2056 7.38564 69.6331 7.98592C70.0605 8.58621 70.2748 9.3552 70.2748 10.2939C70.2748 10.9284 70.164 11.4665 69.9436 11.9085C69.7231 12.3504 69.4157 12.7085 69.0224 12.9849C68.6291 13.2612 68.1769 13.4713 67.6666 13.6162C67.1564 13.7611 66.6109 13.8542 66.0314 13.8956L65.7416 13.73C66.7072 13.7435 67.501 13.8232 68.122 13.9681C68.743 14.113 69.2087 14.3748 69.5192 14.7547C69.8297 15.1345 69.985 15.6965 69.985 16.4417V19.6294H67.2319V16.628C67.2319 16.1177 67.1419 15.7203 66.9628 15.4377C66.7838 15.1552 66.4629 14.9586 66.0003 14.8478C65.5377 14.7371 64.8722 14.6822 64.0028 14.6822H58.8072V19.6294H56.0542ZM58.8072 12.5295H65.1413C65.9144 12.5295 66.5043 12.3432 66.9111 11.9706C67.3178 11.598 67.5217 11.094 67.5217 10.4595C67.5217 9.82507 67.3178 9.39039 66.9111 9.07265C66.5043 8.75491 65.9144 8.59656 65.1413 8.59656H58.8072V12.5295Z'
        fill='currentColor'
      />
      <path
        d='M80.0862 19.8985C78.3061 19.8985 76.7846 19.6191 75.522 19.0602C74.2593 18.5013 73.2937 17.7044 72.624 16.6694C71.9544 15.6344 71.6201 14.3925 71.6201 12.9435C71.6201 11.4945 71.9544 10.2319 72.624 9.19689C73.2937 8.16192 74.2593 7.36499 75.522 6.8061C76.7846 6.24722 78.3061 5.96777 80.0862 5.96777C81.8664 5.96777 83.4054 6.24722 84.6608 6.8061C85.9162 7.36499 86.8788 8.16192 87.5484 9.19689C88.218 10.2319 88.5523 11.4811 88.5523 12.9435C88.5523 14.4059 88.218 15.6344 87.5484 16.6694C86.8788 17.7044 85.9162 18.5013 84.6608 19.0602C83.4054 19.6191 81.8798 19.8985 80.0862 19.8985ZM80.0862 17.5181C81.1905 17.5181 82.1593 17.3525 82.9945 17.0213C83.8297 16.6901 84.4849 16.1861 84.9609 15.5102C85.437 14.8344 85.6751 13.9785 85.6751 12.9435C85.6751 11.9085 85.437 11.0526 84.9609 10.3768C84.4849 9.70093 83.8297 9.19379 82.9945 8.85535C82.1593 8.51692 81.1905 8.34822 80.0862 8.34822C78.9819 8.34822 78.0235 8.51692 77.1676 8.85535C76.3117 9.19379 75.6431 9.70093 75.1597 10.3768C74.6764 11.0526 74.4353 11.9085 74.4353 12.9435C74.4353 13.9785 74.6764 14.8344 75.1597 15.5102C75.6431 16.1861 76.3117 16.6901 77.1676 17.0213C78.0235 17.3525 78.9964 17.5181 80.0862 17.5181Z'
        fill='currentColor'
      />
      <path
        d='M98.0535 18.6359H97.3911L103.518 6.1748H105.174V19.6295H103.518V9.05203L103.849 9.13483L98.5503 19.6295H96.8943L91.5953 9.15553L91.9265 9.07273V19.6295H90.2705V6.1748H91.9265L98.0535 18.6359Z'
        fill='currentColor'
      />
      <path
        d='M108.941 16.1106V14.5581H115.4V16.1106H108.941ZM106.209 19.6295L111.322 6.1748H113.061L118.215 19.6295H116.435L111.943 7.45817H112.44L107.948 19.6295H106.209Z'
        fill='currentColor'
      />
      <path
        d='M119.25 19.6295V6.1748H125.191C126.129 6.1748 126.93 6.3166 127.592 6.59914C128.254 6.88169 128.761 7.30603 129.113 7.87216C129.465 8.43829 129.641 9.14829 129.641 10.0042C129.641 10.5838 129.548 11.0806 129.362 11.4946C129.175 11.9086 128.917 12.2574 128.585 12.5399C128.254 12.8224 127.864 13.0398 127.416 13.1919C126.968 13.3441 126.481 13.4403 125.957 13.4817L125.895 13.3782C126.626 13.4062 127.253 13.4952 127.778 13.6473C128.303 13.7995 128.71 14.0748 128.999 14.4753C129.289 14.8758 129.434 15.4761 129.434 16.2762V19.6295H127.778V16.2762C127.778 15.7245 127.685 15.2929 127.499 14.9824C127.312 14.6719 127.002 14.4515 126.567 14.3201C126.133 14.1886 125.535 14.1234 124.777 14.1234H120.906V19.6295H119.25ZM120.906 12.5709H125.191C126.129 12.5709 126.829 12.3329 127.292 11.8568C127.754 11.3807 127.985 10.7628 127.985 10.0042C127.985 9.24558 127.754 8.71049 127.292 8.3172C126.829 7.92391 126.129 7.72727 125.191 7.72727H120.906V12.5709Z'
        fill='currentColor'
      />
      <path
        d='M134.712 13.0884V12.0121L142.495 19.6295H140.239L133.16 12.5916L139.39 6.1748H141.626L134.712 13.0884ZM131.504 6.1748H133.16V19.6295H131.504V6.1748Z'
        fill='currentColor'
      />
      <path
        d='M145.186 13.7094V18.077H153.073V19.6295H143.53V6.1748H153.052V7.72727H145.186V12.157H151.624V13.7094H145.186Z'
        fill='currentColor'
      />
      <path
        d='M153.59 6.1748H164.354V7.72727H153.59V6.1748ZM158.144 7.56167H159.8V19.6295H158.144V7.56167Z'
        fill='currentColor'
      />
      <path
        d='M164.375 15.2618H165.865C165.906 15.8414 166.079 16.3589 166.383 16.8143C166.686 17.2697 167.104 17.6288 167.635 17.8907C168.166 18.1525 168.791 18.284 169.508 18.284C170.129 18.284 170.65 18.1908 171.071 18.0045C171.492 17.8182 171.809 17.5595 172.023 17.2283C172.237 16.8971 172.344 16.5111 172.344 16.0691C172.344 15.6272 172.233 15.2484 172.013 14.9721C171.792 14.6957 171.475 14.4618 171.061 14.2683C170.647 14.0747 170.157 13.8884 169.591 13.7094C169.053 13.5438 168.507 13.3502 167.956 13.1298C167.404 12.9093 166.89 12.6403 166.414 12.3225C165.937 12.0048 165.555 11.6218 165.265 11.1737C164.975 10.7256 164.83 10.1832 164.83 9.54879C164.83 8.91435 164.999 8.34511 165.337 7.79968C165.676 7.25425 166.172 6.81335 166.828 6.47491C167.483 6.13647 168.273 5.96777 169.198 5.96777C170.647 5.96777 171.73 6.36106 172.447 7.14764C173.165 7.93423 173.51 8.97645 173.482 10.2733H171.992C171.964 9.29315 171.689 8.58937 171.164 8.16192C170.639 7.73448 169.956 7.52024 169.115 7.52024C168.411 7.52024 167.797 7.68273 167.273 8.00667C166.748 8.33062 166.486 8.83776 166.486 9.52809C166.486 9.83133 166.545 10.0973 166.662 10.325C166.779 10.5527 166.972 10.7628 167.242 10.9564C167.511 11.1499 167.876 11.3393 168.339 11.5256C168.801 11.7119 169.37 11.9158 170.046 12.1362C170.488 12.2739 170.94 12.4395 171.402 12.633C171.865 12.8266 172.292 13.0677 172.686 13.3575C173.079 13.6473 173.397 14.0168 173.638 14.4649C173.879 14.9131 174 15.4761 174 16.1519C174 16.8278 173.845 17.4042 173.534 17.9631C173.224 18.522 172.74 18.9743 172.085 19.3189C171.43 19.6636 170.585 19.8364 169.55 19.8364C168.667 19.8364 167.911 19.7153 167.283 19.4742C166.655 19.233 166.141 18.9184 165.741 18.5324C165.34 18.1463 165.034 17.7427 164.82 17.3214C164.605 16.9002 164.465 16.5038 164.395 16.1312C164.326 15.7586 164.32 15.4688 164.375 15.2618Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 18.3593L3.73419 15.6528V25.7707H0V18.3593Z'
        fill='url(#paint0_linear_19_52)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8986 7.34931L11.124 10.1179V0.0786133H14.8592L14.8986 7.34931Z'
        fill='url(#paint1_linear_19_52)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.38965 12.8718L11.1238 10.0908V25.7438H7.38965V12.8718Z'
        fill='url(#paint2_linear_19_52)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.38946 12.872L3.65527 15.653V0H7.38946V12.872Z'
        fill='url(#paint3_linear_19_52)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_19_52'
          x1='1.73006'
          y1='17.7752'
          x2='1.73006'
          y2='25.9707'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19726B' />
          <stop offset='1' stopColor='#04CFBE' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_19_52'
          x1='12.8865'
          y1='8.32633'
          x2='12.8865'
          y2='0.294923'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19726B' />
          <stop offset='1' stopColor='#04CFBE' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_19_52'
          x1='9.11971'
          y1='13.0723'
          x2='9.11971'
          y2='25.5947'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19726B' />
          <stop offset='1' stopColor='#04CFBE' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_19_52'
          x1='5.69819'
          y1='12.741'
          x2='5.69819'
          y2='0.218587'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19726B' />
          <stop offset='1' stopColor='#04CFBE' />
        </linearGradient>
      </defs>
    </>
  ),
};
