interface IBaseIconProps<T extends string> {
  name: T;
  className: `icon ${T}`;
}

export const getIconBaseProps = <T extends string>(
  name: T
): IBaseIconProps<T> => ({
  name,
  className: `icon ${name}` as const,
});
