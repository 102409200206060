import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    width: auto;
    align-items: center;
  `};
`;

export const Toggle = styled(Button)`
  ${() => css`
    padding: 3px 2px;

    > span {
      display: flex;
      align-items: center;
    }

    svg:first-child {
      margin-right: 8px;
    }
  `};
`;
