interface IExportCsv {
  data: {
    headers: Array<{ label: string; id: string }>;
    rows: Array<object>;
  };
  fileName: string;
}

export const useExportCsv = () => {
  const downloadCSV = ({ data, fileName }: IExportCsv) => {
    // Convert the data array into a CSV string
    const csv = [data.headers.map(header => header.label)];
    data.rows.forEach(row => {
      csv.push(data.headers.map(header => row[header.id]));
    });
    // Create a Blob from the CSV string
    const blob = new Blob([csv.join('\n')], { type: 'text/csv' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return { downloadCSV };
};
