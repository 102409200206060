import React from 'react';
import { observer } from 'mobx-react-lite';
import { UseFormReset } from 'react-hook-form';

import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { Controller, Form } from '@trader/components';
import { useLoginSchema } from '@trader/hooks';
import { localStorageService, useI18next } from '@trader/services';
import { Login } from '@trader/containers';
import { images } from '@trader/assets';
import { useMst } from '@trader/store';

import * as Styled from './styled';

type TSavedCredentials = {
  shouldSave: boolean;
  email: string;
};

type TInitialState = {
  username: string;
  password: string;
  shouldSaveCredentials: boolean;
};

type THandleSubmit = (
  reset: UseFormReset<TInitialState>,
  data: TInitialState,
  getRecaptchaBeforeSubmit: () => Promise<string>
) => void;

export const SignIn: React.FC = observer(() => {
  const { translate } = useI18next();
  const store = useMst();
  const { loginSchema } = useLoginSchema();

  const getInitialFormValues = () => {
    const savedCredentials = localStorageService.get(
      LOCAL_STORAGE_KEYS.credentials
    );
    const parsedSavedCredentials = savedCredentials
      ? (JSON.parse(savedCredentials) as TSavedCredentials)
      : null;

    return {
      username: parsedSavedCredentials?.email || '',
      password: '',
      shouldSaveCredentials: parsedSavedCredentials?.shouldSave || false,
    };
  };

  const handleSubmit: THandleSubmit = async (
    reset,
    data,
    getRecaptchaBeforeSubmit
  ) => {
    const credentials: TSavedCredentials = {
      shouldSave: data.shouldSaveCredentials,
      email: data.shouldSaveCredentials ? data.username : '',
    };
    localStorageService.set(
      LOCAL_STORAGE_KEYS.credentials,
      JSON.stringify(credentials)
    );

    const token = await getRecaptchaBeforeSubmit();
    if (token) {
      await store.auth.loginAsync.run({
        username: data.username,
        password: data.password,
        captcha: token,
      });
      reset(getInitialFormValues());
    }
  };

  return (
    <Login bgImage={images.backgroundLoginCapitalmarkets}>
      {getRecaptchaBeforeSubmit => (
        <Form<TInitialState>
          onSubmit={(reset, data) =>
            handleSubmit(reset, data, getRecaptchaBeforeSubmit)
          }
          defaultValues={getInitialFormValues()}
          validationSchema={loginSchema}
          mode='all'
        >
          <Controller
            name='username'
            type='textField'
            fullWidth
            autoFocus
            label={translate('COMMON.LABELS.EMAIL')}
          />
          <Controller
            name='password'
            type='passwordField'
            keyboardType='password'
            fullWidth
            label={translate('COMMON.LABELS.PASSWORD')}
          />
          <Styled.ForgotPassword
            onClick={() => {
              window.open(import.meta.env.VITE_FORGOT_PASSWORD_LINK);
            }}
          >
            {translate('SIGN_IN.FORGOT_PASSWORD')}
          </Styled.ForgotPassword>
          <Controller
            type='checkboxField'
            name='shouldSaveCredentials'
            label={translate('SIGN_IN.REMEMBER_ME')}
          />
          <Styled.Submit
            loading={store.auth.loginAsync.inProgress}
            disabled={store.auth.loginAsync.inProgress}
            fullWidth
            variant='text'
            type='submit'
          >
            {translate('SIGN_IN.TITLE')}
          </Styled.Submit>
        </Form>
      )}
    </Login>
  );
});
