import React from 'react';
import {
  InputAdornment,
  OutlinedTextFieldProps,
  FormControlProps,
  InputLabel,
} from '@mui/material';

import { TextFieldCharacterCounter } from '../textFieldCharacterCounter';
import { ControlInfo } from '../controlInfo';

import * as Styled from './styled';
import { PasswordAdornment } from './components/passwordAdornment';

export interface ITextField
  extends Omit<
    OutlinedTextFieldProps,
    | 'variant'
    | 'error'
    | 'helperText'
    | 'size'
    | 'margin'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'type'
  > {
  shouldHideErrorOnInput?: boolean;
  isError?: boolean;
  shouldHideControlInfo?: boolean;
  helperText?: string;
  inputFormControl?: Omit<FormControlProps, 'variant'>;
  fullWidth?: boolean;
  maxCharacterCount?: number;
  isCharacterCount?: boolean;
  keyboardType?: OutlinedTextFieldProps['type'];
  endAdornment?: React.ReactNode;
}

const maxTextFieldCharacters = 20;

export const TextField = React.forwardRef<HTMLInputElement, ITextField>(
  (
    {
      disabled,
      label,
      placeholder,
      helperText,
      isError,
      shouldHideErrorOnInput,
      value,
      onChange,
      name,
      color,
      shouldHideControlInfo,
      inputFormControl,
      fullWidth,
      maxCharacterCount = maxTextFieldCharacters,
      isCharacterCount = false,
      endAdornment,
      keyboardType = 't',
      ...rest
    },
    ref
  ) => {
    const [isPasswordShown, setIsPasswordShown] = React.useState(false);

    const isPasswordField = keyboardType === 'password';
    const inputType = isPasswordField
      ? isPasswordShown
        ? 'text'
        : 'password'
      : 'text';

    const isCurrentError = shouldHideErrorOnInput ? false : isError;

    const handleClickShowPassword = () => setIsPasswordShown(prev => !prev);

    const passwordAdornment = (
      <PasswordAdornment
        isPasswordShown={isPasswordShown}
        onClickShowPassword={handleClickShowPassword}
      />
    );
    const adornment = isPasswordField ? passwordAdornment : endAdornment;

    return (
      <Styled.InputFormControl
        {...inputFormControl}
        fullWidth={fullWidth}
        variant='outlined'
      >
        <InputLabel>{label}</InputLabel>
        <Styled.Input
          {...rest}
          id={`id-${name}`}
          error={isCurrentError}
          value={value || ''}
          label={label}
          type={inputType}
          onChange={onChange}
          color={color}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          fullWidth={fullWidth}
          ref={ref}
          endAdornment={
            adornment ||
            (isCharacterCount && (
              <InputAdornment position='end'>
                <TextFieldCharacterCounter
                  currentCount={value ? (value as string).length : 0}
                  maxCount={maxCharacterCount}
                />
              </InputAdornment>
            ))
          }
        />
        {!shouldHideControlInfo && (
          <ControlInfo helperText={helperText} isError={isError} />
        )}
      </Styled.InputFormControl>
    );
  }
);
