import { round } from 'lodash';
import * as yup from 'yup';

import {
  checkOnTickSize,
  getPipSizeNumber,
  getPriceDifference,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { TCommonOrderType, TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';

export const usePriceSchema = (type: TCommonOrderType) => {
  const { translate } = useI18next();
  const store = useMst();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const priceDiff = getPriceDifference(
    instrument.stopsLevel,
    instrument.pipSize
  );

  const getStops = (orderSide: TPlaceOrderSide, ask: number, bid: number) => {
    const value = orderSide === 'Buy' ? bid : ask;
    const stopsUp = round(
      value + priceDiff,
      getPipSizeNumber(instrument.pipSize)
    );
    const stopsDown = round(
      value - priceDiff,
      getPipSizeNumber(instrument.pipSize)
    );

    return { stopsUp, stopsDown };
  };

  const priceSchema = yup
    .number()
    .min(
      instrument.pipSize,
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.pipSize,
      })
    )
    .when(['side', 'ask', 'bid'], ([side, ask, bid], schema) =>
      schema.test(
        'trading-diff',
        translate(
          'COMMON.ERRORS.SHOULD_BE_OUTSIDE_RANGE',
          getStops(side, ask, bid)
        ),
        value => {
          if (value) {
            const { stopsDown, stopsUp } = getStops(side, ask, bid);
            return !(+value > +stopsDown && +value < +stopsUp);
          }
          return true;
        }
      )
    )
    .when(['side', 'ask', 'bid'], (_, schema) =>
      schema.test(
        'tick_diff',
        translate('COMMON.ERRORS.TICK_SIZE', {
          tickSize: instrument.tickSize,
        }),
        value => {
          if (value) {
            return !checkOnTickSize(value, instrument.tickSize);
          }
          return true;
        }
      )
    )
    .typeError(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.pipSize,
      })
    );

  return { priceSchema };
};
