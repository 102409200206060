import { useRef } from 'react';
import { BoxProps } from '@mui/material';

import * as Styled from './styled';

interface IWidth {
  width: number;
  minWidth: number;
  maxWidth: number;
  onUpdateWidth: (width: number) => void;
}

interface IHeight {
  height: number;
  minHeight: number;
  maxHeight: number;
  onUpdateHeight: (height: number) => void;
}

type TWidth = Partial<IHeight> & IWidth;

type THeight = Partial<IWidth> & IHeight;

export type THeightMode = 'height';
export type TWidthMode = 'width';

type TContent<T extends THeightMode | TWidthMode> = T extends THeightMode
  ? THeight
  : TWidth;

type TExpandedContent<T extends THeightMode | TWidthMode> = TContent<T> &
  BoxProps & { disabled?: boolean };

export const ExpandedContent = <
  T extends THeightMode | TWidthMode = TWidthMode
>({
  width,
  minWidth,
  maxWidth,
  onUpdateWidth,
  height,
  minHeight,
  maxHeight,
  onUpdateHeight,
  disabled,
  ...boxProps
}: TExpandedContent<T>) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = (e: MouseEvent) => {
    if (!rootRef.current) {
      return;
    }

    if (onUpdateWidth && minWidth && maxWidth) {
      const newWidth = e.clientX - rootRef.current.getBoundingClientRect().left;

      newWidth >= minWidth && newWidth <= maxWidth && onUpdateWidth(newWidth);
    }

    if (onUpdateHeight && minHeight && maxHeight) {
      const newHeight =
        rootRef.current.getBoundingClientRect().bottom - e.clientY;

      newHeight >= minHeight &&
        newHeight <= maxHeight &&
        onUpdateHeight(newHeight);
    }
  };

  const handleAdvancedChartPointerEvent = (value: 'none' | 'initial') => {
    const chart = document.querySelector('.TVChartContainer');
    if (chart) {
      chart.setAttribute('style', `pointer-events: ${value}`);
    }
  };

  const handleMouseDown = () => {
    handleAdvancedChartPointerEvent('none');
    document.body.style.userSelect = 'none';
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    handleAdvancedChartPointerEvent('initial');
    document.body.style.userSelect = 'auto';
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Styled.Root
      {...boxProps}
      ref={rootRef}
      $width={width}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $height={height}
      $minHeight={minHeight}
      $maxHeight={maxHeight}
    >
      {boxProps.children}
      {!disabled && (
        <Styled.ResizeHandler
          $isExpandedHeight={!!height}
          onMouseDown={handleMouseDown}
        />
      )}
    </Styled.Root>
  );
};
