import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${() => css`
    .root_moveable {
      --moveable-color: none;

      > .moveable-control {
        display: none;
      }
    }
  `}
`;
