export const IconSide = {
  left: 'left',
  right: 'right',
} as const;

type TKeysIconSide = keyof typeof IconSide;
export type TIconSide = (typeof IconSide)[TKeysIconSide];

export const IconColors = {
  action: 'action',
  disabled: 'disabled',
  error: 'error',
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
} as const;

type TKeysIconColors = keyof typeof IconColors;
export type TIconColors = (typeof IconColors)[TKeysIconColors];
