import { Instance, types } from 'mobx-state-tree';

import { TPlaceOrderSide, TProtectionType } from '@trader/types';
import { runInAction } from '@trader/utils';
import {
  getPositionsMetricsAsync,
  editPositionAsync,
  closePositionAsync,
  closeAllPositionsAsync,
  getPositionTradingSessionsAsync,
} from './actions';

import { createCollectionModel } from '../../utils/collectionModel';
import { tradingSessionsModel } from '../instruments';

export const positionMetricModel = types
  .model('positionMetricModel', {
    symbol: types.string,
    iconUrl: types.string,
    currency: types.string,
    spreadDiff: types.number,
    swap: types.number,
    value: types.number,
    positionId: types.identifier,
    strategyId: types.optional(types.number, 0),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    quantity: types.number,
    pipSize: types.number,
    openPrice: types.number,
    currentPrice: types.number,
    price: types.number,
    pl: types.number,
    takeProfit: types.optional(
      types.model({
        type: types.frozen<TProtectionType>('TakeProfit'),
        limitPrice: types.number,
      }),
      { type: 'TakeProfit', limitPrice: 0 }
    ),
    stopLoss: types.optional(
      types.model({
        type: types.frozen<TProtectionType>('StopLoss'),
        stopPrice: types.number,
      }),
      { type: 'StopLoss', stopPrice: 0 }
    ),

    // FE only
    exchangeRate: types.optional(types.number, 1),
    sessions: tradingSessionsModel,
    holidays: types.optional(types.array(types.string), []),

    editPositionAsync,
    closePositionAsync,
  })
  .views(() => ({}))
  .actions(() => ({
    runInAction,
  }));

interface IPositionsMetricsAsync {
  getPositionsMetricsAsync: typeof getPositionsMetricsAsync;
  closeAllPositionsAsync: typeof closeAllPositionsAsync;
  getPositionTradingSessionsAsync: typeof getPositionTradingSessionsAsync;
}

export const positionsMetrics = createCollectionModel<
  typeof positionMetricModel,
  IPositionsMetricsAsync
>(positionMetricModel, {
  getPositionsMetricsAsync,
  closeAllPositionsAsync,
  getPositionTradingSessionsAsync,
});

export type TPositionMetricEntity = Instance<typeof positionMetricModel>;
