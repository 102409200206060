import { getIconBaseProps } from '../utils';

export const forex = {
  ...getIconBaseProps('forex'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0649 6.42676V15.4173'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1461 7.92578H10.0244C9.63806 7.92578 9.26752 8.08365 8.99431 8.36466C8.72111 8.64567 8.56763 9.0268 8.56763 9.4242C8.56763 9.82161 8.72111 10.2027 8.99431 10.4837C9.26752 10.7648 9.63806 10.9226 10.0244 10.9226H12.1056C12.4919 10.9226 12.8625 11.0805 13.1357 11.3615C13.4089 11.6425 13.5624 12.0236 13.5624 12.421C13.5624 12.8184 13.4089 13.1996 13.1357 13.4806C12.8625 13.7616 12.4919 13.9195 12.1056 13.9195H8.56763'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
