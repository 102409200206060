import { useEffect } from 'react';

import { emptyFn } from '@trader/constants';
import { setUserActivityToStorage } from '@trader/utils';

/**
 * Custom hook that sets up an event listener for the 'beforeunload' event.
 * This event is fired when the window, the document, and its resources are about to be unloaded.
 */
export const useBeforeUnload = (): void => {
  useEffect(() => {
    const unloadCallback = () => {
      setUserActivityToStorage();
      // TODO: think if we need it
      // event.preventDefault();
      // event.returnValue = '';
      // return;
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
      window.removeEventListener('pagehide', emptyFn);
    };
  }, []);
};
