import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { OrderSection } from './sections/orderSection';
import { InfoSection } from './sections/infoSection';
import * as Styled from './styled';

export const RightBar: FC = observer(() => {
  const store = useMst();

  if (store.ui.sidebar.isRightBarHidden()) {
    return null;
  }

  return (
    <Styled.Root>
      <OrderSection />
      <InfoSection />
    </Styled.Root>
  );
});
