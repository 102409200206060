import { getIconBaseProps } from './utils';

export const currencyWhite = {
  ...getIconBaseProps('currencyWhite'),
  width: '23px',
  height: '22px',
  viewBox: '0 0 23 22',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M-0.000976562 2.34483C-0.000976562 1.04982 1.04884 0 2.34385 0H20.5163C21.8113 0 22.8611 1.04982 22.8611 2.34483V18.7586C22.8611 20.0536 21.8113 21.1034 20.5163 21.1034H2.34385C1.04884 21.1034 -0.000976562 20.0536 -0.000976562 18.7586V2.34483Z'
        fill='white'
      />
      <path
        d='M11.4619 4.68945V16.4136'
        stroke='#212121'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1767 6.64453H10.1058C9.60196 6.64453 9.11875 6.8504 8.76248 7.21685C8.40621 7.5833 8.20605 8.08032 8.20605 8.59855C8.20605 9.11679 8.40621 9.61381 8.76248 9.98026C9.11875 10.3467 9.60196 10.5526 10.1058 10.5526H12.8197C13.3236 10.5526 13.8068 10.7584 14.163 11.1249C14.5193 11.4913 14.7195 11.9884 14.7195 12.5066C14.7195 13.0248 14.5193 13.5219 14.163 13.8883C13.8068 14.2548 13.3236 14.4606 12.8197 14.4606H8.20605'
        stroke='#212121'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
