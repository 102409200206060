import { useCallback, useEffect } from 'react';

import { useGetPageVisibility } from './useGetPageVisibility';
import { MODAL_TYPES, oneSecond } from '@trader/constants';
import { useMst } from '@trader/store';
import {
  checkUserActivitySecondsFromStorage,
  setUserActivityToStorage,
} from '@trader/utils';

const timeInSecondsToLogout = 3590; // 59 minutes 50 seconds;

const activityEvents = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
];

/**
 * Custom hook that automatically logs out the user after a period of inactivity.
 * It tracks user activity and displays a modal before logging out.
 */
export const useAutoLogoutWithNoActivity = () => {
  const store = useMst();
  const isVisible = useGetPageVisibility();

  const isAuth = store.auth.isAuth;
  const userActivity = store.auth.userActivity;

  const activityWatcher = useCallback(() => {
    userActivity.countUpSeconds();
  }, []);

  const resetSeconds = useCallback(() => {
    userActivity.resetSeconds();
  }, []);

  useEffect(() => {
    !isVisible && setUserActivityToStorage();

    if (isAuth && isVisible) {
      checkUserActivitySecondsFromStorage(
        store.auth.userActivity.secondsWithNoActivity,
        store.auth.userActivity.setSeconds
      );

      activityEvents.forEach(function (eventName) {
        document.addEventListener(eventName, resetSeconds, true);
      });

      const interval = setInterval(activityWatcher, oneSecond);

      return () => {
        clearInterval(interval);
        activityEvents.forEach(function (eventName) {
          document.removeEventListener(eventName, resetSeconds, true);
        });
      };
    }
  }, [isAuth, isVisible]);

  useEffect(() => {
    const isLogOutWithNoActivityModal =
      store.ui.modal.id === MODAL_TYPES.logOutWithNoActivity &&
      store.ui.modal.isOpen;

    if (userActivity.secondsWithNoActivity === timeInSecondsToLogout) {
      store.ui.modal.open(MODAL_TYPES.logOutWithNoActivity, {
        shouldHideCloseButton: true,
      });
    } else if (
      userActivity.secondsWithNoActivity > timeInSecondsToLogout &&
      !isLogOutWithNoActivityModal
    ) {
      store.ui.modal.close();
      store.auth.logOut();
    }
  }, [userActivity.secondsWithNoActivity]);
};
