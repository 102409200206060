import { EBrand } from '@trader/types';
import { appConfigUtils } from '@trader/utils';

import { useWholeBusinessLogic } from './useWholeBusinessLogic';
import { useFreeScoreLevelBusinessLogic } from './useFreeScoreLevelBusinessLogic';
import { useMst } from '@trader/store';

export enum EConditionType {
  RegistrationCompleted, // isRegistrationCompleted = false
  ScoreLevel, // isRegistrationCompleted = true but scoreLevel = LOW;
  TradingAllowed, // isTradingAllowed = false;
  DepositAllowed, // isDepositAllowed = false;
}

export type TApplyCondition = () => {
  status: 'passed' | 'failed';
  action?: () => void;
};

export interface IConditionsTypes {
  isRegistrationCompleted: boolean;
  isScoreLevelLow: boolean;
  isTradingAllowed: boolean;
  isDepositAllowed: boolean;
}

type TUseBusinessLogic = (conditionType: EConditionType) => {
  applyCondition: TApplyCondition;
} & IConditionsTypes;

// FrontBroker, TradeItaly, HeroMarkets - useWholeBusinessLogic
// Markets77, TradeGlobal, TrdFx, PipsTrades, ChallengesTrades, Finexo - useFreeScoreLevelBusinessLogic

export const useBusinessLogic: TUseBusinessLogic = conditionType => {
  const store = useMst();
  const profile = store.user.profile;

  const isRegistrationCompleted =
    conditionType === EConditionType.RegistrationCompleted &&
    !profile.isRegistrationCompleted;

  const isScoreLevelLow =
    conditionType === EConditionType.ScoreLevel &&
    profile.isRegistrationCompleted &&
    profile.scoreLevel === 'LOW';

  const isTradingAllowed =
    conditionType === EConditionType.TradingAllowed &&
    !profile.isTradingAllowed;

  const isDepositAllowed =
    conditionType === EConditionType.DepositAllowed &&
    !profile.isDepositAllowed;

  const conditions: IConditionsTypes = {
    isRegistrationCompleted,
    isScoreLevelLow,
    isTradingAllowed,
    isDepositAllowed,
  };

  const rules: Record<EBrand, TApplyCondition> = {
    Markets77: useFreeScoreLevelBusinessLogic(conditions),
    FrontBroker: useWholeBusinessLogic(conditions),
    TradeGlobal: useFreeScoreLevelBusinessLogic(conditions),
    TradeItaly: useWholeBusinessLogic(conditions),
    TrdFx: useFreeScoreLevelBusinessLogic(conditions),
    PipsTrades: useFreeScoreLevelBusinessLogic(conditions),
    ChallengesTrades: useFreeScoreLevelBusinessLogic(conditions),
    Finexo: useFreeScoreLevelBusinessLogic(conditions),
    HeroMarkets: useWholeBusinessLogic(conditions),
  };

  return {
    applyCondition: () => rules[appConfigUtils.getCurrentBrand()](),
    ...conditions,
  };
};
