import { api, IPositionParamsBE, IPositionsHistoryBE } from '@trader/api';
import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { positionsHistorySchema } from './schemas';

export const getPositionsHistoryAsync = createThunk<
  IPositionParamsBE,
  Omit<IPositionsHistoryBE, 'positions'> | void
>(
  ({ shouldClearBeforeMerge = false, ...params }) =>
    async function getPositionsHistory(this: unknown, _options, _flow) {
      const root = getRootInstance();

      try {
        const res = await api.Trading.getPositionsHistory(params, _options);

        const { positions, ...rest } = res;
        root.entities.normalizeMerge(
          positions,
          positionsHistorySchema,
          shouldClearBeforeMerge
        );

        return rest;
      } catch (e) {
        devLoggerService.error('Error in getPositionsHistoryAsync', e);
      }
    }
);
