import { useMst } from '@trader/store';
import { IConditionsTypes, TApplyCondition } from './index';

export const useFreeScoreLevelBusinessLogic = (
  conditions: IConditionsTypes
): TApplyCondition => {
  const store = useMst();

  const isDemoInUse: boolean = store.user.isDemoAccInUse();

  const { isRegistrationCompleted, isTradingAllowed, isDepositAllowed } =
    conditions;

  return () => {
    if (isRegistrationCompleted) {
      return {
        status: isDemoInUse ? 'passed' : 'failed',
        action: () => {
          window.open(import.meta.env.VITE_REGISTRATION_LINK);
        },
      };
    }

    if (isTradingAllowed || isDepositAllowed) {
      return {
        status: 'failed',
        action: () => {
          window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        },
      };
    }

    return {
      status: 'passed',
    };
  };
};
