import { defaultCategories } from '@trader/constants';
import { devLoggerService } from '@trader/services';
import { api } from '@trader/api';
import { TCategoryEntity } from '@trader/store';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { categoriesSchema } from './schemas';

export const getCategoriesAsync = createThunk<void, void>(
  () =>
    async function getCategories(this: unknown, _options, _flow) {
      const root = getRootInstance();
      const response = await api.Category.getCategories(_options);

      // Add ids to category and subcategory fields.
      const categories = (response || []).map(c => ({
        ...c,
        id: c.name,
        subCategories: c.subCategories.map(sc => ({ ...sc, id: sc.name })),
      }));

      try {
        root.entities.normalizeMerge(
          [...defaultCategories, ...categories],
          categoriesSchema,
          true
        );

        const selectedCategory = root.pages.trading.selectedCategory;
        const newCategory =
          root.entities.categories.getAll<TCategoryEntity>()[2];

        root.pages.trading.runInAction(() => {
          root.pages.trading.selectedCategory = selectedCategory || newCategory;
        });
      } catch (e) {
        devLoggerService.error('Error in getCategoriesAsync', e);
      }
    }
);
