import { getMaxDigitWithDot } from '../numberFormats';

interface ICalculate {
  ask: number;
  bid: number;
  pipSize: number;
}

type TCalculate = (params: ICalculate) => string;

export const calculateSpread: TCalculate = ({ ask, bid, pipSize }) => {
  const result = bid - ask;
  const countAfterDot = getMaxDigitWithDot(pipSize);
  const spread = result * multiplyValues[countAfterDot];

  return spread.toFixed(0);
};

const multiplyValues = {
  0: 1,
  1: 10,
  2: 100,
  3: 1000,
  4: 10000,
  5: 100000,
  6: 1000000,
  7: 10000000,
};
