import { TPlaceOrderSide } from '@trader/types';
import { getOrderBasePrice } from './common';

const getLoss = (
  quantity: number,
  rate: string,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number
) => quantity * (getOrderBasePrice(side, bidPrice, askPrice) - +rate);

export const getConvertedLoss = (
  quantity: number,
  stopLossRate: string,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  currencyConvert: number
) =>
  getLoss(quantity, stopLossRate, side, bidPrice, askPrice) / currencyConvert;
