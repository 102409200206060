import styled, { css } from 'styled-components';
import { Wrapper, Typography } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 850px;
    height: 550px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 100%;
    }
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    text-align: start;
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.background.default};
    margin-bottom: 10px;
  `}
`;
