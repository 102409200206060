import { emptyFn, isLiveEnv } from '@trader/constants';

/**
 * Prevents browser error messages.
 */
export const disableConsoleErrors = () => {
  if (isLiveEnv) {
    console.error = emptyFn;
    console.warn = emptyFn;
    console.log = emptyFn;
    console.info = emptyFn;

    window.onerror = function () {
      return true;
    };
  }
};
