import { types, Instance, SnapshotIn } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';
import { EBandType } from '@trader/types';

export const bandModel = types
  .model('bandModel', {
    value: types.string,
    order: types.number,
    id: types.string,
    title: types.optional(types.string, ''),
    type: types.union(
      types.literal(EBandType.lower),
      types.literal(EBandType.upper)
    ),
  })
  .actions(() => ({
    runInAction,
  }));

export type TBandModel = Instance<typeof bandModel>;
export type TBandModelSnapshotIn = SnapshotIn<typeof bandModel>;
