import React from 'react';
import { SliderOwnProps } from '@mui/material';

import * as Styled from './styled';

interface ISlider extends Omit<SliderOwnProps, 'value' | 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  label?: string;
  isLabelValue?: boolean;
}

export const Slider: React.FC<ISlider> = React.memo(
  ({ value, onChange, label, isLabelValue, ...rest }) => {
    return (
      <Styled.Root>
        {label && (
          <Styled.Label>
            {label} {isLabelValue ? value : ''}
          </Styled.Label>
        )}
        <Styled.Slider
          value={typeof value === 'number' ? value : 0}
          onChange={(_event, newValue) => onChange(newValue as number)}
          {...rest}
        />
      </Styled.Root>
    );
  }
);
