import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';

import { defaultPageSize } from '@trader/constants';
import { useInstruments } from '@trader/hooks';
import { useMst } from '@trader/store';

import { Instrument } from './Instrument';

import * as Styled from './styled';

export const Instruments: React.FC = observer(() => {
  const store = useMst();
  const category = store.pages.trading.selectedCategory;
  const subCategory = store.pages.trading.selectedSubCategory;

  const instruments = store.pages.trading.getInstrumentsByCategory(
    category?.name || '',
    subCategory
  );

  useInstruments([...instruments.map(i => i.symbol)]);

  const loadMore = useCallback(async () => {
    await store.entities.instruments.getInstrumentsAsync.run({
      pageNumber: Number((instruments.length / defaultPageSize + 1).toFixed()),
    });
  }, [instruments.length]);

  return (
    <Styled.Box>
      <Virtuoso
        style={{ height: '100%' }}
        data={instruments}
        endReached={(index: number) => {
          if (
            index + 1 >= defaultPageSize &&
            index + 1 < store.pages.trading.instrumentsAmount
          ) {
            loadMore();
          }
        }}
        increaseViewportBy={{
          top: 300,
          bottom: 650,
        }}
        itemContent={(_index, instrument) => {
          return (
            <Instrument
              key={instrument.symbol}
              symbol={instrument.symbol}
              miniChartCoordinates={instrument.miniChartCoordinates}
            />
          );
        }}
      />
    </Styled.Box>
  );
});
