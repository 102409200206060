import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    min-height: 120px;
    width: 410px;
  `}
`;

export const Symbol = styled(Typography)`
  ${() => css`
    font-weight: 700;
    font-size: 16px;
    margin-bottom: -4px;
  `}
`;

export const Text = styled(Typography)`
  ${() => css`
    font-weight: 400;
    font-size: 12px;
  `}
`;

export const InstrumentInfo = styled(Wrapper)`
  ${({ theme }) => css`
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.background.default};
    padding: 8px ${modalHorizontalPadding}px;
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 12px;
    color: ${theme.palette.gray['100']};
  `}
`;

export const Selection = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    border-bottom: 1px solid ${theme.palette.background.default};
    margin-bottom: 12px;
    padding: 16px ${modalHorizontalPadding}px;
  `}
`;

export const SideSelectionButton = styled(Button)<{
  $isSelected: boolean;
}>`
  ${({ theme, $isSelected }) => css`
    width: 180px;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid ${theme.palette.common.black};
    background-color: transparent;
    color: ${theme.palette.common.black};

    ${theme.breakpoints.down('sm')} {
      width: 160px;
    }

    &:hover {
      background: transparent;
      color: ${theme.palette.common.black};
    }

    ${conditionApply(
      [$isSelected],
      css`
        border: 1px solid ${theme.palette.purchaseButtons.green};
        background: ${theme.palette.purchaseButtons.green};
        color: ${theme.palette.common.white};

        &:hover {
          background: ${theme.palette.purchaseButtons.green};
          color: ${theme.palette.common.white};
        }
      `
    )};
  `}
`;

export const Input = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 0 ${modalHorizontalPadding}px;

    .tradingInput-customLabel {
      width: 334px;
    }
  `}
`;

export const ConfirmButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    padding: 12px;
    margin: 12px ${modalHorizontalPadding}px 0 ${modalHorizontalPadding}px;
  `}
`;
