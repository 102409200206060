import React from 'react';
import { TypographyProps } from '@mui/material';
import * as Styled from './styled';

export const Typography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  return <Styled.Typography {...props}>{children}</Styled.Typography>;
};
