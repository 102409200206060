import { getIconBaseProps } from './utils';

export const copier = {
  ...getIconBaseProps('copier'),
  width: '20px',
  height: '23px',
  viewBox: '0 0 20 23',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5.79993 9.11556C5.79993 11.9478 7 13.0807 7.59986 14.2134C7.88064 14.7432 8.27497 16.0715 6.99897 16.5138C2.19973 18.1782 1 19.8775 1 19.8775V21.5768H19V19.8775C19 19.8775 17.7999 18.1782 13 16.479C11.7137 16.0237 12.1235 14.7355 12.4001 14.213C13 13.0801 14.2001 11.9471 14.2001 9.11523'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
      />
      <path
        d='M4.7356 2.64556L5.32037 7.53606H14.6788L15.2639 2.64556L12.4367 4.37736L9.99958 1.42285L7.56246 4.37736L4.7356 2.64556Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
      />
    </>
  ),
};
