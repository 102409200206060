import React from 'react';

import { AssetDetails } from '@trader/containers';

import * as Styled from './styled';

interface IAssets {
  selectedTab?: string;
  navigationKey?: string;
}

export const Assets: React.FC<IAssets> = ({ selectedTab, navigationKey }) => {
  return (
    <Styled.Root>
      <AssetDetails selectedTab={selectedTab} navigationKey={navigationKey} />
    </Styled.Root>
  );
};
