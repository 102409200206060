import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import {
  Form,
  ModalTitle,
  Progress,
  Tabs,
  TradingIsClosed,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { MODAL_TYPES } from '@trader/constants';
import { TInstrumentEntity, useMst } from '@trader/store';
import { TPlaceOrderType, IInitialTradingFormValues } from '@trader/types';

import { usePlaceOrderFormValidation } from './formValidation';
import { MarketOrder } from './components/MarketOrder';
import { LimitOrder } from './components/LimitOrder';

import * as Styled from './styled';
import { TradingRealTimeUpdatesInitializer } from '@trader/trading';

const CreateOrder: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const defaultFormValues = useGetTradingDefaultFormValues({
    symbol: instrument.symbol,
    side: trading.side,
  });
  const { validationSchema } = usePlaceOrderFormValidation('modifyOrder');

  const { applyCondition } = useBusinessLogic(
    EConditionType.RegistrationCompleted
  );
  const { applyCondition: applyTradingAllowedCondition } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

  if (applyCondition().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='completeRegistration'
        title={translate('WARNINGS.COMPLETE_REGISTRATION')}
        content={translate('WARNINGS.HAVENT_COMPLETED_REGISTRATION')}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyCondition()?.action}
      />
    );
  }

  if (instrument.disabledByMarketNews) {
    store.ui.modal.open(MODAL_TYPES.tradingDisabledDueToMarketNews, {
      shouldHideCloseButton: true,
      borderRadius: 10,
    });
    return null;
  }

  if (applyTradingAllowedCondition().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyTradingAllowedCondition()?.action}
      />
    );
  }

  if (instrument.tradingStatus === 'CLOSE_ONLY') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
      />
    );
  }

  if (isDailyTargetGoalReached) {
    store.ui.modal.open(MODAL_TYPES.dailyTargetProfitReached, {
      shouldHideCloseButton: true,
      borderRadius: 10,
    });
    return null;
  }

  const tabs = [
    {
      value: 'Market',
      label: translate('COMMON.LABELS.MARKET'),
      children: <MarketOrder type='modifyOrder' />,
    },
    {
      value: 'EntryLimit',
      label: translate('COMMON.LABELS.LIMIT_STOP_ORDER'),
      children: <LimitOrder type='modifyOrder' />,
    },
  ];

  return (
    <Styled.Root $isInvestmentAccProduct={isInvestmentAccProduct}>
      <ModalTitle
        title={instrument.symbol}
        bottomMargin={isInvestmentAccProduct ? '0' : undefined}
      />
      <Form<IInitialTradingFormValues>
        onSubmit={async (_reset, data) => {
          await trading.prePlaceOrderAsync.run(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ setValue, reset, getValues }) => (
          <TradingRealTimeUpdatesInitializer type='modifyOrder'>
            {isInvestmentAccProduct ? (
              <MarketOrder type='modifyOrder' />
            ) : (
              <Tabs
                tabsPosition='top'
                tabs={tabs}
                onChange={key => {
                  trading.updateOrderType(key as TPlaceOrderType);
                  setValue('orderType', key as TPlaceOrderType);
                  reset(
                    {
                      side: getValues().side,
                      orderType: key as TPlaceOrderType,
                    },
                    { keepValues: true }
                  );
                }}
              />
            )}
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
    </Styled.Root>
  );
});

export const CreateOrderWrapper = observer(() => {
  const store = useMst();

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument;

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  if (!instrument) {
    return (
      <Styled.Root $isLoading $isInvestmentAccProduct={isInvestmentAccProduct}>
        <Progress />
      </Styled.Root>
    );
  }

  return <CreateOrder />;
});
