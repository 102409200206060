import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Content = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 120px;
    height: 30px;
  `};
`;
