import styled, { css } from 'styled-components';
import { Wrapper, Typography } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 920px;
    height: 527px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    text-align: start;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme.palette.background.default};
    margin-bottom: 10px;
  `}
`;
