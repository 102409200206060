import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

interface ILoading {
  $isLoading?: boolean;
  $isInvestmentAccProduct: boolean;
  height?: number;
}

export const Root = styled(Wrapper)<ILoading>`
  ${({ $isLoading, $isInvestmentAccProduct, height }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${$isLoading ? 'center' : 'flex-start'};
    width: ${$isInvestmentAccProduct ? '360px' : '408px'};
    min-height: 300px;

    ${conditionApply(
      [!$isLoading],
      css`
        > div:last-child {
          justify-content: flex-end;
        }
      `
    )}

    ${conditionApply(
      [height],
      css`
        height: ${height}px;
      `
    )}
  `}
`;
