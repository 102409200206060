import React from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const PerformanceSummary: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;

  if (!backTesting.symbolOption) {
    return null;
  }

  const symbol = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.symbolOption.value
  );

  const takeProfit = backTesting?.summary?.totalProfit || 0;
  const avgProfit = backTesting?.summary?.avgProfit || 0;
  const maxProfit = backTesting?.summary?.maxProfit || 0;

  return (
    <Styled.Content>
      <Styled.Item>
        <span>{translate('COMMON.LABELS.TAKE_PROFIT')}:</span>
        <Styled.ItemValue>
          {formatByPipSize(takeProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>{translate('MULI_BANDS.AVERAGE_PROFIT')}:</span>
        <Styled.ItemValue>
          {formatByPipSize(avgProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>{translate('MULI_BANDS.MAX_PROFIT')}:</span>
        <Styled.ItemValue>
          {formatByPipSize(maxProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>{translate('MULI_BANDS.NUMBER_OF_TRADES')}:</span>
        <Styled.ItemValue>
          {backTesting?.summary?.numberOfTrades}
        </Styled.ItemValue>
      </Styled.Item>
    </Styled.Content>
  );
});
