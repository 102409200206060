import * as yup from 'yup';

import {
  highMultiplier,
  lowMultiplier,
  multiplierStep,
} from '@trader/constants';
import { convertFromOption } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';

const minStopLoss = 1;

export const useBackTestingFormValidation = () => {
  const store = useMst();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    convertFromOption<string>(backTesting.getSymbolOption())
  );

  const SlValidation = (isRequired: boolean, value?: number) => {
    if (!isRequired) {
      return true;
    }

    if (!value) {
      return false;
    }

    return value >= minStopLoss;
  };

  return yup.object().shape({
    symbol: yup
      .array()
      .test('check-symbol', 'Required', value => {
        return !!value?.length;
      })
      .required('Required'),
    lowestMultiplier: yup
      .number()
      .typeError('Required')
      .min(lowMultiplier, `Min ${lowMultiplier}`)
      .max(highMultiplier, `Max ${highMultiplier}`)
      .test('check-multiplier', 'Required', value => {
        return !!value || !!Number(value);
      }),
    highestMultiplier: yup
      .number()
      .typeError('Required')
      .min(lowMultiplier, `Min ${lowMultiplier}`)
      .max(highMultiplier, `Max ${highMultiplier}`)
      .when(['lowestMultiplier'], ([lowestMultiplier], schema) => {
        return schema.test(
          'check-lowestMultiplier',
          `Higher than ${lowestMultiplier}`,
          value => {
            if (!value) {
              return false;
            }

            if (lowestMultiplier) {
              return value >= lowestMultiplier;
            }
          }
        );
      }),
    multiplierStep: yup
      .number()
      .typeError('Required')
      .min(multiplierStep, `Min ${multiplierStep}`)
      .max(multiplierStep, `Max ${highMultiplier}`)
      .test('check-multiplier', 'Required', value => {
        return !!value || !!Number(value);
      }),
    from: yup.date().typeError('Invalid date').required('Required'),
    to: yup
      .date()
      .typeError('Invalid date')
      .when(['from'], ([from], schema) => {
        return schema.test('dates-diff', 'Invalid date', value => {
          if (!value) {
            return false;
          }

          if (from) {
            const fromTimestamp = new Date(from).getTime();
            const toTimestamp = new Date(value).getTime();
            return toTimestamp > fromTimestamp;
          }
        });
      })
      .required('Required'),
    sessionFrom: yup
      .date()
      .typeError('Invalid time')
      .when(['isSessionRange'], ([isSessionRange], schema) => {
        return schema.test('sessionFrom', 'Invalid time', value => {
          if (!isSessionRange) {
            return true;
          }

          return !!value;
        });
      }),
    sessionTo: yup
      .date()
      .typeError('Invalid time')
      .when(
        ['sessionFrom', 'isSessionRange'],
        ([sessionFrom, isSessionRange], schema) => {
          return schema.test('sessionTo', 'Invalid time', value => {
            if (!isSessionRange) {
              return true;
            }

            if (!sessionFrom) {
              return true;
            }

            if (!value) {
              return false;
            }

            if (sessionFrom) {
              const fromTimestamp = new Date(sessionFrom).getTime();
              const toTimestamp = new Date(value).getTime();
              return toTimestamp > fromTimestamp;
            }
          });
        }
      ),
    quantity: yup
      .number()
      .typeError('Required')
      .min(instrument?.minOrderSize, `Higher than ${instrument?.minOrderSize}`)
      .max(instrument?.maxOrderSize, `Lower than ${instrument?.maxOrderSize}`)
      .required('Required'),
    sl: yup
      .number()
      .typeError('Must be number')
      .when(['isSl'], ([isSl], schema) => {
        return schema.test('sl', 'Invalid value', value =>
          SlValidation(isSl, value)
        );
      }),
    slDay: yup
      .number()
      .typeError('Must be number')
      .when(['isSlDay'], ([isSlDay], schema) => {
        return schema.test('slDay', 'Invalid value', value =>
          SlValidation(isSlDay, value)
        );
      }),
  });
};
