/* eslint-disable no-throw-literal */
import { VariantType } from 'notistack';

import { httpClient, THttpClientInternalRequestConfig } from './httpClient';
import { refreshTokenWithReSendLastRequest } from './refreshToken';
import { EErrorTypes, IHttpClientResponseError } from './requestErrorType';
import { devLoggerService } from '../devLogger';
import { handleErrors } from './handleErrors';

const STATUS_CODE = {
  401: 401,
  403: 403,
  422: 422,
  500: 500,
};

interface IGetAuthRefreshTokenBE {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  tokenType: string;
}

const REQUEST_TIMEOUT = 10000; // 10s;

interface IConfig {
  getAccessToken: () => string | void;
  refreshToken: () => Promise<IGetAuthRefreshTokenBE | undefined> | void;
  getTokenType: () => string | void;
  getSessionId: () => string | void;
  getBrand: () => string | void;
  getApiType: () => string | void;
  getUserAccount: () => {
    accountType: string;
    platformLogin: string;
    product?: string;
  } | void;
  createNotification: (message: string, variant?: VariantType) => string | void;
  logout: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const initFunc = () => {};

export class HttpClientConfig {
  constructor() {
    this._getAccessToken = initFunc;
    this._refreshToken = initFunc;
    this._getTokenType = initFunc;
    this._getSessionId = initFunc;
    this._getBrand = initFunc;
    this._getApiType = initFunc;
    this._logout = initFunc;
    this._getUserAccount = initFunc;
    this._createNotification = initFunc;
  }

  private _getAccessToken: IConfig['getAccessToken'];
  private _refreshToken: IConfig['refreshToken'];
  private _logout: IConfig['logout'];
  private _getUserAccount: IConfig['getUserAccount'];
  private _getTokenType: IConfig['getTokenType'];
  private _getSessionId: IConfig['getSessionId'];
  private _getBrand: IConfig['getBrand'];
  private _getApiType: IConfig['getApiType'];
  private _createNotification: IConfig['createNotification'];

  initialize(config: IConfig): void {
    this._refreshToken = config.refreshToken;
    this._getSessionId = config.getSessionId;
    this._getBrand = config.getBrand;
    this._getApiType = config.getApiType;
    this._getAccessToken = config.getAccessToken;
    this._getUserAccount = config.getUserAccount;
    this._logout = config.logout;
    this._getTokenType = config.getTokenType;
    this._createNotification = config.createNotification;
    this._setDefaults();
    this._setRequestInterceptors();
    this._setResponseInterceptors();
  }

  private get _authHeader() {
    const token = this._getAccessToken();
    const tokenType = this._getTokenType();
    return token ? `${tokenType} ${token}` : '';
  }

  private get _sessionId() {
    const session = this._getSessionId();
    return session ? session : '';
  }

  private get _apiType() {
    const apiType = this._getApiType();
    return apiType ? apiType : '';
  }

  private get _accountHeader() {
    const account = this._getUserAccount();
    const brand = this._getBrand();
    const header: {
      accountType?: string;
      platformLogin?: string;
      product?: string;
      brand?: string;
    } = {};

    if (account && account.accountType && account.platformLogin) {
      header.accountType = account.accountType;
      header.platformLogin = account.platformLogin;
    }

    if (account && account.product) {
      header.product = account.product;
    }

    if (brand) {
      header.brand = brand;
    }

    return !Object.keys(header).length ? undefined : header;
  }

  // eslint-disable-next-line class-methods-use-this
  private _setDefaults() {
    httpClient.defaults = {
      timeout: REQUEST_TIMEOUT,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  private _setRequestInterceptors() {
    httpClient.interceptors.request.use(
      (
        config: THttpClientInternalRequestConfig
      ): THttpClientInternalRequestConfig => {
        if (this._authHeader) {
          config.headers.Authorization = this._authHeader;

          if (this._sessionId) {
            config.headers.Session = this._sessionId;
          }

          if (
            this._accountHeader?.accountType &&
            !config.headers['Account-Type']
          ) {
            config.headers['Account-Type'] = this._accountHeader.accountType;
          }

          if (
            this._accountHeader?.platformLogin &&
            !config.headers['Platform-Login']
          ) {
            config.headers['Platform-Login'] =
              this._accountHeader.platformLogin;
          }
        }

        if (this._accountHeader?.product) {
          config.headers['Product'] = this._accountHeader.product;
        }

        if (this._accountHeader?.brand) {
          config.headers['Brand'] = this._accountHeader.brand;
        }

        if (this._apiType) {
          // TODO: (av) Fix loop dependency and use urlHelpers.getBaseUrlWithApiType.
          const url = import.meta.env.VITE_BASE_URL;
          const baseUrl = url.replace(/\.([^.]+)\./, `.${this._apiType}.`);
          config.baseURL = baseUrl;
        }

        return config;
      },
      (error: Error): Error => {
        devLoggerService.error('Error in _setRequestInterceptors', { error });
        throw error;
      }
    );
  }

  private _setResponseInterceptors() {
    httpClient.interceptors.response.use(
      response => {
        return {
          ...response,
          data: response.data.data || response.data,
        };
      },
      (error: IHttpClientResponseError) => {
        const response = error.response;
        const errors = error?.response?.data?.errors;
        const errorStatus = error?.response?.data?.status;
        const responseStatusCode = error?.response?.status;

        errors &&
          errorStatus &&
          handleErrors(
            errors,
            errorStatus,
            responseStatusCode,
            this._createNotification
          );
        if (error.code === 'ERR_CANCELED') {
          throw error;
        }

        if (!response?.status) {
          return;
        }

        if (response.status === STATUS_CODE[403]) {
          // TODO: add accessDenied logic if need
          return;
        }

        if (
          response.status === STATUS_CODE[401] ||
          response.status === STATUS_CODE[403]
        ) {
          if (
            errors?.length &&
            errors[0].type === EErrorTypes.SESSION_EXPIRED
          ) {
            this._logout();
          } else {
            const refresh = this._refreshToken as () => Promise<
              IGetAuthRefreshTokenBE | undefined
            >;
            // eslint-disable-next-line consistent-return
            return refreshTokenWithReSendLastRequest(
              response.config,
              refresh,
              this._logout,
              this._accountHeader
            );
          }
        }

        if (response.status >= STATUS_CODE[500]) {
          console.log('STATUS_CODE[500]', STATUS_CODE[500]);
        }
        throw error;
      }
    );
  }
}

export const httpClientConfig = new HttpClientConfig();
