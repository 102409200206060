import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    position: relative;
    width: 100%;
  `}
`;

export const Header = styled(Wrapper)`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 4px 6px 10px;
    border-bottom: 1px solid ${theme.palette.gray['200']};

    button {
      padding: 8px;
    }

    svg {
      margin-top: 2px;
      width: 12px;
      height: 12px;
    }
  `}
`;

export const Content = styled('div')`
  ${() => css`
    width: 100%;
    margin-top: 45px;
    padding: 0 8px 10px 8px;
    overflow: auto;
  `}
`;
