import React from 'react';
import { ISvgIconProps, Icon as StyledIcon } from './styled';
import { Icons, TIconsName } from '@trader/assets';
import { IconColors } from '@trader/themes';
export { Icon as StyledIcon } from './styled';

export interface IIconProps
  extends Omit<ISvgIconProps, 'color' | 'width' | 'height'> {
  iconType: TIconsName;
  color?: ISvgIconProps['color'] | string;
  fontSize?: string;
}

export const Icon: React.FC<IIconProps> = ({
  iconType,
  className,
  fontSize,
  color = 'inherit',
  style,
  ...rest
}) => {
  if (!iconType) {
    return null;
  }

  const iconEntity = Icons[iconType];
  const classNameFromProps = className || '';
  const classNameWithDefault = `icon ${iconEntity.className} ${classNameFromProps}`;
  const propertyName = Object.keys(IconColors).includes(color)
    ? 'color'
    : 'htmlColor';
  const colorProps = { [propertyName]: color };
  const initStyle = { fill: 'transparent', ...style };

  return (
    <StyledIcon
      viewBox={iconEntity.viewBox}
      xmlns='http://www.w3.org/2000/svg'
      className={classNameWithDefault}
      fontSize={fontSize}
      style={initStyle}
      {...colorProps}
      {...rest}
      width={iconEntity.width}
      height={iconEntity.height}
    >
      {iconEntity.body}
    </StyledIcon>
  );
};
