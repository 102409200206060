import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    height: 54px;

    > p {
      font-size: 13px;
      font-weight: 400;
      color: ${theme.palette.gray[800]};
    }
  `}
`;
