const defaultTakeValue = 1500;
const extraTakeValue = 1500;
const convertTimeFor = 1000;

export const marketDataRoutes = {
  getCandleBars: (fromTime: number) =>
    `/historical/bars?take=${!fromTime ? defaultTakeValue : extraTakeValue}${
      fromTime
        ? `&fromTime=${new Date(fromTime * convertTimeFor).toISOString()}`
        : ''
    }`,
  getIndicators: '/historical/indicators',
} as const;
