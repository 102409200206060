import { getIconBaseProps } from './utils';

export const calendar = {
  ...getIconBaseProps('calendar'),
  width: '15px',
  height: '17px',
  viewBox: '0 0 15 17',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M12.5575 2.48242H2.4464C1.64865 2.48242 1.00195 3.14651 1.00195 3.96571V14.3488C1.00195 15.168 1.64865 15.8321 2.4464 15.8321H12.5575C13.3553 15.8321 14.002 15.168 14.002 14.3488V3.96571C14.002 3.14651 13.3553 2.48242 12.5575 2.48242Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.00195 6.93262H14.002'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3906 0.999023V3.96561'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.61328 0.999023V3.96561'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
