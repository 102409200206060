import { getIconBaseProps } from './utils';

export const myHistory = {
  ...getIconBaseProps('myHistory'),
  width: '22px',
  height: '19px',
  viewBox: '0 0 22 19',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M16.096 15.3781C12.757 18.6975 7.34332 18.6975 4.00428 15.3781C0.665239 12.0586 0.665239 6.67673 4.00428 3.35727C7.34332 0.037812 12.757 0.0378114 16.096 3.35727C17.7666 5.01806 18.6014 7.19513 18.6003 9.37187L18.6003 11.2566M18.6003 11.2566L20.5 9.36767M18.6003 11.2566L16.6999 9.36768M11.0003 5.58951V10.3117L6.25018 10.3117'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
