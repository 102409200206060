import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

interface IRoot {
  $alignment?: string;
}

export const Root = styled(Wrapper)<IRoot>`
  ${({ theme, $alignment }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    background: ${theme.palette.background.paper};
    ${conditionApply(
      [$alignment],
      css`
        justify-content: center;
        align-items: center;
      `
    )};

    ${theme.breakpoints.down('sm')} {
      background: ${theme.palette.background.default};
    }
  `};
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    margin: 26px 0 26px 25px;
    padding-right: 25px;
    flex-wrap: wrap;

    overflow: auto;
    justify-content: center;

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    .firstColumn {
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
    }

    .lastColumn {
      flex: 1;
      flex-direction: column;

      ${theme.breakpoints.down('sm')} {
        .swapType {
          max-width: 180px;
        }
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin: 0;
      padding-right: 0;
      padding-top: ${layoutMargin}px;
      border-radius: ${layoutChildrenRadius}px;
      background: ${theme.palette.background.paper};
    }
  `};
`;

export const Stats = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 357px;
    margin-bottom: 50px;

    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
      min-width: 100%;
      padding: 0 8px;
    }
  `};
`;

export const Trend = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    min-width: 270px;
    align-items: center;
    margin-bottom: 20px;
    flex: 1;

    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
      width: 100%;
      padding: 0 8px;
    }
  `};
`;

export const Conditions = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 357px;
    margin-bottom: 20px;

    .tradingHours {
      flex-direction: column;

      span {
        margin-right: 4px;
      }
    }

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
      padding: 0 8px;
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 15px;
    margin-left: 17px;
    font-weight: 700;
    margin-bottom: 13px;
  `};
`;

export const Item = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 17px;
    border: 1px solid ${theme.palette.gray.light};

    .daily-stats-low {
      color: ${theme.palette.purchaseButtons.red};
    }

    .daily-stats-high {
      color: ${theme.palette.purchaseButtons.green};
    }
  `};
`;

export const ItemTitle = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 500;
  `};
`;

export const ItemValue = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 400;
    white-space: normal;
  `};
`;

interface IBorderWidth {
  $type: 'sold' | 'bought';
  $width: number;
  $isFetched: boolean;
  $isGray?: boolean;
}

export const TrendContainer = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 73px;
    box-sizing: content-box;
    width: 220px;
    border-radius: 8px;
    border: 0.5px solid ${theme.palette.gray.light};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `};
`;

export const TrendMain = styled(Wrapper)<IBorderWidth>`
  ${({ theme, $type, $width, $isFetched, $isGray }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    height: 100%;
    padding: 0 17px;

    ${conditionApply(
      [$type === 'sold' && $isFetched],
      css`
        align-items: flex-start;
        border: ${$width
          ? `0.5px solid ${theme.palette.purchaseButtons.red}`
          : `0.5px solid ${theme.palette.purchaseButtons.green}`};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none !important;
        width: ${$width}%;
      `
    )};

    ${conditionApply(
      [$type === 'bought' && $isFetched],
      css`
        align-items: flex-end;
        border: ${$width
          ? `0.5px solid ${theme.palette.purchaseButtons.green}`
          : `0.5px solid ${theme.palette.purchaseButtons.red}`};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none !important;
        width: ${$width}%;
      `
    )};

    ${conditionApply(
      [$isGray],
      css`
        border: 0.5px solid ${theme.palette.gray.light};
      `
    )};

    .bought {
      color: ${theme.palette.purchaseButtons.green};
      text-align: end;
    }

    .sold {
      color: ${theme.palette.purchaseButtons.red};
      text-align: start;
    }
  `};
`;

export const TrendPrice = styled(Typography)`
  ${() => css`
    font-size: 20px;
    font-weight: 700;
  `};
`;

export const TrendTitle = styled(Typography)`
  ${() => css`
    font-size: 14px;
    font-weight: 500;
  `};
`;
