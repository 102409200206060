import { getIconBaseProps } from '../utils';

export const positionsAndAlerts = {
  ...getIconBaseProps('positionsAndAlerts'),
  width: '28px',
  height: '28px',
  viewBox: '0 0 28 28',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M3.81787 2.54541H24.1815V24.8579L21.9673 23.2405C21.2646 22.7272 20.3106 22.7272 19.6079 23.2405L17.3936 24.8579L15.1794 23.2405C14.4767 22.7272 13.5227 22.7272 12.82 23.2405L10.6057 24.8579L8.39153 23.2405C7.68881 22.7272 6.73481 22.7272 6.03209 23.2405L3.81787 24.8579V2.54541Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.363 7.50342L9.63574 7.50342'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.363 12.4624L9.63574 12.4624'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3636 17.4204L12.5454 17.4204'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
