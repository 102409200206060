import React, { useState } from 'react';
import { useMst } from '@trader/store';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { muliBandsInitial } from '@trader/containers';

const Box = React.forwardRef<HTMLDivElement | null>((_props, ref) => {
  const store = useMst();

  const movingValues = store.pages.muliBands.strip.movingValues;
  const floatingBox = store.pages.muliBands.generalTab;

  const [state] = useState({
    top: floatingBox.top,
    left: floatingBox.left,
  });

  return (
    <Styled.Container ref={ref} $top={state.top} $left={state.left}>
      <Styled.Header>
        <Styled.Title>Data Box</Styled.Title>
        <Styled.Close
          onClick={() => store.pages.muliBands.updateGeneralBox(null)}
          iconType='remove'
          isSingleIcon
          variant='text'
        />
      </Styled.Header>
      <Styled.Content>
        {movingValues.map(({ value, title }) => (
          <Styled.Value
            key={title}
            $background={muliBandsInitial[title]?.color}
          >
            <span>{title}:</span> <span>{value}</span>
          </Styled.Value>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
});

export const DataBox = observer(Box);
