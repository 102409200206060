import {
  StudyBooleanInputInfo,
  StudyInputInfo,
  StudyNumericInputInfo,
} from '../charting_library';

export const limitOrdersInputs: StudyInputInfo[] = [
  {
    id: 'useLimitOrders',
    name: 'Use limit orders',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: true,
    isHidden: true,
  },
  {
    id: 'LoLength',
    name: 'Limit orders length',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 12,
    min: 1,
    step: 1,
    isHidden: true,
  },
  {
    id: 'LoMultiplier',
    name: 'Limit orders multiplier',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 0.1,
    min: 0.1,
    step: 0.1,
    isHidden: true,
  },
];
