import styled, { css } from 'styled-components';

import { Wrapper } from '../wrapper';
import { IconButton } from '../iconButton';

interface ILayout {
  $layout?: string;
}

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  `};
`;

export const Actions = styled(Wrapper)<ILayout>`
  ${({ $layout }) => css`
    display: flex;
    flex-direction: ${$layout === 'threeRow' ? 'column' : 'row'};
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  `};
`;

export const Button = styled(IconButton)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.common.black};

    svg {
      width: 20px;
      height: 20px;
    }
  `};
`;
