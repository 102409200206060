import { getIconBaseProps } from '../utils';

export const synthetics = {
  ...getIconBaseProps('synthetics'),
  width: '22px',
  height: '22px',
  viewBox: '0 0 22 22',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M3.2448 17.7552C2 16.5104 2 14.5069 2 10.5C2 6.49306 2 4.48959 3.2448 3.2448C4.48959 2 6.49306 2 10.5 2C14.5069 2 16.5104 2 17.7552 3.2448C19 4.48959 19 6.49306 19 10.5C19 14.5069 19 16.5104 17.7552 17.7552C16.5104 19 14.5069 19 10.5 19C6.49306 19 4.48959 19 3.2448 17.7552Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0937 13.1562C12.0937 12.5694 12.5694 12.0937 13.1562 12.0937H14.2187C14.8056 12.0937 15.2812 12.5694 15.2812 13.1562V14.2187C15.2812 14.8056 14.8056 15.2812 14.2187 15.2812H13.1562C12.5694 15.2812 12.0937 14.8056 12.0937 14.2187V13.1562Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.71875 6.78125C5.71875 6.19445 6.19445 5.71875 6.78125 5.71875H7.84375C8.43055 5.71875 8.90625 6.19445 8.90625 6.78125V7.84375C8.90625 8.43055 8.43055 8.90625 7.84375 8.90625H6.78125C6.19445 8.90625 5.71875 8.43055 5.71875 7.84375V6.78125Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
