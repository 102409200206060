import React from 'react';

import { Typography, Wrapper } from '@trader/components';
import { addSeparatorToIntegerNumber } from '@trader/utils';
import { useI18next } from '@trader/services';
import { TAvailableChallengeEntity } from '@trader/store';

import * as Styled from './styled';

interface IPurchaseDetails {
  challenge?: TAvailableChallengeEntity;
  isPurchaseButtonLoading: boolean;
  onPurchaseButtonClick: () => void;
}

export const PurchaseDetails: React.FC<IPurchaseDetails> = ({
  challenge,
  isPurchaseButtonLoading,
  onPurchaseButtonClick,
}) => {
  const { translate } = useI18next();

  // Hardcode it for now. Need to get the value from back-end later.
  const eligibleDailyProfit = 2.5;
  const detailItems = [
    {
      name: translate('PURCHASE_CHALLENGES.DURATION'),
      value: challenge?.tradingPeriod,
      formatter: (value: unknown) => `${value} Days`,
    },
    {
      name: translate('PURCHASE_CHALLENGES.DAILY_DRAWDOWN'),
      value: challenge?.maxDailyDrawdown,
      formatter: (value: unknown) => `${value}%`,
    },
    {
      name: translate('PURCHASE_CHALLENGES.MAX_DRAWDOWN'),
      value: challenge?.maxTotalDrawdown,
      formatter: (value: unknown) => `${value}%`,
    },
    {
      name: translate('PURCHASE_CHALLENGES.ELIGIBLE_DAILY_PROFIT'),
      value: !challenge ? undefined : eligibleDailyProfit,
      supNumber: 1,
      formatter: (value: unknown) => `${value}%`,
    },
    {
      name: translate('PURCHASE_CHALLENGES.PROFIT_TARGET'),
      value: challenge?.profitTarget,
      formatter: (value: unknown) => `${value}%`,
    },
    {
      name: translate('PURCHASE_CHALLENGES.MIN_TRADING_DAYS'),
      value: challenge?.minTradingDays,
      formatter: (value: unknown) => String(value),
    },
    {
      name: translate('PURCHASE_CHALLENGES.MAX_LEVERAGE'),
      value: challenge?.maxLeverage,
      formatter: (value: unknown) => String(value),
    },
    {
      name: translate('PURCHASE_CHALLENGES.CONTRACT_LIMIT'),
      value: challenge?.contractLimit,
      formatter: (value: unknown) => String(value),
    },
    {
      name: translate('PURCHASE_CHALLENGES.PROFIT_SHARE'),
      value: challenge?.profitShare,
      supNumber: 2,
      formatter: (value: unknown) => `${value}%`,
    },
  ];

  return (
    <>
      <Wrapper flexDirection='column' marginBottom='25px'>
        <Styled.Profit>
          ${addSeparatorToIntegerNumber(challenge?.challengeAmount, ',')}
        </Styled.Profit>
        <Styled.Price>
          {translate('PURCHASE_CHALLENGES.CHALLENGE_PRICE')} $
          {addSeparatorToIntegerNumber(challenge?.purchaseAmount, ',')}
        </Styled.Price>
      </Wrapper>
      <Styled.Details direction='column' spacing='10px'>
        {detailItems.map(details => (
          <Styled.DetailsItem>
            <Typography>
              {details.name}
              {details.supNumber && (
                <Styled.SupText>{details.supNumber}</Styled.SupText>
              )}
            </Typography>
            <Typography>
              {details.value ? details.formatter(details.value) : '--'}
            </Typography>
          </Styled.DetailsItem>
        ))}
      </Styled.Details>
      <Styled.SupTextExplanation>
        <Typography>
          {translate('PURCHASE_CHALLENGES.ELIGIBLE_DAILY_PROFIT_DESCRIPTION')}
        </Typography>
        <Typography>
          {translate('PURCHASE_CHALLENGES.TERMS_AND_CONDITIONS')}{' '}
          <Styled.Link
            href='https://challenges.trade.com/files/regulation/en/Trade_Challenges_TCS.pdf'
            target='_blank'
          >
            {translate('COMMON.LABELS.CLICK_HERE')}
          </Styled.Link>{' '}
          {translate('COMMON.LABELS.TO_READ_MORE')}.
        </Typography>
      </Styled.SupTextExplanation>
      <Styled.PurchaseButton
        onClick={onPurchaseButtonClick}
        loading={isPurchaseButtonLoading}
        disabled={isPurchaseButtonLoading}
      >
        {translate('COMMON.LABELS.PURCHASE')} - ${challenge?.purchaseAmount}
      </Styled.PurchaseButton>
    </>
  );
};
