import { getIconBaseProps } from './utils';

export const fullScreenExit = {
  ...getIconBaseProps('fullScreenExit'),
  width: '19px',
  height: '19px',
  viewBox: '0 0 19 19',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M6.3125 1V4.3125C6.3125 5.41707 5.41707 6.3125 4.3125 6.3125H1'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6875 18L12.6875 14.6875C12.6875 13.5829 13.5829 12.6875 14.6875 12.6875L18 12.6875'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 6.3125L14.6875 6.3125C13.5829 6.3125 12.6875 5.41707 12.6875 4.3125L12.6875 1'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 12.6875L4.3125 12.6875C5.41707 12.6875 6.3125 13.5829 6.3125 14.6875L6.3125 18'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
