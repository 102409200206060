import { getIconBaseProps } from './utils';

export const settings = {
  ...getIconBaseProps('settings'),
  width: '20px',
  height: '20px',
  viewBox: '0 0 20 20',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle
        cx='10.1'
        cy='10.3675'
        r='2.85241'
        stroke='currentColor'
        strokeWidth='1.2'
      />
      <path
        d='M3.49933 4.2479C2.56975 5.24983 1.89474 6.43885 1.50171 7.71271L3.24784 8.72084C4.51558 9.45277 4.51558 11.2826 3.24784 12.0145L1.5 13.0236C1.69518 13.6536 1.9631 14.2717 2.30674 14.8669C2.65037 15.4621 3.05173 16.0032 3.49966 16.4872L5.24753 15.478C6.51527 14.7461 8.09994 15.661 8.09994 17.1249V19.1412C9.39966 19.4378 10.7669 19.4477 12.0994 19.1436L12.0994 17.125C12.0994 15.6611 13.684 14.7462 14.9518 15.4781L16.7 16.4875C17.6296 15.4855 18.3046 14.2965 18.6976 13.0226L16.9514 12.0145C15.6837 11.2825 15.6837 9.45273 16.9514 8.7208L18.6993 7.71165C18.5041 7.08174 18.2362 6.46362 17.8926 5.86843C17.549 5.27322 17.1476 4.73212 16.6996 4.24813L14.9518 5.25725C13.684 5.98918 12.0994 5.07427 12.0994 3.61042V1.59414C10.7996 1.29759 9.43243 1.28765 8.09994 1.59173L8.09994 3.61039C8.09994 5.07425 6.51527 5.98916 5.24753 5.25723L3.49933 4.2479Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
    </>
  ),
};
