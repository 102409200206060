import { IBarFE } from '../models';
import {
  CandlestickData,
  ISeriesApi,
  SeriesDataItemTypeMap,
} from 'lightweight-charts';

type TGenerateNewStickOutput = (
  price: number,
  series: ISeriesApi<'Candlestick'>,
  bars: Array<IBarFE>,
  period: string
) => Array<IBarFE> | undefined;

const timeFromUtc = 1000;

const minutesInHour = 60;
const hoursInDay = 24;
const daysInWeek = 7;

const twoTimes = 2;
const fourTimes = 4;
const fiveTimes = 5;
const fifteenTimes = 15;
const thirtyTimes = 30;

const minute = 60000;
const hour = minute * minutesInHour;
const day = hour * hoursInDay;
const week = day * daysInWeek;

const fiveMinutes = minute * fiveTimes;
const fifteenMinutes = minute * fifteenTimes;
const halfAnHour = minute * thirtyTimes;
const twoHours = hour * twoTimes;
const fourHours = hour * fourTimes;

const range = {
  Minute1: minute,
  Minute5: fiveMinutes,
  Minute15: fifteenMinutes,
  Minute30: halfAnHour,
  Hour1: hour,
  Hour2: twoHours,
  Hour4: fourHours,
  Day1: day,
  Week1: week,
};

let currentTime: number | null = null;

export const generateNewStick: TGenerateNewStickOutput = (
  price,
  series,
  bars,
  period
  // eslint-disable-next-line consistent-return
) => {
  const currentBar = bars[bars.length - 1];
  currentTime = currentBar.time * timeFromUtc;

  const updateTime = new Date(new Date().setSeconds(0, 0)).getTime();

  const currentMinutes = new Date().setSeconds(0, 0);
  const isCreateNewBarByPeriod = updateTime - currentTime >= range[period];

  if (isCreateNewBarByPeriod) {
    currentTime = new Date(new Date().setSeconds(0, 0)).getTime();
    const newBar = {
      open: price,
      high: price,
      low: price,
      close: price,
      time:
        new Date(
          new Date(currentMinutes).setMinutes(
            new Date(currentMinutes).getMinutes()
          )
        ).getTime() / timeFromUtc,
    };
    series.setData([...bars, newBar] as CandlestickData[]);

    return [...bars, newBar];
  }
  if (price) {
    if (currentBar.open === null) {
      currentBar.open = price;
      currentBar.high = price;
      currentBar.low = price;
      currentBar.close = price;
    } else {
      currentBar.close = price;
      currentBar.high = Math.max(currentBar.high, price);
      currentBar.low = Math.min(currentBar.low, price);
    }

    if (import.meta.env.VITE_ENVIRONMENT === 'production') {
      series.update(currentBar as SeriesDataItemTypeMap['Candlestick']);
    } else {
      const seriesSize =
        series['_internal__dataUpdatesConsumer']['_private__seriesMap'].size;

      // prevent disposed warnings
      if (seriesSize || import.meta.env.VITE_ENVIRONMENT === 'production') {
        series.update(currentBar as SeriesDataItemTypeMap['Candlestick']);
      }
    }
  }
};
