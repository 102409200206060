import { types } from 'mobx-state-tree';
import { modal } from './modals';
import { language } from './language';
import { sidebar } from './sidebar';

export const UiModel = types.model('UiModel', {
  modal,
  language,
  sidebar,
});

export const ui = types.optional(UiModel, {});
