import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IAvailablePaymentMethodBE } from '@trader/api';
import { TAvailableChallengeEntity, useMst } from '@trader/store';

import { PurchaseDetails } from './components/purchaseDetails';
import { PaymentMethods } from './components/paymentMethods';
import * as Styled from './styled';

export const PurchaseChallenge: React.FC = observer(() => {
  const store = useMst();

  const [paymentMethods, setPaymentMethods] = useState<
    IAvailablePaymentMethodBE[] | undefined
  >();

  const contentLinkId = store.ui.modal.options.get('contentLinkId');
  const challenge =
    store.entities.availableChallenges.get<TAvailableChallengeEntity>(
      contentLinkId
    );

  const handlePurchaseButtonClick = async () => {
    const response = await store.user.getAvailablePaymentMethodsAsync.run({
      amount: challenge.purchaseAmount,
      tradingAccountId: store.user.activeTradingAcc().tradingId,
      contentLinkId,
    });
    setPaymentMethods(response);
  };

  return (
    <Styled.Root>
      {!paymentMethods ? (
        <PurchaseDetails
          challenge={challenge}
          isPurchaseButtonLoading={
            store.user.getAvailablePaymentMethodsAsync.inProgress
          }
          onPurchaseButtonClick={handlePurchaseButtonClick}
        />
      ) : (
        <PaymentMethods
          paymentMethods={paymentMethods}
          contentLinkId={contentLinkId}
          purchaseAmount={challenge.purchaseAmount}
        />
      )}
    </Styled.Root>
  );
});
