import React from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { PendingOrdersList } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const InstrumentOrders: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  if (!instrument) {
    return <Styled.Root />;
  }

  return (
    <Styled.Root>
      <Styled.Main>
        <Styled.Title>
          {translate('COMMON.LABELS.ORDERS')} - {instrument.displayName}
        </Styled.Title>
        <PendingOrdersList symbol={symbol} />
      </Styled.Main>
    </Styled.Root>
  );
});
