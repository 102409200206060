import styled, { css } from 'styled-components';

export const Root = styled('div')`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid ${theme.palette.yellow.dark};
    border-radius: 5px;
    padding: 55px 16px 16px 16px;
  `};
`;

export const Label = styled('div')`
  ${({ theme }) => css`
    width: 90%;
    position: absolute;
    color: ${theme.palette.yellow.main};
    background-color: white;
    padding: 4px;
    top: -25px;
    left: 10px;
    font-size: 12px;
  `};
`;
