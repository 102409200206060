import {
  IContext,
  MappedObject,
  PineJS,
  StudyBooleanInputInfo,
  StudyColorerPlotInfo,
  StudyFilledAreaInfo,
  StudyInputInfo,
  StudyLinePlotInfo,
  StudyNumericInputInfo,
  StudyShapesPlotInfo,
  StudyStylesInfo,
} from '../charting_library';
import { getInputValues } from './inputValues';
import { baseDefaultsStyles } from '../constants';

const superTrend = {
  superTrend: 'superTrend',
  priceMedian: 'priceMedian',
  superTrendLineArea: 'superTrendLineArea',
  superTrendBgArea: 'superTrendBackgroundArea',
  upSignalShape: 'upSignalShape',
  downSignalShape: 'downSignalShape',
};

export const superTrendPlots = [
  {
    id: superTrend.priceMedian,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: superTrend.superTrend,
    type: 'line' as StudyLinePlotInfo['type'],
  },
  {
    id: superTrend.superTrendLineArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: superTrend.superTrend,
    palette: superTrend.superTrendLineArea,
  },
  {
    id: superTrend.superTrendBgArea,
    type: 'colorer' as StudyColorerPlotInfo['type'],
    target: superTrend.superTrendBgArea,
    palette: superTrend.superTrendBgArea,
  },
  {
    id: superTrend.upSignalShape,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
  {
    id: superTrend.downSignalShape,
    type: 'shapes' as StudyShapesPlotInfo['type'],
  },
];

export const superTrendInputs: StudyInputInfo[] = [
  {
    id: 'superTrendConfirm',
    name: '[SuperTrend] confirm',
    type: 'bool' as StudyBooleanInputInfo['type'],
    defval: true,
  },
  {
    id: 'superTrendFactor',
    name: '[SuperTrend] Factor',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 4,
  },
  {
    id: 'superTrendLength',
    name: '[SuperTrend] Length',
    type: 'integer' as StudyNumericInputInfo['type'],
    defval: 20,
  },
];

export const superTrendFilledAreas: StudyFilledAreaInfo[] = [
  {
    id: superTrend.superTrendBgArea,
    objAId: superTrend.priceMedian,
    objBId: superTrend.superTrend,
    title: '[SuperTrend] Background',
    type: 'plot_plot' as StudyFilledAreaInfo['type'],
    palette: superTrend.superTrendBgArea,
  },
];

export const superTrendPallets = {
  [superTrend.superTrendLineArea]: {
    valToIndex: {
      0: 0,
      1: 1,
    },
    colors: {
      0: {
        name: 'Color 0',
      },
      1: {
        name: 'Color 1',
      },
    },
  },
  [superTrend.superTrendBgArea]: {
    valToIndex: {
      0: 0,
      1: 1,
    },
    colors: {
      0: {
        name: 'Color 0',
      },
      1: {
        name: 'Color 1',
      },
    },
  },
};

export const superTrendDefaults = {
  filledAreasStyle: {
    [superTrend.superTrendLineArea]: {
      color: 'yellow',
      visible: true,
      transparency: 0,
    },
    [superTrend.superTrendBgArea]: {
      color: 'yellow',
      visible: true,
      transparency: 0,
    },
  },
  palettes: {
    [superTrend.superTrendLineArea]: {
      colors: {
        0: {
          color: 'rgb(241,13,41)',
          width: 2,
          style: 0,
        },
        1: {
          color: 'rgb(71,225,19)',
          width: 2,
          style: 0,
        },
      },
    },
    [superTrend.superTrendBgArea]: {
      colors: {
        0: {
          color: 'rgba(239,93,112,0.06)',
          width: 3,
          style: 0,
        },
        1: {
          color: 'rgba(131,241,93,0.06)',
          width: 3,
          style: 0,
        },
      },
    },
  },
  styles: {
    [superTrend.priceMedian]: {
      ...baseDefaultsStyles,
      color: 'rgba(0,0,0,0.06)',
    },
    [superTrend.superTrend]: {
      ...baseDefaultsStyles,
      color: 'rgba(227,13,37,0.39)',
    },
    [superTrend.upSignalShape]: {
      color: 'rgb(71,225,19)',
      textColor: '#000',
      plottype: 'shape_arrow_up',
      location: 'BelowBar',
      visible: true,
    },
    [superTrend.downSignalShape]: {
      color: 'rgb(241,13,41)',
      textColor: '#000',
      plottype: 'shape_arrow_down',
      location: 'AboveBar',
      visible: true,
    },
  },
};

export const superTrendStyles: MappedObject<StudyStylesInfo> = {
  [superTrend.priceMedian]: {
    histogramBase: 0,
    title: 'Price Median',
  },
  [superTrend.superTrend]: {
    histogramBase: 0,
    title: 'Super Trend',
  },
  [superTrend.upSignalShape]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    title: '[SuperTrend] Up Shape',
  },
  [superTrend.downSignalShape]: {
    isHidden: false,
    size: 'small' as StudyStylesInfo['size'],
    joinPoints: true,
    title: '[SuperTrend] Down Shape',
  },
};

interface ISupertrend {
  upperBandBasic: number;
  lowerBandBasic: number;
  upperBand: number;
  lowerBand: number;
  supertrend: number;
  trendDirection: number;
}

interface ISuperTrend {
  context: IContext;
  Pine: PineJS;
  averageTrueRange: number;
  inputValues: ReturnType<typeof getInputValues>;
  close: number;
  priceMedianValue: number;
}

const supertrend: ISupertrend = {
  upperBandBasic: 0,
  lowerBandBasic: 0,
  upperBand: 0,
  lowerBand: 0,
  supertrend: 0,
  trendDirection: 0,
};
let lastSupertrend: Omit<ISupertrend, 'trendDirection'> = {
  upperBandBasic: 0,
  lowerBandBasic: 0,
  upperBand: 0,
  lowerBand: 0,
  supertrend: 0,
};
let lastCandleClose = 0;

export const getSuperTrendValues = ({
  context,
  Pine,
  averageTrueRange,
  inputValues,
  close,
  priceMedianValue,
}: ISuperTrend) => {
  const multiplier = inputValues.superTrendFactor;
  const isSuperTrend = inputValues.useSuperTrend;

  if (!averageTrueRange) {
    return {
      result: [],
      values: supertrend,
    };
  }

  supertrend.upperBandBasic = priceMedianValue + multiplier * averageTrueRange;
  supertrend.lowerBandBasic = priceMedianValue - multiplier * averageTrueRange;

  if (
    supertrend.upperBandBasic < lastSupertrend.upperBand ||
    lastCandleClose > lastSupertrend.upperBand
  ) {
    supertrend.upperBand = supertrend.upperBandBasic;
  } else {
    supertrend.upperBand = lastSupertrend.upperBand;
  }

  if (
    supertrend.lowerBandBasic > lastSupertrend.lowerBand ||
    lastCandleClose < lastSupertrend.lowerBand
  ) {
    supertrend.lowerBand = supertrend.lowerBandBasic;
  } else {
    supertrend.lowerBand = lastSupertrend.lowerBand;
  }

  if (
    lastSupertrend.supertrend === lastSupertrend.upperBand &&
    close <= supertrend.upperBand
  ) {
    supertrend.supertrend = supertrend.upperBand;
  } else if (
    lastSupertrend.supertrend === lastSupertrend.upperBand &&
    close >= supertrend.upperBand
  ) {
    supertrend.supertrend = supertrend.lowerBand;
  } else if (
    lastSupertrend.supertrend === lastSupertrend.lowerBand &&
    close >= supertrend.lowerBand
  ) {
    supertrend.supertrend = supertrend.lowerBand;
  } else if (
    lastSupertrend.supertrend === lastSupertrend.lowerBand &&
    close <= supertrend.lowerBand
  ) {
    supertrend.supertrend = supertrend.upperBand;
  } else {
    supertrend.supertrend = 0;
  }
  if (close > supertrend.supertrend) {
    supertrend.trendDirection = 1; //uptrend
  }

  if (close < supertrend.supertrend) {
    supertrend.trendDirection = 0; //downdtrend
  }

  lastCandleClose = close;
  lastSupertrend = {
    upperBandBasic: supertrend.upperBandBasic,
    lowerBandBasic: supertrend.lowerBandBasic,
    upperBand: supertrend.upperBand,
    lowerBand: supertrend.lowerBand,
    supertrend: supertrend.supertrend,
  };

  const isCrossingST = Pine.Std.cross(
    supertrend.supertrend,
    priceMedianValue,
    context
  );

  let signalST: 'up' | 'down' | null = null;

  if (isCrossingST) {
    if (supertrend.supertrend > priceMedianValue) {
      signalST = 'down';
    } else {
      signalST = 'up';
    }
  }

  const isUpSignalVisible = signalST === 'up' ? 1 : NaN;
  const isSellSignalVisible = signalST === 'down' ? 1 : NaN;

  return {
    values: supertrend,
    result: [
      priceMedianValue,
      isSuperTrend ? supertrend.supertrend : NaN,
      supertrend.trendDirection,
      supertrend.trendDirection,
      isUpSignalVisible,
      isSellSignalVisible,
    ],
  };
};
