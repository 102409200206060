import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from './pipSize';
import { getAmplitudeBasedOnTickSize } from './common';

export const getPriceDifference = (stopsLevel: number, pipSize: number) =>
  stopsLevel * pipSize;

export const getLimitPrice = (
  stopsLevel: number,
  tickSize: number,
  pipSize: number,
  bid: number,
  ask: number,
  side: TPlaceOrderSide
) => {
  const difference = getPriceDifference(stopsLevel, pipSize);
  if (side === 'Buy') {
    return formatByPipSize(
      bid + getAmplitudeBasedOnTickSize(difference, tickSize),
      pipSize
    );
  }
  return formatByPipSize(
    ask - getAmplitudeBasedOnTickSize(difference, tickSize),
    pipSize
  );
};
