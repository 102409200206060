import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { ICustomerAccountMessage } from '@trader/types';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

/**
 * Custom hook that handles customer account updates.
 * It subscribes to the customer account hub and updates the user's profile based on incoming messages.
 */
export const useCustomerAccount = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken;

  const { unsubscribe, subscribe } = useHub<ICustomerAccountMessage>({
    url: '/inboxes',
    hub: EConnectionHub.Inboxes,
    subscriber: EConnectionHubSubscriber.CustomerAccount,
    onMessage: message => {
      store.user.runInAction(() => {
        store.user.profile.isRegistrationCompleted =
          message.isRegistrationCompleted;
      });
    },
    invokedName: 'OnCustomerAccount',
  });

  useEffect(() => {
    if (store.auth.isAuth) {
      subscribe();
    }
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [store.auth.isAuth, idToken]);
};
