import React from 'react';
import { observer } from 'mobx-react-lite';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { useMst } from '@trader/store';

import { Switcher } from '../switcher';

export const ChangeTheme: React.FC = observer(() => {
  const store = useMst();

  const isDarkMode = store.app.themeMode === 'dark';

  return (
    <>
      <LightModeIcon color={!isDarkMode ? 'primary' : 'disabled'} />
      <Switcher
        checked={isDarkMode}
        onChange={() => {
          store.app.toggleThemeMode();
        }}
        shouldHideControlInfo
      />
      <DarkModeIcon color={isDarkMode ? 'primary' : 'disabled'} />
    </>
  );
});
