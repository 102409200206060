import { getIconBaseProps } from './utils';

export const limits = {
  ...getIconBaseProps('limits'),
  width: '26px',
  height: '24px',
  viewBox: '0 0 26 24',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <g clipPath='url(#clip0_1711_7572)'>
        <path
          d='M14.6501 21.9987C11.6497 21.9987 9.2085 19.6902 9.2085 16.8529C9.2085 14.0155 11.6497 11.707 14.6501 11.707C17.6504 11.707 20.0916 14.0155 20.0916 16.8529C20.0916 19.6902 17.6504 21.9987 14.6501 21.9987ZM14.6501 12.8945C12.342 12.8945 10.4642 14.6702 10.4642 16.8529C10.4642 19.0355 12.342 20.8112 14.6501 20.8112C16.9581 20.8112 18.8359 19.0355 18.8359 16.8529C18.8359 14.6702 16.9581 12.8945 14.6501 12.8945Z'
          fill='currentColor'
        />
        <path
          d='M14.3749 19.6237C14.0284 19.6237 13.7471 19.3577 13.7471 19.0299V14.6758C13.7471 14.348 14.0284 14.082 14.3749 14.082C14.7215 14.082 15.0028 14.348 15.0028 14.6758V19.0299C15.0028 19.3577 14.7215 19.6237 14.3749 19.6237Z'
          fill='currentColor'
        />
        <path
          d='M16.8645 17.4473H12.2601C11.9135 17.4473 11.6322 17.1813 11.6322 16.8535C11.6322 16.5258 11.9135 16.2598 12.2601 16.2598H16.8645C17.2111 16.2598 17.4924 16.5258 17.4924 16.8535C17.4924 17.1813 17.2111 17.4473 16.8645 17.4473Z'
          fill='currentColor'
        />
        <path
          d='M7.1159 19.6244C7.07572 19.6244 7.03553 19.6212 6.99619 19.6133C6.9267 19.6007 0 18.2477 0 10.8607V5.96816C0 5.72274 0.159061 5.50345 0.401002 5.41478L6.88903 3.03978C7.0347 2.98674 7.19627 2.98674 7.34194 3.03978L13.83 5.41478C14.0727 5.50345 14.2318 5.72274 14.2318 5.96816V9.53066C14.2318 9.85841 13.9505 10.1244 13.6039 10.1244C13.2573 10.1244 12.9761 9.85841 12.9761 9.53066V6.37507L7.1159 4.23045L1.25575 6.37507V10.8607C1.25575 16.8726 6.28627 18.239 7.12511 18.425H7.12678C7.465 18.3609 7.79735 18.5691 7.86516 18.8905C7.93381 19.2119 7.7128 19.5247 7.37291 19.5888L7.2172 19.6165C7.18371 19.622 7.14939 19.6244 7.1159 19.6244Z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1711_7572'>
          <rect width='25.3793' height='24' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
};
