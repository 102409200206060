import { getIconBaseProps } from './utils';

export const arrowDown = {
  ...getIconBaseProps('arrowDown'),
  width: '10px',
  height: '7px',
  viewBox: '0 0 10 7',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1 1.5L5 5.5L9 1.5'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
