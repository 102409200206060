import styled, { css } from 'styled-components';

import { Typography, Button } from '@trader/components';

export const TopText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    > div > span {
      color: ${theme.palette.green.dark};
      font-size: 10px;
      font-weight: 600;
    }
  `}
`;
export const DropDownToggle = styled(Button)`
  ${({ theme }) => css`
    margin-left: 5px;
    padding: 3px;

    svg {
      color: ${theme.palette.common.black} !important;
    }
  `}
`;
