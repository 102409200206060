import { getIconBaseProps } from './utils';

export const warning = {
  ...getIconBaseProps('warning'),
  width: '52px',
  height: '52px',
  viewBox: '0 0 52 52',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='26' cy='26' r='26' fill='#FFF4E8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7084 37H13.2908C11.5271 37 10.4271 35.1291 11.313 33.6295L24.0218 12.1193C24.9041 10.6269 27.0959 10.6269 27.9782 12.1193L40.687 33.6295C41.5729 35.1291 40.4729 37 38.7092 37H38.7084ZM38.3227 34.106L26.3928 13.9149C26.2263 13.6532 25.7952 13.6035 25.6005 13.9317L13.6706 34.1176C13.5539 34.3676 13.6951 34.7535 14.0853 34.7542H37.9132C38.2945 34.7535 38.455 34.3712 38.3227 34.1067V34.106ZM27.1175 27.2553C27.0402 27.7054 26.5437 28.027 25.9996 28.027C25.4556 28.027 24.9583 27.7054 24.8818 27.2553L24.2069 21.8231C24.172 21.6192 24.1556 21.4262 24.1564 21.2435V21.1814C24.1564 20.1809 24.9814 19.3697 25.9989 19.3697C27.0164 19.3697 27.8414 20.1809 27.8414 21.1814C27.8414 21.2026 27.8414 21.2231 27.8414 21.2435C27.8414 21.2435 27.8622 21.4087 27.7916 21.8231L27.1175 27.2553ZM25.9989 29.6165C26.9465 29.6165 27.7143 30.3715 27.7143 31.3033C27.7143 32.2351 26.9465 32.99 25.9989 32.99C25.0512 32.99 24.2835 32.2351 24.2835 31.3033C24.2835 30.3715 25.0512 29.6165 25.9989 29.6165Z'
        fill='#FF8500'
      />
    </>
  ),
};
