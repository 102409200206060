import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

interface ILoading {
  $isLoading?: boolean;
  $isInvestmentAccProduct: boolean;
  height?: number;
}

export const Root = styled(Wrapper)<ILoading>`
  ${({ $isLoading, height }) => css`
    flex-direction: column;
    align-items: center;
    justify-content: ${$isLoading ? 'center' : 'flex-start'};
    width: 100%;
    border-radius: 5px;

    ${conditionApply(
      [height],
      css`
        height: ${height}px;
      `
    )}
  `}
`;
