import { getIconBaseProps } from './utils';

export const singleLayout = {
  ...getIconBaseProps('singleLayout'),
  width: '20px',
  height: '20px',
  viewBox: '0 0 20 20',
  body: (
    <rect
      x='19.4'
      y='0.6'
      width='18.8'
      height='18.8'
      rx='2.4'
      transform='rotate(90 19.4 0.6)'
      strokeWidth='1.2'
    />
  ),
};
