import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { tradingHorizontalSpace } from '@trader/constants';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${tradingHorizontalSpace}px;
  `}
`;

export const Main = styled(Wrapper)<{ $isInvestmentAccProduct?: boolean }>`
  ${({ $isInvestmentAccProduct }) => css`
    width: 100%;
    margin: 20px 0 -5px 0;

    ${conditionApply(
      [$isInvestmentAccProduct],
      css`
        margin-top: 10px;
      `
    )}

    .tradingInput-customLabel {
      width: 195px;
    }
  `}
`;

export const DmaHeader = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px ${tradingHorizontalSpace}px 0 0;
  `}
`;
export const Info = styled(Typography)`
  ${() => css`
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
  `}
`;

export const Price = styled(Typography)`
  ${() => css`
    font-size: 13px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
  `}
`;
export const FreMargin = styled(Typography)`
  ${() => css`
    width: 100%;
    font-size: 14px;
    padding: 0 ${tradingHorizontalSpace}px;
    align-items: flex-start;
    margin: 15px 0 5px 0;
  `}
`;
