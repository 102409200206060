import React from 'react';
import styled, { css } from 'styled-components';
import { Box, BoxProps } from '@mui/material';

import { conditionApply } from '@trader/utils';

import { Wrapper } from '@trader/components';
import { layoutMargin, sideBarWidth, rightBarWidth } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

interface IMain extends BoxProps {
  $isSidebarOpen?: boolean;
  children: React.ReactNode;
}
export const Main = styled<React.FC<IMain>>(({ $isSidebarOpen, ...rest }) => (
  <Box {...rest} />
))`
  ${({ theme, $isSidebarOpen }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    transition: ${theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
    ${conditionApply(
      [$isSidebarOpen],
      `
        transition: ${theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })};`
    )};
  `}
`;

export const Body = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`;

const getLeftBarWidth = (width: number) => `${width + layoutMargin}px`;
const getRightBarWidth = (width: number) => `${width + layoutMargin}px`;
export const Content = styled(Wrapper)<{
  $leftBarWidth: number;
  $showRightBar: boolean;
}>`
  ${({ theme, $leftBarWidth, $showRightBar }) => css`
    height: 100%;
    width: calc(
      100% - ${getLeftBarWidth($leftBarWidth || sideBarWidth)} -
        ${$showRightBar ? getRightBarWidth(rightBarWidth) : '0px'}
    );
    border-radius: 4px;
    display: flex;
    flex: 3;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `};
`;
