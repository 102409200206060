import { ThemeOptions } from '@mui/material';
import {
  SelectOverride,
  TooltipOverride,
  TypographyOverride,
} from '../overridedComponents';
import { breakpoints, darkPallet, typography } from '../constants';
import { customSpacing, IThemeOptions } from './index';

export const darkTheme = (options?: IThemeOptions): ThemeOptions => {
  return {
    components: {
      MuiTypography: TypographyOverride,
      MuiTooltip: TooltipOverride,
      MuiSelect: SelectOverride(options),
    },
    typography: options?.typography ? options.typography : typography,
    breakpoints,
    palette: options?.palette ? options.palette : darkPallet,
    spacing: customSpacing,
  };
};
