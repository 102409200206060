/* eslint-disable no-magic-numbers */
import { defaultPipSize, defaultPipsWithTickSize } from '@trader/constants';
import { getTradingAmplitude } from './common';

export const getRealPipsFromModel = (
  pips: number | string,
  category: string | undefined | null
): number => {
  return +pips * (category === 'Indices' ? 10 : 1);
};

export const getAmplitudeFromModel = (
  pips: number | undefined,
  pipSize: number,
  category: string | undefined | null
) => {
  return getTradingAmplitude(
    getRealPipsFromModel(String(pips ?? 0), category),
    pipSize ?? defaultPipSize
  );
};

export const getModelPips = (
  stopsLevel: number,
  category?: string | null
): string => {
  return String(stopsLevel / (category === 'Indices' ? 10 : 1));
};

interface IDEfaultPipsBasedOnTickSize {
  stopsLevel: number;
  tickSize: number;
  pipSize: number;
  category: string | null;
}

export const getDefaultPipsBaseOnTickSize = ({
  stopsLevel,
  pipSize,
  category,
  tickSize,
}: IDEfaultPipsBasedOnTickSize): string => {
  const amplitude = getAmplitudeFromModel(stopsLevel, pipSize, category);

  if (!tickSize) {
    return getModelPips(stopsLevel, category);
  }

  return amplitude > tickSize
    ? (amplitude / tickSize).toString()
    : defaultPipsWithTickSize;
};
