import { getIconBaseProps } from './utils';

export const minus = {
  ...getIconBaseProps('minus'),
  width: '9px',
  height: '2px',
  viewBox: '0 0 9 2',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1 1L8 1'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
