import styled, { css } from 'styled-components';

import { layoutMargin } from '@trader/constants';
import { Button, Wrapper } from '@trader/components';

export const Content = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    padding: 60px ${layoutMargin}px ${layoutMargin}px ${layoutMargin}px;
  `};
`;

export const Filters = styled(Wrapper)`
  ${({ theme }) => css`
    position: absolute;
    top: 59px;
    padding-top: 15px;
    z-index: ${theme.zIndex.modal};
    background: ${theme.palette.background.paper};
    width: 100%;
    height: 65px;

    div:first-child {
      margin-right: 10px;
    }
  `};
`;

export const Download = styled(Button)`
  ${({ theme }) => css`
    position: absolute;
    top: 14px;
    right: 110px;
    z-index: ${theme.zIndex.modal};

    &:hover {
      background: ${theme.palette.primary.main};
    }
  `};
`;
