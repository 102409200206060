import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';
import {
  layoutChildrenRadius,
  modalHorizontalPadding,
} from '@trader/constants';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .assets {
      height: 100%;
      margin-top: 0;

      > button {
        display: none;
      }
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;
export const Title = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${modalHorizontalPadding}px 20px ${modalHorizontalPadding}px;
    margin-bottom: 10px;

    > div {
      width: 20px;
      height: 20px;
    }

    > p {
      font-size: 18px;
      font-weight: 700;
      margin-left: 5px;
    }

    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 20px;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};
  `}
`;
export const Body = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 34px ${modalHorizontalPadding}px 0 ${modalHorizontalPadding}px;
  `}
`;

export const Action = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: center;

    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

interface IButton {
  $isTransparent: boolean;
}

export const ActionButton = styled(Button)<IButton>`
  ${({ theme, $isTransparent, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 29px;
    font-size: 13px;
    font-weight: 500;
    color: ${theme.palette.white.main};
    background: ${theme.palette.purchaseButtons.green};

    ${conditionApply(
      [$isTransparent],
      css`
        background: transparent;
        color: ${theme.palette.text.secondary};
        width: 145px;
        border: 1px solid ${theme.palette.primary.dark};
      `
    )}
    ${conditionApply(
      [$isTransparent && disabled],
      css`
        background: transparent !important;
        border: 1px solid ${theme.palette.gray.light};
      `
    )}
    &:hover {
      background: ${$isTransparent
        ? 'transparent'
        : theme.palette.purchaseButtons.green};
    }
  `};
`;
