import React from 'react';

import { TIconsName } from '@trader/assets';
import { Icon } from '@trader/components';

import * as Styled from './styled';

interface IModalNotification {
  iconTypeName: TIconsName;
  title: string;
  content: string;
  buttonContent: string;
  onClick: () => void;
}

export const ModalNotification: React.FC<IModalNotification> = ({
  iconTypeName,
  title,
  content,
  buttonContent,
  onClick,
}) => {
  return (
    <Styled.Root>
      <Icon iconType={iconTypeName} />
      <Styled.Title>{title}</Styled.Title>
      <Styled.Content>{content}</Styled.Content>
      <Styled.ConfirmButton onClick={onClick}>
        {buttonContent}
      </Styled.ConfirmButton>
    </Styled.Root>
  );
};
