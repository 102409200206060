import { schema } from 'normalizr';

const positionMetric = new schema.Entity(
  'positionsMetrics',
  {},
  {
    idAttribute: 'positionId',
  }
);

export const positionsMetricsSchema = [positionMetric];
