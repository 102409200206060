import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;

    .assets {
      height: 100%;
      margin-top: 0;
      > button {
        display: none;
      }
    }
  `};
`;
