import { requestService, TAsyncActionOptions } from '@trader/services';
import { marketDataRoutes } from './routes';
import { ICandleBarBE, IIndicators } from './models';

export class Historical {
  routes = marketDataRoutes;

  async getCandleBars(
    symbol: string,
    period: string,
    fromTime: number,
    options?: TAsyncActionOptions
  ): Promise<Array<ICandleBarBE>> {
    const resp = await requestService.get<Array<ICandleBarBE>>(
      this.routes.getCandleBars(fromTime),
      {
        ...options?.cancelToken,
        params: { symbol, period },
      }
    );

    return resp?.data;
  }

  async getIndicators(
    symbol: string,
    options?: TAsyncActionOptions
  ): Promise<IIndicators> {
    const resp = await requestService.get<IIndicators>(
      this.routes.getIndicators,
      {
        ...options?.cancelToken,
        params: { symbol },
      }
    );

    return resp?.data;
  }
}
