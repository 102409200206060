class ObjectUtils {
  getValueByPath<T = unknown>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: Record<string, any>,
    path: string
  ): T | undefined {
    const arr = path.split('.');
    return arr.reduce((prev, current) => {
      if (prev && current in prev) {
        return prev[current];
      }
      return undefined;
    }, obj) as T;
  }
}

export const objectUtils = new ObjectUtils();
