import React from 'react';

import { IChangeFieldEvent, TOptions } from '@trader/types';
import { Switcher } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IIndicator {
  shouldDisplay: boolean;
  disabled: boolean;
  checked: boolean;
  name: string;
  leftLabel: string;
  innerHtml: string;
  onChange: (
    event: IChangeFieldEvent<TOptions> | React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const Indicator: React.FC<IIndicator> = props => {
  const { translate } = useI18next();

  if (props.shouldDisplay) {
    return (
      <Styled.SwitcherContainer>
        <Switcher
          name={props.name}
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          leftLabel={translate(props.leftLabel)}
        />
        {props.disabled && (
          <Styled.Note
            dangerouslySetInnerHTML={{
              __html: translate(props.innerHtml),
            }}
          />
        )}
      </Styled.SwitcherContainer>
    );
  }
  return null;
};
