export const getIndicatorInfoUsingIndicator = (indicator: string) => {
  switch (indicator) {
    case 'pivot':
      return {
        color: 'rgb(101, 67, 186)',
        title: 'Pivot',
      };
    case 'resistance1':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R1',
      };
    case 'resistance2':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R2',
      };
    case 'resistance3':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R3',
      };
    case 'support1':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S1',
      };
    case 'support2':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S2',
      };
    case 'support3':
    default:
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S3',
      };
  }
};
