import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { useMst } from '@trader/store';

export const Indicators: React.FC = observer(() => {
  const store = useMst();
  const indicators = store.pages.muliBands.indicators;

  return (
    <Styled.Root>
      {indicators.map(indicator => (
        <Styled.Tab
          $isSelected={indicator.isActive}
          variant='text'
          key={indicator.title}
          onClick={indicator.toggleActive}
        >
          {indicator.title}
        </Styled.Tab>
      ))}
    </Styled.Root>
  );
});
