import { Stack } from '@mui/material';
import styled, { css } from 'styled-components';

import { Wrapper, Typography, Button } from '@trader/components';

export const Profit = styled(Typography)`
  ${() => css`
    display: block;
    font-size: 40px;
    font-weight: 700;
  `}
`;

export const Price = styled(Typography)`
  ${() => css`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  `}
`;

export const Details = styled(Stack)`
  ${({ theme }) => css`
    padding: 15px 0;
    margin-bottom: 18px;
    border-top: 1px solid ${theme.palette.gray.main};
    border-bottom: 1px solid ${theme.palette.gray.main};
  `}
`;

export const DetailsItem = styled(Wrapper)`
  ${() => css`
    align-items: center;
    justify-content: space-between;

    :nth-child(1) {
      font-size: 14px;
      font-weight: 500;
    }

    :nth-child(2) {
      font-size: 14px;
      font-weight: 700;
    }
  `}
`;

export const PurchaseButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.green['800']};
    font-weight: 700;
    padding: 12px;

    &:hover {
      background-color: ${theme.palette.green['800']};
    }
  `}
`;

export const SupText = styled('sup')`
  ${() => css`
    font-size: 8px !important;
    margin-left: 2px;
  `}
`;

export const SupTextExplanation = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    margin-bottom: 18px;

    > p {
      display: block;
      font-size: 13px;
      font-weight: 400;
      text-wrap: wrap;
    }
  `}
`;

export const Link = styled('a')`
  ${({ theme }) => css`
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    color: ${theme.palette.blue['300']};
  `}
`;
