import React from 'react';
import { TooltipProps } from '@mui/material';
import * as Styled from './styled';

interface ITooltip extends Omit<TooltipProps, 'children'> {
  children: string | React.ReactElement;
  maxWidth?: number;
}

export const Tooltip: React.FC<ITooltip> = ({
  title,
  children,
  placement = 'bottom-start',
  maxWidth,
  ...rest
}) => {
  return (
    <Styled.Tooltip
      $maxWidth={maxWidth}
      title={title}
      placement={placement}
      arrow
      {...rest}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                // eslint-disable-next-line no-magic-numbers
                offset: [-20, -10],
              },
            },
          ],
        },
      }}
    >
      <div>{children}</div>
    </Styled.Tooltip>
  );
};
