import React from 'react';
import * as Styled from './styled';

interface IProfitValue {
  value: number;
}

export const ProfitValue: React.FC<IProfitValue> = ({ value }) => {
  return (
    <Styled.Value $isAboveZero={value > 0} $isEqualZero={value === 0}>
      {value}
    </Styled.Value>
  );
};
