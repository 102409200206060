import { LazyLoadImage } from 'react-lazy-load-image-component';

import { images } from '@trader/assets';
import { Icon, Typography } from '@trader/components';
import { addSeparatorToIntegerNumber } from '@trader/utils';
import { useI18next } from '@trader/services';

import { IPurchaseList } from '../index';
import * as Styled from './styled';

interface IPurchaseListCard {
  challengeAmount: number;
  purchaseAmount: number;
  purchaseType: IPurchaseList['purchaseType'];
  withChartUpBackground?: boolean;
  onPurchaseButtonClick: () => void;
}

export const PurchaseListCard = ({
  challengeAmount,
  purchaseAmount,
  purchaseType,
  withChartUpBackground,
  onPurchaseButtonClick,
}: IPurchaseListCard) => {
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <Styled.Body>
        <Styled.Profit>
          ${addSeparatorToIntegerNumber(challengeAmount, ',')}
        </Styled.Profit>
        <Styled.Price>
          <Typography>{translate('COMMON.LABELS.PRICE')}:</Typography>{' '}
          <Typography>
            ${addSeparatorToIntegerNumber(purchaseAmount, ',')}
          </Typography>
        </Styled.Price>
      </Styled.Body>
      {withChartUpBackground ? (
        <LazyLoadImage
          alt='background'
          src={images.purchaseChallengeChartUpBgImage}
        />
      ) : (
        <LazyLoadImage
          alt='background'
          src={images.purchaseChallengeChartDownBgImage}
        />
      )}
      <Styled.PurchasedButton onClick={onPurchaseButtonClick}>
        {translate('COMMON.LABELS.PURCHASE')}
      </Styled.PurchasedButton>
      {purchaseType === 'instantChallenges' && (
        <Styled.InstantChallengeIcon>
          <Icon iconType='lightning' />
        </Styled.InstantChallengeIcon>
      )}
    </Styled.Root>
  );
};
