import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${() => css`
    margin-top: 19px;
    width: 100%;
    flex-direction: column;
  `};
`;

export const Tab = styled(Button)<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    justify-content: flex-start;
    align-items: center;
    padding: 1px 17px 1px 17px;
    color: ${theme.palette.blue[400]};
    border-radius: 0;

    svg {
      margin-right: 10px;
    }

    ${conditionApply(
      [$isSelected],
      css`
        color: ${theme.palette.white.main};
        background: ${theme.palette.blue[500]};
      `
    )}
    &:hover {
      background: ${theme.palette.blue[500]};
      color: ${theme.palette.white.main};
    }
  `};
`;
