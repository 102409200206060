import React from 'react';
import { OutlinedInput, FormControlProps, FormControl } from '@mui/material';
import styled, { css } from 'styled-components';
import { conditionApply } from '@trader/utils';
import { ITextField } from './index';

export const Input = styled(OutlinedInput)<ITextField>`
  ${({ theme, disabled }) => css`
    color: ${theme.palette.common.black};

    & > div {
      ${conditionApply(
        [disabled],
        `
        background-color: #f5f5f5;
        & input.Mui-disabled {
          color: #e0e0e0
        }`
      )}
    }
  `}
`;

interface IInputFormControlProps extends FormControlProps {
  fullWidth?: boolean;
}

export const InputFormControl = styled<React.FC<IInputFormControlProps>>(
  ({ fullWidth, ...rest }) => <FormControl {...rest} />
)`
  ${({ fullWidth, theme }) => css`
    & label.Mui-focused {
      color: ${theme.palette.common.black};
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border: 1px solid ${theme.palette.common.black};
      }
    }

    ${conditionApply(
      [fullWidth],
      css`
        width: 100%;
      `
    )}
  `}
`;
