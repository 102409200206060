import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { NAVIGATE_TO } from '@trader/constants';
import { useMst } from '@trader/store';

import { DateRangeSelection } from './components/dateRangeSelection';
import * as Styled from './styled';

export const AccountStatement: React.FC = observer(() => {
  const nowDate = new Date().toISOString().split('T')[0];

  const store = useMst();

  const [selectedFromDate, setSelectedFromDate] = useState(nowDate);
  const [selectedToDate, setSelectedToDate] = useState(nowDate);

  const platformLogin = store.ui.modal.options.get('platformLogin');
  const product = store.ui.modal.options.get('product');
  const accountType = store.ui.modal.options.get('accountType');
  const accountCurrency = store.ui.modal.options.get('tradingAccountCurrency');

  const handleGenerateButtonClick = () => {
    const params = new URLSearchParams({
      from: selectedFromDate,
      to: selectedToDate,
      currency: accountCurrency,
      platformLogin,
      product,
      accountType,
    }).toString();

    const url = `${window.location.origin}${NAVIGATE_TO.main.accountStatementReport}?${params}`;
    window.open(url, '_blank');
    store.ui.modal.close();
  };

  return (
    <Styled.Root>
      <DateRangeSelection
        fromDate={selectedFromDate}
        toDate={selectedToDate}
        onGenerateClick={handleGenerateButtonClick}
        onFromDateChange={setSelectedFromDate}
        onToDateChange={setSelectedToDate}
      />
    </Styled.Root>
  );
});
