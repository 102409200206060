import { getIconBaseProps } from './utils';

export const tradeLogoClosed = {
  ...getIconBaseProps('tradeLogoClosed'),
  width: '31px',
  height: '24px',
  viewBox: '0 0 31 24',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M16.4346 8.02737L16.8559 8.71042L25.7466 23.8656H30.6343L18.6678 3.7583L16.4346 8.02737Z'
        fill='#005C97'
      />
      <path
        d='M17.4442 1.66494L17.2336 1.32341C16.9808 0.896504 16.7279 0.597669 16.433 0.341525C16.138 0.0853814 15.7588 0 15.2953 0C14.8318 0 14.4526 0.128072 14.1576 0.341525C13.8206 0.597669 13.5677 0.896504 13.3149 1.32341L0 23.8214H4.63494L14.5369 6.83051L17.4442 1.66494Z'
        fill='#005C97'
      />
    </>
  ),
};
