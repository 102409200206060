import { getIconBaseProps } from './utils';

export const favoriteOn = {
  ...getIconBaseProps('favoriteOn'),
  width: '22px',
  height: '20px',
  viewBox: '0 0 22 20',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M14.3428 6.16883L11.5082 0.310098C11.3082 -0.103366 10.6918 -0.103366 10.4918 0.310099L7.65723 6.16883C7.5757 6.33735 7.40819 6.45337 7.2149 6.47522L0.494471 7.23488C0.0201944 7.28849 -0.170276 7.84737 0.180368 8.15652L5.14895 12.5371C5.29186 12.6631 5.35584 12.8508 5.31791 13.0328L3.999 19.3611C3.90592 19.8076 4.40458 20.1531 4.82133 19.9307L10.7266 16.7792C10.8965 16.6886 11.1035 16.6886 11.2734 16.7792L17.1787 19.9307C17.5954 20.1531 18.0941 19.8076 18.001 19.3611L16.6821 13.0328C16.6442 12.8508 16.7081 12.6631 16.851 12.5371L21.8196 8.15652C22.1703 7.84737 21.9798 7.28849 21.5055 7.23488L14.7851 6.47522C14.5918 6.45337 14.4243 6.33735 14.3428 6.16883Z'
      fill='#FEDF3E'
    />
  ),
};
