import styled, { css } from 'styled-components';

import { Typography } from '../typography';

export const Time = styled(Typography)`
  ${() => css`
    text-transform: uppercase;
    text-align: center;

    font-size: 12px;
    font-weight: 400;
  `};
`;
