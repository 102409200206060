import {
  applySnapshot,
  getIdentifier,
  isValidReference,
  IAnyStateTreeNode,
} from 'mobx-state-tree';

import { devLoggerService, localStorageService } from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';

import { IStoresPersist } from './index';

export const rehydrate = (store: IAnyStateTreeNode, key: string) => {
  const snapshot = localStorageService.get(LOCAL_STORAGE_KEYS.persist);
  if (snapshot && JSON.parse(snapshot)[key]) {
    try {
      applySnapshot(store, JSON.parse(snapshot)[key]);
    } catch (error) {
      devLoggerService.error('error in rehydrate function', error);
      // const app = JSON.parse(snapshot).app;
      // const user = JSON.parse(snapshot).user;
      // const ui = JSON.parse(snapshot).ui;
      // console.log('fd', app, user, ui);
      // applySnapshot(store, {
      //   app,
      //   user,
      //   ui,
      // });
    }
  }
};

export const createPersist = (persist: Array<IStoresPersist>) => {
  window.addEventListener('pagehide', () => {
    let persistModel = {};

    persist.forEach(store => {
      // going throw whiteList using reduce and create object like { trading: { selectedCategory: value, instrumentsAmount: value } }
      if (store.whiteList?.length) {
        const obj = store.whiteList.reduce((accumulatedValue, key) => {
          if (
            typeof store.store[key] === 'object' &&
            isValidReference(() => store.store[key]) &&
            getIdentifier(store.store[key])
          ) {
            // resolve the issue in case our modal is reference type and sent only reference identifier (like selectedCategory)
            return {
              ...accumulatedValue,
              [store.key]: {
                ...accumulatedValue[store.key],
                [key]: getIdentifier(store.store[key]),
              },
            };
          }
          // handle the rest of the mst models
          return {
            ...accumulatedValue,
            [store.key]: {
              ...accumulatedValue[store.key],
              [key]: store.store[key],
            },
          };
        }, {});
        // update persistModel with new object filled by whiteList keys
        persistModel = { ...persistModel, ...obj };
      } else {
        // update persistModel without whiteList keys, using whole mst model
        persistModel = { ...persistModel, [store.key]: store.store };
      }

      // reduce amount of saved entities to predefined amount
      if (store?.amountOfEntitiesToSave) {
        const entities = Array.from(store.store.collection).slice(
          0,
          store?.amountOfEntitiesToSave
        ) as Array<Array<string | number | IAnyStateTreeNode>>;

        const collectionValue = entities.reduce(
          (accumulatedValue, collectionItem) => ({
            ...accumulatedValue,
            [collectionItem[0]]: collectionItem[1],
          }),
          {}
        );
        persistModel = {
          ...persistModel,
          [store.key]: { collection: collectionValue },
        };
      }
    });

    localStorageService.set(
      LOCAL_STORAGE_KEYS.persist,
      JSON.stringify(persistModel)
    );
  });
};
