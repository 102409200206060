import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';

import { Wrapper } from '../wrapper';
import { ICommonStyledProps } from '@trader/types';

export const ContainerControlInfo = styled(Wrapper)<ICommonStyledProps>`
  ${({ theme, $isError, $isDisabled }) => css`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px 0;
    font-size: 14px;
    color: ${theme.palette.primary.main};
    ${conditionApply(
      [$isError],
      `
      color: ${theme.palette.red.main};
    `
    )}
    ${conditionApply(
      [$isDisabled],
      `
      color: ${theme.palette.gray.main};
    `
    )}
  `}
`;
