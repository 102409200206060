import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0 0 8px;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: 700;
    color: ${theme.palette.common.black};
  `}
`;

export const CloseIcon = styled(Wrapper)`
  ${() => css`
    svg {
      margin-top: 2px;
      width: 12px;
      height: 12px;
    }
  `}
`;
