import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';
import { convertFromOption } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';

import { IBackTestingInitialValues } from './index';

export const Quantity = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const symbol = convertFromOption(backTesting.getSymbolOption());

  const { setValue, formState } = useFormContext<IBackTestingInitialValues>();

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  useEffect(() => {
    if (formState.dirtyFields.symbol && !backTesting?.symbolOption?.value) {
      return setValue('quantity', 0);
    }

    if (instrument) {
      setValue('quantity', instrument.minOrderSize);
    } else {
      store.entities.instruments.getInstrumentSpecificationAsync.run(symbol);
    }
  }, [symbol, formState.dirtyFields.symbol, instrument?.minOrderSize]);

  return (
    <Controller
      disabled={!symbol}
      type='textField'
      label={translate('COMMON.LABELS.QUANTITY')}
      name='quantity'
    />
  );
});
