import { api } from '@trader/api';

import { createThunk } from '../../utils/asyncModel';
import { getRootInstance } from '../../configureStore/configureStore';
import { availableChallengesSchema } from './schemas';

export const getAvailableChallengesAsync = createThunk<void, void>(
  () =>
    async function getAvailableChallenges(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const root = getRootInstance();
      const response = await api.Challenge.getAvailableChallenges(_options);
      root.entities.normalizeMerge(response, availableChallengesSchema, true);
    }
);
