import React from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';

import { useMst } from '@trader/store';

import * as Styled from './styled';

export interface IHeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<IHeaderProps> = observer(({ children }) => {
  const store = useMst();

  return (
    <Styled.AppBar>
      <Styled.Backdrop
        open={
          store.entities.tradingAccounts.changeTradingAccountAsync.inProgress
        }
      >
        <CircularProgress color='primary' />
      </Styled.Backdrop>
      <Styled.Toolbar>{children}</Styled.Toolbar>
    </Styled.AppBar>
  );
});
