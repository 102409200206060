import { getIconBaseProps } from './utils';

export const tradeItalyLoginLightLogo = {
  ...getIconBaseProps('tradeItalyLoginLightLogo'),
  width: '177px',
  height: '34px',
  viewBox: '0 0 177 34',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M104.156 33.0002C103.652 33.0002 103.191 32.8607 102.772 32.5817C102.362 32.3028 102.032 31.923 101.784 31.4426C101.536 30.9621 101.412 30.4196 101.412 29.8151C101.412 29.2029 101.536 28.6605 101.784 28.1877C102.04 27.7073 102.381 27.3314 102.807 27.0602C103.241 26.7889 103.725 26.6533 104.26 26.6533C104.578 26.6533 104.869 26.6998 105.132 26.7928C105.395 26.8858 105.624 27.0175 105.818 27.188C106.019 27.3508 106.182 27.5406 106.306 27.7576C106.438 27.9746 106.519 28.2071 106.55 28.4551L106.283 28.3621V26.7812H107.492V32.884H106.283V31.4309L106.562 31.3496C106.515 31.5588 106.418 31.7642 106.271 31.9656C106.132 32.1594 105.95 32.3338 105.725 32.4887C105.508 32.6437 105.264 32.7677 104.993 32.8607C104.729 32.9537 104.45 33.0002 104.156 33.0002ZM104.469 31.8959C104.834 31.8959 105.155 31.8068 105.434 31.6285C105.713 31.4503 105.93 31.2062 106.085 30.8962C106.248 30.5785 106.329 30.2181 106.329 29.8151C106.329 29.4199 106.248 29.0673 106.085 28.7573C105.93 28.4473 105.713 28.2032 105.434 28.025C105.155 27.8468 104.834 27.7576 104.469 27.7576C104.113 27.7576 103.795 27.8468 103.516 28.025C103.245 28.2032 103.028 28.4473 102.865 28.7573C102.71 29.0673 102.633 29.4199 102.633 29.8151C102.633 30.2181 102.71 30.5785 102.865 30.8962C103.028 31.2062 103.245 31.4503 103.516 31.6285C103.795 31.8068 104.113 31.8959 104.469 31.8959Z'
        fill='#798CA4'
      />
      <path
        d='M98.1883 32.8838V26.781H99.3856V32.8838H98.1883ZM98.7695 25.4326C98.5138 25.4326 98.3162 25.3667 98.1767 25.2349C98.0372 25.1032 97.9675 24.9172 97.9675 24.677C97.9675 24.4522 98.0372 24.2701 98.1767 24.1306C98.3239 23.9911 98.5216 23.9214 98.7695 23.9214C99.0253 23.9214 99.2229 23.9873 99.3624 24.119C99.5019 24.2507 99.5716 24.4367 99.5716 24.677C99.5716 24.9017 99.498 25.0838 99.3508 25.2233C99.2113 25.3628 99.0175 25.4326 98.7695 25.4326Z'
        fill='#798CA4'
      />
      <path
        d='M94.5156 32.8838V24.2817H95.7129V32.8838H94.5156Z'
        fill='#798CA4'
      />
      <path
        d='M88.9031 33.0002C88.3994 33.0002 87.9383 32.8607 87.5198 32.5817C87.1091 32.3028 86.7798 31.923 86.5318 31.4426C86.2838 30.9621 86.1598 30.4196 86.1598 29.8151C86.1598 29.2029 86.2838 28.6605 86.5318 28.1877C86.7875 27.7073 87.1285 27.3314 87.5547 27.0602C87.9887 26.7889 88.473 26.6533 89.0078 26.6533C89.3255 26.6533 89.6161 26.6998 89.8796 26.7928C90.1431 26.8858 90.3717 27.0175 90.5654 27.188C90.7669 27.3508 90.9296 27.5406 91.0536 27.7576C91.1854 27.9746 91.2668 28.2071 91.2978 28.4551L91.0304 28.3621V26.7812H92.2393V32.884H91.0304V31.4309L91.3094 31.3496C91.2629 31.5588 91.166 31.7642 91.0188 31.9656C90.8793 32.1594 90.6972 32.3338 90.4724 32.4887C90.2554 32.6437 90.0113 32.7677 89.7401 32.8607C89.4766 32.9537 89.1976 33.0002 88.9031 33.0002ZM89.217 31.8959C89.5812 31.8959 89.9028 31.8068 90.1818 31.6285C90.4608 31.4503 90.6778 31.2062 90.8328 30.8962C90.9955 30.5785 91.0769 30.2181 91.0769 29.8151C91.0769 29.4199 90.9955 29.0673 90.8328 28.7573C90.6778 28.4473 90.4608 28.2032 90.1818 28.025C89.9028 27.8468 89.5812 27.7576 89.217 27.7576C88.8605 27.7576 88.5428 27.8468 88.2638 28.025C87.9926 28.2032 87.7756 28.4473 87.6128 28.7573C87.4578 29.0673 87.3803 29.4199 87.3803 29.8151C87.3803 30.2181 87.4578 30.5785 87.6128 30.8962C87.7756 31.2062 87.9926 31.4503 88.2638 31.6285C88.5428 31.8068 88.8605 31.8959 89.217 31.8959Z'
        fill='#798CA4'
      />
      <path
        d='M82.3251 32.8836V25.2231H83.5224V32.8836H82.3251ZM81.058 27.9432V26.7808H84.9406V27.9432H81.058Z'
        fill='#798CA4'
      />
      <path
        d='M75.4371 32.8836V31.7212H76.7506V25.909H75.4371V24.7466H79.3196V25.909H78.0061V31.7212H79.3196V32.8836H75.4371Z'
        fill='#798CA4'
      />
      <path
        d='M6 3.87282H12.1896V17.4457H15.0379V3.87282H21.2275V1H6V3.87282Z'
        fill='#005C97'
      />
      <path
        d='M40.0729 10.4402C40.6708 9.51448 40.9705 8.36555 40.9717 6.99342C40.9842 6.14637 40.8457 5.3038 40.5625 4.50461C40.3087 3.79817 39.9043 3.15437 39.3768 2.61713C38.842 2.0855 38.1965 1.67631 37.4854 1.41825C36.6738 1.12851 35.8159 0.987111 34.9534 1.00092H23.0545V17.4457H25.9231V3.87861H34.9496C35.9542 3.87861 36.7332 4.13849 37.2865 4.65826C37.8399 5.17803 38.1172 5.97285 38.1184 7.04274C38.1184 8.11389 37.8526 8.93211 37.321 9.4974C36.7893 10.0627 35.9989 10.3453 34.9496 10.3453H28.0477V12.8778H34.2477L38.4416 17.4457H42.5456L37.5524 12.4472C38.5925 12.072 39.4786 11.3664 40.0729 10.4402V10.4402Z'
        fill='#005C97'
      />
      <path
        d='M55.3373 6.41545L55.6362 6.90061L62.1284 17.4458H65.692L56.9648 3.43652L55.3373 6.41545Z'
        fill='#005C97'
      />
      <path
        d='M55.9464 2.13077L55.8118 1.91559C55.6713 1.65769 55.4806 1.43057 55.251 1.24761C55.0227 1.0787 54.7441 0.991691 54.4603 1.00063C54.1732 0.992613 53.8914 1.0794 53.6584 1.24761C53.4206 1.42717 53.2232 1.65475 53.0789 1.91559L43.7644 17.4457H46.9964L53.9238 5.71765L55.9464 2.13077Z'
        fill='#005C97'
      />
      <path
        d='M82.2935 3.23968C81.5662 2.52089 80.6972 1.95885 79.741 1.58876C78.71 1.18835 77.611 0.988628 76.5039 1.0005H66.9094V17.4452H76.5039C77.6165 17.4571 78.7192 17.2373 79.741 16.8C80.7033 16.3892 81.5717 15.7888 82.2935 15.0352C83.0223 14.2675 83.5914 13.3648 83.9684 12.3786C84.378 11.3171 84.583 10.1887 84.5726 9.05207C84.5866 7.93426 84.3814 6.82443 83.9684 5.78438C83.5906 4.83175 83.0207 3.96579 82.2935 3.23968V3.23968ZM81.2916 11.3368C81.0548 11.9671 80.6911 12.5428 80.2226 13.029C79.7542 13.5151 79.1908 13.9014 78.567 14.1642C77.9151 14.4403 77.2126 14.5792 76.5039 14.5722H69.7813V3.87348H76.5039C77.2114 3.86591 77.9131 4.00016 78.567 4.26819C79.189 4.52182 79.7518 4.89964 80.2205 5.3782C80.6892 5.85676 81.0538 6.42583 81.2916 7.05009C81.5528 7.73292 81.6825 8.45836 81.674 9.1887C81.682 9.92208 81.5523 10.6505 81.2916 11.3368V11.3368Z'
        fill='#005C97'
      />
      <path
        d='M101.019 7.7002H91.8829V10.1366H101.019V7.7002Z'
        fill='#005C97'
      />
      <path
        d='M89.3525 14.5728V3.87282H101.604V1H86.4006V17.4457H101.628V14.5728H89.3525Z'
        fill='#005C97'
      />
      <path
        d='M106.501 15.9222C106.506 16.1247 106.468 16.326 106.391 16.5132C106.314 16.7005 106.199 16.8696 106.052 17.0098C105.908 17.1523 105.737 17.2643 105.548 17.3391C105.36 17.414 105.158 17.45 104.956 17.4452C104.756 17.4514 104.558 17.4159 104.373 17.3409C104.188 17.2659 104.021 17.1531 103.882 17.0098C103.608 16.7142 103.456 16.3261 103.456 15.9231C103.456 15.5202 103.608 15.1321 103.882 14.8365C104.021 14.6931 104.188 14.5804 104.373 14.5054C104.558 14.4304 104.756 14.3949 104.956 14.4011C105.159 14.397 105.361 14.434 105.549 14.5098C105.737 14.5856 105.909 14.6986 106.052 14.8422C106.198 14.9814 106.313 15.1493 106.39 15.3352C106.467 15.5211 106.505 15.7209 106.501 15.9222V15.9222Z'
        fill='#005C97'
      />
      <path
        d='M134.607 17.4453C133.51 17.4561 132.424 17.2362 131.419 16.7999C130.466 16.3847 129.604 15.7854 128.884 15.0365C128.152 14.2702 127.581 13.3665 127.204 12.378C126.796 11.3169 126.592 10.189 126.602 9.05296C126.588 7.9234 126.792 6.8017 127.204 5.74904C127.579 4.79304 128.152 3.92605 128.884 3.20388C129.612 2.49596 130.477 1.94249 131.425 1.57689C132.441 1.18439 133.523 0.988798 134.612 1.00063H138.63C139.749 0.98729 140.861 1.18678 141.905 1.58842C142.862 1.95378 143.73 2.51534 144.455 3.23671C145.179 3.95809 145.744 4.82318 146.112 5.77594C146.514 6.81728 146.714 7.9255 146.701 9.04143C146.711 10.1775 146.507 11.3054 146.1 12.3665C145.722 13.3545 145.152 14.258 144.422 15.025C143.696 15.7756 142.829 16.375 141.869 16.7883C140.844 17.2268 139.739 17.4466 138.624 17.4338L134.607 17.4453ZM138.624 14.564C139.34 14.5723 140.051 14.4337 140.711 14.1567C141.334 13.8997 141.897 13.5175 142.365 13.0341C142.834 12.5506 143.197 11.9761 143.433 11.3465C143.695 10.6609 143.826 9.93249 143.819 9.19894C143.826 8.46784 143.696 7.74186 143.433 7.05908C143.198 6.4328 142.834 5.86219 142.365 5.3834C141.896 4.90461 141.333 4.52808 140.711 4.27765C140.048 4.00919 139.339 3.87469 138.624 3.88195H134.607C133.9 3.87098 133.199 4.0023 132.545 4.26804C131.935 4.52041 131.383 4.89356 130.922 5.36487C130.46 5.8475 130.097 6.41681 129.857 7.03987C129.592 7.72533 129.461 8.45492 129.471 9.18934C129.464 9.92288 129.595 10.6513 129.857 11.3369C130.095 11.9713 130.457 12.5524 130.922 13.0465C131.382 13.5242 131.934 13.9044 132.545 14.1644C133.194 14.4408 133.893 14.5795 134.599 14.5716L138.624 14.564Z'
        fill='#005C97'
      />
      <path
        d='M116.1 17.438C115.037 17.4488 113.983 17.229 113.008 16.7928C112.081 16.3781 111.243 15.7791 110.542 15.0303C109.833 14.2643 109.279 13.361 108.912 12.3729C108.517 11.3123 108.319 10.1849 108.329 9.04932C108.315 7.92026 108.513 6.79904 108.912 5.74685C109.277 4.79126 109.832 3.92465 110.542 3.20279C111.249 2.49556 112.088 1.94238 113.008 1.57652C113.994 1.18418 115.043 0.988682 116.1 1.00051H124.774V3.88057H116.1C115.417 3.87368 114.74 4.0082 114.109 4.2761C113.518 4.52457 112.982 4.89436 112.535 5.36284C112.086 5.84515 111.734 6.41421 111.5 7.03712C111.243 7.72228 111.116 8.45154 111.126 9.18564C111.119 9.91887 111.246 10.6469 111.5 11.3323C111.732 11.9665 112.084 12.5473 112.535 13.0411C112.981 13.5195 113.516 13.8997 114.109 14.1586C114.738 14.4348 115.416 14.5734 116.1 14.5656H124.774V17.4457L116.1 17.438Z'
        fill='#005C97'
      />
      <path
        d='M168.275 17.2241L165.188 5.96006L160.613 16.3855C160.478 16.6873 160.277 16.9553 160.025 17.1698C159.781 17.3578 159.479 17.4551 159.17 17.4449C158.504 17.4449 158.023 17.0918 157.726 16.3855L153.174 5.96006L150.083 17.2241H147.311L151.459 2.14736C151.652 1.38245 152.118 1 152.859 1C153.154 1.00059 153.443 1.08501 153.692 1.24332C153.957 1.41242 154.163 1.65898 154.281 1.94896L159.255 13.2785L164.226 1.94896C164.493 1.31632 164.944 1 165.58 1C166.26 1 166.712 1.38245 166.936 2.14736L171.066 17.2241H168.275Z'
        fill='#005C97'
      />
    </>
  ),
};
