import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  LeftSideBarMenu,
  SearchInstrument,
  TradingTabs,
} from '@trader/components';
import { modifyIconsPerCategory } from '@trader/constants';
import { TCategoryEntity, useMst } from '@trader/store';

import { InstrumentsTable } from './components/instrumentsTable';
import * as Styled from './styled';

const tabs = {
  Watchlist: <InstrumentsTable />,
  Popular: <InstrumentsTable />,
};

export const StableDesign = observer(({ tabsList }: { tabsList: string[] }) => {
  const store = useMst();
  const categories = store.entities.categories.getAll<TCategoryEntity>();

  const tabsWithChildren = () =>
    tabsList.reduce(
      (prev, current) => ({
        ...prev,
        [current]: tabs[current],
      }),
      {}
    );

  const getTabs = useMemo(() => {
    return categories.map(category => ({
      label: category.name,
      value: category.name,
      isDefault: category.isDefault,
      icon:
        modifyIconsPerCategory[category.name] ||
        modifyIconsPerCategory.Commodities,
      disabled:
        !category.hasInstrumentsLayout && !tabsWithChildren()[category.name],
      children: tabsWithChildren()[category.name] || <InstrumentsTable />,
    }));
  }, [JSON.stringify(categories)]);

  return (
    <LeftSideBarMenu isOpen={true}>
      <Styled.Search>
        <SearchInstrument />
      </Styled.Search>
      <TradingTabs tabs={getTabs} />
    </LeftSideBarMenu>
  );
});
