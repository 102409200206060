import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';
import {
  useAmountSchema,
  usePriceSchema,
  useProtectionSchema,
} from '@trader/hooks';
import { TCommonOrderType } from '@trader/types';

export const usePlaceOrderFormValidation = (type: TCommonOrderType) => {
  const { currentLng } = useI18next();

  const { amountSchema } = useAmountSchema(type);
  const { priceSchema } = usePriceSchema(type);
  const { protectionSchema } = useProtectionSchema(type);

  const validationSchema = useMemo(() => {
    return yup
      .object()
      .shape({
        ask: yup.number(),
        bid: yup.number(),
        side: yup.string(),
        orderType: yup.string(),
        amount: amountSchema,
        price: priceSchema,
      })
      .concat(protectionSchema);
  }, [currentLng]);

  return { validationSchema };
};
