import React from 'react';
import {
  CircularProgressbar as CircularProgressbarReact,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useTheme } from 'styled-components';

interface ICircularProgressBar {
  value: number;
  text: string;
  textColor?: string;
  textSize?: string;
  pathColor?: string;
  rotation?: number;
  width?: number;
  height?: number;
  maxValue?: number;
}

const defaultMaxValue = 100;
const countFromTheBottom = 0.5;

export const CircularProgressBar: React.FC<ICircularProgressBar> = ({
  value,
  text,
  maxValue = defaultMaxValue,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <CircularProgressbarReact
      styles={{
        ...buildStyles({
          pathColor: rest.pathColor || theme.palette.blue.main,
          rotation: rest.rotation || countFromTheBottom,
        }),
        text: {
          dominantBaseline: 'central',
          fontSize: rest.textSize || '25px',
          fill: rest.textColor || theme.palette.text.primary,
        },
        root: { width: rest.width || '40px', height: rest.height || '40px' },
      }}
      value={value}
      maxValue={maxValue}
      text={text}
    />
  );
};
