import React, { forwardRef } from 'react';

import { useI18next } from '@trader/services';

import { INotificationProps } from '../types';

import * as Styled from '../styled';
import { getNetPl } from '@trader/utils';

interface IClosedAll extends Omit<INotificationProps, 'message'> {
  amountOfClosed: number;
  title: string;
  profit?: number;
}

export const ClosedAll: React.FC<IClosedAll> = forwardRef<
  HTMLDivElement,
  IClosedAll
>(({ closeSnackbar, amountOfClosed, title, profit }, ref) => {
  const { translate } = useI18next();

  const pl = getNetPl(profit || 0, '');

  return (
    <Styled.NotificationRoot ref={ref}>
      <Styled.Close onClick={closeSnackbar} iconType='remove' />
      <Styled.SuccessIcon iconType='challengeCompleted' />
      <Styled.Content>
        <Styled.Title>
          {amountOfClosed} {title} {translate('NOTIFICATIONS.CLOSED')}
        </Styled.Title>
        {profit && (
          <Styled.Text $plColor={pl.textColor}>
            {translate('COMMON.LABELS.PROFIT')}: <span>{pl.stringValue}</span>
          </Styled.Text>
        )}
      </Styled.Content>
    </Styled.NotificationRoot>
  );
});
