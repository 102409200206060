import styled, { css } from 'styled-components';

interface IRoot {
  isActive: boolean;
}

export const Root = styled('div')<IRoot>`
  ${({ isActive, theme }) => css`
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: ${isActive ? theme.palette.gray.main : 'transparent'};
    border-radius: 7px;
  `}
`;

export const Title = styled('span')<IRoot>`
  ${({ theme, isActive }) => css`
    font-size: 14px;
    font-weight: 500;
    color: ${isActive ? theme.palette.black.main : theme.palette.common.black};
  `}
`;
