import { getIconBaseProps } from './utils';

export const paperArrow = {
  ...getIconBaseProps('paperArrow'),
  width: '25px',
  height: '11px',
  viewBox: '0 0 25 11',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M15.4363 1.50772C13.5431 -0.502573 10.4736 -0.502573 8.58038 1.50772L0 10.6347L24.0244 10.627L15.4363 1.50772Z'
      fill='currentColor'
    />
  ),
};
