import { FC } from 'react';

import { useI18next } from '@trader/services';
import { ModalTitle } from '@trader/components';

import * as Styled from './styled';

interface IDateRangeSelection {
  fromDate: string;
  toDate: string;
  onGenerateClick: () => void;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
}

export const DateRangeSelection: FC<IDateRangeSelection> = ({
  fromDate,
  toDate,
  onGenerateClick,
  onFromDateChange,
  onToDateChange,
}) => {
  const nowDate = new Date().toISOString().split('T')[0];

  const { translate } = useI18next();

  const isValidDate = date => {
    return date !== null && date !== '';
  };

  const handleFromDateChange = event => {
    onFromDateChange(event.target.value);
  };

  const handleToDateChange = event => {
    onToDateChange(event.target.value);
  };

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.SELECT_REPORT_PERIOD')} />
      <Styled.Content>
        <Styled.Label>{translate('COMMON.LABELS.FROM')}:</Styled.Label>
        <Styled.Input
          type='date'
          value={fromDate}
          max={toDate}
          onChange={handleFromDateChange}
        />
        {isValidDate(fromDate) ? (
          ''
        ) : (
          <Styled.Error>
            {translate('COMMON.ERRORS.FROM_DATE_IS_REQUIRED')}
          </Styled.Error>
        )}
        <Styled.Label style={{ marginTop: '12px' }}>
          {translate('COMMON.LABELS.TO')}:
        </Styled.Label>
        <Styled.Input
          type='date'
          value={toDate}
          min={fromDate}
          max={nowDate}
          onChange={handleToDateChange}
        />
        {isValidDate(toDate) ? (
          ''
        ) : (
          <Styled.Error>
            {translate('COMMON.ERRORS.TO_DATE_IS_REQUIRED')}
          </Styled.Error>
        )}
        <Styled.ActionButton
          disabled={fromDate === '' || toDate === ''}
          onClick={onGenerateClick}
        >
          {translate('COMMON.LABELS.GENERATE')}
        </Styled.ActionButton>
      </Styled.Content>
    </Styled.Root>
  );
};
