import { useCallback } from 'react';

import { Checkbox, IconButton } from '@trader/components';
import { useI18next } from '@trader/services';
import { EChallenge } from '@trader/types';
import { useMst } from '@trader/store';

import * as Styled from '../styled';

export const useRenderCellActions = () => {
  const { translate } = useI18next();
  const store = useMst();

  const copier = store.pages.copier;
  const masterChallenges = store.pages.copier.getChallengesByType(
    EChallenge.Master
  );

  const renderLeftAction = useCallback((challengeId: number) => {
    const selectedChallengesString = copier.selectedChallenges.join(',');

    return (
      <Checkbox
        checked={selectedChallengesString.includes(challengeId.toString())}
        onChange={() => copier.handleSelectChallenge(challengeId)}
        shouldHideControlInfo
      />
    );
  }, []);

  const renderRightGeneralAction = useCallback(
    (challengeId: number) => {
      return (
        <Styled.Action>
          <Styled.ActionButton
            disabled={!masterChallenges.length}
            $isTransparent
            size='medium'
            onClick={() =>
              copier.assignSlaveAsync.run({ slaveId: challengeId })
            }
          >
            + {translate('COPIER.CONNECT_MASTER')}
          </Styled.ActionButton>
          <Styled.ActionButton
            size='medium'
            onClick={() => copier.assignMasterAsync.run(challengeId)}
          >
            + {translate('COPIER.CREATE_MASTER')}
          </Styled.ActionButton>
        </Styled.Action>
      );
    },
    [masterChallenges.length]
  );

  const renderRightChosenAction = useCallback((challengeId: number) => {
    const disconnectAccount = () => {
      copier.removeAccountAsync.run({ challengeId });
    };

    return <IconButton iconType='remove' onClick={disconnectAccount} />;
  }, []);

  return {
    renderLeftAction,
    renderRightGeneralAction,
    renderRightChosenAction,
  };
};
