import styled, { css } from 'styled-components';

import { Typography } from '../../../typography';
import { Wrapper } from '../../../wrapper';

export const Symbol = styled(Wrapper)`
  ${() => css`
    align-items: center;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 500;
    color: ${theme.palette.primary.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
  `};
`;
