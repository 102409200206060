import { useMst } from '@trader/store';

import * as Styled from '../../styled';

export const PriceAlertIconButton = ({ symbol }: { symbol: string }) => {
  const store = useMst();

  const handleOpenPriceAlertModal = () => {
    store.ui.modal.open('createPriceAlert', { symbol });
  };

  return (
    <Styled.ActionButton
      size='medium'
      onClick={handleOpenPriceAlertModal}
      iconType='priceAlert'
    />
  );
};
