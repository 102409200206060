import styled, { css } from 'styled-components';

import { IconButton, Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

export const Shrinked = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    background: ${theme.palette.background.paper};
    padding-top: 7px;
    margin-top: ${layoutMargin}px;
    height: 43px;
    border-radius: ${layoutChildrenRadius}px;

    .MuiTabs-root {
      padding-bottom: 4px;
      padding-left: 8px;
      min-height: 40px;
      border-bottom: none;
    }
  `};
`;

export const Toggle = styled(IconButton)<{ $isShrinked: boolean }>`
  ${({ theme, $isShrinked }) => css`
    position: absolute;
    top: ${$isShrinked ? '7px' : '9px'};
    right: ${$isShrinked ? '5px' : '8px'};
    z-index: ${theme.zIndex.modal};
  `};
`;

export const Title = styled('div')`
  ${({ theme }) => css`
    display: none;

    ${theme.breakpoints.down('sm')} {
      display: block;
      text-transform: uppercase;
      padding: 0 0 5px 10px;
      font-size: 12px;
      font-weight: 700;
      line-height: 17px;
    }
  `};
`;
