import { getIconBaseProps } from './utils';

export const fullScreen = {
  ...getIconBaseProps('fullScreen'),
  width: '19px',
  height: '19px',
  viewBox: '0 0 19 19',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M0.620117 5.87793L0.620117 2.56543C0.620118 1.46086 1.51555 0.565429 2.62012 0.565429L5.93262 0.56543'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6201 12.2529V15.5654C17.6201 16.67 16.7247 17.5654 15.6201 17.5654H12.3076'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3076 0.56543L15.6201 0.56543C16.7247 0.56543 17.6201 1.46086 17.6201 2.56543L17.6201 5.87793'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.93262 17.5654L2.62012 17.5654C1.51555 17.5654 0.620118 16.67 0.620118 15.5654L0.620118 12.2529'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
