import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import {
  footerHeight,
  headerHeight,
  layoutChildrenRadius,
  layoutMargin,
  rightBarWidth,
} from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    height: inherit;
    width: ${rightBarWidth}px;
    margin-left: ${layoutMargin}px;
    z-index: 1;
    position: relative;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
  `}
`;

const footerAndHeaderHeight =
  headerHeight + layoutMargin * 2 + footerHeight + layoutMargin * 2;
export const Section = styled(Wrapper)<{
  $openedSectionsCount: number;
  $withBottomSpacing?: boolean;
}>`
  ${({ theme, $openedSectionsCount, $withBottomSpacing }) => css`
    width: ${rightBarWidth}px;
    height: calc(
      (100vh - ${footerAndHeaderHeight}px) / ${$openedSectionsCount}
    );
    margin-bottom: ${$withBottomSpacing ? layoutMargin : 0}px;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    overflow: auto;
  `}
`;
