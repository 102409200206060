import { TPlaceOrderSide } from '@trader/types';

import { getOrderBasePrice } from './common';

export const getProfit = (
  quantity: number,
  rate: string,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number
) => {
  return (
    quantity * Math.abs(+rate - getOrderBasePrice(side, bidPrice, askPrice))
  );
};

export const getConvertedProfit = (
  quantity: number,
  takeProfitRate: string,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  currencyConvert: number
) =>
  getProfit(quantity, takeProfitRate, side, bidPrice, askPrice) /
  currencyConvert;
