import styled, { css } from 'styled-components';
import { Typography as MuiTypography } from '@mui/material';

export const Typography = styled(MuiTypography)`
  ${({ theme }) => css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: ${theme.palette.common.black};
  `}
`;
