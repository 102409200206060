import { getIconBaseProps } from './utils';

export const completed = {
  ...getIconBaseProps('completed'),
  width: '90px',
  height: '69px',
  viewBox: '0 0 90 69',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M86.8405 25.2723C88.0743 25.2723 89.0745 24.2665 89.0745 23.0258C89.0745 21.7851 88.0743 20.7794 86.8405 20.7794C85.6067 20.7794 84.6064 21.7851 84.6064 23.0258C84.6064 24.2665 85.6067 25.2723 86.8405 25.2723Z'
        fill='#FFA954'
      />
      <path
        d='M68.902 47.1348L66.9472 43.1642C66.735 42.7318 66.735 42.2432 66.9472 41.8107L68.902 37.8401C69.9911 35.6274 69.1254 32.9878 66.9416 31.8365L63.0097 29.7641C62.5853 29.5395 62.2948 29.1407 62.211 28.669L61.4459 24.3165C61.0158 21.8903 58.7595 20.2616 56.3076 20.6042L51.901 21.222C51.4262 21.2894 50.9571 21.1378 50.6108 20.8064L47.3882 17.6894C45.6121 15.9765 42.8419 16.0215 41.0659 17.7344L37.8656 20.8064C37.5193 21.1378 37.0502 21.2894 36.5754 21.222L32.1688 20.6042C29.7169 20.2616 27.4605 21.8903 27.0361 24.3165L26.2653 28.669C26.1816 29.1407 25.8911 29.5395 25.4667 29.7641L21.5347 31.8365C19.351 32.9878 18.4853 35.6274 19.5744 37.8401L21.5292 41.8107C21.7414 42.2432 21.7414 42.7318 21.5292 43.1642L19.5744 47.1348C18.4853 49.3476 19.351 51.9816 21.5347 53.1385L25.4667 55.2108C25.8911 55.4355 26.1816 55.8342 26.2653 56.3004L27.0361 60.6585C27.4605 63.0846 29.7169 64.7133 32.1688 64.3707L36.5754 63.7473C37.0502 63.68 37.5193 63.8372 37.8656 64.1686L41.0659 67.2406C41.9539 68.0998 43.0988 68.5267 44.2382 68.5267C45.3831 68.5267 46.5225 68.0998 47.4105 67.2406L50.6108 64.1686C50.9571 63.8372 51.4262 63.68 51.901 63.7473L56.3076 64.3707C56.5254 64.3988 56.7432 64.4157 56.9611 64.4157C59.1504 64.4157 61.0549 62.8656 61.4459 60.6585L62.211 56.3004C62.2948 55.8342 62.5853 55.4355 63.0097 55.2108L66.9416 53.1385C69.1254 51.9816 69.9911 49.3476 68.902 47.1348Z'
        fill='#D9E7E5'
      />
      <path
        d='M36.7949 43.2699L42.156 48.631L52.8783 37.9088'
        stroke='#2AA074'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.91129 58.829C6.1456 58.829 7.14533 57.8237 7.14533 56.5825C7.14533 55.3413 6.1456 54.3361 4.91129 54.3361C3.67698 54.3361 2.67725 55.3413 2.67725 56.5825C2.67725 57.8237 3.67698 58.829 4.91129 58.829Z'
        fill='#FFA954'
      />
      <path
        d='M55.4562 4.49291C56.6905 4.49291 57.6903 3.48762 57.6903 2.24645C57.6903 1.00529 56.6905 0 55.4562 0C54.2219 0 53.2222 1.00529 53.2222 2.24645C53.2222 3.48762 54.2219 4.49291 55.4562 4.49291Z'
        fill='black'
      />
      <path
        d='M88.6094 54.2238L85.4594 53.0725L83.5493 58.3461L86.6993 59.503L88.6094 54.2238Z'
        fill='#7648F8'
      />
      <path
        d='M23.8654 12.0749C25.0997 12.0749 26.0994 11.0696 26.0994 9.82842C26.0994 8.58726 25.0997 7.58197 23.8654 7.58197C22.6311 7.58197 21.6313 8.58726 21.6313 9.82842C21.6313 11.0696 22.6311 12.0749 23.8654 12.0749Z'
        fill='#7648F8'
      />
      <path
        d='M3.2999 21.4815L0.149902 22.6328L2.06001 27.912L5.21 26.7551L3.2999 21.4815Z'
        fill='#2AA074'
      />
    </>
  ),
};
