import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  TInstrumentEntity,
  TOrderMetricEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import { getLimitPrice } from '@trader/utils';

import { useTriggerTradingFormFields } from './useTriggerTradingFormFields';
import { TCommonOrderType } from '@trader/types';

export const useUpdatePricesInRealTime = (type: TCommonOrderType) => {
  const store = useMst();
  const { setValue, formState, getValues } = useFormContext();
  const { triggerAllPriceRelatedFields, triggerPriceField } =
    useTriggerTradingFormFields();

  const modal = store.ui.modal;

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const isPriceTouched = formState.touchedFields.price;
  const isPendingOrder = getValues().orderType !== 'Market';

  const orderId = type === 'modifyOrder' && modal.options.get('orderId');
  const positionId = type === 'modifyOrder' && modal.options.get('positionId');

  const orderMetric =
    store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
  const positionMetric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const price =
    orderMetric?.stopPrice ||
    orderMetric?.limitPrice ||
    positionMetric?.openPrice ||
    undefined;

  useEffect(() => {
    if (!isPriceTouched) {
      setValue(
        'price',
        price?.toString() ||
          getLimitPrice(
            instrument.stopsLevel,
            instrument.tickSize,
            instrument.pipSize,
            instrument.bid,
            instrument.ask,
            getValues().side
          )
      );
    }
  }, [price, getValues().side, instrument.bid, instrument.ask, isPriceTouched]);

  useEffect(() => {
    setValue('ask', instrument.ask);
    isPendingOrder ? triggerPriceField() : triggerAllPriceRelatedFields();
  }, [isPendingOrder, instrument.ask]);

  useEffect(() => {
    setValue('bid', instrument.bid);
    isPendingOrder ? triggerPriceField() : triggerAllPriceRelatedFields();
  }, [isPendingOrder, instrument.bid]);

  useEffect(() => {
    isPendingOrder && triggerAllPriceRelatedFields();
  }, [getValues().price]);
};
