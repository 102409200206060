import { getIconBaseProps } from './utils';

export const filledCircleCheckmark = {
  ...getIconBaseProps('filledCircleCheckmark'),
  width: '52px',
  height: '52px',
  viewBox: '0 0 52 52',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle cx='26' cy='26' r='26' fill='#E8FAF9' />
      <path
        d='M37.2693 18.0445C36.538 17.3122 35.3504 17.3127 34.6181 18.0445L22.6862 29.9769L17.3829 24.6737C16.6507 23.9415 15.4636 23.9415 14.7313 24.6737C13.9991 25.406 13.9991 26.5931 14.7313 27.3253L21.3601 33.9541C21.726 34.32 22.2058 34.5034 22.6857 34.5034C23.1655 34.5034 23.6458 34.3205 24.0117 33.9541L37.2693 20.6961C38.0016 19.9643 38.0016 18.7768 37.2693 18.0445Z'
        fill='#26A69A'
      />
    </>
  ),
};
